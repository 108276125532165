import T, { translate } from '@lang/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useToast } from '@hooks/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';

interface ToggleOrganisationActiveProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	organisationId: number;
	active: boolean;
	onSuccess?: () => void;
}

const ToggleOrganisationActive: React.FC<ToggleOrganisationActiveProps> = ({
	open,
	setOpen,
	organisationId,
	active,
	onSuccess,
}) => {
	// ---------------------
	// State / Hooks
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [errorMsg, setErrorMsg] = useState<string>(
		translate('error_generic')
	);

	// --------------------
	// Mutation
	const updateOrganisation = useMutation(api.sa.organisations.updateSingle, {
		onSuccess: () => {
			// invalidate organisations
			queryClient.invalidateQueries(['sa.organisations.getMultiple']);
			// cb
			if (onSuccess !== undefined) onSuccess();
			// toast
			addToast({
				title: translate('toast_updated_title', {
					name: 'Organisation',
				}),
				message: translate('toast_updated_message', {
					name: 'Organisation',
				}),
				type: 'success',
			});
		},
		onError: (error: AxiosAPIError) => {
			setErrorMsg(
				error.response?.data.message || translate('error_generic')
			);
		},
	});

	// --------------------
	// Functions
	const onConfirm = async () => {
		await updateOrganisation.mutateAsync({
			id: organisationId,
			body: {
				active: active ? 0 : 1,
			},
		});
	};

	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			onClose={() => {
				setErrorMsg('');
				updateOrganisation.reset();
			}}
			title={`${
				active
					? T.modals.toggle_org_active.active_title
					: T.modals.toggle_org_active.inactive_title
			}`}
			onConfirm={onConfirm}
			formAction={{
				loading: {
					is: updateOrganisation.isLoading,
					message: translate('updating'),
				},
				error: {
					is: updateOrganisation.isError,
					message: errorMsg,
				},
			}}>
			<div>
				<p>
					{active
						? T.modals.toggle_org_active.active_body
						: T.modals.toggle_org_active.inactive_body}
				</p>
			</div>
		</ConfirmationModal>
	);
};

export default ToggleOrganisationActive;
