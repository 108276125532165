interface GradientBackgroundProps {
	children?: React.ReactNode;
}

const GradientBackground: React.FC<GradientBackgroundProps> = ({
	children,
}) => {
	return (
		<span
			className={
				'absolute top-0 left-0 right-0 bottom-0 flex z-0 bg-brandPrimary'
			}>
			<span className={'block w-[30%] h-full gradient-bg-1-1'}></span>
			<span className={'block w-[10%] h-full gradient-bg-1-2'}></span>
			<span className={'block w-[30%] h-full gradient-bg-1-3'}></span>
			<span className={'block w-[30%] h-full gradient-bg-1-4'}></span>
			{children}
		</span>
	);
};

export default GradientBackground;
