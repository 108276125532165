import T, { translate } from '@lang/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useToast } from '@hooks/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';

interface SyncVideoProps {
	videoId: number;

	open: boolean;
	setOpen: (state: boolean) => void;
	onSuccess?: () => void;
}

const SyncVideo: React.FC<SyncVideoProps> = ({
	videoId,

	open,
	setOpen,
	onSuccess,
}) => {
	// ---------------------
	// State / Hooks
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [errorMsg, setErrorMsg] = useState<string>(
		translate('error_generic')
	);

	// --------------------
	// Mutation
	const syncVideo = useMutation(api.sa.videos.syncVideo, {
		onSuccess: () => {
			queryClient.invalidateQueries(['sa.videos.getMultiple']);
			if (onSuccess !== undefined) onSuccess();
			addToast({
				title: translate('toast_video_synced_title'),
				message: translate('toast_video_synced_message'),
				type: 'success',
			});
		},
		onError: (error: AxiosAPIError) => {
			setErrorMsg(
				error.response?.data.message || translate('error_generic')
			);
		},
	});

	// --------------------
	// Functions
	const onConfirm = async () => {
		await syncVideo.mutateAsync({
			id: videoId,
		});
	};

	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			title={T.modals.sync_video.title}
			onClose={() => {
				setErrorMsg('');
				syncVideo.reset();
			}}
			onConfirm={onConfirm}
			type={'warning'}
			formAction={{
				loading: {
					is: syncVideo.isLoading,
					message: translate('deleting'),
				},
				error: {
					is: syncVideo.isError,
					message: errorMsg,
				},
			}}>
			<div>
				<p>{T.modals.sync_video.body}</p>
			</div>
		</ConfirmationModal>
	);
};

export default SyncVideo;
