import { useState, useMemo } from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
// Components
import AlertMessage from '@components/partials/AlertMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CopyAlertProps {
	text: string;
	copy: string;
}

const CopyAlert: React.FC<CopyAlertProps> = ({ text, copy }) => {
	// -------------------------------------------------
	// State / Hooks
	const [copiedURL, setCopiedURL] = useState(false);
	const [copiedTimeout, setCopiedTimeout] = useState<NodeJS.Timeout | null>();

	// -------------------------------------------------
	// Memos
	const copyLoginURLText = useMemo(() => {
		if (copiedURL) return 'Copied!';
		return copy;
	}, [copiedURL, copy]);

	// -------------------------------------------------
	// Render
	return (
		<AlertMessage
			type={'info'}
			onClick={() => {
				navigator.clipboard.writeText(copy);
				setCopiedURL(true);
				if (copiedTimeout !== null) clearTimeout(copiedTimeout);

				setCopiedTimeout(
					setTimeout(() => {
						setCopiedURL(false);
					}, 3000)
				);
			}}>
			<div className="flex w-full flex-col">
				<div className="flex w-full items-center overflow-hidden">
					<FontAwesomeIcon
						icon={faCopy}
						className="mr-2 text-title"
					/>
					<p className="break-words text-sm font-semibold text-title">
						{copyLoginURLText}
					</p>
				</div>
				<p className="mt-1 text-sm text-body">{text}</p>
			</div>
		</AlertMessage>
	);
};

export default CopyAlert;
