import { useMemo } from 'react';
// Assets
import placeholderCourseImg from '@assets/images/placeholder-course.jpg';
import placeholderQuiz from '@assets/images/placeholder-quiz.jpg';
import placeholderVideo from '@assets/images/placeholder-video.jpg';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Badges from '@components/partials/Badges';
import { Link } from 'react-router-dom';
// Actions
import BookmarkButtonAction from '@components/actions/BookmarkButton';

interface BookmarkRowProps {
	bookmark: BookmarkesRes;
}

const BookmarkRow: React.FC<BookmarkRowProps> = ({ bookmark }) => {
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Memos
	const placeholderValue = useMemo(() => {
		switch (bookmark.bookmarkable_type) {
			case 'course':
				return placeholderCourseImg;
			case 'quiz':
				return placeholderQuiz;
			case 'video':
				return placeholderVideo;
			default:
				return placeholderVideo;
		}
	}, [bookmark.bookmarkable_type]);

	const bookmarkLink = useMemo(() => {
		switch (bookmark.bookmarkable_type) {
			case 'course':
				return `/${organisation?.slug}/courses/${bookmark.course_id}/redirect`;
			case 'quiz':
				return `/${organisation?.slug}/courses/${bookmark.course_id}}/assessment/${bookmark.bookmarkable_id}`;
			case 'video':
				return `/${organisation?.slug}/courses/${bookmark.course_id}/video/${bookmark.bookmarkable_id}`;
			default:
				return `/${organisation?.slug}/courses/${bookmark.course_id}/redirect`;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// -----------------------------
	// Render
	return (
		<tr>
			<td className="td-default">
				<Link
					to={bookmarkLink}
					className="flex items-center text-sm font-semibold hover:underline">
					<div className="relative mr-2.5 w-24 after:block after:pt-[56%]">
						<div className="absolute inset-0">
							<img
								src={
									bookmark.bookmarkable?.thumbnail ||
									placeholderValue
								}
								className="block h-full w-full object-cover"
								alt={bookmark.bookmarkable?.title}
								loading="lazy"
							/>
						</div>
					</div>
					{bookmark.bookmarkable?.title}
				</Link>
			</td>
			<td className="td-default">
				<Badges theme="green">{bookmark.bookmarkable_type}</Badges>
			</td>
			<td className="td-default text-right">
				<div className="flex justify-end">
					<BookmarkButtonAction
						courseId={bookmark.course_id}
						bookmarkable_id={bookmark.bookmarkable_id}
						bookmark={bookmark}
						type={
							bookmark.bookmarkable_type as
								| 'course'
								| 'quiz'
								| 'video'
						}
						simplified={true}
					/>
				</div>
			</td>
		</tr>
	);
};

export default BookmarkRow;
