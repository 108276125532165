import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
// Hooks
import { ProvideOrganisation } from '@hooks/useOrganisation';
import { ProvideAuth } from '@hooks/useAuth';
// Utils
import { AxiosInterceptor } from '@utils/index';

const App = () => {
	// --------------------------------------
	// Render
	return (
		<ProvideOrganisation>
			<ProvideAuth>
				<AxiosInterceptor>
					<Outlet />
					<Toaster position="bottom-right" reverseOrder={true} />
				</AxiosInterceptor>
			</ProvideAuth>
		</ProvideOrganisation>
	);
};

export default App;
