import { translate } from '@lang/index';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Component
import NavigationLink from '@components/groups/navigation/NavigationLink';

interface SubscriptionsLinkProps {
	urlMode: 'superadmin' | 'organisation';
}

const SubscriptionsLink: React.FC<SubscriptionsLinkProps> = ({ urlMode }) => {
	const { organisation } = useOrganisation();

	// --------------------------------------
	// Queries / Mutations
	const billingPortal = useQuery(
		['organisations.current.getBillingPortal'],
		() => {
			return api.organisations.current.getBillingPortal({
				id: organisation?.id as number,
			});
		},
		{
			enabled: urlMode === 'organisation',
		}
	);
	const saOptions = useQuery(
		['sa.options.getOptions'],
		() => {
			return api.sa.options.getOptions();
		},
		{
			enabled: urlMode === 'superadmin',
		}
	);

	// --------------------------------------
	// Memos
	const target = useMemo(() => {
		if (urlMode === 'superadmin')
			return saOptions.data?.data.stripe_url as string;
		if (urlMode === 'organisation')
			return billingPortal.data?.data.url as string;
		return '';
	}, [urlMode, billingPortal, saOptions]);

	// --------------------------------------
	// Render
	return (
		<NavigationLink
			to={target}
			label={translate('subscriptions')}
			icon={faCreditCard}
			permission={urlMode === 'superadmin' || urlMode === 'organisation'}
			mode="external"
		/>
	);
};

export default SubscriptionsLink;
