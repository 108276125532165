import T from '@lang/index';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Modal from '@components/modal/Modal';
import MessageBlock from '@components/blocks/MessageBlock';
import Date from '@components/partials/Date';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

interface ListCourseNominationsProps {
	courseId: number;
	mode: 'team' | 'user';
	open: boolean;
	setOpen: (state: boolean) => void;
}

const ListCourseNominations: React.FC<ListCourseNominationsProps> = ({
	courseId,
	mode,
	open,
	setOpen,
}) => {
	// -------------------------------------------------
	// State
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries / Mutations
	const course = useQuery(
		['organisations.courses.getSingle.admin', courseId],
		() => {
			return api.organisations.courses.getSingle({
				courseId: courseId,
				organisationId: organisation?.id as number,
				include: {
					items: false,
					itemsCount: false,
					itemsModel: false,
					itemsModelType: false,
					media: false,
					mediaCount: false,
					type: false,
					typeCount: false,
					teams: true,
					users: true,
					meta: false,
				},
			});
		},
		{
			enabled: organisation?.id !== undefined,
		}
	);

	// -------------------------------------------------
	// Memos
	const items = useMemo(() => {
		if (mode === 'team') {
			return course.data?.data.data.teams;
		} else {
			return course.data?.data.data.users;
		}
	}, [course.data, mode]);

	// -------------------------------------------------
	// Render
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.course_nominations.title}
			isLoading={course.isLoading}
			isError={course.isError}>
			<ul>
				{items?.map((item, index) => (
					<li key={index} className="border-b border-border">
						<Link
							to={
								mode === 'team'
									? `/${organisation?.slug}/teams/${item.id}`
									: `/${organisation?.slug}/users/${item.id}`
							}
							className="flex items-center justify-between rounded-md p-2.5 hover:bg-uiLight">
							<div className="flex flex-col">
								<h3 className="font-semibold text-title">
									{item.name}
								</h3>
								<span
									className="text-body"
									title="Date nominated">
									<Date
										date={
											mode === 'team'
												? (item as CourseResTeams)
														.course_team_created_at
												: (item as CourseResUsers)
														.course_user_created_at
										}
									/>
								</span>
							</div>
							<div>
								<FontAwesomeIcon
									icon={faLink}
									className="text-primary"
								/>
							</div>
						</Link>
					</li>
				))}
			</ul>
			{items?.length === 0 && (
				<MessageBlock
					title="No nominations"
					message={`There are no ${mode} nominations for this course`}
				/>
			)}
		</Modal>
	);
};

export default ListCourseNominations;
