import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Button from '@components/partials/Button';
import Input from '@components/forms/Input';
import Select from '@components/forms/Select';
import Form from '@components/forms/Form';
import FormAction from '@components/forms/FormAction';
import Textarea from '@components/forms/Textarea';
import FormColumns from '@components/blocks/FormColumns';

interface UpdateVideoActionProps extends ActionProps {
	video: SaVideosRes;
	videoTypes: SaCategoriesRes[];
}

const UpdateVideoAction: React.FC<UpdateVideoActionProps> = ({
	video,
	videoTypes,
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();

	const [title, setTitle] = useState(video.title || '');
	const [description, setDescription] = useState(video.desc || '');
	const [syncId, setSyncId] = useState(video.sync_id || '');
	const [ref, setRef] = useState(video.ref || '');
	const [typeId, setTypeId] = useState(video.type_id || 1);

	// --------------------------------------
	// Queries / Mutations
	const syncVideo = useMutation(api.sa.videos.syncVideo, {
		onError: () => {
			callback(true);
		},
	});
	const updateVideo = useMutation(api.sa.videos.updateSingle, {
		onSuccess: async () => {
			if (syncId !== video.sync_id) {
				await syncVideo.mutateAsync({
					id: video.id,
				});
			}

			addToast({
				title: translate('toast_updated_title', {
					name: 'video',
				}),
				message: translate('toast_updated_message', {
					name: 'video',
				}),
				type: 'success',
			});
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await updateVideo.mutateAsync({
			id: video.id,
			body: returnModifiedData([
				{
					key: 'title',
					state: title,
					defaultState: video.title,
				},
				{
					key: 'desc',
					state: description,
					defaultState: video.desc,
				},
				{
					key: 'sync_id',
					state: syncId,
					defaultState: video.sync_id,
				},
				{
					key: 'ref',
					state: ref,
					defaultState: video.ref,
				},
				{
					key: 'type_id',
					state: typeId,
					defaultState: video.type_id,
				},
			]),
		});
	};

	// --------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<FormColumns>
				<Select
					label={translate('video_type')}
					value={
						videoTypes.find((type) => type.id === typeId)?.name ||
						''
					}
					setValue={(state) => {
						const type = videoTypes.find(
							(type) => type.name === state
						);
						setTypeId(type?.id || 1);
					}}
					errors={formState.errors.value['type_id']}
					options={
						videoTypes.map((type) => {
							return {
								value: type.name,
								label: `${type.name} - ${type.id}`,
							};
						}) || []
					}
				/>
				<Input
					id="ref"
					type="text"
					label={translate('ref')}
					name="ref"
					value={ref}
					setValue={setRef}
					errors={formState.errors.value['ref']}
					required={false}
				/>
			</FormColumns>
			<Input
				value={title}
				setValue={setTitle}
				name="title"
				label={translate('title')}
				id="title"
				type="text"
				errors={formState.errors.value['title']}
				required={true}
			/>
			<Input
				value={syncId}
				setValue={setSyncId}
				name="sync_id"
				label={translate('sync_id')}
				id="sync_id"
				type="text"
				errors={formState.errors.value['sync_id']}
				required={false}
			/>
			<Textarea
				value={description}
				setValue={setDescription}
				name="desc"
				label={translate('description')}
				id="desc"
				errors={formState.errors.value['desc']}
				required={false}
			/>
			{/* Update */}
			<FormAction
				loading={{
					is: updateVideo.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateVideo.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateVideoAction;
