import { translate } from '@lang/index';
import type { Item } from '@components/groups/course-builder/Container';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
// Utils
import { helpers } from '@utils/index';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
// Components
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
// Group
import CourseBuilderContainer from '@components/groups/course-builder/Container';
import CourseBuilderDetails from '@components/groups/course-builder/Details';
import SubmitBar from '@components/forms/SubmitBar';

interface CreateCourseProps {
	tab: 'details' | 'builder';
	setTab: React.Dispatch<React.SetStateAction<'details' | 'builder'>>;
}

const CreateCourseAction: React.FC<CreateCourseProps> = ({ tab, setTab }) => {
	// -------------------------------------------
	// State & Hooks
	const formState = useFormState();
	const navigate = useNavigate();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const location = useLocation();

	const [selectedItems, setSelectedItems] = useState<Item[]>([]);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [courseType, setCourseType] = useState<SaCourseTypesRes['id']>(1);
	const [ref, setRef] = useState('');
	const [selectedCategories, setSelectedCategories] = useState<
		Array<SaCategoriesRes>
	>([]);
	const [image, setImage] = useState<File | undefined | null>(undefined);
	const [file, setFile] = useState<File | undefined | null>(undefined);
	const [slug, setSlug] = useState('');
	const [isPublic, setIsPublic] = useState(true);
	const [stripeProductId, setStripeProductId] = useState('');
	const [popular, setPopular] = useState(false);
	const [durationText, setDurationText] = useState('');
	const [aboutText, setAboutText] = useState('');
	const [completionAbilities, setCompletionAbilities] = useState('');

	// -------------------------------------------
	// Queries & Mutations
	const courseTypes = useQuery(['sa.courseTypes.get'], () => {
		return api.sa.courseTypes.get();
	});
	const createCourse = useMutation(api.sa.courses.createSingle, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(['sa.courses.getMultiple']);
			addToast({
				title: translate('toast_created_title', {
					name: 'Course',
				}),
				message: translate('toast_created_message', {
					name: 'Course',
				}),
				type: 'success',
			});
			navigate(`/courses/${data.data.data.id}/details`);
		},
		onError: (error: AxiosAPIError) => {
			setTab('details');
			helpers.mutationErrorHandler(error, formState);
		},
	});

	// -------------------------------------------
	// Functions
	const handleSubmit = async () => {
		formState.errors.setErrors({});
		formState.errorMessage.setErrorMessage('');
		await createCourse.mutateAsync({
			course_type_id: courseType,
			title: title,
			desc: description,
			ref: ref,
			category_ids: selectedCategories.map((category) => category.id),
			image: image,
			items: selectedItems.map((item, index) => ({
				model_id: item.data.id,
				model_type: item.type,
				sort: index,
			})),
			file: file,
			slug: slug || null,
			public: isPublic ? 1 : 0,
			stripe_product_id: stripeProductId || null,
			popular_at: popular ? new Date().toISOString() : null,
			meta: {
				duration_text: durationText,
				about_text: aboutText,
				completion_abilities: completionAbilities,
			},
		});
	};
	const setCourseTypeOnLoad = () => {
		const searchParams = new URLSearchParams(location.search);
		const typeP = searchParams.get('type');
		if (typeP === 'content') setCourseType(2);
		else if (typeP === 'course') setCourseType(1);
		else return;
	};

	// -------------------------------------------
	// Effects
	useEffect(() => {
		setCourseTypeOnLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItems]);

	// -------------------------------------------
	// Render
	if (courseTypes.isLoading) return <Loading type="page" />;
	if (courseTypes.isError) return <Error type="page" />;

	return (
		<>
			{tab === 'details' && (
				<CourseBuilderDetails
					state={{
						title,
						description,
						ref,
						selectedCategories,
						image,
						formState,
						courseType,
						courseTypes: courseTypes.data?.data.data,
						file,
						slug,
						isPublic,
						stripeProductId,
						popular,
						durationText,
						aboutText,
						completionAbilities,
					}}
					mutate={{
						setTitle,
						setDescription,
						setRef,
						setSelectedCategories,
						setImage,
						setCourseType,
						setFile,
						setSlug,
						setIsPublic,
						setStripeProductId,
						setPopular,
						setDurationText,
						setAboutText,
						setCompletionAbilities,
					}}
				/>
			)}
			{tab === 'builder' && (
				<CourseBuilderContainer
					selectedItems={selectedItems}
					setSelectedItems={setSelectedItems}
					mode="create"
				/>
			)}
			<SubmitBar
				onSubmit={handleSubmit}
				buttonText={translate('create')}
				loading={{
					isLoading: createCourse.isLoading,
					message: translate('creating'),
				}}
				error={{
					isError: createCourse.isError,
					message: formState.errorMessage.value,
				}}>
				<p className="mb-1 text-sm text-uiDark">
					{selectedItems.length || 0} {translate('items_selected')}
				</p>
			</SubmitBar>
		</>
	);
};

export default CreateCourseAction;
