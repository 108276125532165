import { translate } from '@lang/index';
import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import packageJson from '@root/../package.json';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Textarea from '@components/forms/Textarea';
import Button from '@components/partials/Button';
import FormActionModal from '@components/forms/FormActionModal';
// Queries
import { useAuthenticatedUser } from '@queries/user';

interface ReportBugActionProps extends ActionProps {}

const ReportBugAction: React.FC<ReportBugActionProps> = ({ callback }) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();

	const [message, setMessage] = useState('');

	// --------------------------------------
	// Queries / Mutations
	const user = useAuthenticatedUser();
	const status = useQuery(['root.status'], () => {
		return api.root.status();
	});
	const sendEnquiry = useMutation(api.public.sendEnquiry, {
		onSuccess: () => {
			addToast({
				title: translate('toast_bug_report_title'),
				message: translate('toast_bug_report_message'),
				type: 'success',
			});
			resetForm();
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Effects
	useEffect(() => {
		if (user.isSuccess && status.isSuccess && !message) {
			setMessage(`Name: ${user.data?.data.data.name} (ID: ${user.data?.data.data.id})
APP: v${packageJson.version}
API: ${status.data?.data.version}

`);
		}
	}, [
		user.isSuccess,
		status.isSuccess,
		user.data?.data.data.name,
		user.data?.data.data.id,
		status.data?.data.version,
		message,
	]);

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await sendEnquiry.mutateAsync({
			body: {
				title: 'Mr',
				name: 'Bug Report',
				email: user.data?.data.data.email || '',
				message: message,
				last_name: '',
			},
		});
	};
	const resetForm = () => {
		setMessage('');
		sendEnquiry.reset();
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
	};

	// --------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isError={user.isError}
			isLoading={user.isLoading}>
			<Textarea
				id="message"
				label={translate('message')}
				name="message"
				value={message}
				setValue={setMessage}
				errors={formState.errors.value['message']}
				required={true}
				className={'h-60'}
				maxLength={500}
			/>
			<FormActionModal
				loading={{
					is: sendEnquiry.isLoading,
					message: translate('sending_message'),
				}}
				error={{
					is: sendEnquiry.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('report')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default ReportBugAction;
