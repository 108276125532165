import { translate } from '@lang/index';
import { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import NominatedCourses from '@components/partials/NominatedCourses';
import Button from '@components/partials/Button';
import FormAction from '@components/forms/FormAction';
import FormRow from '@components/forms/FormRow';

interface UpdateOrgUserCoursesActionProps extends ActionProps {
	user: UserRes;
	courses: CourseRes[];
	teams: TeamRes[];
	organisationId: number;
}

const UpdateOrgUserCoursesAction: React.FC<UpdateOrgUserCoursesActionProps> = ({
	user,
	courses,
	teams,
	organisationId,
	callback,
}) => {
	// -------------------------------------------------
	// State
	const formState = useFormState();
	const { addToast } = useToast();
	const [lock, setLock] = useState<boolean>(false);
	// Inputs
	const [selectedCourses, setSelectedCourses] = useState<number[]>([]);

	// -------------------------------------------------
	// Queries / Mutations
	const updateUser = useMutation(api.organisations.users.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Organisation',
				}),
				message: translate('toast_updated_message', {
					name: 'Organisation',
				}),
				type: 'success',
			});
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		await updateUser.mutateAsync({
			id: user.id,
			organisationId: organisationId,
			body: returnModifiedData([
				{
					key: 'course_ids',
					state: selectedCourses.map((id) => id),
					defaultState: user.courses?.map((course) => course.id),
				},
			]),
		});
	};
	const getLockedCourses = () => {
		const lockedCourses: number[] = [];

		user.teams?.forEach((team) => {
			const teamV = teams.find((t) => t.id === team.id);
			if (!teamV) return;
			if (!teamV.courses) return;

			teamV.courses.forEach((course) => {
				if (!lockedCourses.find((id) => id === course.id)) {
					lockedCourses.push(course.id);
				}
			});
		});
		return lockedCourses;
	};

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		if (!lock) {
			setSelectedCourses(user.courses?.map((course) => course.id) || []);
			setLock(true);
		}
	}, [user, lock]);

	// -------------------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<FormRow
				title={translate('nominated_courses')}
				description={translate('update_org_user_course_description')}>
				<NominatedCourses
					courses={courses}
					lockedCourses={getLockedCourses()}
					selectedCourses={selectedCourses}
					setSelectedCourses={setSelectedCourses}
				/>
			</FormRow>
			<FormAction
				loading={{
					is: updateUser.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateUser.isError,
					message: formState.errorMessage.value,
				}}>
				<Button theme="primary" type="submit">
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateOrgUserCoursesAction;
