import { useMemo } from 'react';
import { GoToT, IsItemLockedT } from '@routes/courses/single/View';
// Utils
import { helpers } from '@utils/index';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import ProgressBar from '@components/partials/ProgressBar';
import NavigationItemGroup from '@components/groups/single-course/NavigationItemGroup';
import Button from '@components/partials/Button';
import { Link } from 'react-router-dom';

interface SingleCourseNavigationProps {
	mode: 'preview' | 'standard';
	course: SaCourseRes | CourseRes;
	currentItem?: CourseItems;
	status: CourseStatusRes[];
	nextItem?: CourseItems;
	prevItem?: CourseItems;
	progress?: OrgCourseProgressRes;
	categories: SaCategoriesRes[] | CategoriesRes[];
	functions: {
		goTo: GoToT;
		isItemLocked: IsItemLockedT;
	};
}

const SingleCourseNavigation: React.FC<SingleCourseNavigationProps> = ({
	mode,
	course,
	status,
	nextItem,
	prevItem,
	progress,
	categories,
	functions: { goTo, isItemLocked },
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

	const navigationTree = useMemo(() => {
		return helpers.courseItemTree(course.items || []);
	}, [course.items]);

	const percent = useMemo(() => {
		if (!progress) return 0;
		return Math.round(parseFloat(progress.percentage));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [progress]);

	// -------------------------------------------------
	// Functions
	const openChapterGroup = (item: CourseItems) => {
		const currentItemIndex = course.items?.findIndex((courseItem) => {
			return (
				courseItem.model_id === item.model_id &&
				courseItem.model_type === item.model_type
			);
		});

		if (currentItemIndex === undefined || currentItemIndex === -1)
			return null;

		const tempItems = course.items?.slice(0, currentItemIndex + 1);
		// loop in reverse to find the chapter
		let chapterIndex = -1;
		for (let i = tempItems?.length || 0; i >= 0; i--) {
			const item = tempItems?.[i];
			if (!item) continue;
			if (item.model_type === 'chapter') {
				chapterIndex = i;
				break;
			}
		}
		if (chapterIndex === -1) return null;

		const chapterId = course.items?.[chapterIndex].model_id;
		if (!chapterId) return null;

		const chapterGroup = document.getElementById(`chapter-${chapterId}`);
		if (!chapterGroup) return null;

		// if aria-expanded is true, then the dropdown is open
		const isDropdownOpen = chapterGroup.getAttribute('aria-expanded');
		if (isDropdownOpen === 'true') return null;
		else {
			chapterGroup.click();
		}
	};
	const navigateToItemEvent = (item: CourseItems) => {
		if (isItemLocked(item)) return null;
		goTo(item);
		openChapterGroup(item);
	};

	// -------------------------------------------------
	// Render
	return (
		<>
			{/* Nav Sidebar */}
			<div className="border border-border xl:rounded-md">
				<ul className="p-5">
					{navigationTree.map((group, index) => (
						<NavigationItemGroup
							key={index}
							mode={mode}
							status={status}
							group={group}
							categories={categories}
							functions={{
								goTo,
							}}
						/>
					))}
				</ul>
			</div>
			{/* Bottom Nav bar */}
			<div
				className="fixed bottom-0 right-0 left-0 z-10 flex h-24 flex-col-reverse justify-center bg-uiLight px-5 md:px-7 lg:left-[300px] xs:h-16 xs:flex-row xs:items-center xs:justify-between"
				style={{
					boxShadow: '0px -4px 20px rgba(0, 0, 0, 0.15)',
				}}>
				<div>
					{mode === 'standard' && (
						<span className="flex items-center xs:mr-10">
							<span className="w-full xs:w-[100px]">
								<ProgressBar progress={percent} />
							</span>
							{percent === 100 ? (
								<Link
									to={`/${organisation?.slug}/courses/${course.id}/success`}
									className="ml-2 text-center text-xs font-semibold text-emerald-500 hover:underline">
									{percent}%
								</Link>
							) : (
								<span className="ml-2 text-center text-xs font-semibold">
									{percent}%
								</span>
							)}
						</span>
					)}
				</div>
				<div className="mb-2 grid grid-cols-2 gap-2.5 xs:mb-0 xs:flex">
					<Button
						theme="primary"
						type="button"
						className="xs:mr-2.5"
						disabled={isItemLocked(prevItem)}
						onClick={() => {
							if (!prevItem) return null;
							navigateToItemEvent(prevItem);
						}}>
						Previous
					</Button>
					<Button
						theme="primary"
						type="button"
						disabled={isItemLocked(nextItem)}
						onClick={() => {
							if (!nextItem) return null;
							navigateToItemEvent(nextItem);
						}}>
						Next
					</Button>
				</div>
			</div>
		</>
	);
};

export default SingleCourseNavigation;
