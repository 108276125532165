import { translate } from '@lang/index';
import { useMemo } from 'react';
import {
	faChalkboardUser,
	faBookOpenReader,
} from '@fortawesome/free-solid-svg-icons';
// Hooks
import { useOrganisation } from '@hooks/index';
// Groups
import ListWrapper from '@components/groups/navigation/ListWrapper';
import NavigationLink from '@components/groups/navigation/NavigationLink';

interface StandardListProps {
	permissions: {
		viewCourses: boolean;
		manageNotes: boolean;
	};
	setNavOpen?: (open: boolean) => void;
}

const StandardList: React.FC<StandardListProps> = ({
	permissions,
	setNavOpen,
}) => {
	// --------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

	// --------------------------------------
	// Memos
	const viewList = useMemo(() => {
		return permissions.viewCourses || permissions.manageNotes;
	}, [permissions.viewCourses, permissions.manageNotes]);

	// --------------------------------------
	// Render
	if (!viewList) return null;

	return (
		<ListWrapper>
			<NavigationLink
				to={`/${organisation?.slug}/courses`}
				label={translate('couses')}
				icon={faChalkboardUser}
				permission={permissions.viewCourses}
				setNavOpen={setNavOpen}
			/>
			<NavigationLink
				to={`/${organisation?.slug}/video-library`}
				label={translate('video_library')}
				icon={faBookOpenReader}
				permission={permissions.viewCourses}
				setNavOpen={setNavOpen}
			/>
		</ListWrapper>
	);
};

export default StandardList;
