import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Select from '@components/forms/Select';
import FormActionModal from '@components/forms/FormActionModal';
import Button from '@components/partials/Button';

interface CreateOrganisationActionProps extends ActionProps {}

const CreateOrganisationAction: React.FC<CreateOrganisationActionProps> = ({
	callback,
}) => {
	// --------------------------------------
	// State / Hooks
	const navigate = useNavigate();
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	const [name, setName] = useState('');
	const [active] = useState(true);
	const [planId, setPlanId] = useState<number | undefined>(undefined);

	// --------------------------------------
	// Queries / Mutations
	const createOrganisation = useMutation(api.sa.organisations.createSingle, {
		onSuccess: (data) => {
			// make invalidate any organisation queries
			resetForm();
			queryClient.invalidateQueries(['sa.organisations.getMultiple']);
			navigate(`/organisations/${data.data.data.id}`);
			addToast({
				title: translate('toast_created_title', {
					name: 'Organisation',
				}),
				message: translate('toast_created_message', {
					name: 'Organisation',
				}),
				type: 'success',
			});
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});
	const plans = useQuery(['sa.plans.getAll'], api.sa.plans.getAll);

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await createOrganisation.mutateAsync({
			name: name,
			active: active ? 1 : (0 as 0 | 1),
			plan_id: planId,
		});
	};
	const resetForm = () => {
		setName('');
		setPlanId(undefined);

		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
		createOrganisation.reset();
	};

	// --------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isLoading={plans.isLoading}
			isError={plans.isError}>
			<Input
				id="organisationName"
				type="text"
				label={translate('organisation_name')}
				name="name"
				value={name}
				setValue={setName}
				errors={formState.errors.value['name']}
				required={true}
			/>
			<Select
				label={translate('plan')}
				value={
					plans.data?.data.data.find((plan) => plan.id === planId)
						?.name || 'No plan'
				}
				setValue={(state) => {
					if (state === 'No plan') return setPlanId(undefined);
					const type = plans.data?.data.data.find(
						(type) => type.name === state
					);
					setPlanId(type?.id || 1);
				}}
				errors={formState.errors.value['type_id']}
				options={[
					{
						value: 'No plan',
						label: 'No plan',
					},
					...(plans.data?.data.data.map((type) => {
						return {
							value: type.name,
							label: `${type.name} - ${type.id}`,
						};
					}) || []),
				]}
			/>
			<FormActionModal
				loading={{
					is: createOrganisation.isLoading,
					message: translate('creating'),
				}}
				error={{
					is: createOrganisation.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('create')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default CreateOrganisationAction;
