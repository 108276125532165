import { translate } from '@lang/index';
// Components
import Checkbox from '@components/forms/Checkbox';
import NoData from '@components/partials/NoData';

interface NominatedCoursesProps {
	courses: CourseRes[];

	lockedCourses: Array<number>;
	selectedCourses: Array<number>;
	setSelectedCourses: (selectedCourses: Array<number>) => void;
}

const NominatedCourses: React.FC<NominatedCoursesProps> = ({
	courses,
	lockedCourses,
	selectedCourses,
	setSelectedCourses,
}) => {
	return (
		<>
			<ul>
				{courses.map((course) => {
					const checked = selectedCourses.includes(course.id);
					return (
						<li key={course.id}>
							<Checkbox
								name={course.title}
								label={course.title}
								checked={
									checked || lockedCourses.includes(course.id)
								}
								setChecked={(checked) => {
									if (checked) {
										setSelectedCourses([
											...selectedCourses,
											course.id,
										]);
									} else {
										setSelectedCourses(
											selectedCourses.filter(
												(id) => id !== course.id
											)
										);
									}
								}}
								errors={[]}
								disabled={lockedCourses.includes(course.id)}
							/>
						</li>
					);
				})}
			</ul>
			{courses.length === 0 && (
				<NoData message={translate('no_courses_assigned')} />
			)}
		</>
	);
};

export default NominatedCourses;
