import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, Navigate } from 'react-router-dom';
// API
import api from 'api';
// Components
import PageHeading from '@components/blocks/PageHeading';
import Breadcrumbs from '@components/partials/Breadcrumbs';
import HeadingTabs from '@components/partials/HeadingTabs';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
// Actions
import EditCourseAction from '@components/actions/EditCourse';

const CoursesEdit = () => {
	// -------------------------------------------------
	// State / Hooks
	const { id, tab } = useParams<{
		id: string;
		tab: 'details' | 'builder';
	}>();
	const navigate = useNavigate();

	// -------------------------------------------------
	// Query / Mutation
	const course = useQuery(['sa.courses.getSingle'], () => {
		return api.sa.courses.getSingle({
			id: id || '0',
			include: {
				categories: false,
				categoriesCount: false,
				items: false,
				itemsCount: false,
				itemsModel: false,
				media: false,
				mediaCount: false,
				organisations: false,
				organisationsCount: false,
				categoriesMeta: false,
				type: false,
				meta: false,
			},
		});
	});

	// -------------------------------------------------
	// Render
	if (tab !== 'details' && tab !== 'builder')
		return <Navigate to={`/courses/${id}/details`} />;

	if (course.isLoading) return <Loading type="page" />;
	if (course.isError) return <Error type="page" />;

	return (
		<>
			<PageHeading
				title={`${course.data.data.data.title}`}
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: '/courses',
								label: translate('courses'),
							},
							{
								href: `/courses/${id}/details`,
								label: translate('edit'),
							},
						]}
					/>
				}>
				<HeadingTabs
					activeKey={tab}
					tabs={[
						{
							name: translate('details'),
							key: 'details',
							onClick: () => {
								navigate(`/courses/${id}/details`);
							},
						},
						{
							name: translate('builder'),
							key: 'builder',
							onClick: () => {
								navigate(`/courses/${id}/builder`);
							},
						},
						{
							name: translate('progress'),
							key: 'progress',
							navigationWarning: true,
							onClick: () => {
								navigate(`/progress?filter[course_id]=${id}`);
							},
						},
						{
							name: translate('preview'),
							key: 'preview',
							navigationWarning: true,
							onClick: () => {
								navigate(`/courses/${id}/redirect`);
							},
						},
					]}
				/>
			</PageHeading>
			<EditCourseAction tab={tab} />
		</>
	);
};

export default CoursesEdit;
