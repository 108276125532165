import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import FormActionModal from '@components/forms/FormActionModal';
import Button from '@components/partials/Button';

interface CreateOrgTeamActionProps extends ActionProps {
	organisationSlug: string;
	organisationId?: number;
}

const CreateOrgTeamAction: React.FC<CreateOrgTeamActionProps> = ({
	callback,
	organisationSlug,
	organisationId,
}) => {
	// --------------------------------------
	// State / Hooks
	const navigate = useNavigate();
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [name, setName] = useState('');

	// --------------------------------------
	// Queries / Mutations
	const createTeam = useMutation(api.organisations.teams.createSingle, {
		onSuccess: (data) => {
			// make invalidate any organisation queries
			resetForm();
			queryClient.invalidateQueries(['organisations.teams.getMultiple']);
			navigate(`/${organisationSlug}/teams/${data.data.data.id}`);
			addToast({
				title: translate('toast_created_title', {
					name: 'Team',
				}),
				message: translate('toast_created_message', {
					name: 'Team',
				}),
				type: 'success',
			});
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await createTeam.mutateAsync({
			organisationId: organisationId || 0,
			body: {
				name: name,
			},
		});
	};
	const resetForm = () => {
		setName('');
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
		createTeam.reset();
	};

	// --------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<Input
				id="teamName"
				type="text"
				label={translate('name')}
				name="name"
				value={name}
				setValue={setName}
				errors={formState.errors.value['name']}
				required={true}
			/>
			<FormActionModal
				loading={{
					is: createTeam.isLoading,
					message: translate('creating'),
				}}
				error={{
					is: createTeam.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('create')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default CreateOrgTeamAction;
