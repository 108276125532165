import { translate } from '@lang/index';
import { AxiosResponse } from 'axios';
import type { Item } from '@components/groups/course-builder/Container';
import { useState, useEffect } from 'react';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
// Utils
import { helpers } from '@utils/index';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
import { useMutation } from '@tanstack/react-query';
// Components
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
// Group
import CourseBuilderContainer from '@components/groups/course-builder/Container';
import CourseBuilderDetails from '@components/groups/course-builder/Details';
import SubmitBar from '@components/forms/SubmitBar';

interface EditCourseActionProps {
	tab: 'details' | 'builder';
	setTab?: React.Dispatch<React.SetStateAction<'details' | 'builder'>>;
}

const EditCourseAction: React.FC<EditCourseActionProps> = ({ tab, setTab }) => {
	// -------------------------------------------
	// State & Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const { id } = useParams();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const [locked, setLocked] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const [courseTypes, setCourseTypes] = useState<SaCourseTypesRes[]>([]);
	const [quizTypes, setQuizTypes] = useState<SaQuizTypesRes[]>([]);
	const [videoTypes, setVideoTypes] = useState<SaVideoTypesRes[]>([]);
	const [course, setCourse] = useState<SaCourseRes | null>(null);

	const [selectedItems, setSelectedItems] = useState<Item[]>([]);
	const [courseType, setCourseType] = useState<SaCourseTypesRes['id']>(1);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [ref, setRef] = useState('');
	const [selectedCategories, setSelectedCategories] = useState<
		Array<SaCategoriesRes>
	>([]);

	const [image, setImage] = useState<File | undefined | null>(undefined);
	const [file, setFile] = useState<File | undefined | null>(undefined);
	const [slug, setSlug] = useState('');
	const [isPublic, setIsPublic] = useState(false);
	const [stripeProductId, setStripeProductId] = useState('');
	const [popular, setPopular] = useState(false);
	const [durationText, setDurationText] = useState('');
	const [aboutText, setAboutText] = useState('');
	const [completionAbilities, setCompletionAbilities] = useState('');

	const [clearFile, setClearFile] = useState(false);

	// -------------------------------------------
	// Queries & Mutations
	const updateCourse = useMutation(api.sa.courses.updateSingle, {
		onSuccess: (data) => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Course',
				}),
				message: translate('toast_updated_message', {
					name: 'Course',
				}),
				type: 'success',
			});

			queryClient.invalidateQueries(['sa.courses.getMultiple']);
			queryClient.invalidateQueries(['sa.courses.getSingle']);
			queryClient.invalidateQueries(['sa.quizTypes.get']);
			queryClient.invalidateQueries(['sa.courseTypes.get']);

			setCourseData(data.data.data, quizTypes, videoTypes);
		},
		onError: (error: AxiosAPIError) => {
			if (setTab) setTab('details');
			else navigate(`/courses/${id}/details`);
			helpers.mutationErrorHandler(error, formState);
		},
	});

	const queryRes = useQueries({
		queries: [
			{
				queryKey: ['sa.categories.getAll.quizType'],
				queryFn: () =>
					api.sa.categories.getAll({
						include: {
							meta: false,
							metaCount: false,
						},
						filters: [
							{
								key: 'scope',
								value: 'quizType',
							},
						],
					}),
			},
			{
				queryKey: ['sa.courses.getSingle', id],
				queryFn: () => {
					if (!id) {
						return Promise.reject(translate('error_generic'));
					}
					return api.sa.courses.getSingle({
						id: id,
						include: {
							categories: true,
							categoriesCount: true,
							items: true,
							itemsCount: true,
							itemsModel: true,
							media: true,
							mediaCount: true,
							organisations: false,
							organisationsCount: false,
							categoriesMeta: false,
							type: false,
							meta: true,
						},
					});
				},
			},
			{
				queryKey: ['sa.courseTypes.get'],
				queryFn: () => api.sa.courseTypes.get(),
			},
			{
				queryKey: ['sa.categories.getAll.videoType'],
				queryFn: () =>
					api.sa.categories.getAll({
						include: {
							meta: false,
							metaCount: false,
						},
						filters: [
							{
								key: 'scope',
								value: 'videoType',
							},
						],
					}),
			},
		],
	});
	const allFinished = queryRes.every((query) => query.isSuccess);
	const anyError = queryRes.some((query) => query.isError);

	// -------------------------------------------
	// Effects
	useEffect(() => {
		if (allFinished) {
			const quizTypesRes = queryRes[0].data as AxiosResponse<
				APIResponse<SaQuizTypesRes[]>
			>;
			const courseRes = queryRes[1].data as AxiosResponse<
				APIResponse<SaCourseRes>
			>;
			const courseTypesRes = queryRes[2].data as AxiosResponse<
				APIResponse<SaCourseTypesRes[]>
			>;
			const videoTypesRes = queryRes[3].data as AxiosResponse<
				APIResponse<SaVideoTypesRes[]>
			>;

			if (!locked) {
				setLocked(true);

				setCourseTypes(courseTypesRes.data.data);
				setQuizTypes(quizTypesRes.data.data);
				setVideoTypes(videoTypesRes.data.data);
				setCourseData(
					courseRes.data.data,
					quizTypesRes.data.data,
					videoTypesRes.data.data
				);

				setIsLoading(false);
			}
		}
	}, [allFinished, locked, queryRes]);

	// -------------------------------------------
	// Functions
	const handleSubmit = async () => {
		if (!course) return;
		formState.errors.setErrors({});
		formState.errorMessage.setErrorMessage('');

		const updatePopular = popular && course.popular_at ? true : false;

		const body = helpers.deepDiff(
			{
				title: course.title,
				desc: course.desc || '',
				course_type_id: course.course_type_id,
				ref: course.ref || '',
				category_ids: course.categories?.map((category) => category.id),
				items: course.items?.map((item) => ({
					model_id: item.model_id,
					model_type: item.model_type,
					sort: item.sort,
				})),
				slug: course.slug,
				public: course.public ? 1 : 0,
				stripe_product_id: course.stripe_product_id,
				popular_at: course.popular_at,
				meta: {
					duration_text: course?.meta?.duration_text || '',
					about_text: course?.meta?.about_text || '',
					completion_abilities:
						course?.meta?.completion_abilities || '',
				},
			},
			{
				title,
				desc: description,
				course_type_id: courseType,
				ref,
				category_ids: selectedCategories.map((category) => category.id),
				items: selectedItems.map((item, index) => ({
					model_id: item.data.id,
					model_type: item.type,
					sort: index,
				})),
				slug,
				public: isPublic ? 1 : 0,
				stripe_product_id: stripeProductId || null,
				popular_at: updatePopular ? new Date().toISOString() : null,
				meta: {
					duration_text: durationText || '',
					about_text: aboutText || '',
					completion_abilities: completionAbilities || '',
				},
			}
		);

		const fileBody = {
			image: image,
			file: file,
		};

		await updateCourse.mutateAsync({
			id: parseInt(id || '0') || 0,
			body: body,
			contentType: 'application/json',
		});

		if (image || file) {
			await updateCourse.mutateAsync({
				id: parseInt(id || '0') || 0,
				body: fileBody,
				contentType: 'multipart/form-data',
			});
		}
	};
	const setCourseData = (
		course: SaCourseRes,
		qt: SaQuizTypesRes[],
		vt: SaVideoTypesRes[]
	) => {
		if (!course.items) setSelectedItems([]);

		setCourse(course);

		setTitle(course.title);
		setDescription(course.desc || '');
		setCourseType(course.course_type_id);
		setRef(course.ref || '');
		setSelectedCategories(
			course.categories?.map((category) => category) || []
		);

		setSlug(course.slug);
		setIsPublic(course.public || false);
		setStripeProductId(course.stripe_product_id || '');
		setPopular(course.popular_at ? true : false);

		setDurationText(course.meta?.duration_text || '');
		setAboutText(course.meta?.about_text || '');
		setCompletionAbilities(course.meta?.completion_abilities || '');

		setImage(undefined);
		// setFile(undefined);
		setClearFile(false);

		const items =
			course.items?.map((item) => {
				let type = undefined;
				if (item.model_type === 'quiz') {
					type = qt.find((quizType) => {
						return quizType.id === item.model.type_id;
					});
				}
				if (item.model_type === 'video') {
					type = vt.find((videoType) => {
						return videoType.id === item.model.type_id;
					});
				}
				return {
					id: `${item.model_type}_${item.model_id}`,
					type: item.model_type,
					type_id: item.model.type_id,
					data: {
						id: item.model_id,
						hashid: '',
						title: item.model.title,
						desc: item.model.desc,
						type: type,
					},
				};
			}) || [];

		setSelectedItems(items);
	};

	// -------------------------------------------
	// Render
	if (!id) return null;
	if (isLoading) return <Loading type="page" />;
	if (anyError) return <Error type="page" />;

	return (
		<>
			{tab === 'details' && (
				<CourseBuilderDetails
					course={course || undefined}
					state={{
						title,
						description,
						ref,
						selectedCategories,
						image,
						formState,
						courseType,
						courseTypes: courseTypes || [],
						file,
						slug,
						isPublic,
						stripeProductId,
						popular,
						durationText,
						aboutText,
						completionAbilities,
						clearFile,
					}}
					mutate={{
						setTitle,
						setDescription,
						setRef,
						setSelectedCategories,
						setImage,
						setCourseType,
						setFile,
						setSlug,
						setIsPublic,
						setStripeProductId,
						setPopular,
						setDurationText,
						setAboutText,
						setCompletionAbilities,
						setClearFile,
					}}
				/>
			)}
			{tab === 'builder' && (
				<CourseBuilderContainer
					selectedItems={selectedItems}
					setSelectedItems={setSelectedItems}
					mode="edit"
				/>
			)}
			<SubmitBar
				onSubmit={handleSubmit}
				buttonText={translate('update')}
				loading={{
					isLoading: updateCourse.isLoading,
					message: translate('updating'),
				}}
				error={{
					isError: updateCourse.isError,
					message: formState.errorMessage.value,
				}}>
				<p className="mb-1 text-sm text-uiDark">
					{selectedItems.length || 0} {translate('items_selected')}
				</p>
			</SubmitBar>
		</>
	);
};

export default EditCourseAction;
