// Components
import DataList from '@components/partials/DataList';
import Badges from '@components/partials/Badges';

interface UserStatsUserSummaryProps {
	stats: {
		videosViewed: number;
		certificates: number;
		quizzesTaken: number;
		praticalQuizzesTaken: number;
		coursesInProgress: number;
		coursesCompleted: number;
	};
}

const UserStatsUserSummary: React.FC<UserStatsUserSummaryProps> = ({
	stats,
}) => {
	// -------------------------------------------------
	// Render
	return (
		<DataList
			items={[
				{
					label: 'Videos viewed',
					children: (
						<Badges theme="green">{stats.videosViewed || 0}</Badges>
					),
				},
				{
					label: 'Quizzes taken',
					children: (
						<Badges theme="green">{stats.quizzesTaken || 0}</Badges>
					),
				},
				{
					label: 'Practical exercises taken',
					children: (
						<Badges theme="green">
							{stats.praticalQuizzesTaken || 0}
						</Badges>
					),
				},
				{
					label: 'Courses in progress',
					children: (
						<Badges theme="green">
							{stats.coursesInProgress || 0}
						</Badges>
					),
				},
				{
					label: 'Courses completed',
					children: (
						<Badges theme="green">
							{stats.coursesCompleted || 0}
						</Badges>
					),
				},
				{
					label: 'Course certificates issued',
					children: (
						<Badges theme="green">{stats.certificates || 0}</Badges>
					),
				},
			]}
		/>
	);
};

export default UserStatsUserSummary;
