import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSelectedRow } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import Pagination from '@components/query/Pagination';
import QuizRow from '@components/rows/QuizRow';
import TopPagination from '@components/query/FormUtilityBar';
// Modals
import DeleteQuiz from '@components/modal/DeleteQuiz';

interface QuizTableProps extends QueryTableProps {
	utilityBar?: React.ReactNode;
}

const QuizTable: React.FC<QuizTableProps> = ({
	enabled,
	queryString,
	searchParams,
	utilityBar,
	resetFilters,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { selectedRow, setSelectedRow } = useSelectedRow<SaQuizzesRes | null>(
		null
	);

	// Modal State
	const [openDelete, setOpenDelete] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const quizzes = useQuery(
		['sa.quizzes.getMultiple', queryString],
		() => {
			return api.sa.quizzes.getMultiple({
				queryString: queryString,
				include: {
					type: true,
					typeCount: false,
					media: false,
					mediaCount: false,
				},
			});
		},
		{ enabled: enabled, keepPreviousData: true }
	);
	const categories = useQuery(['sa.categories.getMultiple.quiz'], () => {
		return api.sa.categories.getMultiple({
			include: {
				meta: true,
				metaCount: false,
			},
			filters: [
				{
					key: 'scope',
					value: 'quizType',
				},
			],
			perPage: -1,
		});
	});

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={quizzes.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: quizzes.isLoading || categories.isLoading,
					error: quizzes.isError || categories.isError,
					noData: quizzes.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Assessment',
					}),
					message: translate('no_data_message', {
						name: 'assessment',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('title'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'title'
							),
							type: 'sortable',
						},
						{
							label: translate('ref'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'ref'
							),
						},
						{
							label: translate('type'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'type_id'
							),
						},
						{
							label: translate('updated_at'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'updated_at'
							),
							type: 'sortable',
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{quizzes.data?.data.data.map((quiz, index) => (
						<QuizRow
							key={index}
							quiz={quiz}
							categories={categories.data?.data.data || []}
							selectRow={(quiz, action) => {
								setSelectedRow(quiz);
								switch (action) {
									case 'delete': {
										setOpenDelete(true);
										break;
									}
								}
							}}
						/>
					))}
				</Table>
				<Pagination
					meta={quizzes.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
				{selectedRow !== null && (
					<DeleteQuiz
						quizId={selectedRow.id}
						onSuccess={() => searchParams.page.setValue(1)}
						open={openDelete}
						setOpen={setOpenDelete}
					/>
				)}
			</DynamicBlock>
		</>
	);
};

export default QuizTable;
