import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import CreateVideoAction from '@components/actions/CreateVideo';

interface CreateVideoProps {
	open: boolean;
	setOpen: (state: boolean) => void;
}

const CreateVideo: React.FC<CreateVideoProps> = ({ open, setOpen }) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.create_video.title}>
			<CreateVideoAction
				callback={(success, data) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default CreateVideo;
