import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSelectedRow } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import VideoRow from '@components/rows/VideoRow';
import Pagination from '@components/query/Pagination';
import TopPagination from '@components/query/FormUtilityBar';
// Modals
import DeleteVideo from '@components/modal/DeleteVideo';

interface VideoTableProps extends QueryTableProps {
	utilityBar?: React.ReactNode;
}

const VideoTable: React.FC<VideoTableProps> = ({
	queryString,
	searchParams,
	utilityBar,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { selectedRow, setSelectedRow } = useSelectedRow<SaVideosRes | null>(
		null
	);

	// Modal State
	const [openDelete, setOpenDelete] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const videos = useQuery(
		['sa.videos.getMultiple', queryString],
		() => {
			return api.sa.videos.getMultiple({
				queryString,
				include: {
					value: false,
					media: true,
				},
			});
		},
		{
			enabled: enabled,
		}
	);

	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={videos.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: videos.isLoading,
					error: videos.isError,
					noData: videos.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Video',
					}),
					message: translate('no_data_message', {
						name: 'video',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('title'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'title'
							),
							type: 'sortable',
						},
						{
							label: translate('ref'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'ref'
							),
							type: 'sortable',
						},
						{
							label: translate('synced'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'sync_id'
							),
							type: 'sortable',
						},
						{
							label: translate('date_last_synced'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'synced_at'
							),
							type: 'sortable',
						},
						{
							label: translate('date_last_modified'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'updated_at'
							),
							type: 'sortable',
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{videos.data?.data.data.map((video, index) => (
						<VideoRow
							key={index}
							video={video}
							selectRow={(video, action) => {
								setSelectedRow(video);
								switch (action) {
									case 'delete': {
										setOpenDelete(true);
										break;
									}
								}
							}}
						/>
					))}
				</Table>
				<Pagination
					meta={videos.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
				{/* Modals */}
				{selectedRow !== null && (
					<DeleteVideo
						videoId={selectedRow.id}
						open={openDelete}
						setOpen={setOpenDelete}
					/>
				)}
			</DynamicBlock>
		</>
	);
};

export default VideoTable;
