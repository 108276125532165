import { translate } from '@lang/index';
import { useMemo } from 'react';
import {
	faUser,
	faAward,
	faPeopleGroup,
	faCog,
	faBook,
	faFolderClosed,
} from '@fortawesome/free-solid-svg-icons';
// Hooks
import { useOrganisation } from '@hooks/index';
// Groups
import ListWrapper from '@components/groups/navigation/ListWrapper';
import NavigationLink from '@components/groups/navigation/NavigationLink';

interface AdminListProps {
	permissions: {
		manageOrganisation: boolean;
		manageUsers: boolean;
		manageTeams: boolean;
	};
	setNavOpen?: (open: boolean) => void;
}

const AdminList: React.FC<AdminListProps> = ({ permissions, setNavOpen }) => {
	// --------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

	// --------------------------------------
	// Memos
	const viewList = useMemo(() => {
		if (organisation?.plan_id === null) return false;

		return (
			permissions.manageOrganisation ||
			permissions.manageUsers ||
			permissions.manageTeams
		);
	}, [
		permissions.manageOrganisation,
		permissions.manageUsers,
		permissions.manageTeams,
		organisation?.plan_id,
	]);

	// --------------------------------------
	// Render
	if (!viewList) return null;

	return (
		<ListWrapper>
			<NavigationLink
				to={`/${organisation?.slug}/teams`}
				label={translate('teams')}
				icon={faPeopleGroup}
				permission={permissions.manageTeams}
				setNavOpen={setNavOpen}
			/>
			<NavigationLink
				to={`/${organisation?.slug}/users`}
				label={translate('users')}
				icon={faUser}
				permission={permissions.manageUsers}
				setNavOpen={setNavOpen}
				items={[
					{
						permission: permissions.manageOrganisation,
						component: (
							<NavigationLink
								to={`/${organisation?.slug}/user-awards`}
								label={translate('awards')}
								icon={faAward}
								permission={permissions.manageOrganisation}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
					{
						permission: permissions.manageOrganisation,
						component: (
							<NavigationLink
								to={`/${organisation?.slug}/logins`}
								label={translate('logins')}
								icon={faFolderClosed}
								permission={permissions.manageOrganisation}
								setNavOpen={setNavOpen}
								isChild={true}
							/>
						),
					},
				]}
			/>
			<NavigationLink
				to={`/${organisation?.slug}/course-nominations`}
				label={translate('course_nominations')}
				icon={faBook}
				permission={permissions.manageOrganisation}
				setNavOpen={setNavOpen}
			/>
			<NavigationLink
				to={`/${organisation?.slug}/manage`}
				label={translate('manage_organisation')}
				icon={faCog}
				permission={permissions.manageOrganisation}
				setNavOpen={setNavOpen}
			/>
		</ListWrapper>
	);
};

export default AdminList;
