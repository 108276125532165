import { translate } from '@lang/index';
import { Outlet, Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// Hooks
import { useOrganisation, useToast } from '@hooks/index';

const OrganisationGuard: React.FC = () => {
	const { organisationSlug } = useParams();
	const { organisation } = useOrganisation();
	const { addToast } = useToast();

	if (organisationSlug) {
		if (organisation && organisation.slug !== organisationSlug) {
			addToast({
				title: translate('toast_unauthorised_title'),
				message: translate('toast_unauthorised_message'),
				type: 'error',
			});
			return <Navigate to="/" />;
		}
	}

	return <Outlet />;
};

export default OrganisationGuard;
