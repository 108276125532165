import Color from 'color';
import { useMemo, useState, useEffect } from 'react';
// Hooks
import { useOrganisation } from '@hooks/index';
// Assets
import poweredByBaobab from '@assets/images/powered-by.svg';
import poweredByBaobabDark from '@assets/images/powered-by-dark.svg';

interface PoweredByLogoProps {
	overideMode?: 'dark' | 'light';
	thumbnail?: string;
}

const PoweredByLogo: React.FC<PoweredByLogoProps> = ({
	overideMode,
	thumbnail,
}) => {
	// --------------------------------------
	// State & Hooks
	const { organisation } = useOrganisation();
	const [showPoweredBy, setShowPoweredBy] = useState<boolean>(false);

	// --------------------------------------
	// Memos
	const poweredByMode = useMemo(() => {
		const computedStyles = getComputedStyle(document.documentElement);
		const headerBg = computedStyles.getPropertyValue(
			'--theme-ui-header-background'
		);
		const headerBgColor = Color(headerBg);
		if (headerBg === '') return 'dark';
		if (overideMode) return overideMode;
		if (headerBgColor.isLight()) return 'dark';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	// --------------------------------------
	// Effects
	useEffect(() => {
		const computedStyles = getComputedStyle(document.documentElement);
		const headerBg = computedStyles.getPropertyValue(
			'--theme-ui-header-background'
		);
		if (headerBg && thumbnail) {
			setShowPoweredBy(true);
		} else setShowPoweredBy(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	// --------------------------------------
	// Return
	if (!showPoweredBy) return null;

	return (
		<img
			className="ml-5 h-6 w-auto"
			src={
				poweredByMode === 'dark' ? poweredByBaobabDark : poweredByBaobab
			}
			alt=""
		/>
	);
};

export default PoweredByLogo;
