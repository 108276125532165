import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Switch from '@components/forms/Switch';
import FormRow from '@components/forms/FormRow';
import Button from '@components/partials/Button';
import FormAction from '@components/forms/FormAction';
import Select from '@components/forms/Select';

interface UpdateOrganisationActionProps extends ActionProps {
	organisation: SaOrganisationsRes;
	organisations: Array<SaOrganisationsRes>;
	plans: SaPlanRes[];
	setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateOrganisationAction: React.FC<UpdateOrganisationActionProps> = ({
	organisation,
	organisations,
	plans,
	callback,
	setChangesMade,
}) => {
	// -------------------------------------------------
	// State
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	// Inputs
	const [name, setName] = useState(organisation.name);
	const [slug, setSlug] = useState(organisation.slug);
	const [source, setSource] = useState(organisation.source);
	const [type, setType] = useState(organisation.type);
	const [active, setActive] = useState(organisation.active);
	const [planId, setPlanId] = useState<number | null>(organisation.plan_id);
	const [themeOrganisationId, setThemeOrganisationId] = useState<
		number | null
	>(organisation.theme_organisation_id);

	// -------------------------------------------------
	// Queries / Mutations
	const updateOrganisation = useMutation(api.sa.organisations.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Organisation',
				}),
				message: translate('toast_updated_message', {
					name: 'Organisation',
				}),
				type: 'success',
			});
			queryClient.invalidateQueries(['sa.organisations.getMultiple']);
			queryClient.invalidateQueries(['sa.organisations.getSingle']);
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		await updateOrganisation.mutateAsync({
			id: organisation.id,
			body: returnModifiedData([
				{
					key: 'name',
					state: name,
					defaultState: organisation.name,
				},
				{
					key: 'active',
					state: active,
					defaultState: organisation.active,
				},
				{
					key: 'plan_id',
					state: planId,
					defaultState: organisation.plan_id,
				},
				{
					key: 'slug',
					state: slug,
					defaultState: organisation.slug,
				},
				{
					key: 'type',
					state: type,
					defaultState: organisation.type,
				},
				{
					key: 'source',
					state: source,
					defaultState: organisation.source,
				},
				{
					key: 'theme_organisation_id',
					state: themeOrganisationId,
					defaultState: organisation.theme_organisation_id,
				},
			]),
		});
	};

	// -------------------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			onChange={() => {
				setChangesMade(true);
			}}>
			{/* Organisation Details */}
			<FormRow title={translate('details')}>
				<Input
					id="name"
					type="text"
					label={translate('organisation_name')}
					name="name"
					value={name}
					setValue={setName}
					errors={formState.errors.value['name']}
					required={true}
				/>
				<Input
					id="slug"
					type="text"
					label={translate('slug')}
					name="slug"
					value={slug}
					setValue={setSlug}
					errors={formState.errors.value['slug']}
					required={true}
				/>
				<Input
					id="type"
					type="text"
					label={translate('type')}
					name="type"
					value={type || ''}
					setValue={setType}
					errors={formState.errors.value['type']}
					required={false}
				/>
				<Input
					id="source"
					type="text"
					label={translate('source')}
					name="source"
					value={source || ''}
					setValue={setSource}
					errors={formState.errors.value['source']}
					required={false}
				/>
				<Select
					label={translate('plan')}
					value={
						plans.find((plan) => plan.id === planId)?.name ||
						'No plan'
					}
					setValue={(state) => {
						if (state === 'No plan') return setPlanId(null);
						const type = plans.find((type) => type.name === state);
						setPlanId(type?.id || 1);
					}}
					errors={formState.errors.value['type_id']}
					options={[
						{
							value: 'No plan',
							label: 'No plan',
						},
						...(plans.map((type) => {
							return {
								value: type.name,
								label: `${type.name} - ${type.id}`,
							};
						}) || []),
					]}
				/>
				<Switch
					label={translate('active')}
					name="active"
					checked={active}
					setChecked={setActive}
					errors={formState.errors.value['active']}
				/>

				<Select
					label={translate('theme_organisation')}
					value={themeOrganisationId}
					setValue={(state) => {
						if (!state) return setThemeOrganisationId(null);
						setThemeOrganisationId(state as number);
					}}
					errors={formState.errors.value['theme_organisation_id']}
					options={[
						{
							value: null,
							label: 'No organisation',
						},
						...(organisations.map((org) => {
							return {
								value: org.id,
								label: `${org.name} - ${org.id}`,
							};
						}) || []),
					]}
					describedBy={translate('theme_organisation_id_description')}
				/>
			</FormRow>

			{/* Update */}
			<FormAction
				loading={{
					is: updateOrganisation.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateOrganisation.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateOrganisationAction;
