import { useState, useRef } from 'react';
import placeholderVideo from '@assets/images/placeholder-video.jpg';
// Hooks
import { useVideoPlayer } from '@hooks/index';
// Components
import VideoTransscript from '@components/partials/VideoTranscript';

interface VideoPreviewProps {
	video: SaVideosRes;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({ video }) => {
	// -------------------------------------------------
	// State / Hooks
	const playerContainerRef = useRef<HTMLDivElement>(null);

	const [timestamp, setTimestamp] = useState(0);
	const { VideoPlayer, playerRef } = useVideoPlayer({
		target: 0.5,
		options: {
			html5: {
				hls: {
					enableLowInitialPlaylist: false,
				},
			},
			autoplay: false,
			controls: true,
			responsive: true,
			fluid: true,
			volume: 0,
			preload: 'false',
			poster: video.thumbnail || '',
			sources: [
				{
					src: video.meta?.hls_playlist_url,
					type: 'application/x-mpegURL',
				},
			],
		},
		tracks: video.meta?.tracks,
		setTimestamp,
	});

	// -------------------------------------------------
	// Render
	return (
		<div className="flex flex-col">
			<div
				className="mb-5 h-auto overflow-hidden rounded-md"
				ref={playerContainerRef}>
				{video.synced_at === null ? (
					<div className="relative w-full after:block after:pb-[56%]">
						<div className="absolute inset-0">
							<img
								className="block h-full w-full object-cover"
								src={placeholderVideo}
								alt={video.title}
							/>
						</div>
					</div>
				) : (
					<VideoPlayer />
				)}
			</div>
			<div className="rounded-md bg-uiDarkHover">
				<VideoTransscript
					textTracks={video.textTracks}
					mode={'dark'}
					currentTimestamp={timestamp}
					goToTimestamp={(timestamp) => {
						playerRef.current?.currentTime(timestamp);
					}}
				/>
			</div>
		</div>
	);
};

export default VideoPreview;
