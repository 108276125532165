import { translate } from '@lang/index';
import { useEffect, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
// API
import api from 'api';
// Hooks
import { useFormState, useOrganisation } from '@hooks/index';
// Components
import Form from '@components/forms/Form';
import Loading from '@components/partials/Loading';
import Input from '@components/forms/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Queries
import { useAuthenticatedUser } from '@queries/user';

interface SearchBarProps {
	query: string;
	setQuery: (query: string) => void;
	fillFromUrl?: boolean;
	resetOnSubmit?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
	query,
	setQuery,
	fillFromUrl = false,
	resetOnSubmit = false,
}) => {
	// --------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const navigate = useNavigate();
	const inputParentRef = useRef<HTMLInputElement>(null);
	const formState = useFormState();
	const queryClient = useQueryClient();

	// --------------------------------------
	// Queries / Mutations
	const user = useAuthenticatedUser();
	const createSearch = useMutation(api.organisations.searches.createSingle, {
		onSuccess: () => {
			queryClient.invalidateQueries([
				'organisations.searches.createSingle',
			]);
		},
	});

	// --------------------------------------
	// Function
	const navigateToRoute = () => {
		const queryString = query.length > 0 ? `?filter[s]=${query}` : '';
		navigate(`/search${queryString}`);
		const current = inputParentRef.current;
		if (current) {
			const input = current.querySelector('input');
			if (input) {
				input.blur();
			}
		}
	};

	const onSubmit = async () => {
		if (query.length > 0 && organisation?.id) {
			await createSearch.mutateAsync({
				organisationId: organisation.id,
				body: {
					value: query,
				},
			});
		}
		navigateToRoute();
		if (resetOnSubmit) {
			setQuery('');
		}
	};

	// --------------------------------------
	// Effects
	useEffect(() => {
		if (fillFromUrl) {
			const urlParams = new URLSearchParams(window.location.search);
			const query = urlParams.get('filter[s]') || '';
			if (query) {
				setQuery(query);
			}
		}
	}, [fillFromUrl, setQuery]);

	// --------------------------------------
	// Render

	if (user.isLoading) {
		return (
			<div className="relative h-10 overflow-hidden rounded-md">
				<Loading type="skeleton" />
			</div>
		);
	}

	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			className="w-full">
			<div ref={inputParentRef} className="flex w-full">
				<div className="relative w-full">
					<Input
						id="search"
						type="text"
						name="search"
						value={query}
						setValue={setQuery}
						errors={formState.errors.value['search']}
						showErrors={false}
						autoComplete="off"
						placeholder={translate('search_for_videos_and_notes')}
						required={false}
						inputStyle="rounded-r-none placeholder:text-sm"
					/>
				</div>
				<button
					type="submit"
					className="h-10 w-10 rounded-r-md bg-brandPrimary text-brandPrimaryText hover:bg-brandPrimaryHover focus:outline-none focus:ring-2 focus:ring-brandPrimaryLight">
					<FontAwesomeIcon icon={faSearch} />
				</button>
			</div>
		</Form>
	);
};

export default SearchBar;
