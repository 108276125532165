import { translate } from '@lang/index';
import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSelectedRow } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import OrganisaionUsersRow from '@components/rows/OrganisaionUsersRow';
import Pagination from '@components/query/Pagination';
import TopPagination from '@components/query/FormUtilityBar';
// Modals
import CreateUpdateUser from '@components/modal/CreateUpdateUser';
import ToggleOrgUserSeatSA from '@components/modal/ToggleOrgUserSeatSA';

interface OrganisationUsersTableProps extends QueryTableProps {
	organisation: SaOrganisationsRes;
	utilityBar?: React.ReactNode;
}

const OrganisationUsersTable: React.FC<OrganisationUsersTableProps> = ({
	searchParams,
	queryString,
	utilityBar,
	organisation,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// Queries / Mutations
	const { selectedRow, setSelectedRow } = useSelectedRow<SaUsersRes | null>(
		null
	);
	const users = useQuery(
		['sa.users.getMultiple', queryString],
		() => {
			return api.sa.users.getMultiple({
				queryString: queryString,
				filters: [
					{
						key: 'organisation_ids',
						value: organisation.id,
					},
				],
				include: {
					firstLogin: false,
					lastLogin: true,
					organisations: true,
					rolesIgnoringTeams: true,
				},
			});
		},
		{
			enabled: enabled,
		}
	);

	// Modal State
	const [openCreateUpdate, setOpenCreateUpdate] = useState(false);
	const [openToggleSeat, setOpenToggleSeat] = useState(false);

	// -------------------------------------------------
	// Memos
	const selectedOrganisation = useMemo(() => {
		return selectedRow?.organisations?.find(
			(org) => org.id === organisation.id
		);
	}, [selectedRow, organisation]);

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={users.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: users.isLoading,
					error: users.isError,
					noData: users.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'User',
					}),
					message: translate('no_data_message', {
						name: 'user',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('name'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'name'
							),
							type: 'sortable',
						},
						{
							label: translate('seated'),
							type: 'standard',
						},
						{
							label: translate('roles'),
							type: 'standard',
						},
						{
							label: translate('last_login'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'last_login'
							),
							type: 'sortable',
						},
						{
							label: translate('created_at'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'created_at'
							),
							type: 'sortable',
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{users.data?.data.data.map((user, index) => (
						<OrganisaionUsersRow
							key={index}
							user={user}
							organisationId={organisation.id}
							selectRow={(user, action) => {
								setSelectedRow(user);
								switch (action) {
									case 'update': {
										setOpenCreateUpdate(true);
										break;
									}
									case 'toggle-seat': {
										setOpenToggleSeat(true);
										break;
									}
								}
							}}
						/>
					))}
				</Table>
				<Pagination
					meta={users.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
			</DynamicBlock>
			{/* Modals */}
			{selectedRow !== null && (
				<>
					<CreateUpdateUser
						mode="update"
						open={openCreateUpdate}
						setOpen={setOpenCreateUpdate}
						userId={selectedRow.id}
						onSuccess={() => {
							// searchParams.page.setValue(1);
							// users.refetch();
						}}
					/>
					<ToggleOrgUserSeatSA
						open={openToggleSeat}
						setOpen={setOpenToggleSeat}
						userId={selectedRow.id}
						organisationId={organisation.id}
						state={selectedOrganisation?.seated_at ? true : false}
						onSuccess={() => {
							// searchParams.page.setValue(1);
							// users.refetch();
						}}
					/>
				</>
			)}
		</>
	);
};

export default OrganisationUsersTable;
