import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import Sort from '@components/query/Sort';
import Search from '@components/query/Search';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Button from '@components/partials/Button';
import QuizTable from '@components/table/sa/QuizTable';
import Filter from '@components/query/Filter';
// Modals
import CreateQuiz from '@components/modal/CreateQuiz';

const QuizList = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'title',
					value: 'off',
				},
				{
					key: 'updated_at',
					value: 'off',
				},
				{
					key: 'ref',
					value: 'asc',
				},
				{
					key: 'type_id',
					value: 'off',
				},
			],
			filter: [
				{
					key: 'has_media',
					value: '',
					type: 'boolean',
				},
				{
					key: 'type_id',
					value: '',
					type: 'select',
				},
			],
		});

	// Modal State
	const [openCreateQuiz, setOpenCreateQuiz] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const categories = useQuery(['sa.categories.getAll'], () => {
		return api.sa.categories.getAll({
			filters: [
				{
					key: 'scope',
					value: 'quizType',
				},
			],
			include: {
				meta: false,
				metaCount: false,
			},
			sort: {
				name: 'asc',
			},
		});
	});

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('assessments_library')}
				body={translate('assessments_library_body')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'type_id',
								label: translate('type'),
								values: categories.data?.data.data.map(
									(category) => {
										return {
											label: `${category.name} - ${category.id}`,
											value: category.id.toString(),
										};
									}
								),
								allowAll: true,
							},
							{
								key: 'has_media',
								label: translate('has_media'),
							},
						]}
						loading={categories.isLoading}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'title',
								label: translate('title'),
							},
							{
								key: 'updated_at',
								label: translate('updated'),
							},
							{
								key: 'ref',
								label: translate('ref'),
							},
							{
								key: 'type_id',
								label: translate('type'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={translate('search_for_assessment')}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<QuizTable
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					utilityBar={
						<Button
							theme={'primary'}
							onClick={() => setOpenCreateQuiz(true)}>
							{translate('add_assessment')}
						</Button>
					}
				/>
			</PageWrapper>
			{/* Modals */}
			<CreateQuiz open={openCreateQuiz} setOpen={setOpenCreateQuiz} />
		</>
	);
};

export default QuizList;
