import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { type AxiosResponse } from 'axios';
import classNames from 'classnames';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Assets
import notifySVG from '@assets/images/svgs/notify.svg';
// API
import api from 'api';
// Hooks
import { useOrganisation, useAuth, useGrantedAwards } from '@hooks/index';
// Utils
import { helpers, dateHelpers } from '@utils/index';
// Components
import PageHeading from '@components/blocks/PageHeading';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import GraidentBackground from '@components/partials/GradientBackground';
import MessageBlock from '@components/blocks/MessageBlock';
import CourseBreakdownIcons from '@components/partials/CourseBreakdownIcons';
import Button from '@components/partials/Button';
import LearningOutcomes from '@components/modal/LearningOutcomes';
// Groups
import CourseNavigation from '@components/groups/single-course/Navigation';
import CourseContent from '@components/groups/single-course/Content';
import BookmarkButtonAction from '@components/actions/BookmarkButton';

// Types
interface CoursesViewProps {
	mode: 'preview' | 'standard';
}

export type GetPrevItemT = (allowChapter?: boolean) => CourseItems | undefined;

export type GetNextItemT = (allowChapter?: boolean) => CourseItems | undefined;

export type GoToT = (item: CourseItems) => void;

export type IsItemLockedT = (
	item?: CourseItems,
	status?: CourseStatusRes[]
) => boolean;

type PreviewCourseRes = AxiosResponse<APIResponse<SaCourseRes>, any>;
type StandardCourseRes = AxiosResponse<APIResponse<CourseRes>, any>;
type PreviewCategoriesRes = AxiosResponse<APIResponse<SaCategoriesRes[]>, any>;
type StandardCategoriesRes = AxiosResponse<APIResponse<CategoriesRes[]>, any>;

// Component
const CoursesView: React.FC<CoursesViewProps> = ({ mode }) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { userId } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const { id, itemType, itemId } = useParams<{
		id: string;
		itemType: 'video' | 'assessment' | 'chapter';
		itemId: string;
	}>();
	const { GrantedAwardsModal, refetchAwards } = useGrantedAwards({
		enabled: mode === 'standard',
	});

	const [learningOutcomesModal, setLearningOutcomesModal] = useState(false);
	const [showStickyTitleBar, setShowStickyTitleBar] = useState(false);
	const [titleBarHeight, setTitleBarHeight] = useState(0);
	const [initialPercent, setInitialPercent] = useState<undefined | number>();
	const [hasAccess, setHasAccess] = useState(true);

	// Data
	const [currentItem, setCurrentItem] = useState<CourseItems | undefined>();

	// References
	const scrollSection = useRef<HTMLDivElement>(null);
	const stickyTitleBar = useRef<HTMLDivElement>(null);

	const itemTypeActual = useMemo(() => {
		if (itemType === 'assessment') return 'quiz';
		else return itemType;
	}, [itemType]);

	// -------------------------------------------------
	// Queries / Mutations
	const course = useQuery<PreviewCourseRes | StandardCourseRes>(
		[
			mode === 'preview'
				? 'single-course.preview'
				: 'single-course.standard',
			id,
			organisation?.id,
		],
		() => {
			if (mode === 'preview') {
				return api.sa.courses.getSingle({
					id: id || '0',
					include: {
						categories: false,
						categoriesCount: false,
						items: true,
						itemsCount: true,
						itemsModel: true,
						media: true,
						mediaCount: false,
						organisations: false,
						organisationsCount: false,
						categoriesMeta: false,
						type: true,
						meta: true,
					},
				});
			}
			return api.organisations.courses.getSingle({
				organisationId: organisation?.id || 0,
				courseId: parseInt(id || '0'),
				include: {
					items: true,
					itemsCount: true,
					itemsModel: true,
					itemsModelType: true,
					media: true,
					mediaCount: true,
					type: true,
					typeCount: true,
					teams: false,
					users: false,
					meta: true,
				},
			});
		},
		{
			onSuccess: (data) => {
				checkItem(data.data.data.items);

				const org = data?.data.data.organisations
					? data?.data.data.organisations[0]
					: null;
				let access = true;
				if (org) {
					if (org.access.start_date || org.access.end_date) {
						access = dateHelpers.isBetween(
							new Date(),
							org.access.start_date
								? new Date(org.access.start_date)
								: new Date(),
							org.access.end_date
								? new Date(org.access.end_date)
								: undefined
						);
					} else {
						access = true;
					}
				}
				setHasAccess(access);
			},
		}
	);
	const categories = useQuery<PreviewCategoriesRes | StandardCategoriesRes>(
		[
			mode === 'preview'
				? 'sa.categories.getMultiple'
				: 'organisations.categories.getAll',
			id,
			organisation?.id,
		],
		() => {
			if (mode === 'preview') {
				return api.sa.categories.getMultiple({
					include: {
						meta: true,
						metaCount: false,
					},
					perPage: -1,
				});
			}
			return api.organisations.categories.getAll({
				organisationId: organisation?.id || 0,
				include: {
					meta: true,
					metaCount: false,
				},
			});
		}
	);
	const status = useQuery(
		['organisations.courses.getStatus', id, organisation?.id],
		() => {
			return api.organisations.courses.getStatus({
				organisationId: organisation?.id || 0,
				courseId: parseInt(id || '0'),
			});
		},
		{
			enabled: mode === 'standard',
			onSuccess: () => {
				refetchAwards();
				progress.refetch();
			},
		}
	);
	const bookmarks = useQuery(
		['organisations.bookmarks.getMultiple', id, organisation?.id],
		() => {
			return api.organisations.bookmarks.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					bookmarkable: false,
					course: false,
					courseMedia: false,
					bookmarkableMedia: false,
				},
				filters: [
					{
						key: 'course_id',
						value: id as string,
					},
				],
				perPage: -1,
			});
		},
		{
			enabled: mode === 'standard',
		}
	);
	const progress = useQuery(
		[
			'organisations.courses.progress.getMultiple',
			id,
			organisation?.id,
			userId,
		],
		() => {
			return api.organisations.courses.progress.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: false,
					user: false,
				},
				filters: [
					{
						key: 'course_id',
						value: id as string,
					},
					{
						key: 'user_id',
						value: userId as number,
					},
				],
			});
		},
		{
			enabled: mode === 'standard' && userId !== undefined,
			onSuccess: (data) => {
				if (data.data.data.length === 0) {
					setInitialPercent(0);
					return;
				}
				const progressItem = data.data.data[0];
				const percent = parseFloat(progressItem.percentage || '0');

				if (initialPercent !== undefined) {
					// check if the progress has changed
					if (
						initialPercent !== 100 &&
						percent !== initialPercent &&
						percent === 100
					) {
						navigate(
							`/${organisation?.slug}/courses/${id}/success`
						);
					}
				} else {
					// set the initial progress percent
					setInitialPercent(percent);
				}
			},
		}
	);

	// -------------------------------------------------
	// Event Functions
	const handleResize = () => {
		if (!stickyTitleBar.current) {
			return;
		}
		const titleBarHeight = stickyTitleBar.current?.offsetHeight;
		setTitleBarHeight(titleBarHeight || 0);
	};
	const handleScroll = () => {
		// if the scrollsection is past the height of the css variable --page-heading-height then show the sticky title bar
		if (!scrollSection.current) {
			setShowStickyTitleBar(false);
			setTitleBarHeight(0);
			return;
		}

		const pheadingHeight = getComputedStyle(document.documentElement)
			.getPropertyValue('--page-heading-height')
			.replace('px', '');

		if (scrollSection.current.scrollTop > parseInt(pheadingHeight)) {
			setShowStickyTitleBar(true);
		} else {
			setShowStickyTitleBar(false);
		}
	};
	// -------------------------------------------------
	// Functions
	const checkItem = (items: CourseRes['items']) => {
		const navigateLink = helpers.courseRedirectLink(
			mode,
			id,
			organisation?.slug
		);

		// Find the current item
		const itemExists = items?.find(
			(item) =>
				item.model_type === itemTypeActual &&
				item.model.id === parseInt(itemId as string)
		);

		// If the item doesn't exist, redirect to the course page
		if (!itemExists) {
			setCurrentItem(undefined);
			return;
		}

		// If the item is locked, redirect to the last unlocked item
		const itemLocked = helpers.isItemLocked(itemExists, status.data?.data);
		if (itemLocked) {
			return navigate(navigateLink, {
				replace: true,
			});
		}

		setCurrentItem(itemExists);
	};
	const convertModelType = (type: string) => {
		if (type === 'quiz') return 'assessment';
		return type;
	};
	const getCurrentItemIndex = () => {
		const noChapters = helpers.courseItemsWithNoChapters(
			course.data?.data.data.items || []
		);
		const index = noChapters.findIndex((item) => {
			return (
				item.model_id === currentItem?.model_id &&
				item.model_type === currentItem?.model_type
			);
		});
		const items = course.data?.data.data.items || [];
		const allIndex = items.findIndex((item) => {
			return (
				item.model_id === currentItem?.model_id &&
				item.model_type === currentItem?.model_type
			);
		});

		return {
			noChapters: {
				index,
				items: noChapters,
			},
			all: {
				index: allIndex,
				items: course.data?.data.data.items || [],
			},
		};
	};
	const isItemLocked = (item?: CourseItems) => {
		if (mode === 'preview') return false;
		if (!item) return true;
		const itemStatus = status.data?.data.find((si) => {
			return (
				si.model_id === item.model_id &&
				si.model_type === item.model_type
			);
		});
		return !itemStatus?.can;
	};
	const getNextItem = (allowChapter?: boolean) => {
		const { all, noChapters } = getCurrentItemIndex();

		if (allowChapter) {
			if (all.index === undefined || all.index === -1) return undefined;
			const nextItem = all.items[all.index + 1];
			return nextItem;
		} else {
			if (noChapters.index === undefined || noChapters.index === -1)
				return undefined;
			const nextItem = noChapters.items[noChapters.index + 1];
			return nextItem;
		}
	};
	const getPrevItem = (allowChapter?: boolean) => {
		const { all, noChapters } = getCurrentItemIndex();

		if (allowChapter) {
			if (all.index === undefined || all.index === -1) return undefined;
			const prevItem = all.items[all.index - 1];
			return prevItem;
		} else {
			if (noChapters.index === undefined || noChapters.index === -1)
				return undefined;
			const prevItem = noChapters.items[noChapters.index - 1];
			return prevItem;
		}
	};
	const goTo = (item: CourseItems) => {
		if (mode === 'standard') {
			const locked = helpers.isItemLocked(item, status.data?.data);
			if (locked) return;
		}
		const routePath = mode === 'standard' ? `/${organisation?.slug}` : '';
		navigate(
			`${routePath}/courses/${id}/${convertModelType(item.model_type)}/${
				item.model_id
			}`
		);
	};

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		const scrollSectionEle = scrollSection.current;

		handleScroll();
		handleResize();

		// Resize event
		window.addEventListener('resize', handleResize);
		scrollSectionEle?.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('resize', handleResize);
			scrollSectionEle?.removeEventListener('scroll', handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [course]);

	// On item type and item id change, find the current item and validate it
	useEffect(() => {
		if (course.data?.data.data.items !== undefined)
			checkItem(course.data?.data.data.items);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemTypeActual, itemId]);

	// -------------------------------------------------
	// Memos
	const courseOnlyHasChapters = useMemo(() => {
		return helpers.courseOnlyHasChapters(
			course.data?.data.data.items || []
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [course]);

	const nextItem = useMemo(() => {
		return getNextItem(courseOnlyHasChapters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentItem]);

	const prevItem = useMemo(() => {
		return getPrevItem(courseOnlyHasChapters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentItem]);

	const isFirstItem = useMemo(() => {
		return currentItem === undefined || prevItem === undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentItem, prevItem]);

	const hasItems = useMemo(() => {
		return course.data?.data.data.items?.length !== 0;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [course]);

	const isSuccess = useMemo(() => {
		if (location.pathname.includes('/success')) return true;
		return false;
	}, [location.pathname]);

	const courseBookmark = useMemo(
		() => {
			return bookmarks.data?.data.data.find((bookmark) => {
				return (
					bookmark.bookmarkable_type === 'course' &&
					bookmark.bookmarkable_id === parseInt(id || '0')
				);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[bookmarks, id]
	);

	const showLoading = useMemo(() => {
		if (mode === 'standard') {
			return (
				course.isLoading ||
				status.isLoading ||
				bookmarks.isLoading ||
				progress.isLoading
			);
		}
		return course.isLoading;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		course.isLoading,
		status.isLoading,
		bookmarks.isLoading,
		progress.isLoading,
	]);

	const showError = useMemo(() => {
		if (mode === 'standard') {
			return (
				course.isError ||
				status.isError ||
				bookmarks.isError ||
				progress.isError
			);
		}
		return course.isError;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [course.isError, status.isError, bookmarks.isError, progress.isError]);

	// -------------------------------------------------
	// Render
	if (showLoading) {
		return <Loading type="page" />;
	}
	if (showError) {
		return <Error type="page" />;
	}

	return (
		<>
			<div
				ref={scrollSection}
				className="page-full relative overflow-y-auto">
				<PageHeading
					title={course.data?.data.data.title as string}
					body={course.data?.data.data.desc || undefined}
					className={'!z-30'}>
					<div className="flex flex-wrap items-center justify-between gap-2.5">
						<CourseBreakdownIcons
							size={'sm'}
							items={course.data?.data.data.items || []}
							categories={categories.data?.data.data || []}
						/>
						<div className="flex flex-wrap items-center gap-2.5">
							{course.data?.data.data.meta
								?.completion_abilities && (
								<Button
									type="button"
									theme="outline"
									onClick={() =>
										setLearningOutcomesModal(true)
									}>
									<FontAwesomeIcon
										icon={faGraduationCap}
										className="mr-2"
									/>
									{translate('learning_outcomes')}
								</Button>
							)}
							{course.data?.data.data.file && (
								<Button
									type="link"
									theme="outline"
									href={course.data.data.data.file}>
									Download Resources
								</Button>
							)}
							{mode === 'standard' && (
								<BookmarkButtonAction
									courseId={course.data?.data.data.id}
									bookmarkable_id={
										course.data?.data.data.id as number
									}
									bookmark={courseBookmark}
									type={'course'}
									simplified={true}
									refetch={() => {
										bookmarks.refetch();
									}}
								/>
							)}
						</div>
					</div>
				</PageHeading>
				{/* Title bar */}
				<div
					ref={stickyTitleBar}
					className={classNames(
						'duration-400 sticky top-0 left-0 right-0 z-20 shadow-lg transition-transform ease-in-out',
						{
							'-translate-y-full': !showStickyTitleBar,
							'translate-y-0': showStickyTitleBar,
						}
					)}
					style={{
						marginTop: `-${titleBarHeight}px`,
					}}>
					<div className="flex w-full items-center bg-uiLight p-5 py-4 md:px-7">
						<h1 className="text-lg text-title md:text-xl">
							{course.data?.data.data.title}
						</h1>
					</div>
					<div className="relative z-0 h-1 w-full">
						<GraidentBackground />
					</div>
				</div>
				{/* No Items In Course */}
				{!hasItems && (
					<div className="p-7">
						<MessageBlock
							title={translate('no_items_in_course_title')}
							message={translate('no_items_in_course_message')}
							image={notifySVG}
						/>
					</div>
				)}
				{/* Has Items */}
				{hasItems && (
					<>
						{/* Content */}
						<div
							className="duration-400 w-full transition-all ease-in-out xl:sticky xl:float-right xl:mb-16 xl:w-[calc(100%-400px)]"
							style={{
								top: showStickyTitleBar
									? `${titleBarHeight}px`
									: `0px`,
							}}>
							<div className="p-5 md:p-7 xl:pl-0">
								<CourseContent
									mode={mode}
									course={
										course.data?.data.data as
											| SaCourseRes
											| CourseRes
									}
									currentItem={currentItem}
									nextItem={nextItem}
									isFirstItem={isFirstItem}
									isSuccess={isSuccess}
									bookmarks={bookmarks.data?.data.data || []}
									courseAccess={hasAccess}
									functions={{
										goTo,
										getNextItem,
										getPrevItem,
										refetchStatus: async () => {
											if (mode === 'standard')
												await status.refetch();
										},
										refetchBookmarks: async () => {
											if (mode === 'standard')
												await bookmarks.refetch();
										},
									}}
								/>
							</div>
						</div>
						{/* Navigation */}
						<div
							className="duration-400 z-10 mb-[96px] w-full pr-0 transition-all ease-in-out xl:sticky xl:float-left xl:w-[400px] xl:p-7 xs:mb-16"
							style={{
								top: showStickyTitleBar
									? `${titleBarHeight}px`
									: `0px`,
							}}>
							<CourseNavigation
								mode={mode}
								course={
									course.data?.data.data as
										| SaCourseRes
										| CourseRes
								}
								status={status.data?.data || []}
								nextItem={nextItem}
								prevItem={prevItem}
								progress={progress.data?.data.data[0]}
								categories={categories.data?.data.data || []}
								functions={{
									goTo,
									isItemLocked,
								}}
							/>
						</div>
					</>
				)}
			</div>
			<GrantedAwardsModal />
			<LearningOutcomes
				course={course.data?.data.data as SaCourseRes | CourseRes}
				open={learningOutcomesModal}
				setOpen={setLearningOutcomesModal}
			/>
		</>
	);
};

export default CoursesView;
