import { Outlet, useNavigate } from 'react-router-dom';
// Components
import Loading from '@components/partials/Loading';
// Queries
import { useAuthenticatedUser } from '@queries/user';

interface SuperAdminGuardProps {
	inverse: boolean;
}

const SuperAdminGuard: React.FC<SuperAdminGuardProps> = ({ inverse }) => {
	const navigate = useNavigate();

	const user = useAuthenticatedUser({
		onSuccess: (data) => {
			if (!inverse) {
				if (!data.data?.data.is_super_admin) {
					navigate('/');
				}
			} else {
				if (data.data?.data.is_super_admin) {
					navigate('/');
				}
			}
		},
	});

	if (user.isLoading) return <Loading type="page" />;
	return <Outlet />;
};

export default SuperAdminGuard;
