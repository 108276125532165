import { faGear } from '@fortawesome/free-solid-svg-icons';
// Hooks
import { usePermissions } from '@hooks/index';
// Components
import AccountSwitcher from '@components/partials/AccountSwitcher';
import OrgPlanCount from '@components/partials/OrgPlanCount';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface AccountDetailsBarProps {
	organisationRes?: OrganisationRes;
}

const AccountDetailsBar: React.FC<AccountDetailsBarProps> = ({
	organisationRes,
}) => {
	// --------------------------------------
	// State / Hooks
	const { manageOrganisation } = usePermissions();

	// --------------------------------------
	// Render
	return (
		<>
			<AccountSwitcher />
			{organisationRes?.plan_id && manageOrganisation && (
				<Link
					to={`/${organisationRes?.slug}/manage`}
					className="flex w-full flex-col border-b border-border bg-uiLight px-4 py-2.5">
					<div className="flex items-center">
						<div className="flex w-full flex-col font-medium">
							<div className="flex items-center justify-between">
								<h3 className="mb-1 text-sm font-medium text-title">
									{organisationRes.plan?.name}
								</h3>
								<FontAwesomeIcon
									icon={faGear}
									className="text-xs text-current"
								/>
							</div>
							<OrgPlanCount organisation={organisationRes} />
						</div>
					</div>
				</Link>
			)}
		</>
	);
};

export default AccountDetailsBar;
