import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
// api
import api from 'api';
// Utils
import { helpers } from '@utils/index';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { returnModifiedData } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Textarea from '@components/forms/Textarea';
import FormActionModal from '@components/forms/FormActionModal';
import Button from '@components/partials/Button';
import Select from '@components/forms/Select';
import FormColumns from '@components/blocks/FormColumns';

interface CreateVideoActionProps extends ActionProps {}

const CreateVideoAction: React.FC<CreateVideoActionProps> = ({ callback }) => {
	// --------------------------------------
	// State / Hooks
	const formState = useFormState();
	const { addToast } = useToast();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const [title, setTtile] = useState('');
	const [description, setDescription] = useState('');
	const [ref, setRef] = useState('');
	const [typeId, setTypeId] = useState<number>();
	const [syncId, setSyncId] = useState('');

	// --------------------------------------
	// Queries / Mutations
	const videoTypes = useQuery(
		['sa.categories.getAll.videoType'],
		() => {
			return api.sa.categories.getAll({
				include: {
					meta: false,
					metaCount: false,
				},
				filters: [
					{
						key: 'scope',
						value: 'videoType',
					},
				],
				sort: {
					name: 'asc',
				},
			});
		},
		{
			onSuccess: (data) => {
				setTypeId(data.data.data[0].id);
			},
		}
	);
	const syncVideo = useMutation(api.sa.videos.syncVideo, {
		onError: () => {
			callback(true);
		},
	});
	const createVideo = useMutation(api.sa.videos.createSingle, {
		onSuccess: async (video) => {
			if (syncId) {
				await syncVideo.mutateAsync({
					id: video.data.data.id,
				});
			}

			resetForm();
			queryClient.invalidateQueries(['sa.videos.getMultiple']);
			queryClient.invalidateQueries(['sa.videos.getAll']);
			addToast({
				title: translate('toast_created_title', {
					name: 'Video',
				}),
				message: translate('toast_created_message', {
					name: 'Video',
				}),
				type: 'success',
			});
			navigate(`/videos/${video.data.data.id}`);
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await createVideo.mutateAsync({
			title,
			...returnModifiedData([
				{
					key: 'desc',
					state: description,
					defaultState: '',
				},
				{
					key: 'ref',
					state: ref,
					defaultState: '',
				},
				{
					key: 'type_id',
					state: typeId,
					defaultState: '',
				},
				{
					key: 'sync_id',
					state: syncId,
					defaultState: '',
				},
			]),
		});
	};
	const resetForm = () => {
		setTtile('');
		setDescription('');
		setRef('');
		setTypeId(videoTypes.data?.data.data[0].id || 1);
		setSyncId('');
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
		createVideo.reset();
	};

	// --------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isLoading={videoTypes.isLoading}
			isError={videoTypes.isError}>
			<FormColumns>
				<Select
					label={translate('video_type')}
					value={
						videoTypes.data?.data.data.find(
							(type) => type.id === typeId
						)?.name || ''
					}
					setValue={(state) => {
						const type = videoTypes.data?.data.data.find(
							(type) => type.name === state
						);
						setTypeId(type?.id || 1);
					}}
					errors={formState.errors.value['type_id']}
					options={
						videoTypes.data?.data.data.map((type) => {
							return {
								value: type.name,
								label: `${type.name} - ${type.id}`,
							};
						}) || []
					}
				/>
				<Input
					id="ref"
					type="text"
					label={translate('ref')}
					name="ref"
					value={ref}
					setValue={setRef}
					errors={formState.errors.value['ref']}
					required={false}
				/>
			</FormColumns>
			<Input
				id="title"
				type="text"
				label={translate('title')}
				name="title"
				value={title}
				setValue={setTtile}
				errors={formState.errors.value['title']}
				required={true}
			/>
			<Input
				id="sync_id"
				type="text"
				label={translate('sync_id')}
				name="sync_id"
				value={syncId}
				setValue={setSyncId}
				errors={formState.errors.value['sync_id']}
				required={false}
			/>
			<Textarea
				id="description"
				label={translate('description')}
				name="desc"
				value={description}
				setValue={setDescription}
				errors={formState.errors.value['desc']}
				required={false}
			/>
			<FormActionModal
				loading={{
					is: createVideo.isLoading || syncVideo.isLoading,
					message: translate('creating'),
				}}
				error={{
					is: createVideo.isError || syncVideo.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('create')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default CreateVideoAction;
