import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// Hooks
import { useSearchParams, useOrganisation } from '@hooks/index';
// API
import api from 'api';
// Components
import Sort from '@components/query/Sort';
import Filter from '@components/query/Filter';
import Search from '@components/query/Search';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Button from '@components/partials/Button';
import OrgUsersTable from '@components/table/admin/OrgUsersTable';
// Modals
import InviteOrgUser from '@components/modal/InviteOrgUser';

const OrganisationUsersList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'name',
					value: 'off',
				},
				{
					key: 'email',
					value: 'off',
				},
				{
					key: 'last_login',
					value: 'off',
				},
				{
					key: 'created_at',
					value: 'off',
				},
			],
			filter: [
				{
					key: 'teams.id',
					value: '',
					type: 'select',
				},
			],
		});

	// Modal State
	const [openInviteUser, setOpenInviteUser] = useState(false);

	// -------------------------------------------------
	// Queries & Mutations
	const teams = useQuery(['organisations.teams.getMultiple'], () => {
		return api.organisations.teams.getMultiple({
			organisationId: organisation?.id || 0,
			queryString: '',
			include: {
				courses: false,
				coursesCount: false,
				users: false,
				usersCount: false,
			},
			sort: {
				name: 'asc',
			},
			perPage: -1,
		});
	});

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('users')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'teams.id',
								label: translate('teams'),
								values: teams.data?.data.data.map((team) => {
									return {
										label: team.name,
										value: team.id.toString(),
									};
								}),
							},
						]}
						loading={teams.isLoading}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'name',
								label: translate('name'),
							},
							{
								key: 'email',
								label: translate('email'),
							},
							{
								key: 'last_login',
								label: translate('last_login'),
							},
							{
								key: 'created_at',
								label: translate('created_at'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={translate('search_for_user')}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<OrgUsersTable
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					permissions={{
						edit: true,
						stats: true,
						seat: true,
						delete: true,
						addToTeam: true,
						removeFromTeam: false,
					}}
					utilityBar={
						<Button
							theme={'primary'}
							onClick={() => setOpenInviteUser(true)}>
							{translate('add_user')}
						</Button>
					}
				/>
			</PageWrapper>
			{/* Modals */}
			<InviteOrgUser
				organisationId={organisation?.id || 0}
				open={openInviteUser}
				setOpen={setOpenInviteUser}
			/>
		</>
	);
};
export default OrganisationUsersList;
