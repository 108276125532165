import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation, useSearchParams } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Pagination from '@components/query/Pagination';
import VideoSearchRow from '@components/rows/VideoSearchRow';

interface VideoSearchListProps {}

const VideoSearchList: React.FC<VideoSearchListProps> = () => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { searchParams, queryString, enabled } = useSearchParams({
		page: 1,
		search: '',
		searchKey: 's',
		perPage: 10,
		sort: [],
		filter: [],
	});

	// -------------------------------------------------
	// Queries / Mutations
	const videos = useQuery(
		['organisations.videos.searchVideos', queryString],
		() => {
			return api.organisations.videos.searchVideos({
				queryString: queryString,
				organisationId: organisation?.id || 0,
				include: {
					courses: true,
					cues: true,
					media: true,
					mediaCount: true,
				},
			});
		},
		{
			enabled,
			keepPreviousData: true,
		}
	);

	// -------------------------------------------------
	// Render
	return (
		<DynamicBlock
			state={{
				loading: videos.isLoading,
				error: videos.isError,
				noData: videos.data?.data.data.length === 0,
			}}
			noData={{
				title: translate('no_data_title', {
					name: 'Search',
				}),
				message: translate('no_data_message', {
					name: 'search',
				}),
			}}>
			<ul>
				{videos.data?.data.data.map((video) => (
					<VideoSearchRow
						key={video.id}
						video={video}
						query={searchParams.search.value}
					/>
				))}
			</ul>
			<Pagination
				meta={videos.data?.data?.meta}
				page={searchParams.page.value}
				setPage={searchParams.page.setValue}
			/>
		</DynamicBlock>
	);
};

export default VideoSearchList;
