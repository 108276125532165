import { translate } from '@lang/index';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
// api
import api from 'api';
// Components
import Error from '@components/partials/Error';
import Loading from '@components/partials/Loading';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Breadcrumbs from '@components/partials/Breadcrumbs';
import HeadingTabs from '@components/partials/HeadingTabs';
import Button from '@components/partials/Button';
// Actions
import UpdateOrganisationAction from '@components/actions/UpdateOrganisation';
// Modals
import InviteOrgUserSA from '@components/modal/InviteOrgUserSA';

const OrganisationEdit: React.FC = () => {
	// -------------------------------------------------
	// State
	const { id } = useParams();
	const [changesMade, setChangesMade] = useState(false);
	const navigate = useNavigate();
	const [openInviteUser, setOpenInviteUser] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const organisation = useQuery(
		['sa.organisations.getSingle', id],
		() => {
			return api.sa.organisations.getSingle({
				id: id as string,
				include: {
					courses: true,
					coursesCount: false,
					media: false,
					mediaCount: false,
					plan: false,
					planCount: false,
					partnerCourseCategories: false,
					meta: false,
				},
			});
		},
		{
			enabled: !!id,
		}
	);
	const organisations = useQuery(['sa.organisations.getMultiple'], () => {
		return api.sa.organisations.getMultiple({
			include: {
				courses: false,
				coursesCount: false,
				media: false,
				mediaCount: false,
				plan: false,
				planCount: false,
				users: false,
				usersCount: false,
				subscriptionsCount: false,
				subscriptionsItems: false,
				seatedUsersCount: false,
			},
			filters: {
				has_plan: 1,
			},
			perPage: -1,
		});
	});
	const plans = useQuery(['sa.plans.getAll'], api.sa.plans.getAll);

	// -------------------------------------------------
	// Render
	if (organisation.isLoading || plans.isLoading || organisations.isLoading)
		return <Loading type="page" />;
	if (organisation.isError || plans.isError || organisations.isError)
		return <Error type="page" />;

	return (
		<>
			<PageHeading
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: '/organisations',
								label: translate('organisations'),
							},
							{
								href: `/organisations/${id}`,
								label: translate('edit'),
							},
						]}
					/>
				}
				title={
					organisation.data.data.data.name ||
					translate('edit_organisation')
				}>
				<div className="flex flex-wrap justify-between gap-2.5">
					<HeadingTabs
						tabs={[
							{
								name: translate('edit'),
								href: `/organisations/${id}`,
							},
							{
								name: translate('users'),
								onClick: () => {
									navigate(`/organisations/${id}/users`);
								},
								navigationWarning: changesMade,
							},
							{
								name: translate('courses'),
								onClick: () => {
									navigate(`/organisations/${id}/courses`);
								},
								navigationWarning: changesMade,
							},
							{
								name: translate('partner'),
								onClick: () => {
									navigate(
										`/organisations/${id}/partnership`
									);
								},
								navigationWarning: changesMade,
							},
						]}
					/>
					<div>
						<Button
							type="button"
							theme="primary"
							onClick={() => {
								setOpenInviteUser(true);
							}}>
							{translate('add_user')}
						</Button>
					</div>
				</div>
			</PageHeading>
			<PageWrapper>
				<UpdateOrganisationAction
					organisation={organisation.data.data.data}
					organisations={organisations.data.data.data || []}
					plans={plans.data.data.data}
					setChangesMade={setChangesMade}
					callback={(success) => {
						if (success) {
							organisation.refetch();
							setChangesMade(false);
						}
					}}
				/>
				<InviteOrgUserSA
					open={openInviteUser}
					setOpen={setOpenInviteUser}
					organisationId={organisation.data.data.data.id}
				/>
			</PageWrapper>
		</>
	);
};

export default OrganisationEdit;
