import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSearchParams, useOrganisation } from '@hooks/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Filter from '@components/query/Filter';
import DynamicBlock from '@components/blocks/DynamicBlock';
import Pagination from '@components/query/Pagination';
import PerPage from '@components/query/PerPage';
import UtilityRow from '@components/blocks/UtilityRow';
import Search from '@components/query/Search';
import NoteRow from '@components/rows/NoteRow';

const OrganisationNotesList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [],
			filter: [
				{
					key: 'noteable_type',
					value: '',
					type: 'select',
				},
				{
					key: 'course_id',
					value: '',
					type: 'select',
				},
			],
		});
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries / Mutations
	const courses = useQuery(['organisations.courses.getMultiple'], () => {
		return api.organisations.courses.getMultiple({
			organisationId: organisation?.id as number,
			include: {
				items: false,
				itemsModel: false,
				itemsModelType: false,
				media: false,
				type: false,
				teamsCount: false,
				usersCount: false,
			},
			sort: {
				title: 'asc',
			},
			perPage: -1,
		});
	});
	const notes = useQuery(
		['organisations.notes.getMultiple(', queryString, organisation?.id],
		() => {
			return api.organisations.notes.getMultiple({
				organisationId: organisation?.id as number,
				queryString: queryString,
				include: {
					noteable: true,
					course: true,
				},
				filters: {
					has_context: 1,
				},
			});
		},
		{
			enabled: !!organisation?.id && enabled,
		}
	);

	// -----------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('notes')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'noteable_type',
								label: translate('type'),
								values: [
									{
										label: translate('quizzes'),
										value: 'quiz',
									},
									{
										label: translate('videos'),
										value: 'video',
									},
								],
								allowAll: true,
							},
							{
								key: 'course_id',
								label: translate('course'),
								values: courses.data?.data.data.map(
									(course) => {
										return {
											label: course.title,
											value: course.id.toString(),
										};
									}
								),
								allowAll: true,
							},
						]}
						loading={courses.isLoading}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<UtilityRow>
					<div className="flex w-full justify-end">
						<PerPage
							perPage={searchParams.perPage.value}
							setPerPage={searchParams.perPage.setValue}
							options={[
								{
									label: '10',
									value: 10,
								},
								{
									label: '20',
									value: 20,
								},
								{
									label: '40',
									value: 40,
								},
								{
									label: 'All',
									value: 500,
								},
							]}
						/>
					</div>
				</UtilityRow>
				<DynamicBlock
					state={{
						loading: notes.isLoading,
						error: notes.isError,
						noData: notes.data?.data.data.length === 0,
					}}
					noData={{
						title: translate('no_data_title', {
							name: 'Note',
						}),
						message: translate('no_data_message', {
							name: 'note',
						}),
					}}>
					<ul>
						{notes.data?.data.data.map((note) => (
							<NoteRow key={note.id} note={note} />
						))}
					</ul>
					<Pagination
						meta={courses.data?.data?.meta}
						page={searchParams.page.value}
						setPage={searchParams.page.setValue}
					/>
				</DynamicBlock>
			</PageWrapper>
		</>
	);
};

export default OrganisationNotesList;
