import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import C from '@root/constants';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Breadcrumbs from '@components/partials/Breadcrumbs';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import HeadingTabs from '@components/partials/HeadingTabs';

import Loading from '@components/partials/Loading';
import ErrorBlock from '@components/partials/Error';
// Actions
import UpdateOrgUserCoursesAction from '@components/actions/UpdateOrgUserCourses';

const OrganisationUserCourses: React.FC = () => {
	// --------------------------------------
	// State & Hooks
	const { id } = useParams();
	const { organisation } = useOrganisation();

	// --------------------------------------
	// Queries & Mutations
	const user = useQuery(
		['organisations.users.getSingle', organisation, id],
		() => {
			if (!id) throw new Error(translate('no_id_provided'));
			return api.organisations.users.getSingle({
				organisationId: organisation?.id || 0,
				id: parseInt(id),
				include: {
					courses: true,
					lastLogin: false,
					lastLoginCount: false,
					teams: true,
					teamsCount: true,
					roles: false,
				},
			});
		}
	);
	const teams = useQuery(
		['organisations.teams.getMultiple', organisation],
		() => {
			return api.organisations.teams.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					courses: true,
					coursesCount: false,
					users: false,
					usersCount: false,
				},
				perPage: -1,
			});
		}
	);

	const courses = useQuery(
		['organisations.courses.getMultiple.courses.noin', organisation],
		() => {
			return api.organisations.courses.getMultiple({
				queryString: '',
				organisationId: organisation?.id || 0,
				filters: C.NOMINATED_COURSE_FILTER,
				include: {
					items: false,
					itemsModel: false,
					itemsModelType: false,
					media: false,
					type: false,
					teamsCount: false,
					usersCount: false,
				},
				perPage: -1,
			});
		}
	);
	// --------------------------------------
	// Render
	if (user.isLoading || courses.isLoading || teams.isLoading)
		return <Loading type="page" />;
	if (user.isError || courses.isError || teams.isError)
		return <ErrorBlock type="page" />;

	return (
		<>
			<PageHeading
				title={translate('user_nominated_courses')}
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: `/${organisation?.slug}/users`,
								label: translate('users'),
							},
							{
								href: `/${organisation?.slug}/users/${id}`,
								label:
									user.data.data.data.name ||
									translate('details'),
							},
							{
								href: `/${organisation?.slug}/users/${id}/nominated-courses`,
								label: translate('nominated_courses'),
							},
						]}
					/>
				}>
				<HeadingTabs
					tabs={[
						{
							name: translate('details'),
							href: `/${organisation?.slug}/users/${id}`,
						},
						{
							name: translate('statistics'),
							href: `/${organisation?.slug}/users/${id}/statistics`,
						},
						{
							name: translate('nominated_courses'),
							href: `/${organisation?.slug}/users/${id}/nominated-courses`,
						},
						{
							name: translate('teams'),
							href: `/${organisation?.slug}/users/${id}/teams`,
						},
					]}
				/>
			</PageHeading>
			<PageWrapper>
				<UpdateOrgUserCoursesAction
					user={user.data.data.data}
					courses={courses.data.data.data}
					teams={teams.data.data.data}
					organisationId={organisation?.id || 0}
					callback={(success) => {
						if (success) {
							user.refetch();
							courses.refetch();
						}
					}}
				/>
			</PageWrapper>
		</>
	);
};
export default OrganisationUserCourses;
