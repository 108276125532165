import { translate } from '@lang/index';
import { useMemo } from 'react';
// Components
import Modal from '@components/modal/Modal';

interface LearningOutcomesProps {
	course: SaCourseRes | CourseRes;
	open: boolean;
	setOpen: (state: boolean) => void;
}

const LearningOutcomes: React.FC<LearningOutcomesProps> = ({
	open,
	setOpen,
	course,
}) => {
	// ----------------------
	// Memos
	const itemsSplit = useMemo(() => {
		if (!course.meta?.completion_abilities) return [];
		return course.meta.completion_abilities.split('\n');
	}, [course]);

	// -------------------------------------------------
	// Render
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={translate('learning_outcomes')}>
			<ol>
				{itemsSplit.map((item, index) => (
					<li
						key={index}
						className="mb-2 flex w-full items-start last:mb-0">
						<span className="mr-2.5 mt-[2px] flex h-5 w-5 min-w-[20px] items-center justify-center rounded-full bg-brandPrimary text-brandPrimaryText">
							{index + 1}
						</span>
						{item}
					</li>
				))}
			</ol>
		</Modal>
	);
};

export default LearningOutcomes;
