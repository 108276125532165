import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import Sort from '@components/query/Sort';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Breadcrumbs from '@components/partials/Breadcrumbs';
import HeadingTabs from '@components/partials/HeadingTabs';
import Error from '@components/partials/Error';
import Loading from '@components/partials/Loading';
import Button from '@components/partials/Button';
// Table
import OrganisationUsersTable from '@components/table/sa/OrganisationUsersTable';
// Modals
import InviteOrgUserSA from '@components/modal/InviteOrgUserSA';

const OrganisationUsers = () => {
	// -------------------------------------------------
	// State
	const { id } = useParams();
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			perPage: 10,
			sort: [
				{
					key: 'name',
					value: 'off',
				},
				{
					key: 'email',
					value: 'off',
				},
				{
					key: 'last_login',
					value: 'off',
				},
				{
					key: 'created_at',
					value: 'off',
				},
			],
			filter: [],
		});
	const [openInviteUser, setOpenInviteUser] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const organisation = useQuery(
		['sa.organisations.getSingle', id],
		() => {
			return api.sa.organisations.getSingle({
				id: id as string,
				include: {
					courses: true,
					coursesCount: false,
					media: false,
					mediaCount: false,
					plan: false,
					planCount: false,
					partnerCourseCategories: false,
					meta: false,
				},
			});
		},
		{
			enabled: !!id,
		}
	);
	// -------------------------------------------------
	// Render
	if (organisation.isLoading) return <Loading type="page" />;
	if (organisation.isError) return <Error type="page" />;

	return (
		<>
			<PageHeading
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: '/organisations',
								label: translate('organisations'),
							},
							{
								href: `/organisations/${id}`,
								label: translate('edit'),
							},
							{
								href: `/organisations/${id}`,
								label: translate('users'),
							},
						]}
					/>
				}
				title={
					organisation.data.data.data.name ||
					translate('edit_organisation')
				}>
				<div className="flex flex-wrap justify-between gap-2.5">
					<HeadingTabs
						tabs={[
							{
								name: translate('edit'),
								href: `/organisations/${id}`,
							},
							{
								name: translate('users'),
								href: `/organisations/${id}/users`,
							},
							{
								name: translate('courses'),
								href: `/organisations/${id}/courses`,
							},
							{
								name: translate('partner'),
								href: `/organisations/${id}/partnership`,
							},
						]}
					/>
					<div>
						<Button
							type="button"
							theme="primary"
							onClick={() => {
								setOpenInviteUser(true);
							}}>
							{translate('add_user')}
						</Button>
					</div>
				</div>
			</PageHeading>
			<PageWrapper>
				<OrganisationUsersTable
					enabled={enabled}
					organisation={organisation.data.data.data}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					utilityBar={
						<Sort
							sort={searchParams.sort.value}
							setSort={searchParams.sort.setValue}
							labels={[
								{
									key: 'name',
									label: translate('name'),
								},
								{
									key: 'email',
									label: translate('email'),
								},
								{
									key: 'last_login',
									label: translate('last_login'),
								},
								{
									key: 'created_at',
									label: translate('created_at'),
								},
							]}
						/>
					}
				/>
				<InviteOrgUserSA
					open={openInviteUser}
					setOpen={setOpenInviteUser}
					organisationId={organisation.data.data.data.id}
				/>
			</PageWrapper>
		</>
	);
};

export default OrganisationUsers;
