import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@hooks/index';

interface AuthenticatedGuardProps {
	unAuthenticatedOnly: boolean;
}

const AuthenticatedGuard: React.FC<AuthenticatedGuardProps> = ({
	unAuthenticatedOnly,
}) => {
	const { auth } = useAuth();
	const location = useLocation();

	if (unAuthenticatedOnly) {
		if (auth) {
			if (location.pathname === '/password-reset')
				return <Navigate to="/account" />;
			return <Navigate to="/" />;
		}
	} else {
		if (!auth) {
			return <Navigate to="/login" />;
		}
	}

	return <Outlet />;
};

export default AuthenticatedGuard;
