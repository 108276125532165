import classNames from 'classnames';
import { useRef } from 'react';
import {
	faBookOpen,
	faBook,
	faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface NavigationChapterItemProps {
	chapter: ChapterCourseItem;
	overflowHeight: number;
	hasChildren: boolean;
	functions: {
		setDropdownMaxHeight: () => void;
	};
}

const NavigationChapterItem: React.FC<NavigationChapterItemProps> = ({
	chapter,
	overflowHeight,
	hasChildren,
	functions: { setDropdownMaxHeight },
}) => {
	// Refs
	const dropdownButtonRef = useRef<HTMLButtonElement>(null);

	return (
		<div className="px-2">
			<div className="group relative flex items-center">
				<button
					id={`chapter-${chapter.model_id}`}
					aria-expanded={overflowHeight > 0}
					ref={dropdownButtonRef}
					className={classNames('chapter-group', {
						'cursor-pointer': hasChildren,
						'cursor-default': !hasChildren,
					})}
					onClick={setDropdownMaxHeight}
					aria-label="Toggle dropdown">
					<FontAwesomeIcon
						className={classNames('mr-3 h-6 w-6 text-uiDarkGray', {
							'cursor-pointer': hasChildren,
						})}
						icon={overflowHeight > 0 ? faBookOpen : faBook}
						onClick={() => {
							dropdownButtonRef.current?.click();
						}}
					/>
				</button>
				<h2
					className={classNames(
						'pr-8 text-sm font-semibold text-title',
						{
							'cursor-pointer group-hover:underline': hasChildren,
						}
					)}
					onClick={() => {
						dropdownButtonRef.current?.click();
					}}>
					{chapter.model.title}
				</h2>
				{hasChildren && (
					<button
						className="absolute right-0 top-1/2 -translate-y-1/2"
						onClick={() => {
							dropdownButtonRef.current?.click();
						}}>
						<FontAwesomeIcon
							className={classNames(
								'h-4 w-4 text-uiDarkGray text-opacity-60 transition-all duration-200 group-hover:text-brandTertiaryHover',
								{
									'rotate-180 transform': overflowHeight > 0,
								}
							)}
							icon={faChevronCircleDown}
						/>
					</button>
				)}
			</div>
			{/*  Description */}
			{chapter.model.desc && (
				<p className="pt-1 pb-1 text-sm text-body">
					{chapter.model.desc}
				</p>
			)}
		</div>
	);
};

export default NavigationChapterItem;
