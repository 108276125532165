import { rolesT, translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Button from '@components/partials/Button';
import FormAction from '@components/forms/FormAction';
import FormRow from '@components/forms/FormRow';
import Switch from '@components/forms/Switch';
import MultiSelect from '@components/forms/MultiSelect';
// Queries
import { useAuthenticatedUser } from '@queries/user';

interface UpdateOrgUserProps extends ActionProps {
	user: UserRes;
	organisationId: number;
}

const UpdateOrgUser: React.FC<UpdateOrgUserProps> = ({
	user,
	organisationId,
	callback,
}) => {
	// -------------------------------------------------
	// State
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	// Inputs
	const [seated, setSeated] = useState<boolean>(
		user.seated_at !== null ? true : false
	);
	const [selectedRoles, setSelectedRoles] = useState<
		{
			key: string;
			label: string;
			data: RoleRes;
		}[]
	>(
		user.roles?.map((role) => {
			return {
				key: role.name,
				label: rolesT(role.name),
				data: role,
			};
		}) || []
	);

	// -------------------------------------------------
	// Queries / Mutations
	const currentUser = useAuthenticatedUser();
	const updateUser = useMutation(api.organisations.users.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'User',
				}),
				message: translate('toast_updated_message', {
					name: 'User',
				}),
				type: 'success',
			});

			queryClient.invalidateQueries(['organisations.current.get']);

			if (currentUser.data?.data.data.id === user.id) {
				queryClient.invalidateQueries(['root.getAuthenticatedUser']);
			}

			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});
	const roles = useQuery(['organisations.roles.getAll'], () => {
		return api.organisations.roles.getAll({
			organisationId: organisationId,
		});
	});

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		updateUser.mutateAsync({
			id: user.id,
			organisationId: organisationId,
			body: returnModifiedData([
				{
					key: 'seated',
					state: seated,
					defaultState: user.seated_at !== null ? true : false,
				},
				{
					key: 'role_ids',
					state: selectedRoles.map((role) => {
						return role.data.id;
					}),
					defaultState:
						user.roles?.map((role) => {
							return role.id;
						}) || [],
				},
			]),
		});
	};

	// -------------------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<FormRow title={translate('update')}>
				<Switch
					name="seated"
					label={translate('seated')}
					checked={seated}
					setChecked={setSeated}
					errors={formState.errors.value['seated']}
				/>
				<MultiSelect
					label={translate('roles')}
					name={'items'}
					id={'items'}
					values={selectedRoles}
					setValues={setSelectedRoles}
					errors={{}}
					options={
						roles.data?.data.data.map((role) => {
							return {
								key: role.name,
								label: rolesT(role.name),
								data: role,
							};
						}) || []
					}
					placeholder={translate('assign_roles')}
				/>
			</FormRow>
			<FormAction
				loading={{
					is: updateUser.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateUser.isError,
					message: formState.errorMessage.value,
				}}>
				<Button theme="primary" type="submit">
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateOrgUser;
