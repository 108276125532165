import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Types
import { NestedItem } from '@utils/helpers';
import { GoToT } from '@routes/courses/single/View';
// Items
import ChapterItem from '@components/groups/single-course/items/ChapterItem';
import VideoQuizItem from '@components/groups/single-course/items/VideoQuizItem';
import classNames from 'classnames';

interface NavigationItemGroupProps {
	mode: 'preview' | 'standard';
	group: NestedItem;
	status: CourseStatusRes[];
	categories: SaCategoriesRes[] | CategoriesRes[];
	functions: {
		goTo: GoToT;
	};
}

const NavigationItemGroup: React.FC<NavigationItemGroupProps> = ({
	mode,
	group,
	status,
	categories,
	functions: { goTo },
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { itemId } = useParams<{
		organisationSlug: string;
		id: string;
		itemType: 'video' | 'assessment';
		itemId: string;
	}>();
	const [lockAutoSelect, setLockAutoSelect] = useState(false);
	const [overflowHeight, setOverflowHeight] = useState(0);

	// Refs
	const dropdownRef = useRef<HTMLDivElement>(null);

	// -------------------------------------------------
	// Functions
	const setDropdownMaxHeight = () => {
		const dropdown = dropdownRef.current;
		if (!dropdown) return;
		if (!group.children.length) return;

		if (overflowHeight > 0) return setOverflowHeight(0);

		const dropdownHeight = dropdown.scrollHeight;
		setOverflowHeight(dropdownHeight);
		//
		const allChapterGroups = document.querySelectorAll(
			'.chapter-group'
		) as NodeListOf<HTMLButtonElement>;

		// close all other dropdowns
		allChapterGroups.forEach((chapterGroup) => {
			if (chapterGroup.getAttribute('aria-expanded') === 'true') {
				chapterGroup.click();
			}
		});
	};
	const anItemIsSelected = () => {
		if (!itemId) return false;
		return group.children.some((item) => {
			return item.model_id === parseInt(itemId);
		});
	};

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		if (lockAutoSelect) return;
		if (!anItemIsSelected()) return;
		setDropdownMaxHeight();
		setLockAutoSelect(true);
		// eslint-disable-next-line
	}, []);

	// -------------------------------------------------
	// Render
	return (
		<li
			className={classNames('w-full ', {
				'mt-2.5 border-t border-border pt-3.5 first:mt-0 first:border-t-0 first:pt-0':
					group.item.model_type === 'chapter',
			})}>
			{/* Top Level Item */}
			{group.item.model_type === 'chapter' ? (
				<ChapterItem
					chapter={group.item}
					overflowHeight={overflowHeight}
					hasChildren={group.children.length > 0}
					functions={{
						setDropdownMaxHeight,
					}}
				/>
			) : (
				<VideoQuizItem
					mode={mode}
					item={group.item}
					topLevel={true}
					status={status}
					categories={categories}
					functions={{
						goTo,
					}}
				/>
			)}
			{/* Dropdown Row */}
			{group.children.length > 0 && (
				<div
					ref={dropdownRef}
					className="overflow-hidden transition-all duration-200"
					style={{ maxHeight: `${overflowHeight}px` }}>
					<ul className="mt-4 mb-1 border-l pl-4">
						{group.children.map((item, index) => (
							<VideoQuizItem
								key={index}
								mode={mode}
								item={item}
								topLevel={false}
								status={status}
								categories={categories}
								functions={{
									goTo,
								}}
							/>
						))}
					</ul>
				</div>
			)}
		</li>
	);
};

export default NavigationItemGroup;
