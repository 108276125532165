import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, Navigate } from 'react-router-dom';
import { type AxiosResponse } from 'axios';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/useOrganisation';
// Components
import Loading from '@components/partials/Loading';

interface CourseViewRedirectProps {
	mode: 'preview' | 'standard';
}

const CourseViewRedirect: React.FC<CourseViewRedirectProps> = ({ mode }) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { id } = useParams<{
		id: string;
	}>();
	const [firstItem, setFirstItem] = useState<CourseItems | undefined>(
		undefined
	);
	const [settingData, setSettingData] = useState(true);

	// -------------------------------------------------
	// Queries / Mutations
	type PreviewCourseRes = AxiosResponse<APIResponse<SaCourseRes>, any>;
	type StandardCourseRes = AxiosResponse<APIResponse<CourseRes>, any>;

	const course = useQuery<PreviewCourseRes | StandardCourseRes>(
		[
			mode === 'preview'
				? 'single-course.preview'
				: 'single-course.standard',
			id,
			organisation?.id,
		],
		() => {
			if (mode === 'preview') {
				return api.sa.courses.getSingle({
					id: id || '0',
					include: {
						categories: false,
						categoriesCount: false,
						items: true,
						itemsCount: true,
						itemsModel: true,
						media: false,
						mediaCount: false,
						organisations: false,
						organisationsCount: false,
						categoriesMeta: false,
						type: false,
						meta: false,
					},
				});
			}
			return api.organisations.courses.getSingle({
				organisationId: organisation?.id || 0,
				courseId: parseInt(id || '0'),
				include: {
					items: true,
					itemsCount: true,
					itemsModel: true,
					itemsModelType: true,
					media: true,
					mediaCount: true,
					type: true,
					typeCount: true,
					teams: false,
					users: false,
					meta: false,
				},
			});
		},
		{
			onSuccess: (data) => {
				const firstNonChapter = data.data.data.items?.find(
					(item) => item.model_type !== 'chapter'
				) as VideoCourseItem | QuizCourseItem | undefined;

				if (firstNonChapter) {
					setFirstItem(firstNonChapter);
				} else {
					setFirstItem(data.data.data.items?.[0]);
				}

				setSettingData(false);
			},
		}
	);

	const firstItemModelType = useMemo(() => {
		if (firstItem?.model_type === 'quiz') return 'assessment';
		else return firstItem?.model_type;
	}, [firstItem?.model_type]);

	// -------------------------------------------------
	// Render
	if (course.isLoading || settingData) return <Loading type="page" />;

	// Go To course item
	const slugPrefix =
		mode === 'preview' ? '/courses' : `/${organisation?.slug}/courses`;

	if (!course.data?.data.data.items?.length || firstItem === undefined) {
		return <Navigate to={`${slugPrefix}/${id}`} replace={true} />;
	}

	return (
		<Navigate
			to={`${slugPrefix}/${id}/${firstItemModelType}/${firstItem.model_id}`}
			replace={true}
		/>
	);
};

export default CourseViewRedirect;
