import { useMemo } from 'react';
// Types
import { NestedItem } from '@utils/helpers';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Modal from '@components/modal/Modal';
import CategoryIcon from '@components/partials/CategoryIcon';
import { Link } from 'react-router-dom';
import Button from '@components/partials/Button';

interface CourseChapterProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	courseId: number;
	nestedItem: NestedItem;
	categories: CategoriesRes[];
}

const CourseChapter: React.FC<CourseChapterProps> = ({
	open,
	setOpen,
	courseId,
	nestedItem,
	categories,
}) => {
	// -------------------------------------------------
	// State
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Memos
	const firstChild = useMemo(() => {
		return nestedItem.children[0];
	}, [nestedItem]);

	// -------------------------------------------------
	// Render
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={nestedItem.item.model.title}
			description={nestedItem.item.model.desc || ''}>
			<ul>
				{nestedItem.children.map((child, index) => (
					<Item
						key={index}
						item={child}
						categories={categories}
						courseId={courseId}
						organisationSlug={organisation?.slug}
					/>
				))}
			</ul>
			{nestedItem.children.length === 0 && (
				<p>No topics found in this chapter.</p>
			)}
			<div className="mt-5 border-t border-border pt-5">
				<Button
					type="link"
					theme="primary"
					href={`/${organisation?.slug}/courses/${courseId}/${
						firstChild?.model_type === 'quiz'
							? 'assessment'
							: 'video'
					}/${firstChild?.model_id}`}>
					Go to topic
				</Button>
			</div>
		</Modal>
	);
};

interface ItemProps {
	item: VideoCourseItem | QuizCourseItem;
	categories: CategoriesRes[];
	courseId: number;
	organisationSlug: string | undefined;
}

const Item: React.FC<ItemProps> = ({
	item,
	categories,
	organisationSlug,
	courseId,
}) => {
	// -------------------------------------------------
	// Memos
	const category = useMemo(
		() => categories.find((category) => category.id === item.model.type_id),
		[categories, item.model.type_id]
	);

	const categoryScope = useMemo<CategoryScopes>(() => {
		if (item.model_type === 'quiz') return 'quizType';
		if (item.model_type === 'video') return 'videoType';
		return 'quizType';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item.model_type]);

	// -------------------------------------------------
	// Render
	return (
		<li className="mb-2 last:mb-0">
			<Link
				to={`/${organisationSlug}/courses/${courseId}/${
					item.model_type === 'quiz' ? 'assessment' : 'video'
				}/${item.model_id}`}
				className="group flex items-center">
				<span className="relative mr-3">
					<CategoryIcon
						colour={category?.meta.icon_colour}
						initials={category?.meta.icon_icon_initials}
						label={category?.name}
						categoryScope={categoryScope}
						size={'sm'}
					/>
				</span>
				<span className="text-left text-sm group-hover:underline">
					{item.model.title}
				</span>
			</Link>
		</li>
	);
};

export default CourseChapter;
