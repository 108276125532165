import { translate } from '@lang/index';
// import { useMemo } from 'react';
import { faChair, faUser } from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import Badges from '@components/partials/Badges';
import { Link } from 'react-router-dom';
// import classNames from 'classnames';

interface OrganisationRowProps {
	organisation: SaOrganisationsRes;
	selectRow: (
		course: SaOrganisationsRes,
		action: 'invite' | 'activate' | 'delete'
	) => void;
	stripe_link?: string;
}

const OrganisationRow: React.FC<OrganisationRowProps> = ({
	organisation,
	selectRow,
	stripe_link,
}) => {
	// -------------------------------------------------
	// Memos
	// const allCourses = useMemo(() => {
	// 	return organisation.subscribed_to?.includes('courses');
	// }, [organisation]);

	// const allContent = useMemo(() => {
	// 	return organisation.subscribed_to?.includes('content');
	// }, [organisation]);

	// -------------------------------------------------
	// Render
	return (
		<tr>
			<td className="td-default">
				<Link
					to={`/organisations/${organisation.id}`}
					className="text-sm font-semibold hover:underline">
					{organisation.name}
				</Link>
			</td>
			<td className="td-default">
				{stripe_link ? (
					<a href={stripe_link} target="_blank" rel="noreferrer">
						<Badges theme="grey">
							{organisation.subscriptions_count || 0}
						</Badges>
					</a>
				) : (
					<>-</>
				)}

				{/* <div>
						<FontAwesomeIcon
							title={
								allCourses ? 'Access to all courses' : undefined
							}
							icon={faBook}
							className={classNames('mr-2', {
								'text-icon': !allCourses,
								'text-brandTertiaryHover': allCourses,
							})}
						/>
						<FontAwesomeIcon
							title={
								allContent
									? 'Access to all video libraries'
									: undefined
							}
							icon={faVideo}
							className={classNames({
								'text-icon': !allContent,
								'text-brandTertiaryHover': allContent,
							})}
						/>
					</div> */}
			</td>
			<td className="td-default">
				<Link to={`/organisations/${organisation.id}/courses`}>
					<Badges theme="grey">
						{organisation.courses_count || 0}
					</Badges>
				</Link>
			</td>
			<td className="td-default">
				{organisation.plan ? (
					<div className="flex flex-col justify-center">
						<span className="font-semibold">
							{organisation.plan?.name}
						</span>
						<div className="mt-1 flex">
							<div className="mr-2 text-xs font-semibold">
								<FontAwesomeIcon
									icon={faChair}
									className="mr-1 text-icon"
								/>
								{organisation?.seated_users_count || 0}/
								{organisation.plan?.seats}
							</div>
							<div className="text-xs font-semibold">
								<FontAwesomeIcon
									icon={faUser}
									className="mx-1 text-icon"
								/>
								{organisation?.users_count || 0}/
								{organisation.plan?.users}
							</div>
						</div>
					</div>
				) : (
					<span className="text-xs">{translate('no_plan')}</span>
				)}
			</td>
			<td className="td-default">
				{organisation.source ? organisation.source : <>-</>}
			</td>
			<td className="td-default">
				{organisation.type ? organisation.type : <>-</>}
			</td>
			<td className="td-default">
				<Badges theme={organisation.active ? 'green' : 'red'}>
					{organisation.active ? 'Active' : 'Inactive'}
				</Badges>
			</td>
			<DropdownMenuColumn
				items={[
					{
						label: translate('edit'),
						type: 'link',
						href: `/organisations/${organisation.id}`,
						show: true, //organisation.can.update,
					},
					{
						label: translate('users'),
						type: 'link',
						href: `/organisations/${organisation.id}/users`,
						show: true, //organisation.can.update,
					},
					{
						label: organisation.active
							? translate('suspend')
							: translate('activate'),
						type: 'button',
						show: true, //organisation.can.update,
						onClick: () => {
							selectRow(organisation, 'activate');
						},
					},
					{
						label: translate('delete'),
						type: 'button',
						show: true, //organisation.can.delete,
						onClick: () => {
							selectRow(organisation, 'delete');
						},
					},
				]}
			/>
		</tr>
	);
};

export default OrganisationRow;
