import { translate } from '@lang/index';
import { Outlet, Navigate } from 'react-router-dom';
// Hooks
import { useToast, usePermissions } from '@hooks/index';

interface OrganisationPermissionGuardProps {
	permissions: OrganisationPermissions | OrganisationPermissions[];
}

const OrganisationPermissionGuard: React.FC<
	OrganisationPermissionGuardProps
> = ({ permissions }) => {
	const { hasPermission } = usePermissions();
	const { addToast } = useToast();

	if (!hasPermission(permissions)) {
		addToast({
			title: translate('toast_unauthorised_title'),
			message: translate('toast_unauthorised_message'),
			type: 'error',
		});
		return <Navigate to="/" />;
	}

	return <Outlet />;
};

export default OrganisationPermissionGuard;
