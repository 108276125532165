import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/useOrganisation';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import Pagination from '@components/query/Pagination';
import TopPagination from '@components/query/FormUtilityBar';
import BookmarkRow from '@components/rows/BookmarkRow';

interface BookmarksTableProps extends QueryTableProps {
	utilityBar?: React.ReactNode;
}

const BookmarksTable: React.FC<BookmarksTableProps> = ({
	searchParams,
	queryString,
	utilityBar,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries / Mutations
	const bookmarks = useQuery(
		['organisations.bookmarks.getMultiple', queryString, organisation?.id],
		() => {
			return api.organisations.bookmarks.getMultiple({
				organisationId: organisation?.id as number,
				queryString: queryString,
				include: {
					bookmarkable: true,
					course: true,
					courseMedia: true,
					bookmarkableMedia: true,
				},
				filters: [
					{
						key: 'has_context',
						value: 1,
					},
				],
			});
		},
		{
			enabled: !!organisation?.id && enabled,
		}
	);

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={bookmarks.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: bookmarks.isLoading,
					error: bookmarks.isError,
					noData: bookmarks.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Bookmark',
					}),
					message: translate('no_data_message', {
						name: 'bookmark',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('title'),
							type: 'standard',
						},
						{
							label: translate('type'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'types'
							),
						},
						{
							label: translate('bookmarked'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{bookmarks.data?.data.data.map((bookmark, index) => (
						<BookmarkRow bookmark={bookmark} key={index} />
					))}
				</Table>
				<Pagination
					meta={bookmarks.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
			</DynamicBlock>
		</>
	);
};

export default BookmarksTable;
