import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IconTdProps {
	icon: IconDefinition;
	children: any;
}

const IconTd: React.FC<IconTdProps> = ({ icon, children }) => {
	return (
		<td className="td-default">
			<FontAwesomeIcon className="text-brandTertiary mt-1" icon={icon} />
			<span className="ml-2.5 text-sm">{children}</span>
		</td>
	);
};

export default IconTd;
