import { translate } from '@lang/index';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Types
import { NestedItem } from '@utils/helpers';
// Hooks
import { useSearchParams, useOrganisation } from '@hooks/index';
// Components
import Pagination from '@components/query/Pagination';
import Sort from '@components/query/Sort';
import Search from '@components/query/Search';
import PerPage from '@components/query/PerPage';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import DynamicBlock from '@components/blocks/DynamicBlock';
import UtilityRow from '@components/blocks/UtilityRow';
import VideoLibraryCard from '@components/cards/VideoLibraryCard';
import UpsellButton from '@components/partials/UpsellButton';
// Modals
import CourseChapter from '@components/modal/CourseChapter';

const OrganisationCoursesList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { searchParams, queryString, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'title',
					value: 'off',
				},
				{
					key: 'progress',
					value: 'off',
				},
				{
					key: 'access',
					value: 'asc',
				},
			],
			filter: [],
		});

	const [selectedNestedItem, setSelectedNestedItem] =
		useState<NestedItem | null>(null);
	const [selectedContentId, setSelectedContentId] = useState<number | null>(
		null
	);
	const [openCourseChapterModal, setOpenCourseChapterModal] = useState(false);

	// -------------------------------------------------
	// Mutations / Queries
	const content = useQuery(
		[
			'organisations.content.getMultiple.content',
			queryString,
			organisation,
		],
		() => {
			return api.organisations.courses.getMultiple({
				queryString: queryString,
				organisationId: organisation?.id || 0,
				filters: {
					courseTypeId: 2,
				},
				include: {
					items: true,
					itemsModel: true,
					itemsModelType: true,
					media: true,
					type: true,
					teamsCount: false,
					usersCount: false,
				},
				perPage: 12,
			});
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: enabled,
		}
	);
	const bookmarks = useQuery(
		[
			'organisations.bookmarks.getMultiple.list',
			organisation?.id,
			content.data?.data.data,
		],
		() => {
			return api.organisations.bookmarks.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					bookmarkable: false,
					course: false,
					courseMedia: false,
					bookmarkableMedia: false,
				},
				filters: [
					{
						key: 'course_id',
						value: content.data?.data.data.map(
							(content) => content.id
						) as number[],
					},
					{
						key: 'bookmarkable_type',
						value: 'course',
					},
				],
				perPage: -1,
			});
		},
		{
			enabled: !!content.data?.data.data,
		}
	);
	const categories = useQuery(
		['organisations.categories.getAll', organisation?.id],
		() => {
			return api.organisations.categories.getAll({
				organisationId: organisation?.id || 0,
				include: {
					meta: true,
					metaCount: false,
				},
				sort: {
					name: 'asc',
				},
			});
		}
	);
	const courseProgress = useQuery(
		['organisations.courses.progress.getMultiple', organisation?.id],
		() => {
			return api.organisations.courses.progress.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: false,
					user: false,
				},
				perPage: -1,
			});
		}
	);

	// -------------------------------------------------
	// Memos
	const isLoading = useMemo(() => {
		return (
			content.isLoading || bookmarks.isLoading || courseProgress.isLoading
		);
	}, [content.isLoading, bookmarks.isLoading, courseProgress.isLoading]);

	const isError = useMemo(() => {
		return content.isError || bookmarks.isError || courseProgress.isError;
	}, [content.isError, bookmarks.isError, courseProgress.isError]);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('video_library')}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'title',
								label: translate('title'),
							},
							{
								key: 'progress',
								label: translate('progress'),
							},
							{
								key: 'access',
								label: translate('access'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={translate('search_for_video_library')}
					/>
				}
				actions={
					<UpsellButton pulse={true} />
				}
			/>
			<PageWrapper>
				<UtilityRow>
					<div className="flex w-full justify-end">
						<PerPage
							perPage={searchParams.perPage.value}
							setPerPage={searchParams.perPage.setValue}
							options={[
								{
									label: '10',
									value: 10,
								},
								{
									label: '20',
									value: 20,
								},
								{
									label: 'All',
									value: 500,
								},
							]}
						/>
					</div>
				</UtilityRow>
				<DynamicBlock
					state={{
						loading: isLoading,
						error: isError,
						noData: content.data?.data.data.length === 0,
					}}
					noData={{
						title: translate('no_data_title', {
							name: 'Video Library',
						}),
						message: translate('no_video_library_message'),
					}}>
					<ul>
						{content.data?.data.data.map((content, index) => (
							<VideoLibraryCard
								key={index}
								content={content}
								courseProgress={
									courseProgress.data?.data.data || []
								}
								organisationSlug={organisation?.slug || ''}
								bookmarks={bookmarks.data?.data.data}
								categories={categories.data?.data.data || []}
								chapterModal={{
									open: openCourseChapterModal,
									setOpen: setOpenCourseChapterModal,
								}}
								setSelectedNestedItem={setSelectedNestedItem}
								setSelectedContentId={setSelectedContentId}
								functions={{
									refetchBookmarks: bookmarks.refetch,
								}}
							/>
						))}
					</ul>
					<Pagination
						meta={content.data?.data?.meta}
						page={searchParams.page.value}
						setPage={searchParams.page.setValue}
					/>
				</DynamicBlock>
			</PageWrapper>
			{selectedNestedItem !== null && selectedContentId !== null && (
				<CourseChapter
					open={openCourseChapterModal}
					setOpen={setOpenCourseChapterModal}
					courseId={selectedContentId}
					nestedItem={selectedNestedItem}
					categories={categories.data?.data.data || []}
				/>
			)}
		</>
	);
};
export default OrganisationCoursesList;
