import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
// api
import api from 'api';
// Hooks
import { useSearchParams, useOrganisation, usePermissions } from '@hooks/index';
// Components
import Pagination from '@components/query/Pagination';
import Sort from '@components/query/Sort';
import Filter from '@components/query/Filter';
import CategoryTabFilter from '@components/query/CategoryTabFilter';
import Search from '@components/query/Search';
import PerPage from '@components/query/PerPage';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import DynamicBlock from '@components/blocks/DynamicBlock';
import UtilityRow from '@components/blocks/UtilityRow';
import GridContainer from '@components/blocks/GridContainer';
import CourseCard from '@components/cards/CourseCard';
import FilterPresetsRow from '@components/query/FilterPresetsRow';
import UpsellButton from '@components/partials/UpsellButton';
// Modal
import CourseExpiredNavigation from '@components/modal/CourseExpiredNavigation';

const OrganisationCoursesList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { hasOrgPlan } = usePermissions();
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 12,
			sort: [
				{
					key: 'title',
					value: 'off',
				},
				{
					key: 'progress',
					value: 'off',
				},
				{
					key: 'access',
					value: 'asc',
				},
			],
			filter: [
				{
					key: 'recommended',
					type: 'boolean',
					value: '',
				},
				{
					key: 'category_ids',
					type: 'select',
					value: '',
				},
			],
		});
	const [navigationWarningOpen, setNavigationWarningOpen] = useState(false);
	const [selectedCourseId, setSelectedCourseId] = useState<number | null>(
		null
	);

	// -------------------------------------------------
	// Mutations / Queries
	const courses = useQuery(
		[
			'organisations.courses.getMultiple.courses',
			queryString,
			organisation,
		],
		() => {
			return api.organisations.courses.getMultiple({
				queryString: queryString,
				organisationId: organisation?.id || 0,
				filters: {
					courseTypeId: 1,
				},
				include: {
					items: true,
					itemsModel: true,
					itemsModelType: true,
					media: true,
					type: true,
					teamsCount: false,
					usersCount: false,
				},
				perPage: 12,
			});
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			enabled: enabled,
		}
	);
	const bookmarks = useQuery(
		[
			'organisations.bookmarks.getMultiple.list',
			organisation?.id,
			courses.data?.data.data,
		],
		() => {
			return api.organisations.bookmarks.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					bookmarkable: false,
					course: false,
					courseMedia: false,
					bookmarkableMedia: false,
				},
				filters: [
					{
						key: 'course_id',
						value: courses.data?.data.data.map(
							(course) => course.id
						) as number[],
					},
					{
						key: 'bookmarkable_type',
						value: 'course',
					},
				],
				perPage: -1,
			});
		},
		{
			enabled: !!courses.data?.data.data,
		}
	);
	const categories = useQuery(
		['organisations.categories.getAll', organisation?.id],
		() => {
			return api.organisations.categories.getAll({
				organisationId: organisation?.id || 0,
				include: {
					meta: true,
					metaCount: false,
				},
				sort: {
					ref: 'asc',
				},
			});
		}
	);
	const courseProgress = useQuery(
		['organisations.courses.progress.getMultiple', organisation?.id],
		() => {
			return api.organisations.courses.progress.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: false,
					user: false,
				},
				perPage: -1,
			});
		}
	);

	// -------------------------------------------------
	// Memos
	const courseCategoryLabels = useMemo(() => {
		const catLabels = [];

		for (let i = 0; i < (categories.data?.data.data || []).length; i++) {
			if (categories.data?.data.data[i].category_scope_id === 1) {
				catLabels.push({
					label: categories.data?.data.data[i].name,
					value: categories.data?.data.data[i].id.toString(),
				});
			}
		}
		return catLabels;
	}, [categories.data?.data.data]);

	const isLoading = useMemo(() => {
		return (
			courses.isLoading || bookmarks.isLoading || courseProgress.isLoading
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courses.isLoading, bookmarks.isLoading || courseProgress.isLoading]);

	const isError = useMemo(() => {
		return courses.isError || bookmarks.isError || courseProgress.isError;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courses.isError, bookmarks.isError || courseProgress.isError]);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('courses')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'recommended',
								label: translate('recommended'),
								permission: hasOrgPlan,
							},
							{
								key: 'category_ids',
								label: translate('categories'),
								values: courseCategoryLabels,
								allowAll: true,
							},
						]}
						loading={categories.isLoading}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'title',
								label: translate('title'),
							},
							{
								key: 'progress',
								label: translate('progress'),
							},
							{
								key: 'access',
								label: translate('access'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder="Search for a course"
						width="full"
					/>
				}
				actions={
					<UpsellButton pulse={true} />
				}
			>
				<CategoryTabFilter 
					filter={searchParams.filter.value}
					setFilter={searchParams.filter.setValue}
					resetFilters={resetFilters}
					categories={courseCategoryLabels}
					loading={isLoading}
					totalCourses={courses.data?.data.data.length || 0}
				/>
			</PageHeading>
			<PageWrapper>
				<UtilityRow>
					<div className="flex w-full justify-between">
						<FilterPresetsRow
							filter={searchParams.filter.value}
							setMultipleFilters={
								searchParams.filter.setMultipleValues
							}
							presets={[
								{
									label: 'Nominated',
									filters: [
										{
											key: 'recommended',
											value: '1',
										},
									],
									permission: hasOrgPlan,
								},
							]}
						/>
						<PerPage
							perPage={searchParams.perPage.value}
							setPerPage={searchParams.perPage.setValue}
							options={[
								{
									label: '12',
									value: 12,
								},
								{
									label: '24',
									value: 24,
								},
								{
									label: 'All',
									value: 500,
								},
							]}
						/>
					</div>
				</UtilityRow>
				<DynamicBlock
					state={{
						loading: isLoading,
						error: isError,
						noData: courses.data?.data.data.length === 0,
					}}
					noData={{
						title: translate('no_data_title', {
							name: 'Course',
						}),
						message: translate('no_org_courses_message'),
					}}>
					<GridContainer
						state={{
							loading: false,
							error: false,
							noData: false,
						}}>
						{courses.data?.data.data.map((course, index) => (
							<CourseCard
								key={index}
								course={course}
								organisationSlug={organisation?.slug || ''}
								bookmarks={bookmarks.data?.data.data || []}
								categories={categories.data?.data.data || []}
								courseProgress={
									courseProgress.data?.data.data || []
								}
								functions={{
									showNavigationWarning: (courseId) => {
										setSelectedCourseId(courseId);
										setNavigationWarningOpen(true);
									},
								}}
							/>
						))}
					</GridContainer>
					<Pagination
						meta={courses.data?.data?.meta}
						page={searchParams.page.value}
						setPage={searchParams.page.setValue}
					/>
				</DynamicBlock>
				<CourseExpiredNavigation
					open={navigationWarningOpen && !!selectedCourseId}
					setOpen={setNavigationWarningOpen}
					courseId={selectedCourseId as number}
				/>
			</PageWrapper>
		</>
	);
};
export default OrganisationCoursesList;
