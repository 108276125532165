import { translate } from '@lang/index';
import { faUsers, faBook } from '@fortawesome/free-solid-svg-icons';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import { Link } from 'react-router-dom';
import IconTd from '@components/partials/IconTd';

interface OrgTeamRowProps {
	team: TeamRes;
	organisationSlug: string;
	selectRow: (team: TeamRes, action: 'delete') => void;
}

const OrgTeamRow: React.FC<OrgTeamRowProps> = ({
	team,
	organisationSlug,
	selectRow,
}) => {
	return (
		<tr>
			<td className="td-default">
				<Link
					to={`/${organisationSlug}/teams/${team.id}`}
					className="text-sm font-semibold hover:underline">
					{team.name}
				</Link>
			</td>
			<IconTd icon={faUsers}>{team.users_count || 0}</IconTd>
			<IconTd icon={faBook}>{team.courses_count || 0}</IconTd>
			<DropdownMenuColumn
				items={[
					{
						label: translate('edit'),
						type: 'link',
						href: `/${organisationSlug}/teams/${team.id}`,
						show: true,
					},
					{
						label: translate('delete'),
						type: 'button',
						show: true,
						onClick: () => {
							selectRow(team, 'delete');
						},
					},
				]}
			/>
		</tr>
	);
};

export default OrgTeamRow;
