import { translate } from '@lang/index';
import { useState, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Textarea from '@components/forms/Textarea';
import Select from '@components/forms/Select';
import Button from '@components/partials/Button';
import SingleFileUpload from '@components/forms/SingleFileUpload';
import FormRow from '@components/forms/FormRow';
import FormAction from '@components/forms/FormAction';
import FormColumns from '@components/blocks/FormColumns';
import TabList from '@components/blocks/TabList';
import MessageBlock from '@components/blocks/MessageBlock';

interface UpdateQuizActionProps extends ActionProps {
	quiz: SaQuizzesRes;
	quizTypes: SaQuizTypesRes[];
}

const UpdateQuizAction: React.FC<UpdateQuizActionProps> = ({
	quiz,
	quizTypes,
	callback,
}) => {
	// -------------------------------------------------
	// State
	const { addToast } = useToast();
	const formState = useFormState();
	const [title, setTitle] = useState(quiz.title);
	const [desc, setDesc] = useState(quiz.desc || '');
	const [ref, setRef] = useState(quiz.ref || '');
	const [typeId, setTypeId] = useState(quiz.type_id);
	const [newFile, setNewFile] = useState<File | undefined | null>(undefined);

	const [active, setActive] = useState('preview');

	// -------------------------------------------------
	// Queries / Mutations
	const updateQuiz = useMutation(api.sa.quizzes.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Assessment',
				}),
				message: translate('toast_updated_message', {
					name: 'Assessment',
				}),
				type: 'success',
			});
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		await updateQuiz.mutateAsync({
			id: quiz.id,
			body: returnModifiedData([
				{
					key: 'title',
					state: title,
					defaultState: quiz.title,
				},
				{
					key: 'desc',
					state: desc,
					defaultState: quiz.desc,
				},
				{
					key: 'ref',
					state: ref,
					defaultState: quiz.ref,
				},
				{
					key: 'file',
					state: newFile,
					defaultState: undefined,
				},
				{
					key: 'type_id',
					state: typeId,
					defaultState: quiz.type_id,
				},
			]),
		});
	};

	// -------------------------------------------------
	// Memo
	const queryParam = useMemo(() => {
		const url = api.organisations.quizzes.results.xAPIStatement();
		return `?endpoint=${url}&actor={}&preview=1`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// -------------------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<FormRow title={translate('details')}>
				<Input
					id="quizTitle"
					type="text"
					name="title"
					label={translate('title')}
					value={title}
					setValue={setTitle}
					errors={formState.errors.value['title']}
					required={true}
				/>
				<FormColumns>
					<Select
						label={translate('assessment_type')}
						value={typeId}
						setValue={(state) => {
							setTypeId(state as number);
						}}
						errors={formState.errors.value['type_id']}
						options={
							quizTypes.map((type) => {
								return {
									value: type.id,
									label: `${type.name} - ${type.id}`,
								};
							}) || []
						}
					/>
					<Input
						id="quizRef"
						type="text"
						name="ref"
						label={translate('ref')}
						value={ref}
						setValue={setRef}
						errors={formState.errors.value['ref']}
						required={false}
					/>
				</FormColumns>
				<Textarea
					id="quizDesc"
					name="desc"
					label={translate('description')}
					value={desc}
					setValue={setDesc}
					errors={formState.errors.value['desc']}
					required={false}
				/>
			</FormRow>
			<FormRow title={translate('storyline')} asLabel={true}>
				<TabList
					active={active}
					setActive={setActive}
					options={[
						{
							label: 'Preview',
							value: 'preview',
						},
						{
							label: 'Upload',
							value: 'upload',
						},
					]}
				/>
				{active === 'upload' && (
					<SingleFileUpload
						id="quizFile"
						name="file"
						errors={formState.errors.value['file']}
						required={false}
						formats={['application/zip']}
						value={newFile}
						setValue={setNewFile}
						current={
							quiz.file_extracted
								? `${quiz.file_extracted}${queryParam}`
								: undefined
						}
						clearFile={false}
						setClearFile={() => {}}
						downloadText={
							quiz?.file?.file_name || 'Preview assessment'
						}
					/>
				)}
				{active === 'preview' && quiz.file_extracted !== null && (
					<div className="relative w-full overflow-hidden rounded-md before:block before:pb-[65%]">
						<iframe
							sandbox="allow-same-origin allow-scripts"
							className="absolute inset-0 h-full w-full"
							src={`${quiz.file_extracted}${queryParam}`}
							title="assessment"
							allowFullScreen
						/>
					</div>
				)}
				{active === 'preview' && quiz.file_extracted === null && (
					<MessageBlock
						title="No preview available"
						message="Please upload and save a storyline zip file to preview the assessment."
					/>
				)}
			</FormRow>
			<FormAction
				loading={{
					is: updateQuiz.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateQuiz.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateQuizAction;
