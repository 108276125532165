import { translate } from '@lang/index';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Checkbox from '@components/forms/Checkbox';
import Select from '@components/forms/Select';
import Textarea from '@components/forms/Textarea';
import FormRow from '@components/forms/FormRow';
import FormAction from '@components/forms/FormAction';
import Button from '@components/partials/Button';

interface UpdateOrganisationPartnerActionProps extends ActionProps {
	organisation: SaOrganisationsRes;
	categories: Array<SaCategoriesRes>;
	changesMade: boolean;
	setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateOrganisationPartnerAction: React.FC<
	UpdateOrganisationPartnerActionProps
> = ({ organisation, changesMade, setChangesMade, callback, categories }) => {
	// -------------------------------------------------
	// State
	const formState = useFormState();
	const { addToast } = useToast();

	const [isPartner, setIsPartner] = useState(organisation.is_partner);
	const [partnerText, setPartnerText] = useState(
		organisation.meta?.partner_text || ''
	);
	const [partnerCategoryId, setPartnerCategoryId] = useState(
		organisation?.partner_category_id || null
	);

	// -------------------------------------------------
	// Queries / Mutations
	const updateOrganisation = useMutation(api.sa.organisations.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Organisation',
				}),
				message: translate('toast_updated_message', {
					name: 'Organisation',
				}),
				type: 'success',
			});
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Memos
	const updateBody = useMemo(() => {
		return helpers.deepDiff(
			{
				is_partner: organisation.is_partner,
				meta: {
					partner_text: organisation.meta?.partner_text || '',
				},
				partner_category_id: organisation?.partner_category_id || null,
			},
			{
				is_partner: isPartner,
				meta: {
					partner_text: partnerText,
				},
				partner_category_id: partnerCategoryId,
			}
		);
	}, [organisation, isPartner, partnerText, partnerCategoryId]);

	//  -------------------------------------------------
	// Effects
	useEffect(() => {
		if (Object.keys(updateBody).length > 0) {
			setChangesMade(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateBody]);

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		await updateOrganisation.mutateAsync({
			id: organisation.id,
			body: updateBody,
		});
	};

	// -------------------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			className="pb-16">
			<FormRow title={translate('details')}>
				<Checkbox
					checked={isPartner}
					setChecked={setIsPartner}
					label={translate('enabled')}
					errors={formState.errors.value['is_partner']}
					name="is_partner"
					// describedBy={translate('partner_enabled_description', {
					// 	slug: organisation.slug,
					// })}
				/>
				<Textarea
					id="meta.partner_text"
					label={translate('content')}
					name="meta.partner_text"
					value={partnerText}
					setValue={setPartnerText}
					errors={formState.errors.value['meta.parter_text']}
					required={false}
				/>
				<Select
					label={translate('partner_category')}
					value={partnerCategoryId}
					setValue={(state) => {
						if (!state) return setPartnerCategoryId(null);
						setPartnerCategoryId(state as number);
					}}
					errors={formState.errors.value['type_id']}
					options={[
						{
							value: null,
							label: 'No category',
						},
						...(categories.map((cat) => {
							return {
								value: cat.id,
								label: `${cat.name} - ${cat.id}`,
							};
						}) || []),
					]}
				/>
			</FormRow>
			{/* Update */}
			<FormAction
				loading={{
					is: updateOrganisation.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateOrganisation.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value || !changesMade}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateOrganisationPartnerAction;
