import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import UpdateCourseNominationsAction from '@components/actions/UpdateCourseNominations';

interface UpdateCourseNominationsProps {
	courseId: number;
	open: boolean;
	setOpen: (state: boolean) => void;
	onSuccess?: () => void;
}

const UpdateCourseNominations: React.FC<UpdateCourseNominationsProps> = ({
	courseId,
	open,
	setOpen,
	onSuccess,
}) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.update_nominations.title}>
			<UpdateCourseNominationsAction
				courseId={courseId}
				callback={(success) => {
					if (success) {
						setOpen(false);
						onSuccess && onSuccess();
					}
				}}
			/>
		</Modal>
	);
};

export default UpdateCourseNominations;
