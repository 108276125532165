import { translate } from '@lang/index';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import PageHeading from '@components/blocks/PageHeading';
import HeadingTabs from '@components/partials/HeadingTabs';
// Groups
import SearchBar from '@components/groups/search/SearchBar';
import PageWrapper from '@components/layout/PageWrapper';
// Lists
import VideoSearchList from '@components/lists/VideoSearchList';
import NoteSearchList from '@components/lists/NoteSearchList';

const Search: React.FC = () => {
	// -------------------------------------------
	// State & Hooks
	const [query, setQuery] = useState<string>('');
	const [tab, setTab] = useState<'videos' | 'notes'>('videos');
	const navigate = useNavigate();

	// -------------------------------------------
	// Functions
	const changeTab = (tab: 'videos' | 'notes') => {
		setTab(tab);
		if (query.length > 0) {
			navigate(`/search?filter[s]=${query}`);
		} else {
			navigate(`/search`);
		}
	};

	// -------------------------------------------
	// Effects
	useEffect(() => {
		// highlight search query on page
	}, [query]);

	// -------------------------------------------
	// Render
	return (
		<>
			<PageHeading title={translate('search')}>
				<div className="mb-4 w-full border-b border-border pb-4">
					<SearchBar
						fillFromUrl={true}
						query={query}
						setQuery={setQuery}
					/>
				</div>
				<div className="flex items-center justify-between">
					<HeadingTabs
						activeKey={tab}
						tabs={[
							{
								name: translate('videos'),
								key: 'videos',
								onClick: () => changeTab('videos'),
							},
							{
								name: translate('notes'),
								key: 'notes',
								onClick: () => changeTab('notes'),
							},
						]}
					/>
				</div>
			</PageHeading>
			<PageWrapper>
				{tab === 'videos' && <VideoSearchList />}
				{tab === 'notes' && <NoteSearchList />}
			</PageWrapper>
		</>
	);
};

export default Search;
