import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
// Assets
import baobabGreen from '@assets/images/baobab-green.svg';
// api
import api from 'api';
// Utils
import { organisationTheme } from '@utils/index';
// Hooks
import { useOrganisation } from '@hooks/useOrganisation';
// Components
import PoweredByLogo from '@components/partials/PoweredByLogo';
import Preloader from '@components/partials/Preloader';

const AuthLayout: React.FC = () => {
	// --------------------------------------
	// State / Hooks
	const [headerLogo, setHeaderLogo] = useState<string>();
	const { organisationSlug } = useParams();
	const { clearOrganisation } = useOrganisation();
	const navigate = useNavigate();

	// --------------------------------------
	// Queries / Mutations
	const theme = useQuery(
		['public.getThemes', organisationSlug],
		() => {
			return api.public.getThemes({
				slug: organisationSlug,
			});
		},
		{
			enabled: !!organisationSlug,
			onSuccess: (data) => {
				if (data.data.data.logo_thumbnail) {
					setHeaderLogo(data.data.data.logo_thumbnail);
				}
				organisationTheme.setTheme({
					colours: {
						ui: {
							header: data.data?.data?.meta?.colours
								?.header_background,
						},
						accents: {
							primary:
								data.data?.data?.meta?.colours?.primary_accent,
							secondary:
								data.data?.data?.meta?.colours
									?.secondary_accent,
							tertiary:
								data.data?.data?.meta?.colours?.tertiary_accent,
						},
					},
				});
			},
			onError: (data) => {
				const d = data as AxiosError;
				const status = d.request.status;
				if (status === 404) {
					clearOrganisation();
					navigate('/login');
				}
			},
		}
	);

	// --------------------------------------
	// Memos
	const showPreloader = useMemo(() => {
		return theme.isLoading && organisationSlug !== undefined;
	}, [theme.isLoading, organisationSlug]);

	// --------------------------------------
	// Effects
	useEffect(() => {
		if (organisationSlug === undefined) {
			organisationTheme.resetTheme();
			clearOrganisation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisationSlug]);

	if (showPreloader) return <Preloader />;

	// --------------------------------------
	// Render
	return (
		<div className="flex h-screen overflow-y-auto">
			<main className="flex h-full w-full items-center justify-center xl:w-1/2 ">
				<div className="m-auto w-full max-w-lg p-10">
					<div className="mb-10 flex items-center">
						<img
							src={headerLogo || baobabGreen}
							alt=""
							className="h-10"
						/>
						<PoweredByLogo
							overideMode="dark"
							thumbnail={headerLogo}
						/>
					</div>
					<Outlet />
				</div>
			</main>
			<div className="fixed top-5 right-5 bottom-5 hidden w-[calc(50%-20px)] rounded-md bg-brandPrimary xl:block"></div>
		</div>
	);
};

export default AuthLayout;
