import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Breadcrumbs from '@components/partials/Breadcrumbs';
import ErrorBlock from '@components/partials/Error';
import Loading from '@components/partials/Loading';
// Actions
import UpdateQuizAction from '@components/actions/UpdateQuiz';

const QuizEdit = () => {
	// -------------------------------------------------
	// State / Hooks
	const { id } = useParams();

	// -------------------------------------------------
	// Queries / Mutations
	const quiz = useQuery(
		['sa.quizzes.getSingle', id],
		() => {
			return api.sa.quizzes.getSingle({
				id: id as string,
				include: {
					type: true,
					typeCount: true,
					media: true,
					mediaCount: true,
				},
			});
		},
		{
			enabled: !!id,
		}
	);
	const quizTypes = useQuery(['sa.categories.getAll.quizType'], () => {
		return api.sa.categories.getAll({
			include: {
				meta: false,
				metaCount: false,
			},
			filters: [
				{
					key: 'scope',
					value: 'quizType',
				},
			],
			sort: {
				name: 'asc',
			},
		});
	});

	// -------------------------------------------------
	// Render
	if (quiz.isLoading || quizTypes.isLoading) return <Loading type="page" />;
	if (quiz.isError || quizTypes.isError) return <ErrorBlock type="page" />;

	return (
		<>
			<PageHeading
				title={quiz.data.data.data.title}
				body={quiz.data.data.data.desc || ''}
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: '/assessments',
								label: translate('assessmnets'),
							},
							{
								href: `/assessments/${id}`,
								label: translate('edit'),
							},
						]}
					/>
				}></PageHeading>
			<PageWrapper>
				<UpdateQuizAction
					quiz={quiz.data.data.data}
					quizTypes={quizTypes.data.data.data}
					callback={(success) => {
						if (success) {
							quiz.refetch();
						}
					}}
				/>
			</PageWrapper>
		</>
	);
};

export default QuizEdit;
