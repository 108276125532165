import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
// api
import api from 'api';
// Hooks
import { useSearchParams, useOrganisation, usePermissions } from '@hooks/index';
// Utils
import { dateHelpers } from '@utils/index';
// Components
import Sort from '@components/query/Sort';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Filter from '@components/query/Filter';
import FilterPresetsRow from '@components/query/FilterPresetsRow';
// Tables
import QuizResultTable from '@components/table/shared/QuizResultTable';

const Progress: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'created_at',
					value: 'desc',
				},
			],
			filter: [
				{
					key: 'pass',
					value: '',
					type: 'boolean',
				},
				{
					key: 'quiz.type_id',
					value: '',
					type: 'select',
				},
				{
					key: 'course_id',
					value: '',
					type: 'select',
				},
				{
					key: 'user_id',
					value: '',
					type: 'select',
				},
				{
					key: 'created_at_from',
					value: '',
					type: 'date',
				},
				{
					key: 'created_at_to',
					value: '',
					type: 'date',
				},
			],
		});
	const { organisation } = useOrganisation();
	const { manageOrganisation } = usePermissions();
	const { convertDate, currentDate, monthsBefore, weeksBefore, daysBefore } =
		dateHelpers;

	// -------------------------------------------------
	//	Query / Mutation
	const courses = useQuery(
		['organisations.courses.getAll'],
		() => {
			return api.organisations.courses.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					items: false,
					itemsModel: false,
					itemsModelType: false,
					media: false,
					type: false,
					teamsCount: false,
					usersCount: false,
				},
				perPage: -1,
			});
		},
		{
			enabled: !!organisation?.id,
		}
	);
	const users = useQuery(
		['organisations.users.getMultiple'],
		() => {
			return api.organisations.users.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					roles: false,
					lastLogin: false,
				},
				perPage: -1,
			});
		},
		{
			enabled: !!organisation?.id && manageOrganisation,
		}
	);
	const quizTypes = useQuery(
		['organisations.categories.getAll'],
		() => {
			return api.organisations.categories.getAll({
				organisationId: organisation?.id as number,
				include: {
					meta: false,
					metaCount: false,
				},
				filters: [
					{
						key: 'scope',
						value: 'quizType',
					},
				],
			});
		},
		{
			enabled: !!organisation?.id,
		}
	);

	// -------------------------------------------------
	// Memos
	const filterLoading = useMemo(() => {
		if (manageOrganisation) {
			return courses.isLoading || users.isLoading || quizTypes.isLoading;
		} else {
			return courses.isLoading || quizTypes.isLoading;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		manageOrganisation,
		courses.isLoading,
		users.isLoading,
		quizTypes.isLoading,
	]);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title="Assessment Results"
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						labels={[
							{
								key: 'pass',
								label: translate('pass'),
							},
							{
								key: 'quiz.type_id',
								label: translate('assessment_types'),
								allowAll: true,
								values: quizTypes.data?.data.data.map(
									(video) => {
										return {
											label: video.name,
											value: video.id.toString(),
										};
									}
								),
							},
							{
								key: 'course_id',
								label: translate('course'),
								allowAll: true,
								values: courses.data?.data.data.map(
									(cuorse) => {
										return {
											label: cuorse.title,
											value: cuorse.id.toString(),
										};
									}
								),
							},
							{
								key: 'user_id',
								label: translate('users'),
								allowAll: true,
								values: users.data?.data.data.map((user) => {
									return {
										label: user.name || user.email,
										value: user.id.toString(),
									};
								}),
								permission: manageOrganisation,
							},
							{
								key: 'created_at_from',
								label: translate('assessment_taken_from'),
							},
							{
								key: 'created_at_to',
								label: translate('assessment_taken_to'),
							},
						]}
						loading={filterLoading}
						resetFilters={resetFilters}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'created_at',
								label: translate('assessment_taken'),
							},
						]}
					/>
				}
			/>
			<PageWrapper>
				<QuizResultTable
					mode={'organisation'}
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					utilityBar={
						<FilterPresetsRow
							filter={searchParams.filter.value}
							setMultipleFilters={
								searchParams.filter.setMultipleValues
							}
							presets={[
								{
									label: 'Taken in the last 1 month',
									filters: [
										{
											key: 'created_at_from',
											value: convertDate(
												monthsBefore(currentDate, 1)
											),
										},
										{
											key: 'created_at_to',
											value: convertDate(currentDate),
										},
									],
								},
								{
									label: 'Taken in the last 1 week',
									filters: [
										{
											key: 'created_at_from',
											value: convertDate(
												weeksBefore(currentDate, 1)
											),
										},
										{
											key: 'created_at_to',
											value: convertDate(currentDate),
										},
									],
								},
								{
									label: 'Taken in the last day',
									filters: [
										{
											key: 'created_at_from',
											value: convertDate(
												daysBefore(currentDate, 1)
											),
										},
										{
											key: 'created_at_to',
											value: convertDate(currentDate),
										},
									],
								},
							]}
						/>
					}
				/>
			</PageWrapper>
		</>
	);
};
export default Progress;
