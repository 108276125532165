const Preloader: React.FC = () => {
	return (
		<div
			className={'fixed top-0 left-0 right-0 bottom-0 bg-white z-[1000]'}>
			<div
				role="status"
				className="fixed top-0 left-0 right-0 bottom-0 bg-uiGray animate-pulse z-0"></div>
		</div>
	);
};

export default Preloader;
