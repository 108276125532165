import { useMutation } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useToast } from '@hooks/index';

const EmailVerifyBanner: React.FC = () => {
	// ---------------------
	// State / Hooks
	const { addToast } = useToast();

	// --------------------
	// Mutations
	const sendEmailVerification = useMutation(api.auth.sendEmailVerification, {
		onSuccess: (data) => {
			addToast({
				title: 'Email verification sent',
				message: 'Please check your email for a verification link',
				type: 'success',
			});
		},
	});

	return (
		<button
			className="flex h-10 w-full items-center justify-center bg-brandSecondary transition-colors duration-200 hover:bg-brandSecondaryHover"
			onClick={() => {
				sendEmailVerification.mutateAsync();
			}}>
			{sendEmailVerification.isLoading && (
				<p className="text-sm font-semibold text-white">Sending...</p>
			)}
			{sendEmailVerification.isError && (
				<p className="text-sm font-semibold text-white">
					Something went wrong, please try again
				</p>
			)}
			{!sendEmailVerification.isLoading &&
				!sendEmailVerification.isError && (
					<p className="text-sm font-semibold text-white">
						Kindly review your inbox to verify your email address. Haven't received the email? Please click here to resend.
					</p>
				)}
		</button>
	);
};

export default EmailVerifyBanner;
