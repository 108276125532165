import T from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Components
import Modal from '@components/modal/Modal';
// Actions
import UpdateOrgUserTeamsAction from '@components/actions/UpdateOrgUserTeams';

interface UpdateOrgUserTeamsProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	organisationId: number;
	userId: UserRes['id'];
	onSuccess?: () => void;
}

const UpdateOrgUserTeams: React.FC<UpdateOrgUserTeamsProps> = ({
	open,
	setOpen,
	organisationId,
	userId,
	onSuccess,
}) => {
	// -------------------------------------------------
	// Queries & Mutation
	const teams = useQuery(
		['organisations.teams.getMultiple', organisationId],
		() => {
			return api.organisations.teams.getMultiple({
				organisationId: organisationId,
				include: {
					courses: false,
					coursesCount: false,
					users: false,
					usersCount: false,
				},
				perPage: -1,
			});
		}
	);
	const user = useQuery(['organisations.users.getSingle', userId], () => {
		return api.organisations.users.getSingle({
			organisationId: organisationId,
			id: userId,
			include: {
				courses: false,
				lastLogin: false,
				lastLoginCount: false,
				teams: false,
				teamsCount: false,
				roles: false,
			},
		});
	});

	// -------------------------------------------------
	// Render
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.update_org_user_teams.title}
			isLoading={teams.isLoading || user.isLoading}
			isError={teams.isError || user.isError}>
			{user.data?.data.data && teams.data?.data?.data && (
				<UpdateOrgUserTeamsAction
					user={user.data?.data.data}
					teams={teams.data?.data?.data}
					organisationId={organisationId}
					mode="modal"
					callback={(success) => {
						if (success) {
							setOpen(false);
							onSuccess && onSuccess();
						}
					}}
				/>
			)}
		</Modal>
	);
};

export default UpdateOrgUserTeams;
