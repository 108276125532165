import { translate } from '@lang/index';
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
// Hooks
import { useFormState } from '@hooks/index';
// API
import api from 'api';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import FormRow from '@components/forms/FormRow';
import Input from '@components/forms/Input';
import Textarea from '@components/forms/Textarea';
import SingleImageUpload from '@components/forms/SingleImageUpload';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import SelectCategories from '@components/partials/SelectCategories';
import Button from '@components/partials/Button';
import Switch from '@components/forms/Switch';
// Modals
import CreateCategory from '@components/modal/CreateCategory';
import SingleFileUpload from '@components/forms/SingleFileUpload';

interface CourseBuilderDetailsProps {
	course?: SaCourseRes;
	state: {
		title: string;
		description: string;
		ref: string;
		selectedCategories: SaCategoriesRes[];
		courseType: SaCourseTypesRes['id'];
		courseTypes: SaCourseTypesRes[];
		image: File | undefined | null;
		formState: ReturnType<typeof useFormState>;
		file: File | undefined | null;
		slug: string;
		isPublic: boolean;
		stripeProductId: string;
		popular: boolean;
		durationText: string;
		aboutText: string;
		completionAbilities: string;
		clearFile?: boolean;
	};
	mutate: {
		setTitle: React.Dispatch<React.SetStateAction<string>>;
		setDescription: React.Dispatch<React.SetStateAction<string>>;
		setRef: React.Dispatch<React.SetStateAction<string>>;
		setSelectedCategories: React.Dispatch<
			React.SetStateAction<SaCategoriesRes[]>
		>;
		setCourseType: React.Dispatch<
			React.SetStateAction<SaCourseTypesRes['id']>
		>;
		setImage: React.Dispatch<React.SetStateAction<File | undefined | null>>;
		setFile: React.Dispatch<React.SetStateAction<File | undefined | null>>;
		setSlug: React.Dispatch<React.SetStateAction<string>>;
		setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
		setStripeProductId: React.Dispatch<React.SetStateAction<string>>;
		setPopular: React.Dispatch<React.SetStateAction<boolean>>;
		setDurationText: React.Dispatch<React.SetStateAction<string>>;
		setAboutText: React.Dispatch<React.SetStateAction<string>>;
		setCompletionAbilities: React.Dispatch<React.SetStateAction<string>>;
		setClearFile?: React.Dispatch<React.SetStateAction<boolean>>;
	};
}

const CourseBuilderDetails: React.FC<CourseBuilderDetailsProps> = ({
	course,
	state,
	mutate,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const {
		title,
		description,
		ref,
		selectedCategories,
		courseType,
		courseTypes,
		image,
		formState,
		file,
		slug,
		isPublic,
		stripeProductId,
		popular,
		durationText,
		aboutText,
		completionAbilities,
	} = state;
	const {
		setTitle,
		setDescription,
		setRef,
		setSelectedCategories,
		setImage,
		setFile,
		setSlug,
		setIsPublic,
		setStripeProductId,
		setPopular,
		setDurationText,
		setAboutText,
		setCompletionAbilities,
	} = mutate;
	const [courseTypeName, setCourseTypeName] =
		useState<SaCourseTypesRes['name']>('');
	const [openCreateCategory, setOpenCreateCategory] = useState(false);
	const location = useLocation();

	// -------------------------------------------------
	// Queries & Mutations
	const categories = useQuery(['sa.categories.getAll'], () => {
		return api.sa.categories.getAll({
			filters: [
				{
					key: 'scope',
					value: 'courseCategory',
				},
			],
			sort: {
				name: 'asc',
			},
			include: {
				meta: false,
				metaCount: false,
			},
		});
	});

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		if (courseType && courseTypes) {
			const params = new URLSearchParams(location.search);
			const type = params.get('type');
			if (type) {
				const courseTypeObj = courseTypes.find(
					(courseTypeObj) => courseTypeObj.name === type
				);
				if (courseTypeObj && courseTypeName === '') {
					setCourseTypeName(courseTypeObj.name);
				}
			} else {
				const courseTypeObj = courseTypes.find(
					(courseTypeObj) => courseTypeObj.id === courseType
				);
				if (courseTypeObj) {
					setCourseTypeName(courseTypeObj.name);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseType, courseTypes]);

	// -------------------------------------------------
	// Functions
	const slugify = (str: string) => {
		return str
			.replace(/[^\w\s]/gi, '')
			.toLowerCase()
			.replace(/ /g, '-')
			.replace(/_/g, '-');
	};

	// -------------------------------------------------
	// Render
	if (categories.isLoading) return <Loading type="page" />;
	if (categories.isError) return <Error type="page" />;

	return (
		<PageWrapper className="mb-16">
			<FormRow title={translate('details')}>
				{/* <Select
						label={translate('type')}
						options={
							courseTypes?.map((type) => {
								return {
									value: type.name,
									label: typeT(type.name),
								};
							}) || []
						}
						value={courseTypeName}
						setValue={(value) => {
							setCourseTypeName(value as string);
							setCourseType(
								courseTypes?.find((type) => type.name === value)
									?.id || 1
							);
						}}
						errors={[]}
					/> */}
				<Input
					id="ref"
					type="text"
					label={translate('ref')}
					name="ref"
					value={ref}
					setValue={setRef}
					errors={formState.errors.value['ref']}
					required={false}
				/>

				{courseTypeName === 'course' && (
					<>
						<SelectCategories
							categories={categories.data?.data.data}
							selelected={selectedCategories}
							setSelected={setSelectedCategories}
						/>
						<Button
							type="button"
							theme="outline"
							className="mb-5"
							onClick={() => {
								setOpenCreateCategory(true);
							}}>
							{translate('add_category')}
						</Button>
					</>
				)}

				<div className="grid grid-cols-2 gap-5">
					<Input
						id="title"
						type="text"
						label={translate('title')}
						name="title"
						value={title}
						setValue={setTitle}
						errors={formState.errors.value['title']}
						required={true}
						onBlur={() => {
							if (slug === '') {
								setSlug(slugify(title));
							}
						}}
					/>
					<Input
						id="slug"
						type="text"
						label={translate('slug')}
						name="slug"
						value={slug}
						setValue={setSlug}
						errors={formState.errors.value['slug']}
						required={false}
					/>
				</div>
				<Input
					id="stripe_product_id"
					type="text"
					label={translate('stripe_product_id')}
					name="stripe_product_id"
					value={stripeProductId}
					setValue={setStripeProductId}
					errors={formState.errors.value['stripe_product_id']}
					required={false}
				/>
				<Textarea
					id="description"
					label={translate('description')}
					name="desc"
					value={description}
					setValue={setDescription}
					errors={formState.errors.value['desc']}
					required={false}
				/>
				<Switch
					name="public"
					label={translate('public')}
					checked={isPublic}
					setChecked={setIsPublic}
					errors={formState.errors.value['public']}
					describedBy={translate(
						'determines_if_the_course_is_visible'
					)}
				/>
				<Switch
					name="popular"
					label={translate('popular')}
					checked={popular}
					setChecked={setPopular}
					errors={formState.errors.value['popular']}
				/>
			</FormRow>
			<FormRow title={translate('assets')}>
				<SingleImageUpload
					id="thumbnail"
					label={translate('thumbnail')}
					name="image"
					errors={formState.errors.value['image']}
					required={true}
					formats={['image/jpeg', 'image/png']}
					value={image}
					setValue={setImage}
					describedBy={translate('image_described_by', {
						width: '940',
						height: '530',
						type: 'jpg',
					})}
					current={course?.thumbnail}
				/>
				<SingleFileUpload
					id="file"
					label={translate('resources')}
					name="file"
					errors={formState.errors.value['file']}
					required={false}
					formats={['application/zip']}
					value={file}
					setValue={setFile}
					describedBy={translate('resources_file_description')}
					current={course?.file || undefined}
					clearFile={state.clearFile}
					setClearFile={mutate.setClearFile}
				/>
			</FormRow>
			<FormRow title={translate('marketing')}>
				<Input
					id="meta[duration_text]"
					type="text"
					label={translate('duration')}
					name="meta[duration_text]"
					value={durationText}
					setValue={setDurationText}
					errors={formState.errors.value['meta[duration_text]']}
					required={false}
				/>
				<Textarea
					id="meta[about_text]"
					label={translate('about')}
					name="meta[about_text]"
					value={aboutText}
					setValue={setAboutText}
					errors={formState.errors.value['meta[about_text]']}
					required={false}
				/>
				<Textarea
					id="meta[completion_abilities]"
					label={translate('learning_outcomes')}
					name="meta[completion_abilities]"
					value={completionAbilities}
					setValue={setCompletionAbilities}
					errors={
						formState.errors.value['meta[completion_abilities]']
					}
					required={false}
				/>
			</FormRow>
			<CreateCategory
				open={openCreateCategory}
				allowScope={'courseCategory'}
				setOpen={setOpenCreateCategory}
				onSuccess={(data) => {
					setSelectedCategories([
						...selectedCategories,
						data as SaCategoriesRes,
					]);
				}}
			/>
		</PageWrapper>
	);
};

export default CourseBuilderDetails;
