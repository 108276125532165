import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// Components
import Navigation from '@components/groups/navigation/Navigation';
import Header from '@components/layout/Header';
import Banner from '@components/blocks/Banner';

const MainLayout: React.FC = () => {
	// ----------------------------------------
	// State
	const [navOpen, setNavOpen] = useState(false);

	// ----------------------------------------
	// Effects
	useEffect(() => {
		if (navOpen) {
			document.body.classList.add('overflow-hidden', 'lg:overflow-auto');
		} else {
			document.body.classList.remove(
				'overflow-hidden',
				'lg:overflow-auto'
			);
		}
	}, [navOpen]);

	// ----------------------------------------
	// Render
	return (
		<>
			<Header layout="main" navOpen={navOpen} setNavOpen={setNavOpen} />
			<Navigation open={navOpen} setOpen={setNavOpen} />
			<main
				className={
					'relative z-0 ml-0 mt-[75px] min-h-[calc(100vh-75px)] overflow-x-hidden transition-all duration-200 lg:ml-[300px]'
				}
				style={{}}>
				<Banner />
				<Outlet />
			</main>
		</>
	);
};

export default MainLayout;
