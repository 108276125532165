import { translate } from '@lang/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Utils
import { returnModifiedData, helpers } from '@utils/index';
// Hooks
import {
	useSearchParams,
	useFormState,
	useToast,
	useOrganisation,
} from '@hooks/index';
// Components
import OrgUsersTable from '../../table/admin/OrgUsersTable';

interface OrgTeamsUsersTableProps {
	team: TeamRes;
}

const OrgTeamsUsersTable: React.FC<OrgTeamsUsersTableProps> = ({ team }) => {
	// -------------------------------------------------
	// State
	const { addToast } = useToast();
	const { organisation } = useOrganisation();
	const queryClient = useQueryClient();
	const formState = useFormState();

	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			perPage: 10,
			sort: [
				{
					key: 'name',
					value: 'off',
				},
				{
					key: 'email',
					value: 'off',
				},
				{
					key: 'created_at',
					value: 'off',
				},
			],
			filter: [],
		});

	// -------------------------------------------------
	// Queries / Mutations
	const updateTeam = useMutation(api.organisations.teams.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Team',
				}),
				message: translate('toast_updated_message', {
					name: 'Team',
				}),
				type: 'success',
			});
			queryClient.invalidateQueries(['organisations.teams.getSingle']);
			queryClient.invalidateQueries(['organisations.users.getMultiple']);
			queryClient.invalidateQueries([
				'organisations.users.getMultiple.filter',
			]);
			queryClient.invalidateQueries(['organisations.users.getSingle']);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
		},
	});

	// -------------------------------------------------
	// Functions
	const removeUser = async (userId: number) => {
		if (team.users) {
			const userIndex = team.users.findIndex(
				(user) => user.id === userId
			);
			const teamUsers = [...team.users];
			teamUsers.splice(userIndex, 1);

			await updateTeam.mutateAsync({
				id: team.id,
				organisationId: organisation?.id || 0,
				body: returnModifiedData([
					{
						key: 'user_ids',
						state: teamUsers.map((user) => user.id),
						defaultState: team.users?.map((user) => user.id),
					},
				]),
			});
		}
	};

	// -------------------------------------------------
	// Render
	return (
		<OrgUsersTable
			enabled={enabled}
			searchParams={searchParams}
			queryString={queryString}
			resetFilters={resetFilters}
			filters={[
				{
					key: 'teams.id',
					value: team.id,
				},
			]}
			permissions={{
				edit: false,
				stats: false,
				seat: false,
				delete: true,
				addToTeam: false,
				removeFromTeam: true,
			}}
			removeUser={removeUser}
		/>
	);
};

export default OrgTeamsUsersTable;
