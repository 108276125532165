import T, { rolesT, translate } from '@lang/index';
import { useEffect, useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
	faPlusCircle,
	faTimes,
	faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Components
import Modal from '@components/modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/partials/Button';
import MultivalueInput from '@components/forms/MultivalueInput';
import Select from '@components/forms/Select';
import MultiSelect from '@components/forms/MultiSelect';

interface InviteOrgUserProps {
	organisationId: number;
	open: boolean;
	setOpen: (state: boolean) => void;
	defaultTeamId?: number;
}

interface InviteItem {
	emails: string[];
	role_id: number;
	team_ids: number[];
	errors: {
		[key: string]: string[];
	};
}

interface InviteRowProps {
	index: number;
	roles: RoleRes[];
	teams: TeamRes[];
	inviteItem: InviteItem;
	setInviteItem: (state: InviteItem) => void;
	removeInviteItem: (index: number) => void;
	totalItems: number;
	disabled: boolean;
}

const InviteRow: React.FC<InviteRowProps> = ({
	index,
	roles,
	teams,
	inviteItem,
	setInviteItem,
	removeInviteItem,
	totalItems,
	disabled,
}) => {
	return (
		<div className="relative mt-4 first:mt-0">
			<div className="mr-10 grid grid-cols-1 gap-3 md:grid-cols-7">
				<div className="md:col-span-3">
					<MultivalueInput
						label={translate('email_addresses')}
						id={'emails'}
						index={index}
						name={'email'}
						values={inviteItem.emails}
						disabled={disabled}
						setValues={(state) => {
							setInviteItem({
								...inviteItem,
								emails: state,
							});
						}}
						errors={inviteItem.errors}
						placeholder={translate('enter_one_or_more_emails')}
					/>
				</div>
				<div className="md:col-span-2">
					<Select
						label={translate('roles')}
						disabled={disabled}
						value={rolesT(
							roles.find((role) => {
								return role.id === inviteItem.role_id;
							})?.name
						)}
						setValue={(state) => {
							const role = roles.find((role) => {
								return rolesT(role.name) === state;
							});
							setInviteItem({
								...inviteItem,
								role_id: role?.id || 1,
							});
						}}
						errors={inviteItem.errors['role_id']}
						options={roles.map((role) => {
							return rolesT(role.name);
						})}
					/>
				</div>
				<div className="md:col-span-2">
					<MultiSelect
						label={translate('teams')}
						name={'team_ids'}
						disabled={disabled}
						id={'teams'}
						values={inviteItem.team_ids.map((teamId) => {
							const team = teams.find((team) => {
								return team.id === teamId;
							});
							return {
								key: team?.name || '',
								label: team?.name || '',
								data: team,
							};
						})}
						setValues={(state) => {
							setInviteItem({
								...inviteItem,
								team_ids: state.map((team) => {
									return team.data.id;
								}),
							});
						}}
						errors={inviteItem.errors}
						options={teams.map((team) => {
							return {
								key: team.name,
								label: team.name,
								data: team,
							};
						})}
						placeholder={translate('add_to_teams')}
					/>
				</div>
			</div>
			<button
				className={classNames(
					'bg-red-500 absolute bottom-0 top-[28px] right-0 flex w-8 items-center justify-center rounded-full border border-border bg-uiLight text-body transition-colors duration-200 hover:bg-slate-100 hover:text-red md:top-[32px] md:h-8',
					{
						'pointer-events-none opacity-50':
							totalItems === 1 || disabled,
					}
				)}
				onClick={() => {
					if (!disabled) if (totalItems > 1) removeInviteItem(index);
				}}>
				<FontAwesomeIcon icon={faTimes} />
			</button>
		</div>
	);
};

const InviteOrgUser: React.FC<InviteOrgUserProps> = ({
	organisationId,
	open,
	setOpen,
	defaultTeamId,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const [inviteItems, setInviteItems] = useState<InviteItem[]>([]);
	const [totalErrors, setTotalErrors] = useState(0);
	const [totalSent, setTotalSent] = useState(0);
	const queryClient = useQueryClient();
	const [state, setState] = useState<'loading' | 'complete' | 'default'>(
		'default'
	);

	// -------------------------------------------------
	// Queries / Mutations
	const roles = useQuery(
		['organisations.roles.getMultiple', organisationId],
		() => {
			return api.organisations.roles.getAll({
				organisationId: organisationId,
			});
		}
	);
	const teams = useQuery(
		['organisations.teams.getMultiple', organisationId],
		() => {
			return api.organisations.teams.getMultiple({
				organisationId: organisationId,
				include: {
					courses: false,
					coursesCount: false,
					users: false,
					usersCount: false,
				},
				perPage: -1,
			});
		}
	);

	// -------------------------------------------------
	// Memos
	const defaultTeamsArray = useMemo(() => {
		if (defaultTeamId) {
			return [defaultTeamId];
		} else {
			return [];
		}
	}, [defaultTeamId]);

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		const flatten = flattenInvites();
		if (flatten.length === 0) return;

		// Reset
		setState('loading');
		setTotalErrors(0);
		setTotalSent(0);
		setInviteItems((inviteItems) => {
			return inviteItems.map((inviteItem) => {
				return {
					...inviteItem,
					errors: {
						email: [],
						role_id: [],
						team_ids: [],
					},
				};
			});
		});

		let errorCount = 0;
		let successCount = 0;

		// Process
		const promises = flatten.map((flat) => {
			return api.organisations.invites.create({
				organisationId: organisationId,
				body: flat.data,
			});
		});
		const promiseRes = await Promise.allSettled(promises);

		const itemErrors: Array<{
			email: string[];
			role_id: string[];
			team_ids: string[];
			[key: string]: string[];
		}> = inviteItems.map(() => {
			return {
				email: [],
				role_id: [],
				team_ids: [],
			};
		});

		// error / succcess data
		promiseRes.forEach((result, index) => {
			if (result.status === 'rejected') {
				let resErrors = result.reason.response.data.errors as
					| {
							[key: string]: string[] | undefined;
					  }
					| undefined;
				// if there is an email error, add it to the correct email
				let errors = itemErrors[flatten[index].parentIndex];

				if (resErrors?.email !== undefined) {
					// adds unique error to the correct email so we can show a specifici error
					if (resErrors.email) {
						errors[flatten[index].data.email] = resErrors.email;
					}
					// for each key in resErrors
					for (const key in resErrors) {
						if (key === 'email')
							errors.email.concat(resErrors.email || []);
						if (key === 'role_id')
							errors.role_id.concat(resErrors.role_id || []);
						if (key.includes('team_ids')) {
							errors.team_ids.concat(resErrors[key] || []);
						}
					}
				} else {
					if (result.reason.response.data.message !== undefined) {
						errors.email.push(result.reason.response.data.message);
					}
				}

				errorCount++;
			} else {
				successCount++;
			}
		});

		// set errors
		setInviteItems((inviteItems) => {
			return inviteItems.map((inviteItem, index) => {
				return {
					...inviteItem,
					errors: itemErrors[index],
				};
			});
		});

		// Finish
		setState('complete');
		queryClient.invalidateQueries(['organisations.users.getMultiple']);
		queryClient.invalidateQueries([
			'organisations.users.getMultiple.filter',
		]);
		setTotalErrors(errorCount);
		setTotalSent(successCount);
	};

	const resetModal = () => {
		setState('default');
		setInviteItems([
			{
				emails: [],
				role_id: 2,
				team_ids: defaultTeamsArray,
				errors: {
					email: [],
					role_id: [],
					team_ids: [],
				},
			},
		]);
		setTotalSent(0);
		setTotalErrors(0);
	};
	const flattenInvites = () => {
		const flat: {
			parentIndex: number;
			data: {
				email: string;
				role_id: number;
				team_ids: number[];
			};
		}[] = [];
		// flatten data
		inviteItems.forEach((inviteItem) => {
			inviteItem.emails.forEach((email) => {
				flat.push({
					parentIndex: inviteItems.indexOf(inviteItem),
					data: {
						email: email,
						role_id: inviteItem.role_id,
						team_ids: inviteItem.team_ids,
					},
				});
			});
		});
		return flat;
	};
	const newRow = useCallback(() => {
		setInviteItems((inviteItems) => {
			return [
				...inviteItems,
				{
					emails: [],
					role_id: 2,
					team_ids: defaultTeamsArray,
					errors: {
						email: [],
						role_id: [],
						team_ids: [],
					},
				},
			];
		});
	}, [defaultTeamsArray]);
	const closeModal = () => {
		setOpen(false);
		// add a delay so we dont see the values reset before the animation finishes
		setTimeout(() => {
			resetModal();
		}, 200);
	};

	useEffect(() => {
		if (open) {
			if (inviteItems.length === 0) {
				newRow();
			}
		}
	}, [open, newRow, inviteItems]);

	return (
		<Modal
			open={open}
			setOpen={(state) => {
				if (!state) closeModal();
				else setOpen(state);
			}}
			title={T.modals.add_org_user.title}
			description={T.modals.add_org_user.description}
			size={'large'}
			isLoading={roles.isLoading || teams.isLoading}
			isError={roles.isError || teams.isError}>
			{inviteItems.map((inviteItem, index) => {
				return (
					<InviteRow
						key={index}
						index={index}
						roles={roles.data?.data.data || []}
						teams={teams.data?.data.data || []}
						inviteItem={inviteItem}
						setInviteItem={(state) => {
							const newInviteItems = [...inviteItems];
							newInviteItems[index] = state;
							setInviteItems(newInviteItems);
						}}
						removeInviteItem={(index: number) => {
							const newInviteItems = [...inviteItems];
							newInviteItems.splice(index, 1);
							setInviteItems(newInviteItems);
						}}
						totalItems={inviteItems.length}
						disabled={state !== 'default'}
					/>
				);
			})}
			<Button
				theme="text"
				className={classNames({
					'pointer-events-none opacity-50': state !== 'default',
				})}
				onClick={() => {
					if (state === 'default') {
						newRow();
					}
				}}>
				<FontAwesomeIcon icon={faPlusCircle} className="mr-2 mt-5" />
				{translate('add_another')}
			</Button>
			<div className="mt-5 flex justify-end border-t border-border pt-5 ">
				{state === 'default' && (
					<div className="flex flex-wrap gap-3">
						<Button
							theme="outline"
							type="button"
							onClick={() => {
								closeModal();
							}}>
							{translate('cancel')}
						</Button>
						<Button
							theme={'primary'}
							onClick={async () => {
								await onSubmit();
							}}>
							{translate('send_invites')}
						</Button>
					</div>
				)}
				{state === 'loading' && (
					<div className="flex min-h-[40px] w-full items-center">
						<svg
							aria-hidden="true"
							className="mr-2 h-5 w-5 animate-spin fill-brandPrimary text-gray-200 dark:text-gray-800"
							viewBox="0 0 100 101"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="currentColor"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentFill"
							/>
						</svg>
						<p>{translate('sending_invites')}</p>
					</div>
				)}
				{state === 'complete' && (
					<div className="flex w-full flex-col justify-between md:flex-row md:items-end">
						<p className="mb-4 text-sm md:mb-0">
							<FontAwesomeIcon
								icon={faCheckCircle}
								className="mr-2 text-brandPrimaryHover"
							/>
							Sent <b>{totalSent} invite(s)</b>, {totalErrors}{' '}
							failed to send.
						</p>
						<div className="flex flex-wrap gap-3">
							<Button
								theme="outline"
								type="button"
								onClick={() => {
									resetModal();
								}}>
								{translate('send_more_invites')}
							</Button>
							<Button
								theme={'primary'}
								onClick={() => {
									closeModal();
								}}>
								{translate('close')}
							</Button>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default InviteOrgUser;
