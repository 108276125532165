// Components
import Checkbox from '@components/forms/Checkbox';
import Input from '@components/forms/Input';
import { translate } from '@lang/index';
import classNames from 'classnames';

interface OrganisationCheckCourseRowProps {
	course: SaCourseRes;
	checkRow: (
		check: boolean,
		course: SaCourseRes,
		startDate: string,
		endDate: string
	) => void;
	updateRowDates: (
		course: SaCourseRes,
		startDate: string,
		endDate: string
	) => void;
	isChecked: boolean;
	currentRow?: PreserveOrganisationCourses;
	errors: {
		start_date: string[];
		end_date: string[];
	};
}

const OrganisationCheckCourseRow: React.FC<OrganisationCheckCourseRowProps> = ({
	course,
	checkRow,
	updateRowDates,
	currentRow,
	isChecked,
	errors,
}) => {
	// -------------------------------------------------
	// Render
	return (
		<tr>
			<td className="td-default">
				<Checkbox
					name={course.title}
					checked={isChecked}
					setChecked={(v) =>
						checkRow(
							v,
							course,
							currentRow?.start_date || '',
							currentRow?.end_date || ''
						)
					}
					errors={[]}
				/>
			</td>
			<td className="td-default">
				<button
					className={classNames("text-sm font-semibold hover:underline", {
						'text-red': course.public === false,
					})}
					type="button"
					onClick={() => {
						checkRow(
							!isChecked,
							course,
							currentRow?.start_date || '',
							currentRow?.end_date || ''
						);
					}}>
					{course.title} 
					{
						course.public === false && <span className="text-red"> ({translate('private')})</span>
					}
				</button>
			</td>
			<td className="td-default">
				<Input
					id={`start_date_${course.id}`}
					type="date"
					name="start_date"
					value={currentRow?.start_date || ''}
					setValue={(val) => {
						updateRowDates(course, val, currentRow?.end_date || '');
					}}
					errors={errors.start_date}
					required={false}
					showErrors={false}
				/>
			</td>
			<td className="td-default">
				<Input
					id={`end_date_${course.id}`}
					type="date"
					name="end_date"
					value={currentRow?.end_date || ''}
					setValue={(val) => {
						updateRowDates(
							course,
							currentRow?.start_date || '',
							val
						);
					}}
					errors={errors.end_date}
					required={false}
					showErrors={false}
				/>
			</td>
		</tr>
	);
};

export default OrganisationCheckCourseRow;
