import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSelectedRow } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import OrganisationRow from '@components/rows/OrganisationRow';
import Pagination from '@components/query/Pagination';
// Modals
import ToggleOrganisationActive from '@components/modal/ToggleOrganisationActive';
import DeleteOrganisation from '@components/modal/DeleteOrganisation';
import TopPagination from '@components/query/FormUtilityBar';

interface OrganisationsTableProps extends QueryTableProps {
	utilityBar?: React.ReactNode;
}

const OrganisationsTable: React.FC<OrganisationsTableProps> = ({
	queryString,
	searchParams,
	utilityBar,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { selectedRow, setSelectedRow } =
		useSelectedRow<SaOrganisationsRes | null>(null);

	// Modal state
	const [openActiveToggle, setOpenActiveToggle] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const organisations = useQuery(
		['sa.organisations.getMultiple', queryString],
		() => {
			return api.sa.organisations.getMultiple({
				queryString: queryString,
				include: {
					courses: false,
					coursesCount: true,
					media: false,
					mediaCount: false,
					plan: true,
					planCount: false,
					users: false,
					usersCount: true,
					seatedUsersCount: true,
					subscriptionsCount: true,
					subscriptionsItems: true,
				},
			});
		},
		{ keepPreviousData: true, enabled: enabled }
	);
	const saOptions = useQuery(['sa.options.getOptions'], () => {
		return api.sa.options.getOptions();
	});

	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={organisations.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: organisations.isLoading,
					error: organisations.isError,
					noData: organisations.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Organisation',
					}),
					message: translate('no_data_message', {
						name: 'organisation',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('name'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'name'
							),
							type: 'sortable',
						},
						{
							label: translate('subscriptions'),
							type: 'standard',
						},
						{
							label: translate('courses'),
							type: 'standard',
						},
						{
							label: translate('plan'),
							type: 'standard',
						},
						{
							label: translate('source'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'source'
							),
						},
						{
							label: translate('type'),
							type: 'sortable',
							sort: searchParams.sort.value.find(
								(s) => s.key === 'type'
							),
						},
						{
							label: translate('active'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'active'
							),
							type: 'sortable',
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{organisations.data?.data.data.map(
						(organisation, index) => (
							<OrganisationRow
								key={index}
								organisation={organisation}
								stripe_link={
									organisation.stripe_id
										? `${saOptions.data?.data.stripe_url}customers/${organisation.stripe_id}`
										: undefined
								}
								selectRow={(organisation, action) => {
									setSelectedRow(organisation);
									switch (action) {
										case 'activate': {
											setOpenActiveToggle(true);
											break;
										}
										case 'delete': {
											setOpenDelete(true);
											break;
										}
									}
								}}
							/>
						)
					)}
				</Table>
				<Pagination
					meta={organisations.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>

				{selectedRow !== null && (
					<>
						<ToggleOrganisationActive
							open={openActiveToggle}
							setOpen={setOpenActiveToggle}
							organisationId={selectedRow.id}
							active={selectedRow.active}
						/>
						<DeleteOrganisation
							open={openDelete}
							setOpen={setOpenDelete}
							organisationId={selectedRow.id}
							onSuccess={() => searchParams.page.setValue(1)}
						/>
					</>
				)}
			</DynamicBlock>
		</>
	);
};

export default OrganisationsTable;
