import { translate } from '@lang/index';
import type { OrgUsersRowProps } from '@components/rows/OrgUsersRow';
import type { Props } from 'api/organisations/users/get-multiple';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSelectedRow, useOrganisation } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import OrgUsersRow from '@components/rows/OrgUsersRow';
import Pagination from '@components/query/Pagination';
// Modals
import DeleteOrgUser from '@components/modal/DeleteOrgUser';
import ToggleOrgUserSeat from '@components/modal/ToggleOrgUserSeat';
import UpdateOrgUserTeams from '@components/modal/UpdateOrgUserTeams';
import TopPagination from '@components/query/FormUtilityBar';

interface OrgUsersTableProps extends QueryTableProps {
	permissions: OrgUsersRowProps['permissions'];
	filters?: Props['filters'];
	removeUser?: (userId: number) => Promise<void>;
	isProcessing?: boolean;
	utilityBar?: React.ReactNode;
}

const OrgUsersTable: React.FC<OrgUsersTableProps> = ({
	enabled,
	queryString,
	searchParams,
	filters,
	permissions,
	removeUser,
	isProcessing = false,
	utilityBar,
	resetFilters,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { selectedRow, setSelectedRow } = useSelectedRow<UserRes | null>(
		null
	);

	// Modals
	const [openDelete, setOpenDelete] = useState(false);
	const [openSeat, setOpenSeat] = useState(false);
	const [openTeams, setOpenTeams] = useState(false);

	// -------------------------------------------------
	// Mutations / Queries
	const users = useQuery(
		[
			filters !== undefined
				? 'organisations.users.getMultiple.filter'
				: 'organisations.users.getMultiple',
			queryString,
			organisation,
		],
		() => {
			return api.organisations.users.getMultiple({
				queryString,
				organisationId: organisation?.id,
				include: {
					roles: true,
					lastLogin: true,
				},
				filters,
			});
		},
		{
			enabled: enabled && organisation !== null,
		}
	);

	// -------------------------------------------------
	// Render
	if (!organisation) return null;

	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={users.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: users.isLoading || isProcessing,
					error: users.isError,
					noData: users.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'User',
					}),
					message: translate('no_data_message', {
						name: 'user',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('name'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'name'
							),
							type: 'sortable',
						},
						{
							label: translate('seated'),
							type: 'standard',
						},
						{
							label: translate('last_login'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'last_login'
							),
							type: 'sortable',
						},
						{
							label: translate('created_at'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'created_at'
							),
							type: 'sortable',
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{users.data?.data.data.map((user, index) => (
						<OrgUsersRow
							key={index}
							user={user}
							organisationSlug={organisation.slug}
							permissions={permissions}
							selectRow={async (user, action) => {
								setSelectedRow(user);
								switch (action) {
									case 'delete': {
										setOpenDelete(true);
										break;
									}
									case 'seat': {
										setOpenSeat(true);
										break;
									}
									case 'remove_from_team': {
										if (removeUser) {
											await removeUser(user.id);
											users.refetch();
										}
										break;
									}
									case 'add_to_team': {
										setOpenTeams(true);
										break;
									}
								}
							}}
						/>
					))}
				</Table>
				<Pagination
					meta={users.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
				{selectedRow !== null && (
					<>
						<DeleteOrgUser
							userId={selectedRow.id}
							organisationId={organisation?.id || 0}
							onSuccess={() => searchParams.page.setValue(1)}
							open={openDelete}
							setOpen={setOpenDelete}
						/>
						<ToggleOrgUserSeat
							userId={selectedRow.id}
							organisationId={organisation?.id || 0}
							onSuccess={() => users.refetch()}
							open={openSeat}
							setOpen={setOpenSeat}
							state={selectedRow.seated_at !== null}
						/>
						<UpdateOrgUserTeams
							userId={selectedRow.id}
							organisationId={organisation?.id || 0}
							onSuccess={() => users.refetch()}
							open={openTeams}
							setOpen={setOpenTeams}
						/>
					</>
				)}
			</DynamicBlock>
		</>
	);
};

export default OrgUsersTable;
