import { Fragment } from 'react';

const highlightContent = (content: string, query: string) => {
	const regex = new RegExp(query, 'gi');
	const matches = content.match(regex);
	const splitContent = content.split(regex);
	const contentMarkup = splitContent.map((content, index) => {
		if (matches && matches[index]) {
			return (
				<Fragment key={index}>
					<span
						key={index}
						dangerouslySetInnerHTML={{
							__html: content,
						}}
					/>
					<mark
						dangerouslySetInnerHTML={{
							__html: matches[index],
						}}
					/>
				</Fragment>
			);
		} else {
			return content;
		}
	});
	return contentMarkup;
};
export default highlightContent;
