import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import ReportBugAction from '@components/actions/ReportBug';

interface ReportBugProps {
	open: boolean;
	setOpen: (state: boolean) => void;
}

const ReportBug: React.FC<ReportBugProps> = ({ open, setOpen }) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.send_bug_report.title}
			description={T.modals.send_bug_report.description}>
			<ReportBugAction
				callback={(success) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default ReportBug;
