import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { dateHelpers } from '@utils/index';
// Components
import Checkbox from '@components/forms/Checkbox';

interface VideoTranscriptProps {
	textTracks: SaVideosRes['textTracks'];
	mode?: 'dark' | 'light';
	currentTimestamp: number;
	goToTimestamp: (timestamp: number) => void;
}

const VideoTranscript: React.FC<VideoTranscriptProps> = ({
	textTracks,
	mode = 'light',
	currentTimestamp,
	goToTimestamp,
}) => {
	// -----------------------------------------
	// State
	const listRef = useRef<HTMLUListElement>(null);
	const [selectedTrack] = useState<number>(0);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [autoScroll, setAutoScroll] = useState<boolean>(true);

	// -----------------------------------------
	// Memos
	const getCurrentOrNextCue = useCallback(
		(cues: CueItem[]) => {
			for (let i = 0; i < cues.length; i++) {
				const cue = cues[i];
				if (
					cue.start_time <= currentTimestamp &&
					cue.end_time >= currentTimestamp
				) {
					return cue;
				} else if (
					i < cues.length - 1 &&
					cue.end_time < currentTimestamp &&
					cues[i + 1].start_time > currentTimestamp
				) {
					return cues[i + 1];
				}
			}
			return null;
		},
		[currentTimestamp]
	);

	const autoScrollActive = useMemo(() => {
		if (searchQuery !== '') return false;
		return autoScroll;
	}, [searchQuery, autoScroll]);

	const cues = useMemo(() => {
		if (textTracks === undefined) return [];
		if (textTracks.length === 0) return [];

		const cues = textTracks[selectedTrack].cues;
		if (searchQuery === '') return cues;

		return cues.map((item) => {
			if (item.text.toLowerCase().includes(searchQuery.toLowerCase())) {
				return {
					...item,
					hide: false,
				};
			} else
				return {
					...item,
					hide: true,
				};
		});
	}, [searchQuery, selectedTrack, textTracks]);

	const currentIndex = useMemo(() => {
		if (textTracks === undefined) return 0;
		if (textTracks.length === 0) return 0;
		const cues = textTracks[selectedTrack].cues;
		const target = getCurrentOrNextCue(cues);

		if (target === null) return 0;
		const index = cues.findIndex((item) => item.id === target.id);
		return index;
	}, [getCurrentOrNextCue, selectedTrack, textTracks]);

	// -----------------------------------------
	// Functions
	const scrollToTarget = useCallback(() => {
		if (autoScrollActive === false) return;

		const listEle = listRef.current;
		if (!listEle) return;

		if (currentIndex === null) {
			listEle.scrollTo({
				top: 0,
				left: 0,
				behavior: 'auto',
			});
			return;
		}

		const target = listEle.querySelector(
			`[data-index="${currentIndex}"]`
		) as HTMLElement;
		if (!target) return;

		listEle.scrollTo({
			top: target.offsetTop - 5,
			left: 0,
			behavior: 'auto',
		});
	}, [currentIndex, autoScrollActive]);

	// -----------------------------------------
	// Effects
	useEffect(() => {
		scrollToTarget();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentIndex]);

	// -----------------------------------------
	// Render
	if (textTracks === undefined) {
		return (
			<div className="relative h-[250px] w-full border border-border bg-white">
				<NoData />
			</div>
		);
	}
	if (textTracks.length === 0) {
		return (
			<div className="relative h-[250px] w-full border border-border bg-white">
				<NoData />
			</div>
		);
	}

	return (
		<div className="relative h-full w-full">
			<div className="flex items-center p-2.5 pb-0">
				<input
					type="search"
					value={searchQuery}
					onChange={(e) => {
						setSearchQuery(e.target.value);
					}}
					placeholder="Search Transcript"
					className="w-full rounded-md border border-border text-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-brandPrimary"
				/>
				<div className="min-w-max pl-5 pr-5">
					<Checkbox
						label="Auto Scroll"
						labelClassName={
							mode === 'dark'
								? 'text-white text-sm'
								: 'text-title text-sm'
						}
						name={'autoscroll'}
						checked={autoScroll}
						setChecked={setAutoScroll}
						errors={[]}
					/>
				</div>
			</div>
			<div>
				<ul
					ref={listRef}
					className="relative h-[250px] w-full overflow-y-auto p-5">
					{cues.map((item, index) => (
						<CueLine
							key={index}
							item={item}
							index={index}
							activeIndex={currentIndex}
							currentTimestamp={currentTimestamp}
							mode={mode}
							goToTimestamp={goToTimestamp}
						/>
					))}
				</ul>
			</div>
		</div>
	);
};

// Line
const CueLine: React.FC<{
	currentTimestamp: number;
	item: CueItem;
	index: number;
	activeIndex: number;
	mode?: 'dark' | 'light';
	goToTimestamp: (timestamp: number) => void;
}> = ({
	currentTimestamp,
	item,
	index,
	activeIndex,
	mode = 'light',
	goToTimestamp,
}) => {
	// -----------------------------------------
	// Render
	if (item.hide) return null;

	return (
		<li
			data-index={index}
			onClick={() => {
				goToTimestamp(item.start_time);
			}}
			className={classNames(
				'group relative mb-1 flex cursor-pointer items-center justify-between border-b pl-5 pb-1 last:mb-0 last:border-b-0',
				{
					'font-bold': currentTimestamp >= item.start_time,
					'border-white  border-opacity-20': mode === 'dark',
				}
			)}>
			{/* State Circle */}
			<span
				className={classNames(
					'absolute left-0 top-1/2 block h-2 w-2 -translate-y-1/2 transform rounded-full bg-slate-300',
					{
						// In past
						'!bg-brandSecondary':
							currentTimestamp >= item.start_time,
						// currently playing
						'!bg-brandTertiary': activeIndex === index,
					}
				)}></span>
			{/* Text */}
			<span
				className={classNames('text-sm group-hover:underline', {
					underline: activeIndex === index,
					'text-title': mode === 'light',
					'text-lightGray': mode === 'dark',
				})}>
				{item.text}
			</span>
			{/* Start Time */}
			<span
				className={classNames('ml-5 flex flex-col text-sm', {
					'text-title': mode === 'light',
					'text-white': mode === 'dark',
				})}>
				{dateHelpers.formatSeconds(item.start_time)}
			</span>
		</li>
	);
};

// No Data
const NoData: React.FC<{
	mode?: 'dark' | 'light';
}> = ({ mode = 'light' }) => {
	return (
		<div
			className={
				'absolute inset-0 flex flex-col items-center justify-center text-center'
			}>
			<h3
				className={classNames('mb-1 font-bold', {
					'text-white': mode === 'dark',
					'text-title': mode === 'light',
				})}>
				No Transcript
			</h3>
			<p
				className={classNames({
					'text-lightGray': mode === 'dark',
					'text-body': mode === 'light',
				})}>
				There is no transcript for this video.
			</p>
		</div>
	);
};

export default VideoTranscript;
