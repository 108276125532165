import { translate } from '@lang/index';
import { useState } from 'react';
// Components
import TermsBanner from '@components/banners/TermsBanner';
import EmailVerifyBanner from '@components/banners/EmailVerifyBanner';
// Hooks
import { useToast } from '@hooks/index';
// Queries
import { useAuthenticatedUser } from '@queries/user';

const Banner: React.FC = () => {
	// ------------------------------------
	// State / Hooks
	const { addToast } = useToast();
	const [show, setShow] = useState<'none' | 'email' | 'terms'>('none');

	// ------------------------------------
	// Queries
	const user = useAuthenticatedUser({
		onSuccess: (data) => {
			if (!data) return;
			if (data.data?.data.email_verified_at === null) {
				setShow('email');
				addToast({
					title: translate('toast_please_verify_email_title'),
					message: translate('toast_please_verify_email_message'),
					type: 'success',
				});
				return;
			}
			if (data.data?.data.tc_accepted_at === null) {
				setShow('terms');
				// addToast({
				// 	title: translate('toast_please_accept_terms_title'),
				// 	message: translate('toast_please_accept_terms_message'),
				// 	type: 'success',
				// });
				return;
			}
			setShow('none');
		},
	});

	if (show === 'email') {
		return <EmailVerifyBanner />;
	}
	if (show === 'terms') {
		return <TermsBanner fetchUser={() => user.refetch()} />;
	}

	return null;
};

export default Banner;
