import { useMemo } from 'react';
// Components
import Loading from '@components/partials/Loading';
import { translate } from '@lang/index';

interface Props {
	filter: Array<{
		key: string;
		value: string;
		type?: 'boolean' | 'input' | 'select' | 'date';
	}>;
	setFilter: (key: string, value: string) => void;
	resetFilters: () => void;
	categories: Array<{
		label: string;
		value: string;
	}>;
	loading: boolean;
	totalCourses: number;
}

const CategoryTabFilter: React.FC<Props> = ({
	filter,
	setFilter,
	categories,
	loading,
	totalCourses,
}) => {
	const CATEGORY_KEY = 'category_ids';

	// -------------------------------------------------
	// Memos
	const targetFilter = useMemo(() => {
		return filter.find((f) => f.key === CATEGORY_KEY);
	}, [filter]);
	const categoryFilters = useMemo(() => {
		if (!targetFilter) return [];

		return categories.map((c) => {
			return {
				label: c.label,
				value: c.value,
				checked: targetFilter.value === c.value,
			};
		});
	}, [targetFilter, categories]);

	const show = useMemo(() => {
		const catFilter = filter.find((f) => f.key === CATEGORY_KEY);
		if (catFilter?.value) return true;
		if (totalCourses > 1) return true;
		return false;
	}, [filter, totalCourses]);

	// -------------------------------------------------
	// Render
	return (
		<>
			{loading ? (
				<div className="block h-[61px] overflow-hidden rounded-md">
					<Loading type="skeleton" />
				</div>
			) : (
				show && (
					<form className="relative z-10 overflow-hidden">
						<div className="overflow-x-auto pb-2">
							<div className="flex space-x-2 whitespace-nowrap">
								<label
									key={'category-reset'}
									htmlFor={`category-reset`}
									className={`inline-flex cursor-pointer items-center rounded-md px-4 py-2 text-sm font-medium transition-colors duration-200 ease-in-out ${
										targetFilter?.value === ''
											? 'bg-brandPrimary text-brandPrimaryText'
											: 'border border-border text-title'
									}`}>
									<input
										type="radio"
										name="category"
										id={`category-reset`}
										value={''}
										checked={targetFilter?.value === ''}
										onChange={(e) => {
											setFilter(
												CATEGORY_KEY,
												e.target.value
											);
										}}
										className="sr-only"
									/>
									{translate('all')}
								</label>
								{categoryFilters.map((category) => (
									<label
										key={category.value}
										htmlFor={`category-${category.value}`}
										className={`inline-flex cursor-pointer items-center rounded-md px-4 py-2 text-sm font-medium transition-colors duration-200 ease-in-out ${
											category.checked
												? 'bg-brandPrimary text-brandPrimaryText'
												: 'border border-border text-title'
										}`}>
										<input
											type="radio"
											name="category"
											id={`category-${category.value}`}
											value={category.value}
											checked={category.checked}
											onChange={(e) => {
												setFilter(
													CATEGORY_KEY,
													e.target.value
												);
											}}
											className="sr-only"
										/>
										{category.label}
									</label>
								))}
							</div>
						</div>
					</form>
				)
			)}
		</>
	);
};

export default CategoryTabFilter;
