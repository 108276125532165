import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
// api
import api from 'api';
// Hooks
import { useSearchParams, useOrganisation, usePermissions } from '@hooks/index';
// Utils
import { dateHelpers } from '@utils/index';
// Components
import Sort from '@components/query/Sort';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Filter from '@components/query/Filter';
import FilterPresetsRow from '@components/query/FilterPresetsRow';
// Tables
import LogsTable from '@components/table/shared/LogsTable';

const OrgLogsList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'created_at',
					value: 'desc',
				},
			],
			filter: [
				{
					key: 'user_id',
					value: '',
					type: 'select',
				},
				{
					key: 'created_at_from',
					value: '',
					type: 'date',
				},
				{
					key: 'created_at_to',
					value: '',
					type: 'date',
				},
			],
		});
	const { organisation } = useOrganisation();
	const { manageOrganisation } = usePermissions();
	const { convertDate, currentDate, monthsBefore } = dateHelpers;

	// -------------------------------------------------
	//	Query / Mutation
	const users = useQuery(
		['organisations.users.getMultiple'],
		() => {
			return api.organisations.users.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					roles: false,
					lastLogin: false,
				},
				perPage: -1,
			});
		},
		{
			enabled: !!organisation?.id && manageOrganisation,
		}
	);

	// -------------------------------------------------
	// Memos
	const filterLoading = useMemo(() => {
		if (!manageOrganisation) return false;
		return users.isLoading;
	}, [users.isLoading, manageOrganisation]);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title="Logins"
				body=""
				tooltip=""
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						labels={[
							{
								key: 'user_id',
								label: translate('users'),
								allowAll: true,
								values: users.data?.data.data.map((user) => {
									return {
										label: user.name || user.email,
										value: user.id.toString(),
									};
								}),
								permission: manageOrganisation,
							},
							{
								key: 'created_at_from',
								label: translate('started_at'),
							},
							{
								key: 'created_at_to',
								label: translate('started_from'),
							},
						]}
						loading={filterLoading}
						resetFilters={resetFilters}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'created_at',
								label: translate('started'),
							},
						]}
					/>
				}
			/>
			<PageWrapper>
				<LogsTable
					mode={'organisation'}
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					utilityBar={
						<FilterPresetsRow
							filter={searchParams.filter.value}
							setMultipleFilters={
								searchParams.filter.setMultipleValues
							}
							presets={[
								{
									label: 'Logged in within the last month',
									filters: [
										{
											key: 'created_at_from',
											value: convertDate(
												monthsBefore(currentDate, 1)
											),
										},
										{
											key: 'created_at_to',
											value: convertDate(currentDate),
										},
									],
								},
							]}
						/>
					}
				/>
			</PageWrapper>
		</>
	);
};
export default OrgLogsList;
