import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
	QueryClient,
	QueryCache,
	MutationCache,
	QueryClientProvider,
} from '@tanstack/react-query';
import { globalErrorHandler, globalSuccessHandler } from '@utils/index';

// ----------------------------------------------------------------
// Rotues
import Dashboard from '@routes/Dashboard';
import Login from '@routes/Login';
import PasswordResetToken from '@routes/PasswordResetToken';
import PasswordReset from '@routes/PasswordReset';
import Account from '@routes/Account';
import Redirect from '@routes/Redirect';
import NotFound from '@routes/NotFound';
import Error from '@routes/Error';

// ----------------------------------------------------------------
// Super Admin
import Progress from '@routes/progress/List';
import PlansList from '@routes/plans/List';
import LogsList from '@routes/logs/List';
import VideoViewsList from '@routes/video-views/List';
import QuizResultsList from '@routes/quiz-results/List';
import AwardsList from '@routes/awards/List';
import UserAwardsList from '@routes/user-awards/List';
import CategoriesList from '@routes/categories/List';

// Users
import UserList from '@routes/users/List';

// Options
import OptionsList from '@routes/options/List';

// Courses / Content
import CoursesList from '@routes/courses//List';
import CoursesEdit from '@routes/courses/single/Edit';
import CoursesCreate from '@routes/courses/single/Create';

// Assessments
import AssessmentList from '@routes/assessments/List';
import AssessmentEdit from '@routes/assessments/Edit';

// Videos
import VideoList from '@routes/videos/List';
import VideoEdit from '@routes/videos/Edit';

// Organisations
import OrganisationList from '@routes/organisations/List';
import OrganisationEdit from '@routes/organisations/single/Edit';
import OrganisationEditUsers from '@routes/organisations/single/Users';
import OrganisationEditCourses from '@routes/organisations/single/Courses';
import OrganisationEditPartner from '@routes/organisations/single/Partner';

// ----------------------------------------------------------------
// Organisations
import OrganisationManage from '@routes/organisations/Manage';

// Progress
import OrganisationProgressList from '@routes/organisations/progress/List'; // User

// Courses
import OrganisationContentList from '@routes/organisations/content/List'; // User
import OrganisationCoursesList from '@routes/organisations/courses/List'; // User
import CoursesView from '@routes/courses/single/View';
import CourseViewRedirect from '@routes/courses/single/ViewRedirect';

// Course Nominations
import OrganisationCourseNominationsList from '@routes/organisations/course-nominations/List';

// Users
import OrganisationUsersList from '@routes/organisations/users/List';
import OrganisationUserDetails from '@routes/organisations/users/single/Details';
import OrganisationUserStatistics from '@routes/organisations/users/single/Statistics';
import OrganisationUserTeams from '@routes/organisations/users/single/Teams';
import OrganisationUserCourses from '@routes/organisations/users/single/Courses';

// User Awards
import OrganisationUserAwardsList from '@routes/organisations/user-awards/List';

// Teams
import OrganisationTeamsList from '@routes/organisations/teams/List';
import OrganisationTeamEdit from '@routes/organisations/teams/single/Edit';
import OrganisationTeamUsers from '@routes/organisations/teams/single/Users';

// Logs
import OrganisationLogsList from '@routes/organisations/logs/List';

// Stats
import OrganisationVideoViewsList from '@routes/organisations/video-views/List';
import OrganisationAssessmentResultList from '@routes/organisations/assessment-results/List';

// Search
import Search from '@routes/search/Search';

// Awards
import OrganisationMyAwardsList from '@routes/organisations/my-awards/List';

// Bookmarks
import OrganisationBookmarksList from '@routes/organisations/bookmarks/List';

// Notes
import OrganisationNotesList from '@routes/organisations/notes/List';

// ----------------------------------------------------------------
// Layouts
import App from './App';
import MainLayout from '@layouts/Main';
import AuthLayout from '@layouts/AuthLayout';
// ----------------------------------------------------------------
// Guards
import OrganisationGuard from 'guards/Organisation';
import AuthenticatedGuard from 'guards/Authenticated';
import OrganisationPermissionGuard from 'guards/OrganisationPermission';
import SuperAdminGuard from 'guards/SuperAdmin';

const Router = () => {
	// --------------------------------------
	// Query Client
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				retry: false,
				cacheTime: 1000 * 60 * 60 * 24, // 24 hours
			},
		},
		queryCache: new QueryCache({
			onSuccess: globalSuccessHandler,
			onError: (err) => {
				globalErrorHandler(err);
			},
		}),
		mutationCache: new MutationCache({
			onError: (err) => {
				globalErrorHandler(err);
			},
		}),
	});

	// --------------------------------------
	// Render
	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<Routes>
					<Route element={<App />}>
						{/* Public */}
						<Route path="/redirect" element={<Redirect />} />
						<Route path="/error/:type" element={<Error />} />
						{/* Plain Layout */}
						<Route element={<AuthLayout />}>
							{/* No Authentication Only */}
							<Route
								element={
									<AuthenticatedGuard
										unAuthenticatedOnly={true}
									/>
								}>
								<Route
									path="/login"
									element={<Login testmode={true} />}
								/>
								<Route
									path="/:organisationSlug/login"
									element={<Login testmode={true} />}
								/>
								<Route
									path="/password-reset"
									element={<PasswordReset />}
								/>
								<Route
									path="/password-reset/:token"
									element={<PasswordResetToken />}
								/>
							</Route>
						</Route>
						{/* Main Layout */}
						<Route element={<MainLayout />}>
							{/* Autenticated Guard */}
							<Route
								element={
									<AuthenticatedGuard
										unAuthenticatedOnly={false}
									/>
								}>
								<Route index path="/" element={<Dashboard />} />
								<Route path="/account" element={<Account />} />
								{/* Organisation Guard */}
								<Route element={<OrganisationGuard />}>
									{/* Organisation Permission Guard - view_courses */}
									<Route
										element={
											<OrganisationPermissionGuard
												permissions={'view_courses'}
											/>
										}>
										<Route
											path="/search"
											element={<Search />}
										/>
										<Route
											path="/:organisationSlug/video-library"
											element={
												<OrganisationContentList />
											}
										/>
										<Route
											path="/:organisationSlug/courses"
											element={
												<OrganisationCoursesList />
											}
										/>
										<Route
											path="/:organisationSlug/courses/:id/redirect"
											element={
												<CourseViewRedirect
													mode={'standard'}
												/>
											}
										/>
										<Route
											path="/:organisationSlug/courses/:id"
											element={
												<CoursesView
													mode={'standard'}
												/>
											}
										/>
										<Route
											path="/:organisationSlug/courses/:id/success"
											element={
												<CoursesView
													mode={'standard'}
												/>
											}
										/>
										<Route
											path="/:organisationSlug/courses/:id/:itemType/:itemId"
											element={
												<CoursesView
													mode={'standard'}
												/>
											}
										/>
										<Route
											path="/:organisationSlug/bookmarks"
											element={
												<OrganisationBookmarksList />
											}
										/>
										<Route
											path="/:organisationSlug/notes"
											element={<OrganisationNotesList />}
										/>
									</Route>
									{/* Organisation Permission Guard - manage_users */}
									<Route
										element={
											<OrganisationPermissionGuard
												permissions={'manage_users'}
											/>
										}>
										<Route
											path="/:organisationSlug/users"
											element={<OrganisationUsersList />}
										/>
										<Route
											path="/:organisationSlug/users/:id"
											element={
												<OrganisationUserDetails />
											}
										/>
										<Route
											path="/:organisationSlug/users/:id/statistics"
											element={
												<OrganisationUserStatistics />
											}
										/>

										<Route
											path="/:organisationSlug/users/:id/teams"
											element={<OrganisationUserTeams />}
										/>
										<Route
											path="/:organisationSlug/users/:id/nominated-courses"
											element={
												<OrganisationUserCourses />
											}
										/>
										<Route
											path="/:organisationSlug/user-awards"
											element={
												<OrganisationUserAwardsList />
											}
										/>
									</Route>
									{/* Organisation Permission Guard - manage_teams */}
									<Route
										element={
											<OrganisationPermissionGuard
												permissions={'manage_teams'}
											/>
										}>
										<Route
											path="/:organisationSlug/teams"
											element={<OrganisationTeamsList />}
										/>
										<Route
											path="/:organisationSlug/teams/:id"
											element={<OrganisationTeamEdit />}
										/>
										<Route
											path="/:organisationSlug/teams/:id/users"
											element={<OrganisationTeamUsers />}
										/>
									</Route>
									{/* Organisation Permission Guard - manage_organisation */}
									<Route
										element={
											<OrganisationPermissionGuard
												permissions={
													'manage_organisation'
												}
											/>
										}>
										<Route
											path="/:organisationSlug/course-nominations"
											element={
												<OrganisationCourseNominationsList />
											}
										/>
										<Route
											path="/:organisationSlug/manage"
											element={<OrganisationManage />}
										/>
									</Route>
									{/* Organisaiton */}
									<Route
										path="/:organisationSlug/video-views"
										element={<OrganisationVideoViewsList />}
									/>
									<Route
										path="/:organisationSlug/assessment-results"
										element={
											<OrganisationAssessmentResultList />
										}
									/>
									<Route
										path="/:organisationSlug/progress"
										element={<OrganisationProgressList />}
									/>
									<Route
										path="/:organisationSlug/logins"
										element={<OrganisationLogsList />}
									/>
									<Route
										path="/:organisationSlug/awards"
										element={<OrganisationMyAwardsList />}
									/>
								</Route>
								{/* Not Super Admin Guard */}
								<Route
									element={
										<SuperAdminGuard inverse={true} />
									}>
						{/* blank */}
								</Route>
								{/* Super Admin Guard */}
								<Route
									element={
										<SuperAdminGuard inverse={false} />
									}>
									<Route
										path="/users"
										element={<UserList />}
									/>
									<Route
										path="/options"
										element={<OptionsList />}
									/>
									<Route
										path="/progress"
										element={<Progress />}
									/>
									<Route
										path="/plans"
										element={<PlansList />}
									/>
									<Route
										path="/logins"
										element={<LogsList />}
									/>
									<Route
										path="/video-views"
										element={<VideoViewsList />}
									/>
									<Route
										path="/assessment-results"
										element={<QuizResultsList />}
									/>
									<Route
										path="/organisations"
										element={<OrganisationList />}
									/>
									<Route
										path="/organisations/:id"
										element={<OrganisationEdit />}
									/>
									<Route
										path="/organisations/:id/users"
										element={<OrganisationEditUsers />}
									/>
									<Route
										path="/organisations/:id/courses"
										element={<OrganisationEditCourses />}
									/>
									<Route
										path="/organisations/:id/partnership"
										element={<OrganisationEditPartner />}
									/>
									<Route
										path="/awards"
										element={<AwardsList />}
									/>
									<Route
										path="/user-awards"
										element={<UserAwardsList />}
									/>
									<Route
										path="/courses"
										element={
											<CoursesList type={'course'} />
										}
									/>
									<Route
										path="/subjects"
										element={
											<CoursesList type={'content'} />
										}
									/>
									<Route
										path="/courses/:id/:tab"
										element={<CoursesEdit />}
									/>
									<Route
										path="/courses/:id/redirect"
										element={
											<CourseViewRedirect
												mode={'preview'}
											/>
										}
									/>
									<Route
										path="/courses/:id"
										element={
											<CoursesView mode={'preview'} />
										}
									/>
									<Route
										path="/courses/:id/:itemType/:itemId"
										element={
											<CoursesView mode={'preview'} />
										}
									/>
									<Route
										path="/courses/create"
										element={<CoursesCreate />}
									/>
									<Route
										path="/video-library/categories"
										element={
											<CategoriesList mode={'content'} />
										}
									/>
									<Route
										path="/courses/categories"
										element={
											<CategoriesList mode={'course'} />
										}
									/>
									{/* Assessments */}
									<Route
										path="/assessments"
										element={<AssessmentList />}
									/>
									<Route
										path="/assessments/:id"
										element={<AssessmentEdit />}
									/>
									<Route
										path="/assessments/types"
										element={
											<CategoriesList mode={'quiz'} />
										}
									/>
									{/* Videos */}
									<Route
										path="/videos"
										element={<VideoList />}
									/>
									<Route
										path="/videos/:id"
										element={<VideoEdit />}
									/>
									<Route
										path="/videos/types"
										element={
											<CategoriesList mode={'video'} />
										}
									/>
								</Route>
							</Route>
						</Route>
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</QueryClientProvider>
	);
};

export default Router;
