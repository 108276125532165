import { translate } from '@lang/index';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import { Link } from 'react-router-dom';
// Table Columns
import UserColumn from '@components/table-columns/UserColumn';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';
import Badges from '@components/partials/Badges';
import LastLoginColumn from '@components/table-columns/LastLoginColumn';

export interface OrgUsersRowProps {
	user: UserRes;
	organisationSlug: string;
	selectRow: (
		user: UserRes,
		action: 'delete' | 'seat' | 'remove_from_team' | 'add_to_team'
	) => void;
	permissions: {
		edit: boolean;
		stats: boolean;
		seat: boolean;
		delete: boolean;
		addToTeam: boolean;
		removeFromTeam: boolean;
	};
}

const OrgUsersRow: React.FC<OrgUsersRowProps> = ({
	user,
	organisationSlug,
	selectRow,
	permissions,
}) => {
	return (
		<tr>
			<td className="td-default">
				<Link to={`/${organisationSlug}/users/${user.id}`}>
					<UserColumn name={user.name} email={user.email} />
				</Link>
			</td>
			<td className="td-default">
				<Badges
					theme={user.seated_at !== null ? 'green' : 'red'}
					onClick={() => {
						selectRow(user, 'seat');
					}}>
					{user.seated_at !== null
						? translate('seated')
						: translate('not_seated')}
				</Badges>
			</td>
			<td className="td-default">
				<LastLoginColumn date={user?.last_login?.created_at} />
			</td>
			<td className="td-default">
				<DateTimeColumn date={user.created_at || ''} />
			</td>
			<DropdownMenuColumn
				items={[
					{
						label: translate('edit'),
						type: 'link',
						href: `/${organisationSlug}/users/${user.id}`,
						show: permissions.edit,
					},
					{
						label: translate('statistics'),
						type: 'link',
						href: `/${organisationSlug}/users/${user.id}/statistics`,
						show: permissions.stats,
					},
					{
						label: translate('progress'),
						type: 'link',
						href: `/${organisationSlug}/progress?filter[user_id]=${user.id}`,
						show: permissions.stats,
					},
					{
						label: translate('add_to_team'),
						type: 'button',
						show: permissions.addToTeam,
						onClick: () => {
							selectRow(user, 'add_to_team');
						},
					},
					{
						label:
							user.seated_at !== null
								? translate('unseat')
								: translate('seat'),
						type: 'button',
						show: permissions.seat,
						onClick: () => {
							selectRow(user, 'seat');
						},
					},
					{
						label: translate('delete_from_organisation'),
						type: 'button',
						show: permissions.delete,
						onClick: () => {
							selectRow(user, 'delete');
						},
					},
					{
						label: translate('remove_from_team'),
						type: 'button',
						show: permissions.removeFromTeam,
						onClick: () => {
							selectRow(user, 'remove_from_team');
						},
					},
				]}
			/>
		</tr>
	);
};

export default OrgUsersRow;
