import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Utils
import { dateHelpers } from '@utils/index';
// Components
import Breadcrumbs from '@components/partials/Breadcrumbs';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import HeadingTabs from '@components/partials/HeadingTabs';
import Loading from '@components/partials/Loading';
import ErrorBlock from '@components/partials/Error';
import Badges from '@components/partials/Badges';
import DataList from '@components/partials/DataList';
import FormRow from '@components/forms/FormRow';
// Actions
import UpdateOrgUser from '@components/actions/UpdateOrgUser';

const OrganisationUserEdit: React.FC = () => {
	// --------------------------------------
	// State & Hooks
	const { id } = useParams();
	const { organisation } = useOrganisation();

	// --------------------------------------
	// Queries & Mutations
	const user = useQuery(
		['organisations.users.getSingle', organisation, id],
		() => {
			if (!id) throw new Error(translate('no_id_provided'));
			return api.organisations.users.getSingle({
				organisationId: organisation?.id || 0,
				id: parseInt(id),
				include: {
					courses: true,
					lastLogin: true,
					lastLoginCount: false,
					roles: true,
					teams: true,
					teamsCount: true,
				},
			});
		}
	);

	// --------------------------------------
	// Functions

	// --------------------------------------
	// Render
	if (user.isLoading) return <Loading type="page" />;
	if (user.isError) return <ErrorBlock type="page" />;

	return (
		<>
			<PageHeading
				title={translate('user_details')}
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: `/${organisation?.slug}/users`,
								label: translate('users'),
							},
							{
								href: `/${organisation?.slug}/users/${id}`,
								label:
									user.data.data.data.name ||
									translate('details'),
							},
						]}
					/>
				}>
				<HeadingTabs
					tabs={[
						{
							name: translate('details'),
							href: `/${organisation?.slug}/users/${id}`,
						},
						{
							name: translate('statistics'),
							href: `/${organisation?.slug}/users/${id}/statistics`,
						},
						{
							name: translate('nominated_courses'),
							href: `/${organisation?.slug}/users/${id}/nominated-courses`,
						},
						{
							name: translate('teams'),
							href: `/${organisation?.slug}/users/${id}/teams`,
						},
					]}
				/>
			</PageHeading>
			<PageWrapper>
				<FormRow title={translate('user_details')}>
					<DataList
						items={[
							{
								label: translate('name:'),
								children: user.data.data.data.name,
							},
							{
								label: translate('email:'),
								children: user.data.data.data.email,
							},
							{
								label: translate('last_login:'),
								children: user.data.data.data.last_login
									? dateHelpers.formatDate(
											user.data.data.data.last_login
												.created_at,
											'en-gb'
									  )
									: translate('never'),
							},
							{
								label: translate('teams:'),
								children: (
									<Badges theme="green">
										{user.data.data.data.teams_count}
									</Badges>
								),
							},
						]}
					/>
				</FormRow>
				<UpdateOrgUser
					user={user.data.data.data}
					organisationId={organisation?.id || 0}
					callback={(success) => {
						if (success) {
							user.refetch();
						}
					}}
				/>
			</PageWrapper>
		</>
	);
};
export default OrganisationUserEdit;
