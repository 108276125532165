import { useMemo } from 'react';
// Components
import Badges from '@components/partials/Badges';
import DaysText from '@components/partials/DaysText';

interface AccessBadgeProps {
	show: boolean;
	full_access: boolean;
	access_in_days: number | null;
	expires_in_days: number | null;
	expired_ago: number | null;
}

const AccessBadge: React.FC<AccessBadgeProps> = ({
	show,
	full_access,
	access_in_days,
	expires_in_days,
	expired_ago,
}) => {
	// -------------------------------------------------
	// Memos
	const themeColour = useMemo(() => {
		if (full_access) {
			return 'green';
		}
		if (expired_ago || access_in_days) {
			return 'red';
		}
		if (expires_in_days) {
			return 'green';
		}

		return 'grey';
	}, [full_access, expired_ago, access_in_days, expires_in_days]);

	// -------------------------------------------------
	// Render
	if (!show && full_access) {
		return null;
	}

	return (
		<Badges theme={themeColour} className="mb-2">
			{access_in_days && (
				<>
					Access in <DaysText days={access_in_days} />
				</>
			)}
			{expires_in_days && (
				<>
					Expires in <DaysText days={expires_in_days} />
				</>
			)}
			{expired_ago && (
				<>
					Expired <DaysText days={expired_ago} /> ago
				</>
			)}
		</Badges>
	);
};

export default AccessBadge;
