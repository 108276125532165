import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
// api
import api from 'api';
// Utils
import { helpers } from '@utils/index';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Select from '@components/forms/Select';
import Textarea from '@components/forms/Textarea';
import Button from '@components/partials/Button';
import SingleFileUpload from '@components/forms/SingleFileUpload';
import FormActionModal from '@components/forms/FormActionModal';
import FormColumns from '@components/blocks/FormColumns';

interface CreateQuizActionProps extends ActionProps {}

const CreateQuizAction: React.FC<CreateQuizActionProps> = ({ callback }) => {
	// --------------------------------------
	// State / Hooks
	const navigate = useNavigate();
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [title, setTitle] = useState('');
	const [typeId, setTypeId] = useState<number>(1);
	const [desc, setDesc] = useState('');
	const [ref, setRef] = useState('');
	const [file, setFile] = useState<File | undefined | null>(undefined);

	// --------------------------------------
	// Queries / Mutations
	const quizTypes = useQuery(
		['sa.categories.getAll.quizType'],
		() => {
			return api.sa.categories.getAll({
				include: {
					meta: false,
					metaCount: false,
				},
				filters: [
					{
						key: 'scope',
						value: 'quizType',
					},
				],
				sort: {
					name: 'asc',
				},
			});
		},
		{
			onSuccess: (data) => {
				const categories = data.data.data;
				const quizName = categories.find((c) => c.name === 'Quiz');
				if (quizName) setTypeId(quizName.id);
				else setTypeId(data.data.data[0].id);
			},
		}
	);
	const createQuiz = useMutation(api.sa.quizzes.createSingle, {
		onSuccess: (data) => {
			// make invalidate any organisation queries
			queryClient.invalidateQueries(['sa.quizzes.getMultiple']);
			navigate(`/assessments/${data.data.data.id}`);
			addToast({
				title: translate('toast_created_title', {
					name: 'Assessment',
				}),
				message: translate('toast_created_message', {
					name: 'Assessment',
				}),
				type: 'success',
			});
			resetForm();
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// --------------------------------------
	// Functions
	const onSubmit = async () => {
		await createQuiz.mutateAsync({
			title: title,
			desc: desc,
			ref: ref,
			typeId: typeId,
			file: file,
		});
	};
	const resetForm = () => {
		setTitle('');
		setDesc('');
		setRef('');
		setTypeId(1);
		setFile(null);
		createQuiz.reset();
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
	};

	// --------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isLoading={quizTypes.isLoading}
			isError={quizTypes.isError}>
			<FormColumns>
				<Select
					label={translate('assessment_type')}
					value={typeId}
					setValue={(state) => {
						setTypeId(state as number);
					}}
					errors={formState.errors.value['type_id']}
					options={
						quizTypes.data?.data.data.map((type) => {
							return {
								label: `${type.name} - ${type.id}`,
								value: type.id,
							};
						}) || []
					}
				/>
				<Input
					id="quizRef"
					type="text"
					label={translate('ref')}
					name="ref"
					value={ref}
					setValue={setRef}
					errors={formState.errors.value['ref']}
					required={false}
				/>
			</FormColumns>
			<Input
				id="quizTitle"
				type="text"
				label={translate('title')}
				name="title"
				value={title}
				setValue={setTitle}
				errors={formState.errors.value['title']}
				required={true}
			/>
			<Textarea
				id="quizDesc"
				label={translate('description')}
				name="desc"
				value={desc}
				setValue={setDesc}
				errors={formState.errors.value['desc']}
				required={false}
			/>
			<SingleFileUpload
				id="quizFile"
				label={translate('storyline')}
				name="file"
				errors={formState.errors.value['file']}
				required={true}
				formats={['application/zip']}
				value={file}
				setValue={setFile}
			/>
			<FormActionModal
				loading={{
					is: createQuiz.isLoading,
					message: translate('creating'),
				}}
				error={{
					is: createQuiz.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="outline"
					type="button"
					className="mr-3"
					onClick={resetForm}>
					{translate('reset')}
				</Button>
				<Button theme="primary" type="submit">
					{translate('create')}
				</Button>
			</FormActionModal>
		</Form>
	);
};

export default CreateQuizAction;
