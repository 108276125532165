import { GoToT } from '@routes/courses/single/View';
// Components
import Button from '@components/partials/Button';

interface SingleCourseChapterContentProps {
	chapter: ChapterCourseItem;
	nextItem?: CourseItems;
	prevItem?: CourseItems;
	isFirstItem: boolean;
	functions: {
		goTo: GoToT;
	};
}

const SingleCourseChapterContent: React.FC<SingleCourseChapterContentProps> = ({
	chapter,
	nextItem,
	prevItem,
	isFirstItem,
	functions: { goTo },
}) => {
	// -------------------------------------------------
	// Render
	return (
		<div className="w-full rounded-md border-border border flex justify-center flex-col items-center text-center p-10 min-h-[400px]">
			<div className="max-w-[600px] mx-auto">
				<h2 className="text-lg font-semibold mb-2.5">
					{chapter.model.title}
				</h2>
				<p className="mb-10">{chapter.model.desc}</p>
				<div>
					{prevItem && (
						<Button
							theme="outline"
							type="button"
							className="mr-2"
							onClick={() => {
								if (!prevItem) return;
								goTo(prevItem);
							}}>
							Back
						</Button>
					)}
					{nextItem && (
						<Button
							theme="primary"
							type="button"
							disabled={!nextItem}
							onClick={() => {
								if (!nextItem) return;
								goTo(nextItem);
							}}>
							{isFirstItem ? 'Get Startarted' : 'Next'}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default SingleCourseChapterContent;
