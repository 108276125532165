const DaysText: React.FC<{ days: number }> = ({ days }) => {
	return (
		<>
			{days >= 0 ? <>{days}</> : <>~</>}
			{days === 1 ? <> day</> : <> days</>}
		</>
	);
};

export default DaysText;
