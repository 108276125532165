import { translate } from '@lang/index';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import SubmitBar from '@components/forms/SubmitBar';
// Tables
import OrganisationCourseSelectTable from '@components/table/sa/OrganisationCourseSelectTable';

interface UpdateOrganisationCoursesActionProps extends ActionProps {
	organisation: SaOrganisationsRes;
	changesMade: boolean;
	setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
	searchParams: SearchParams;
	queryString: string;
	resetFilters: () => void;
	enabled: boolean;
}

const UpdateOrganisationCoursesAction: React.FC<
	UpdateOrganisationCoursesActionProps
> = ({
	organisation,
	changesMade,
	setChangesMade,
	searchParams,
	queryString,
	callback,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State
	const formState = useFormState();
	const { addToast } = useToast();
	const [dataSet, setDataSet] = useState<boolean>(false);
	// Inputs
	const [checkedCourses, setCheckedCourse] = useState<PreserveOrganisationCourses[]>([]);

	// -------------------------------------------------
	// Queries / Mutations
	const updateOrganisation = useMutation(api.sa.organisations.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Organisation',
				}),
				message: translate('toast_updated_message', {
					name: 'Organisation',
				}),
				type: 'success',
			});
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		if(dataSet) return;
		const checked = organisation.organisation_courses?.map((course) => {
			return {
				id: course.id,
				checked: true,
				start_date: helpers.stringToDateInputValue(course.start_date),
				end_date: helpers.stringToDateInputValue(course.end_date),
			};
		}) || [];
		setDataSet(true);
		setCheckedCourse(checked);
	}, [organisation.organisation_courses, dataSet]);

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		const updateCourses = createCourseArray();

		await updateOrganisation.mutateAsync({
			id: organisation.id,
			body: {
				courses: updateCourses.map(c => {
					return {
						id: c.id,
					start_date: c.start_date ? new Date(c.start_date).toISOString() : undefined,
					end_date: c.end_date ? new Date(c.end_date).toISOString() : undefined,
					}
				}),
			},
		});
	};
	const createCourseArray = () => {
		const courses = checkedCourses.map((course) => {
			if (course.checked) {
				const item: {
					id: number;
					start_date?: string;
					end_date?: string;
				} = {
					id: course.id,
				};
				if (course.start_date) {
					item['start_date'] = course.start_date;
				}
				if (course.end_date) {
					item['end_date'] = course.end_date;
				}
				return item;
			} else {
				return undefined;
			}
		});
		const removeEmpty = courses.filter(
			(course) => course !== undefined
		) as {
			id: number;
			start_date?: string;
			end_date?: string;
		}[];
		return removeEmpty;
	};

	// -------------------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			className="pb-16">
			{/* Table */}
			<OrganisationCourseSelectTable
				setChangesMade={setChangesMade}
				queryString={queryString}
				enabled={enabled}
				searchParams={searchParams}
				checkedCourses={checkedCourses}
				setCheckedCourse={setCheckedCourse}
				errors={formState.errors.value}
				resetFilters={resetFilters}
			/>
			{/* Update */}
			<SubmitBar
				buttonText={translate('update')}
				disabled={!changesMade || formState.lock.value}
				loading={{
					isLoading: updateOrganisation.isLoading,
					message: translate('updating'),
				}}
				error={{
					isError: updateOrganisation.isError,
					message: formState.errorMessage.value,
				}}>
				<span></span>
			</SubmitBar>
		</Form>
	);
};

export default UpdateOrganisationCoursesAction;
