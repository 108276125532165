import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import HeadingTabs from '@components/partials/HeadingTabs';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import CopyAlert from '@components/partials/CopyAlert';
// Actions
import UpdateOrgAction from '@components/actions/UpdateOrg';
// Queries
import { useBillingPortal } from '@root/queries/organisations';
import { useAuthenticatedUser } from '@queries/user';

const OrganisationManage: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries / Mutations
	const user = useAuthenticatedUser();
	const organisationData = useQuery(['organisations.current.get'], () => {
		return api.organisations.current.get({
			id: organisation?.id as number,
			include: {
				media: true,
				mediaCount: true,
				plan: true,
				planCount: true,
				meta: true,
				usersCount: true,
				seatedUsersCount: true,
			},
		});
	});
	const billingPortal = useBillingPortal({
		organisationId: organisation?.id,
	});

	// -------------------------------------------------
	// Render
	if (organisationData.isLoading || billingPortal.isLoading)
		return <Loading type="page" />;
	if (organisationData.isError || billingPortal.isError)
		return <Error type={'page'} />;

	return (
		<>
			<PageHeading title={translate('organisation')}>
				{(billingPortal.data?.data.url !== null || undefined) && (
					<HeadingTabs
						tabs={[
							{
								name: translate('details'),
								href: `/${organisation?.slug}/manage`,
							},
							{
								name: translate('billing'),
								onClick: () => {
									window.open(
										billingPortal.data?.data.url as string,
										'_blank'
									);
								},
							},
						]}
					/>
				)}
			</PageHeading>
			<PageWrapper>
				{/* Alert */}
				{organisationData.data.data.data.plan !== null && (
					<div className="mb-5">
						<CopyAlert
							text="Click to copy your organisations login URL."
							copy={`${window.location.origin}/${organisationData.data.data.data.slug}/login`}
						/>
					</div>
				)}
				<UpdateOrgAction
					organisation={organisationData.data.data.data}
					callback={(success) => {
						if (success) {
							organisationData.refetch();
							user.refetch();
						}
					}}
				/>
			</PageWrapper>
		</>
	);
};

export default OrganisationManage;
