interface NoDataProps {
	title?: string;
	message?: string;
}

const NoData: React.FC<NoDataProps> = ({
	title = 'No Data',
	message = 'There is nothing to display.',
}) => {
	return (
		<div className="w-full h-48 border border-border flex items-center justify-center rounded-lg flex-col text-center">
			<h2 className="font-bold">{title}</h2>
			<p className="text-sm mt-1">{message}</p>
		</div>
	);
};

export default NoData;
