import { useState, useRef } from 'react';
import classNames from 'classnames';
import {
	faTriangleExclamation,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MultivalueInputProps {
	label?: string;
	index: number;
	id: string;
	name: string;
	values: string[];
	setValues: (value: string[]) => void;
	errors: {
		[key: string]: string[];
	};
	placeholder?: string;
	describedBy?: string;
	disabled?: boolean;
}

const MultivalueInput: React.FC<MultivalueInputProps> = ({
	label,
	id,
	index,
	name,
	values,
	setValues,
	errors = {},
	placeholder,
	describedBy,
	disabled = false,
}) => {
	const [newValues, setNewValues] = useState<string>('');
	const input = useRef<HTMLInputElement>(null);

	// ----------------------------------------------
	// Functions

	return (
		<div className={'flex flex-col mb-4 last:mb-0'}>
			{label && (
				<label htmlFor={id} className="text-body block mb-1">
					{label}
				</label>
			)}
			<div
				className={classNames(
					'border border-border min-h-[40px] py-1.5 px-2.5 rounded-md focus:border-brandPrimary focus:ring-brandPrimary relative flex gap-1 items-center flex-wrap',
					{
						'border-red': errors[name]?.length > 0,
					}
				)}
				onClick={() => {
					input.current?.focus();
				}}>
				{values.map((value, index) => (
					<span
						key={index}
						className={classNames(
							'bg-brandPrimary text-brandPrimaryText h-6 pl-2 text-sm flex items-center font-medium rounded-md mr-1',
							{
								'bg-red !text-white': errors[value]?.length > 0,
							}
						)}>
						{value}
						<button
							className={classNames(
								'px-2 h-6 ml-2 bg-brandPrimaryHover rounded-r-md flex items-center text-brandPrimaryText justify-center',
								{
									'!bg-red !text-white':
										errors[value]?.length > 0,
									'!text-opacity-40': disabled,
								}
							)}
							onClick={() => {
								if (!disabled)
									setValues(
										values.filter((v, i) => i !== index)
									);
							}}>
							<FontAwesomeIcon
								icon={faTimes}
								className={classNames('h-3')}
							/>
						</button>
					</span>
				))}
				{values.length === 0 && newValues.length === 0 && (
					<span className="text-body text-opacity-60">
						{placeholder}
					</span>
				)}
				<input
					name={name}
					id={`${id}-${index}`}
					ref={input}
					disabled={disabled}
					className={classNames(
						'box-border w-auto h-auto border-0 bg-transparent font-inherit opacity-100 outline-0 p-0 text-inherit focus:ring-0',
						{
							'absolute left-2.5 top-0 bottom-0':
								values.length === 0,
						}
					)}
					size={newValues.length > 0 ? newValues.length : 1}
					type="text"
					value={newValues}
					onChange={(e) => {
						setNewValues(e.target.value);
					}}
					onKeyDown={(e) => {
						if (
							e.key === 'Enter' ||
							e.key === 'Tab' ||
							e.key === ',' ||
							e.key === ' '
						) {
							e.preventDefault();
							if (newValues.length > 0) {
								setValues([...values, newValues]);
								setNewValues('');
							}
						}
						if (e.key === 'Backspace') {
							if (newValues.length === 0) {
								setValues(values.slice(0, -1));
							}
						}
					}}
					onBlur={() => {
						if (newValues.length > 0) {
							setValues([...values, newValues]);
							setNewValues('');
						}
					}}
				/>
			</div>
			{/* Described By */}
			{describedBy && (
				<p className="text-sm text-body mt-1 text-opacity-60">
					{describedBy}
				</p>
			)}
			{/* Errors */}
			{errors[name]?.length > 0 && (
				<div className="mt-2 flex items-start">
					<FontAwesomeIcon
						icon={faTriangleExclamation}
						className="mr-2 text-red h-3 w-3 mt-1"
					/>
					<p className="text-red text-sm">{errors[name][0]}</p>
				</div>
			)}
		</div>
	);
};

export default MultivalueInput;
