import { translate } from '@lang/index';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import OrganisationCheckCourseRow from '@components/rows/OrganisationCheckCourseRow';
import TopPagination from '@components/query/FormUtilityBar';

interface OrganisationCourseSelectTableProps extends QueryTableProps {
	setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
	checkedCourses: PreserveOrganisationCourses[];
	setCheckedCourse: React.Dispatch<
		React.SetStateAction<PreserveOrganisationCourses[]>
	>;
	errors: {
		[key: string]: string[];
	};
	utilityBar?: React.ReactNode;
}

const OrganisationCourseSelectTable: React.FC<
	OrganisationCourseSelectTableProps
> = ({
	queryString,
	searchParams,
	setChangesMade,
	checkedCourses,
	setCheckedCourse,
	errors,
	utilityBar,
	resetFilters,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const [globalStartDate, setGlobalStartDate] = useState('');
	const [globalEndDate, setGlobalEndDate] = useState('');

	// -------------------------------------------------
	// Queries / Mutations
	const courses = useQuery(
		['sa.courses.getMultiple.select', queryString],
		() => {
			return api.sa.courses.getMultiple({
				queryString: queryString,
				include: {
					items: false,
					itemsModels: false,
					itemsCount: false,
					media: true,
					mediaCount: true,
					categories: false,
					categoriesCount: false,
					categoriesMeta: false,
					organisations: false,
					organisationsCount: false,
				},
				perPage: -1,
			});
		}
	);

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		// add courses.organisation_courses to checkedCourses
		const coursesData = courses.data?.data.data || [];
		coursesData.forEach((course) => {
			// check if course is already in checkedCourses
			const courseIndex = checkedCourses.findIndex(
				(c) => c.id === course.id
			);
			if (courseIndex === -1) {
				// if not, add it
				setCheckedCourse([
					...checkedCourses,
					{
						id: course.id,
						checked: false,
						start_date: '',
						end_date: '',
					},
				]);
			}
		});
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courses, checkedCourses]);

	// -------------------------------------------------
	// Memos
	const selectedCourses = useMemo(() => checkedCourses.filter((c) => c.checked === true), [checkedCourses]);
	const publicCourses = useMemo(() => {
		return courses.data?.data.data.filter((course) => course.public === true);
	}, [courses.data?.data.data]);
	const allPublicCoursesChecked = useMemo(() => publicCourses?.every((c) => {
		return selectedCourses.findIndex((s) => s.id === c.id) !== -1;
	}), [publicCourses, selectedCourses]);

	// -------------------------------------------------
	// Functions
	const rowIsChecked = (course: SaCourseRes) => {
		return checkedCourses.find(
			(c) => c.id === course.id && c.checked === true
		)
			? true
			: false;
	};
	const updateRowDates = (
		course: SaCourseRes,
		startDate: string,
		endDate: string
	) => {
		// Find course in checkedCourses
		const courseIndex = checkedCourses.findIndex((c) => c.id === course.id);
		if (courseIndex === -1) return;

		// Update course in checkedCourses
		const newCheckedCourses = [...checkedCourses];
		newCheckedCourses[courseIndex] = {
			checked: true,
			id: course.id,
			start_date: startDate,
			end_date: endDate,
		};
		setCheckedCourse(newCheckedCourses);
		setChangesMade(true);
	};
	const checkRow = (
		check: boolean,
		course: SaCourseRes,
		startDate: string,
		endDate: string
	) => {
		// Find course in checkedCourses
		const courseIndex = checkedCourses.findIndex((c) => c.id === course.id);
		if (courseIndex === -1) return;

		// Update course in checkedCourses
		const newCheckedCourses = [...checkedCourses];
		newCheckedCourses[courseIndex] = {
			checked: check,
			id: course.id,
			start_date: startDate,
			end_date: endDate,
		};
		setCheckedCourse(newCheckedCourses);
		setChangesMade(true);
	};
	const checkAllRows = (check: boolean) => {
		setCheckedCourse(
			checkedCourses.map((course) => {
				const targetCourse = publicCourses?.find((c) => c.id === course.id);

				return {
					checked: check && targetCourse?.public ? true : false,
					id: course.id,
					start_date: course.start_date,
					end_date: course.end_date,
				};
			}) || []
		);
		setChangesMade(true);
	};
	const courseErrors = (course: SaCourseRes) => {
		const findFromSelected = selectedCourses.findIndex(
			(c) => c.id === course.id
		);
		if (findFromSelected === -1)
			return {
				end_date: [],
				start_date: [],
			};
		return {
			end_date: errors[`courses.${findFromSelected}.end_date`] || [],
			start_date: errors[`courses.${findFromSelected}.start_date`] || [],
		};
	};
	const currentRow = (course: SaCourseRes) => {
		const findFromSelected = selectedCourses.findIndex(
			(c) => c.id === course.id
		);
		if (findFromSelected === -1) return undefined;
		return selectedCourses[findFromSelected];
	};



	// -------------------------------------------------
	// Return
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={courses.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: courses.isLoading,
					error: courses.isError,
					noData: courses.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Course',
					}),
					message: translate('no_courses_assigned'),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('select'),
							type: 'checkbox',
							setChecked: checkAllRows,
							checked: allPublicCoursesChecked
						},
						{
							label: translate('title'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'title'
							),
							type: 'sortable',
						},
						{
							label: translate('start_date'),
							type: 'date',
							value: globalStartDate,
							setValue: (val: string) => {
								setGlobalStartDate(val);
								setCheckedCourse(
									checkedCourses.map((c) => {
										return {
											...c,
											start_date: val,
										};
									})
								);
								setChangesMade(true);
							},
						},
						{
							label: translate('end_date'),
							type: 'date',
							value: globalEndDate,
							setValue: (val: string) => {
								setGlobalEndDate(val);
								setCheckedCourse(
									checkedCourses.map((c) => {
										return {
											...c,
											end_date: val,
										};
									})
								);
								setChangesMade(true);
							},
						},
					]}
					setSort={searchParams.sort.setValue}>
					{courses.data?.data.data.map((course, index) => (
						<OrganisationCheckCourseRow
							key={index}
							course={course}
							isChecked={rowIsChecked(course)}
							checkRow={checkRow}
							updateRowDates={updateRowDates}
							currentRow={currentRow(course)}
							errors={courseErrors(course)}
						/>
					))}
				</Table>
			</DynamicBlock>
		</>
	);
};

export default OrganisationCourseSelectTable;
