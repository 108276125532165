import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Breadcrumbs from '@components/partials/Breadcrumbs';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import HeadingTabs from '@components/partials/HeadingTabs';
import Loading from '@components/partials/Loading';
import ErrorBlock from '@components/partials/Error';
// Actions
import UpdateOrgUserTeamsAction from '@components/actions/UpdateOrgUserTeams';

const OrganisationUserTeams: React.FC = () => {
	// --------------------------------------
	// State & Hooks
	const { id } = useParams();
	const { organisation } = useOrganisation();

	// --------------------------------------
	// Queries & Mutations
	const user = useQuery(
		['organisations.users.getSingle', organisation, id],
		() => {
			if (!id) throw new Error(translate('no_id_provided'));
			return api.organisations.users.getSingle({
				organisationId: organisation?.id || 0,
				id: parseInt(id),
				include: {
					courses: true,
					lastLogin: false,
					lastLoginCount: false,
					teams: true,
					teamsCount: true,
					roles: false,
				},
			});
		}
	);
	const teams = useQuery(
		['organisations.teams.getMultiple', organisation],
		() => {
			return api.organisations.teams.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					courses: false,
					coursesCount: false,
					users: false,
					usersCount: false,
				},
				perPage: -1,
			});
		}
	);

	// --------------------------------------
	// Functions

	// --------------------------------------
	// Render
	if (user.isLoading || teams.isLoading) return <Loading type="page" />;
	if (user.isError || teams.isError) return <ErrorBlock type="page" />;

	return (
		<>
			<PageHeading
				title={translate('user_teams')}
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: `/${organisation?.slug}/users`,
								label: translate('users'),
							},
							{
								href: `/${organisation?.slug}/users/${id}`,
								label:
									user.data.data.data.name ||
									translate('details'),
							},
							{
								href: `/${organisation?.slug}/users/${id}/teams`,
								label: translate('teams'),
							},
						]}
					/>
				}>
				<HeadingTabs
					tabs={[
						{
							name: translate('details'),
							href: `/${organisation?.slug}/users/${id}`,
						},
						{
							name: translate('statistics'),
							href: `/${organisation?.slug}/users/${id}/statistics`,
						},
						{
							name: translate('nominated_courses'),
							href: `/${organisation?.slug}/users/${id}/nominated-courses`,
						},
						{
							name: translate('teams'),
							href: `/${organisation?.slug}/users/${id}/teams`,
						},
					]}
				/>
			</PageHeading>
			<PageWrapper>
				<UpdateOrgUserTeamsAction
					user={user.data.data.data}
					organisationId={organisation?.id || 0}
					teams={teams.data.data.data}
					mode="page"
					callback={(success) => {
						if (success) {
							user.refetch();
							teams.refetch();
						}
					}}
				/>
			</PageWrapper>
		</>
	);
};
export default OrganisationUserTeams;
