import { translate } from '@lang/index';
import { useMemo } from 'react';
import {
	faSquarePollVertical,
	faBarsProgress,
	faVideo,
	faFolderClosed,
	faAward,
	faBookBookmark,
	faNoteSticky,
	faUserGear,
} from '@fortawesome/free-solid-svg-icons';
// Hooks
import { useOrganisation } from '@hooks/index';
// Groups
import ListWrapper from '@components/groups/navigation/ListWrapper';
import NavigationLink from '@components/groups/navigation/NavigationLink';
import SubscriptionsLink from '@components/groups/navigation/SubscriptionsLink';

interface UsersListProps {
	permissions: {
		organisationAccess: boolean;
		viewCourses: boolean;
		manageNotes: boolean;
		manageOrganisation: boolean;
		manageOrganisationWithPlan: boolean;
	};
	setNavOpen?: (open: boolean) => void;
}

const UsersList: React.FC<UsersListProps> = ({ permissions, setNavOpen }) => {
	// --------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

	const showSubscriptionLink = useMemo(() => {
		if (
			organisation?.stripe_id !== null &&
			permissions.manageOrganisation
		) {
			return true;
		}
		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	// --------------------------------------
	// Render
	return (
		<ListWrapper>
			{permissions.organisationAccess && (
				<>
					<NavigationLink
						to={`/${organisation?.slug}/progress`}
						label={translate('progress')}
						icon={faBarsProgress}
						permission={permissions.organisationAccess}
						setNavOpen={setNavOpen}
						openDropdown={true}
						items={[
							{
								permission: permissions.organisationAccess,
								component: (
									<NavigationLink
										to={`/${organisation?.slug}/video-views`}
										label={translate('video_views')}
										icon={faVideo}
										isChild={true}
										setNavOpen={setNavOpen}
									/>
								),
							},
							{
								permission: permissions.organisationAccess,
								component: (
									<NavigationLink
										to={`/${organisation?.slug}/assessment-results`}
										label={translate('assessment_results')}
										icon={faSquarePollVertical}
										isChild={true}
										setNavOpen={setNavOpen}
									/>
								),
							},
						]}
					/>
					<NavigationLink
						to={`/${organisation?.slug}/awards`}
						label={translate('awards')}
						icon={faAward}
						permission={!permissions.manageOrganisationWithPlan}
						setNavOpen={setNavOpen}
					/>
					<NavigationLink
						to={`/${organisation?.slug}/bookmarks`}
						label={translate('bookmarks')}
						icon={faBookBookmark}
						permission={permissions.viewCourses}
						setNavOpen={setNavOpen}
					/>
					<NavigationLink
						to={`/${organisation?.slug}/notes`}
						label={translate('notes')}
						icon={faNoteSticky}
						permission={permissions.manageNotes}
						setNavOpen={setNavOpen}
					/>
					<NavigationLink
						to={`/${organisation?.slug}/logins`}
						label={translate('logins')}
						icon={faFolderClosed}
						permission={!permissions.manageOrganisationWithPlan}
						setNavOpen={setNavOpen}
					/>
				</>
			)}
			<NavigationLink
				to={`/account`}
				label={translate('my_account')}
				icon={faUserGear}
				permission={true}
				setNavOpen={setNavOpen}
			/>
			{showSubscriptionLink && (
				<SubscriptionsLink urlMode={'organisation'} />
			)}
		</ListWrapper>
	);
};

export default UsersList;
