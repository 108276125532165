import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import C from '@root/constants';
// api
import api from 'api';
// Hooks
import { useSelectedRow, useOrganisation } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import Pagination from '@components/query/Pagination';
import NominatedCourseRow from '@components/rows/NominatedCourseRow';
import TopPagination from '@components/query/FormUtilityBar';
// Modals
import UpdateCourseNominations from '@components/modal/UpdateCourseNominations';
import ListCourseNominations from '@components/modal/ListCourseNominations';

interface OrgNominatedCourseTableProps extends QueryTableProps {
	utilityBar?: React.ReactNode;
}

const OrgNominatedCourseTable: React.FC<OrgNominatedCourseTableProps> = ({
	queryString,
	searchParams,
	utilityBar,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { selectedRow, setSelectedRow } = useSelectedRow<CourseRes | null>(
		null
	);
	const [nominationMode, setNominationMode] = useState<'team' | 'user'>(
		'team'
	);

	const [openNominationsModal, setOpenNominationsModal] = useState(false);
	const [openNominationsListModal, setOpenNominationsListModal] =
		useState(false);

	// -------------------------------------------------
	// Mutations / Queries
	const courses = useQuery(
		['organisations.courses.getMultiple.admin', queryString, organisation],
		() => {
			return api.organisations.courses.getMultiple({
				queryString: queryString,
				organisationId: organisation?.id || 0,
				include: {
					items: false,
					itemsModel: false,
					itemsModelType: false,
					media: true,
					type: true,
					teamsCount: true,
					usersCount: true,
				},
				filters: C.NOMINATED_COURSE_FILTER,
			});
		},
		{
			keepPreviousData: true,
			enabled: enabled,
		}
	);

	// -------------------------------------------------
	// Render
	if (!organisation) return null;

	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={courses.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: courses.isLoading,
					error: courses.isError,
					noData: courses.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Course',
					}),
					message: translate('no_data_message', {
						name: 'course',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('title'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'title'
							),
							type: 'sortable',
						},
						{
							label: translate('type'),
							type: 'standard',
						},
						{
							label: translate('nominated_teams'),
							type: 'standard',
						},
						{
							label: translate('nominated_users'),
							type: 'standard',
						},
						{
							label: translate('start_date'),
							type: 'standard',
						},
						{
							label: translate('end_date'),
							type: 'standard',
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{courses.data?.data.data.map((course, index) => (
						<NominatedCourseRow
							key={index}
							course={course}
							selectRow={(course, action) => {
								setSelectedRow(course);
								switch (action) {
									case 'nominate':
										setOpenNominationsModal(true);
										break;
									case 'teams-list':
										setNominationMode('team');
										setOpenNominationsListModal(true);
										break;
									case 'users-list':
										setNominationMode('user');
										setOpenNominationsListModal(true);
										break;
								}
							}}
						/>
					))}
				</Table>
				<Pagination
					meta={courses.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
				{selectedRow && (
					<>
						<UpdateCourseNominations
							courseId={selectedRow.id}
							open={openNominationsModal}
							setOpen={setOpenNominationsModal}
							onSuccess={() => {
								courses.refetch();
							}}
						/>
						<ListCourseNominations
							courseId={selectedRow.id}
							open={openNominationsListModal}
							setOpen={setOpenNominationsListModal}
							mode={nominationMode}
						/>
					</>
				)}
			</DynamicBlock>
		</>
	);
};

export default OrgNominatedCourseTable;
