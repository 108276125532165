import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Breadcrumbs from '@components/partials/Breadcrumbs';
import ErrorBlock from '@components/partials/Error';
import Loading from '@components/partials/Loading';
import VideoPreview from '@components/groups/edit-video/VideoPreview';
import FormRow from '@components/forms/FormRow';
import InfoRow from '@components/blocks/InfoRow';
import Button from '@components/partials/Button';
import DataList from '@components/partials/DataList';
import Badges from '@components/partials/Badges';
import Date from '@components/partials/Date';
// Modals
import SyncVideo from '@components/modal/SyncVideo';
// Actions
import UpdateVideoAction from '@components/actions/UpdateVideo';

const VideoEdit = () => {
	// -------------------------------------------------
	// State / Hooks
	const { id } = useParams();

	// modals
	const [showSync, setShowSync] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const video = useQuery(['sa.videos.getSingle', id], () => {
		if (!id) throw new Error('No ID provided');
		return api.sa.videos.getSingle({
			id,
			include: {
				textTracks: true,
				textTracksCount: true,
				textTracksCues: true,
				media: true,
			},
		});
	});
	const videoTypes = useQuery(['sa.categories.getAll.videoType'], () => {
		return api.sa.categories.getAll({
			include: {
				meta: false,
				metaCount: false,
			},
			filters: [
				{
					key: 'scope',
					value: 'videoType',
				},
			],
			sort: {
				name: 'asc',
			},
		});
	});

	// -------------------------------------------------
	// Render
	if (video.isLoading || videoTypes.isLoading) return <Loading type="page" />;
	if (video.isError || videoTypes.isError) return <ErrorBlock type="page" />;

	return (
		<>
			<PageHeading
				title={video.data.data.data.title}
				body={video.data.data.data.desc || ''}
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: '/videos',
								label: translate('videos'),
							},
							{
								href: `/videos/${id}`,
								label: translate('edit'),
							},
						]}
					/>
				}
			/>
			<PageWrapper>
				<FormRow title={translate('details')}>
					<UpdateVideoAction
						video={video.data.data.data}
						videoTypes={videoTypes.data.data.data}
						callback={(success) => {
							if (success) {
								video.refetch();
							}
						}}
					/>
				</FormRow>
				<FormRow title={translate('preview')}>
					<VideoPreview video={video.data.data.data} />
				</FormRow>
				<FormRow title={translate('meta')}>
					<InfoRow>
						<DataList
							items={[
								{
									label: translate('synced_at:'),
									children: video.data.data.data.synced_at ? (
										<Badges theme="green">
											<Date
												date={
													video.data.data.data
														.synced_at
												}
											/>
										</Badges>
									) : (
										<Badges theme="red">
											{translate('not_synced')}
										</Badges>
									),
								},
							]}
						/>
					</InfoRow>
					<InfoRow info={translate('sync_video_msg')}>
						<Button
							theme="outline"
							type="button"
							onClick={() => setShowSync(true)}>
							{translate('sync_video')}
						</Button>
					</InfoRow>
				</FormRow>
				<SyncVideo
					videoId={video.data.data.data.id}
					open={showSync}
					setOpen={setShowSync}
					onSuccess={() => {
						video.refetch();
					}}
				/>
			</PageWrapper>
		</>
	);
};

export default VideoEdit;
