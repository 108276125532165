import { translate } from '@lang/index';
// Hooks
import { useAuth } from '@hooks/index';
// Components
import Button from '@components/partials/Button';

interface LogOutProps {
	setNavOpen?: (open: boolean) => void;
}

const LogOut: React.FC<LogOutProps> = ({ setNavOpen }) => {
	// -------------------------------------------------
	// State
	const { signout } = useAuth();

	// -------------------------------------------------
	// Queries & Mutations
	const logout = () => {
		signout(
			() => {
				if (setNavOpen) {
					setNavOpen(false);
				}
			},
			(error) => {
				console.log(error);
			}
		);
	};

	// -------------------------------------------------
	// Render
	return (
		<Button theme={'outline'} onClick={() => logout()} className="w-full">
			{translate('log_out')}
		</Button>
	);
};

export default LogOut;
