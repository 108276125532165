import { useMemo } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { faCheck, faEye } from '@fortawesome/free-solid-svg-icons';
// Types
import { GoToT } from '@routes/courses/single/View';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CategoryIcon from '@components/partials/CategoryIcon';

interface VideoQuizItemProps {
	mode: 'preview' | 'standard';
	item: QuizCourseItem | VideoCourseItem;
	topLevel: boolean;
	status: CourseStatusRes[];
	categories: SaCategoriesRes[] | CategoriesRes[];
	functions: {
		goTo: GoToT;
	};
}

const VideoQuizItem: React.FC<VideoQuizItemProps> = ({
	mode,
	item,
	topLevel,
	status,
	categories,
	functions,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { goTo } = functions;

	const { itemId, itemType } = useParams<{
		organisationSlug: string;
		id: string;
		itemType: 'video' | 'assessment';
		itemId: string;
	}>();

	const itemTypeActual = useMemo(() => {
		if (itemType === 'assessment') return 'quiz';
		else return itemType;
	}, [itemType]);

	// -------------------------------------------------
	// Memos
	const itemProgress = useMemo(() => {
		const progress = status.find(
			(statusItem) =>
				statusItem.model_id === item.model_id &&
				statusItem.model_type === item.model_type
		);
		return progress;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const itemActive = useMemo(() => {
		return (
			item.model_id === parseInt(itemId || '') &&
			item.model_type === itemTypeActual
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemId, itemTypeActual]);

	const itemDisabled = useMemo(() => {
		if (mode === 'preview') return false;
		if (item.model_type === 'quiz' && !itemProgress?.can) return true;
		if (item.model_type === 'video' && !itemProgress?.can) return true;
		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode, itemProgress]);

	const category = useMemo(
		() => categories.find((category) => category.id === item.model.type_id),
		[categories, item.model.type_id]
	);
	const categoryScope = useMemo<CategoryScopes>(() => {
		if (item.model_type === 'quiz') return 'quizType';
		if (item.model_type === 'video') return 'videoType';
		return 'quizType';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item.model_type]);

	// -------------------------------------------------
	// Classes
	const subItemDash =
		'before:block before:top-1/2 before:-translate-y-1/2 before:absolute before:-left-4 before:h-[1px] before:w-2 before:bg-border';

	const listItem = classNames(
		'rounded-md px-2 py-2 border text-sm flex items-center justify-between relative  cursor-pointer hover:underline',
		{
			'bg-uiLight border-border': itemActive,
			'border-white': !itemActive,
			'opacity-50 cursor-not-allowed hover:no-underline': itemDisabled,
			[subItemDash]: !topLevel,
		}
	);

	const WrapperELe: React.FC<{
		children: React.ReactNode;
	}> = ({ children }) => {
		if (topLevel) {
			return (
				<div onClick={() => goTo(item)} className={listItem}>
					{children}
				</div>
			);
		}
		return (
			<li onClick={() => goTo(item)} className={listItem}>
				{children}
			</li>
		);
	};

	// -------------------------------------------------
	// Render
	return (
		<WrapperELe>
			<div className="flex items-center ">
				<span className="relative mr-3">
					<CategoryIcon
						colour={category?.meta.icon_colour}
						initials={category?.meta.icon_icon_initials}
						label={category?.name}
						categoryScope={categoryScope}
						size={'sm'}
					/>
				</span>
				<p className="text-sm">{item.model.title}</p>
			</div>
			{mode === 'standard' && (
				<span className="flex w-20 justify-end">
					{item.model_type === 'quiz' && (
						<QuizIcons progress={itemProgress} />
					)}
					{item.model_type === 'video' && (
						<VideoIcons progress={itemProgress} />
					)}
				</span>
			)}
		</WrapperELe>
	);
};

const QuizIcons: React.FC<{ progress?: CourseStatusRes }> = ({ progress }) => {
	if (progress?.completed) {
		return (
			<FontAwesomeIcon icon={faCheck} className={'w-5 text-[#969696]'} />
		);
	}

	return null;
};
const VideoIcons: React.FC<{ progress?: CourseStatusRes }> = ({ progress }) => {
	const meta = progress?.meta as VideoCourseStatusMeta | null;

	if (!progress?.can) return null;

	if (meta !== null && meta.viewed_at) {
		return (
			<FontAwesomeIcon icon={faEye} className={'w-5 text-[#969696]'} />
		);
	}

	return null;
};

export default VideoQuizItem;
