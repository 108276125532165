import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
// api
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Loading from '@components/partials/Loading';
import ErrorBlock from '@components/partials/Error';
import PageHeading from '@components/blocks/PageHeading';
import PageWrapper from '@components/layout/PageWrapper';
import Breadcrumbs from '@components/partials/Breadcrumbs';
import HeadingTabs from '@components/partials/HeadingTabs';
import Button from '@components/partials/Button';
// Group
import OrgTeamsUsersTable from '@components/groups/org-teams/UsersTable';
// Modals
import InviteOrgUser from '@components/modal/InviteOrgUser';

const OrganisationTeamUsers: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { id } = useParams();
	const { organisation } = useOrganisation();

	const [openInviteUser, setOpenInviteUser] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const team = useQuery(['organisations.teams.getSingle', id], () => {
		if (!id) throw new Error(translate('no_id_provided'));
		return api.organisations.teams.getSingle({
			organisationId: organisation?.id || 0,
			id: parseInt(id),
			include: {
				courses: true,
				coursesCount: true,
				users: true,
				usersCount: true,
			},
		});
	});

	// -------------------------------------------------
	// Render
	if (!organisation || !id) return null;
	if (team.isLoading) return <Loading type="page" />;
	if (team.isError) return <ErrorBlock type="page" />;

	return (
		<>
			<PageHeading
				title={translate('edit_team_users')}
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: `/${organisation.slug}/teams`,
								label: translate('teams'),
							},
							{
								href: `/${organisation.slug}/teams/${id}`,
								label: translate('edit'),
							},
							{
								href: `/${organisation.slug}/teams/${id}/users`,
								label: translate('users'),
							},
						]}
					/>
				}>
				<div className="flex flex-wrap justify-between gap-2.5">
					<HeadingTabs
						tabs={[
							{
								name: translate('details'),
								href: `/${organisation.slug}/teams/${id}`,
							},
							{
								name: translate('users'),
								href: `/${organisation.slug}/teams/${id}/users`,
							},
						]}
					/>
					<Button
						theme={'primary'}
						onClick={() => setOpenInviteUser(true)}>
						{translate('add_user')}
					</Button>
				</div>
			</PageHeading>
			<PageWrapper>
				<OrgTeamsUsersTable team={team.data.data.data} />
			</PageWrapper>
			{/* Modals */}
			<InviteOrgUser
				organisationId={organisation?.id || 0}
				open={openInviteUser}
				setOpen={setOpenInviteUser}
				defaultTeamId={team.data.data.data.id}
			/>
		</>
	);
};
export default OrganisationTeamUsers;
