import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
// api
import api from 'api';
// Hooks
import { useSelectedRow, useOrganisation } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Table from '@components/table/Table';
import OrgTeamRow from '@components/rows/OrgTeamRow';
import Pagination from '@components/query/Pagination';
// Modals
import DeleteOrgTeam from '@components/modal/DeleteOrgTeam';
import TopPagination from '@components/query/FormUtilityBar';

interface OrgTeamsTableProps extends QueryTableProps {
	utilityBar?: React.ReactNode;
}

const OrgTeamsTable: React.FC<OrgTeamsTableProps> = ({
	queryString,
	searchParams,
	utilityBar,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { selectedRow, setSelectedRow } = useSelectedRow<TeamRes | null>(
		null
	);

	// Modal State
	const [openDelete, setOpenDelete] = useState(false);

	// -------------------------------------------------
	// Mutations / Queries
	const teams = useQuery(
		['organisations.teams.getMultiple', queryString, organisation],
		() => {
			return api.organisations.teams.getMultiple({
				queryString,
				organisationId: organisation?.id || 0,
				include: {
					courses: false,
					coursesCount: true,
					users: false,
					usersCount: true,
				},
			});
		},
		{
			keepPreviousData: true,
			enabled: enabled,
		}
	);

	// -------------------------------------------------
	// Render
	if (!organisation) return null;

	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={teams.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: teams.isLoading,
					error: teams.isError,
					noData: teams.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Team',
					}),
					message: translate('no_data_message', {
						name: 'team',
					}),
				}}
				resetFilters={resetFilters}>
				<Table
					head={[
						{
							label: translate('name'),
							sort: searchParams.sort.value.find(
								(s) => s.key === 'name'
							),
							type: 'sortable',
						},
						{
							label: translate('users'),
							type: 'standard',
						},
						{
							label: translate('courses'),
							type: 'standard',
						},
						{
							label: translate('edit'),
							type: 'sr-only',
						},
					]}
					setSort={searchParams.sort.setValue}>
					{teams.data?.data.data.map((team, index) => (
						<OrgTeamRow
							key={index}
							team={team}
							organisationSlug={organisation.slug}
							selectRow={(team, action) => {
								setSelectedRow(team);
								switch (action) {
									case 'delete':
										setOpenDelete(true);
										break;
								}
							}}
						/>
					))}
				</Table>
				<Pagination
					meta={teams.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
				{selectedRow !== null && (
					<DeleteOrgTeam
						teamdId={selectedRow.id}
						organisationId={organisation?.id}
						onSuccess={() => searchParams.page.setValue(1)}
						open={openDelete}
						setOpen={setOpenDelete}
					/>
				)}
			</DynamicBlock>
		</>
	);
};

export default OrgTeamsTable;
