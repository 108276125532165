import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
// Assets
import questionsSVG from '@assets/images/svgs/questions.svg';
// Hooks
import { useOrganisation, useToast } from '@hooks/index';
// api
import api from 'api';
// Components
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import TabList from '@components/blocks/TabList';
import MessageBlock from '@components/blocks/MessageBlock';
import NoteRow from '@components/rows/NoteRow';
import AlertMessage from '@components/partials/AlertMessage';
import Button from '@components/partials/Button';

interface SingleCourseQuizContentProps {
	mode: 'preview' | 'standard';
	id: number;
	courseId: number;
	functions: {
		refetchStatus: () => Promise<void>;
	};
}

const SingleCourseQuizContent: React.FC<SingleCourseQuizContentProps> = ({
	id,
	courseId,
	mode,
	functions: { refetchStatus },
}) => {
	// -------------------------------------------------
	// State / Hooks
	const iframeRef = useRef<HTMLIFrameElement | null>(null);
	const { organisation } = useOrganisation();
	const { addToast } = useToast();
	const [active, setActive] = useState('notes');
	const [receivedMessage, setReceivedMessage] = useState<boolean>();
	const [showRetry, setShowRetry] = useState(false);

	// -------------------------------------------------
	// Queries
	type SaQuiz = AxiosResponse<APIResponse<SaQuizzesRes>, any>;
	type OrgQuiz = AxiosResponse<APIResponse<QuizzesRes>, any>;

	const quiz = useQuery<SaQuiz | OrgQuiz>(
		['sa.quizzes.getSingle', id],
		() => {
			if (mode === 'preview') {
				return api.sa.quizzes.getSingle({
					id,
					include: {
						type: true,
						typeCount: false,
						media: true,
						mediaCount: false,
					},
				});
			} else {
				return api.organisations.courses.quizzes.getSingle({
					organisationId: organisation?.id || 0,
					courseId: courseId,
					quizId: id,
					include: {
						type: true,
					},
				});
			}
		}
	);
	const notes = useQuery(
		['organisations.notes.getSingle.quiz', id],
		() => {
			if (mode === 'preview') return;
			return api.organisations.notes.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					noteable: false,
					course: false,
				},
				filters: {
					noteable_type: 'quiz',
					noteable_id: id,
					course_id: courseId,
				},
			});
		},
		{
			enabled: mode === 'standard',
		}
	);

	// -------------------------------------------------
	// Memo
	const queryParam = useMemo(() => {
		const url = api.organisations.quizzes.results.xAPIStatement();
		if (mode === 'standard') {
			return `?endpoint=${url}&actor={}&organisation_id=${organisation?.id}&course_id=${courseId}&quiz_id=${id}&preview=0`;
		}
		return `?endpoint=${url}&actor={}&preview=1`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode, organisation, courseId]);
	const firstNote = useMemo(() => {
		return notes.data?.data.data[0];
	}, [notes.data]);

	// -------------------------------------------------
	// Functions
	const fetchCourseStatus = (e: MessageEvent) => {
		if (e.data.messageId !== 'xapi_statement') return;
		setReceivedMessage(true);

		const data = e.data?.data;
		if (!data.response) return;

		const status = data.response?.status as number | undefined;

		// if status doesnt start with 2
		if (!status?.toString().startsWith('2')) {
			addToast({
				title: translate('toast_error_title'),
				message: translate('toast_error_message'),
				type: 'error',
			});
			return;
		}

		const completion = data.request?.result?.completion as
			| boolean
			| undefined;
		const rawScore = data.request?.result?.score?.raw as number | undefined;
		const success = data.request?.result?.success as boolean | undefined;

		if (rawScore !== undefined && success !== undefined) {
			refetchStatus();
		}
		if (completion) {
			setShowRetry(true);
		}
	};

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		window.addEventListener('message', fetchCourseStatus);
		return () => {
			window.removeEventListener('message', fetchCourseStatus);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quiz]);

	// -------------------------------------------------
	// Show Quiz error
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (receivedMessage) return;
			setReceivedMessage(false);
		}, 5000);
		return () => {
			clearTimeout(timeout);
		};
	}, [receivedMessage]);

	// -------------------------------------------------
	// Render
	if (quiz.isLoading || mode === 'standard' ? notes.isLoading : false) {
		return (
			<div className="relative w-full overflow-hidden rounded-md before:block before:pb-[65%]">
				<Loading type="skeleton" />
			</div>
		);
	}
	if (quiz.isError || notes.isError) {
		return <Error type="block" />;
	}

	if (!quiz.data?.data.data.file_extracted) {
		return (
			<MessageBlock
				title={translate('assessment_not_available_title')}
				message={translate('assessment_not_available_message')}
				image={questionsSVG}
			/>
		);
	}

	return (
		<>
			{receivedMessage === false && (
				<div className="mb-5">
					<AlertMessage
						text={translate('assessment_communication_error')}
						type="error"
					/>
				</div>
			)}
			{showRetry && (
				<Button
					type="button"
					theme="primary"
					className="mb-5 h-14 w-full"
					onClick={() => {
						if (iframeRef.current) {
							iframeRef.current.src = `${quiz.data?.data.data.file_extracted}${queryParam}`;
							setShowRetry(false);
						}
					}}>
					{translate('retry_assessment')}
				</Button>
			)}
			<div className="relative mb-5 w-full before:block before:pb-[65%]">
				<iframe
					sandbox="allow-same-origin allow-scripts"
					className="absolute inset-0 h-full w-full"
					src={`${quiz.data?.data.data.file_extracted}${queryParam}`}
					title="assessment"
					allowFullScreen
					ref={iframeRef}
				/>
			</div>
			{mode === 'standard' && (
				<div>
					<TabList
						active={active}
						setActive={setActive}
						options={[
							{
								label: 'Notes',
								value: 'notes',
							},
						]}
					/>
					{active === 'notes' && (
						<NoteRow
							key={id}
							note={firstNote}
							hideHeading={true}
							create={firstNote === undefined}
							noteableType="quiz"
							noteableId={id}
							courseId={courseId}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default SingleCourseQuizContent;
