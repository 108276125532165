import { rolesT, translate } from '@lang/index';
import { useMemo } from 'react';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import Badges from '@components/partials/Badges';
// Table Columns
import UserColumn from '@components/table-columns/UserColumn';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';
import LastLoginColumn from '@components/table-columns/LastLoginColumn';

interface OrganisaionUsersRowProps {
	user: SaUsersRes;
	organisationId: number;
	selectRow: (course: SaUsersRes, action: 'update' | 'toggle-seat') => void;
}

const OrganisaionUsersRow: React.FC<OrganisaionUsersRowProps> = ({
	user,
	organisationId,
	selectRow,
}) => {
	// -------------------------------------------------
	// Memos
	const organisation = useMemo(() => {
		return user.organisations?.find((org) => org.id === organisationId);
	}, [user.organisations, organisationId]);

	const roles = useMemo(() => {
		const roles: RoleRes[] = [];
		user.roles?.forEach((role) => {
			if (role.organisation_id === organisationId) {
				roles.push(role);
			}
		});
		return roles?.filter((role) => role !== undefined);
	}, [user.roles, organisationId]);

	// -------------------------------------------------
	// Render
	return (
		<tr>
			<td className="td-default">
				<UserColumn name={user.name} email={user.email} />
			</td>
			<td className="td-default">
				<Badges
					theme={organisation?.seated_at ? 'green' : 'red'}
					className={'mr-1'}
					onClick={() => {
						selectRow(user, 'toggle-seat');
					}}>
					{organisation?.seated_at
						? translate('seated')
						: translate('not_seated')}
				</Badges>
			</td>
			<td className="td-default">
				<div>
					{roles?.map((role, index) => (
						<Badges key={index} theme="green" className={'mr-1'}>
							{rolesT(role?.name)}
						</Badges>
					))}
				</div>
			</td>
			<td className="td-default">
				<LastLoginColumn date={user.last_login?.created_at} />
			</td>
			<td className="td-default">
				<DateTimeColumn date={user.created_at || ''} />
			</td>
			<DropdownMenuColumn
				showFirst={true}
				items={[
					{
						label: translate('update'),
						type: 'button',
						onClick: () => {
							selectRow(user, 'update');
						},
						show: true,
					},
					{
						label: translate('progress'),
						type: 'link',
						href: `/progress?filter[user_id]=${user.id}`,
						show: true,
					},
					{
						label: translate('video_views'),
						type: 'link',
						href: `/video-views?filter[user_id]=${user.id}`,
						show: true,
					},
					{
						label: translate('assessment_results'),
						type: 'link',
						href: `/assessment-results?filter[user_id]=${user.id}`,
						show: true,
					},
					{
						label: translate('awards'),
						type: 'link',
						href: `/user-awards?filter[user_id]=${user.id}`,
						show: true,
					},
					{
						label: translate('logins'),
						type: 'link',
						href: `/logins?filter[user_id]=${user.id}`,
						show: true,
					},
				]}
			/>
		</tr>
	);
};
export default OrganisaionUsersRow;
