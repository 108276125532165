import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
// import Table from '@components/table/Table';
import CertificateRow from '@components/rows/CertificateRow';
import Pagination from '@components/query/Pagination';
import TopPagination from '@components/query/FormUtilityBar';

interface CertificateTableProps extends QueryTableProps {
	user?: CurrentUserRes;
	utilityBar?: React.ReactNode;
}

const UserCertificateTable: React.FC<CertificateTableProps> = ({
	queryString,
	searchParams,
	user,
	utilityBar,
	resetFilters,
	enabled,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Mutations / Queries
	const awards = useQuery(
		['organisations.userAwards.getMultiple', queryString, organisation?.id],
		() => {
			return api.organisations.userAwards.getMultiple({
				organisationId: organisation?.id,
				include: {
					user: true,
					award: true,
				},
				queryString,
				filters: {
					user_id: user?.id || undefined,
				},
			});
		},
		{
			enabled: !!organisation?.id && enabled,
			keepPreviousData: true,
		}
	);

	// -------------------------------------------------
	// Render
	return (
		<>
			<TopPagination
				perPage={searchParams.perPage.value}
				setPerPage={searchParams.perPage.setValue}
				options={[
					{
						label: '10',
						value: 10,
					},
					{
						label: '25',
						value: 25,
					},
					{
						label: '50',
						value: 50,
					},
					{
						label: translate('all'),
						value: 500,
					},
				]}
				meta={awards.data?.data?.meta}>
				{utilityBar}
			</TopPagination>
			<DynamicBlock
				state={{
					loading: awards.isLoading,
					error: awards.isError,
					noData: awards.data?.data.data.length === 0,
				}}
				noData={{
					title: translate('no_data_title', {
						name: 'Awards',
					}),
					message: translate('no_data_message', {
						name: 'awards',
					}),
				}}
				resetFilters={resetFilters}>
				<div className="rounded-md border border-border">
					{awards.data?.data.data.map((award, index) => {
						return (
							<CertificateRow
								key={index}
								mode="list"
								certificate={award}
							/>
						);
					})}
				</div>
				<Pagination
					meta={awards.data?.data?.meta}
					page={searchParams.page.value}
					setPage={searchParams.page.setValue}
				/>
			</DynamicBlock>
		</>
	);
};

export default UserCertificateTable;
