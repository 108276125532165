import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
// api
import api from 'api';
// Hooks
import { useSearchParams, useOrganisation, usePermissions } from '@hooks/index';
// Utils
import { dateHelpers } from '@utils/index';
// Components
import Sort from '@components/query/Sort';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import ProgressTable from '@components/table/shared/ProgressTable';
import Filter from '@components/query/Filter';
import FilterPresetsRow from '@components/query/FilterPresetsRow';

const OrganisationProgressList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'percentage',
					value: 'off',
				},
				{
					key: 'created_at',
					value: 'off',
				},
				{
					key: 'updated_at',
					value: 'desc',
				},
			],
			filter: [
				{
					key: 'completed',
					value: '',
					type: 'boolean',
				},
				{
					key: 'teams.id',
					value: '',
					type: 'select',
				},
				{
					key: 'course_id',
					value: '',
					type: 'select',
				},
				{
					key: 'user_id',
					value: '',
					type: 'select',
				},
				{
					key: 'created_at_from',
					value: '',
					type: 'date',
				},
				{
					key: 'created_at_to',
					value: '',
					type: 'date',
				},
				{
					key: 'updated_at_from',
					value: '',
					type: 'date',
				},
				{
					key: 'updated_at_to',
					value: '',
					type: 'date',
				},
			],
		});
	const { convertDate, currentDate, monthsBefore } = dateHelpers;
	const { organisation } = useOrganisation();
	const { manageOrganisation } = usePermissions();

	// -------------------------------------------------
	//	Query / Mutation
	const teams = useQuery(
		['organisations.teams.getAll'],
		() => {
			return api.organisations.teams.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					courses: false,
					coursesCount: false,
					users: false,
					usersCount: false,
				},
				sort: {
					name: 'asc',
				},
				perPage: -1,
			});
		},
		{
			enabled: !!organisation?.id && manageOrganisation,
		}
	);
	const courses = useQuery(
		['organisations.courses.getAll'],
		() => {
			return api.organisations.courses.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					items: false,
					itemsModel: false,
					itemsModelType: false,
					media: false,
					type: false,
					teamsCount: false,
					usersCount: false,
				},
				sort: {
					title: 'asc',
				},
				perPage: -1,
			});
		},
		{
			enabled: !!organisation?.id,
		}
	);
	const users = useQuery(
		['organisations.users.getMultiple'],
		() => {
			return api.organisations.users.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					roles: false,
					lastLogin: false,
				},
				sorts: {
					name: 'asc',
				},
				perPage: -1,
			});
		},
		{
			enabled: !!organisation?.id && manageOrganisation,
		}
	);

	// -------------------------------------------------
	// Memos
	const filterLoading = useMemo(() => {
		if (manageOrganisation) {
			return teams.isLoading || courses.isLoading || users.isLoading;
		} else {
			return courses.isLoading;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		manageOrganisation,
		teams.isLoading,
		courses.isLoading,
		users.isLoading,
	]);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('progress')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'completed',
								label: translate('completed'),
							},
							{
								key: 'course_id',
								label: translate('course'),
								allowAll: true,
								values: courses.data?.data.data.map(
									(course) => {
										return {
											label: course.title,
											value: course.id.toString(),
										};
									}
								),
							},
							{
								key: 'teams.id',
								label: translate('teams'),
								allowAll: true,
								values: teams.data?.data.data.map((team) => {
									return {
										label: team.name,
										value: team.id.toString(),
									};
								}),
								permission: manageOrganisation,
							},
							{
								key: 'user_id',
								label: translate('users'),
								allowAll: true,
								values: users.data?.data.data.map((user) => {
									return {
										label: user.name || user.email,
										value: user.id.toString(),
									};
								}),
								permission: manageOrganisation,
							},
							{
								key: 'created_at_from',
								label: translate('started_at'),
							},
							{
								key: 'created_at_to',
								label: translate('started_from'),
							},
							{
								key: 'updated_at_from',
								label: translate('last_activity_at'),
							},
							{
								key: 'updated_at_to',
								label: translate('last_activity_from'),
							},
						]}
						loading={filterLoading}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'percentage',
								label: translate('progress'),
							},
							{
								key: 'created_at',
								label: translate('started'),
							},
							{
								key: 'updated_at',
								label: translate('last_activity'),
							},
						]}
					/>
				}
			/>
			<PageWrapper>
				<ProgressTable
					mode={'organisation'}
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					utilityBar={
						<FilterPresetsRow
							filter={searchParams.filter.value}
							setMultipleFilters={
								searchParams.filter.setMultipleValues
							}
							presets={[
								{
									label: 'Started in the last 3 months',
									filters: [
										{
											key: 'created_at_from',
											// to 10/10/2021
											value: convertDate(
												monthsBefore(currentDate, 3)
											),
										},
										{
											key: 'created_at_to',
											value: convertDate(currentDate),
										},
									],
								},
								{
									label: 'Active in the last 3 months',
									filters: [
										{
											key: 'updated_at_from',
											value: convertDate(
												monthsBefore(currentDate, 3)
											),
										},
										{
											key: 'updated_at_to',
											value: convertDate(currentDate),
										},
									],
								},
							]}
						/>
					}
				/>
			</PageWrapper>
		</>
	);
};
export default OrganisationProgressList;
