import T, { translate } from '@lang/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useToast } from '@hooks/index';
// Components
import ConfirmationModal from '@components/modal/ConfirmationModal';

interface ToggleOrgUserSeatProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	userId: number;
	organisationId?: number;
	state: boolean;
	onSuccess?: () => void;
}

const ToggleOrgUserSeat: React.FC<ToggleOrgUserSeatProps> = ({
	open,
	setOpen,
	state,
	onSuccess,
	organisationId,
	userId,
}) => {
	// ---------------------
	// State / Hooks
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [errorMsg, setErrorMsg] = useState<string>(
		translate('error_generic')
	);

	// --------------------
	// Mutation
	const updateUser = useMutation(api.organisations.users.updateSingle, {
		onSuccess: () => {
			// invalidate users
			queryClient.invalidateQueries(['organisations.users.getMultiple']);
			queryClient.invalidateQueries([
				'organisations.users.getMultiple.filter',
			]);

			queryClient.invalidateQueries(['organisations.current.get']);
			// cb
			if (onSuccess !== undefined) onSuccess();
			// toast
			addToast({
				title: translate('toast_updated_title', {
					name: 'Organisation',
				}),
				message: translate('toast_updated_message', {
					name: 'Organisation',
				}),
				type: 'success',
			});
		},
		onError: (error: AxiosAPIError) => {
			setErrorMsg(
				error.response?.data.message || translate('error_generic')
			);
		},
	});

	// --------------------
	// Functions
	const onConfirm = async () => {
		await updateUser.mutateAsync({
			id: userId,
			organisationId: organisationId,
			body: {
				seated: state ? 0 : 1,
			},
		});
		setOpen(false);
	};

	return (
		<ConfirmationModal
			open={open}
			setOpen={setOpen}
			onClose={() => {
				setErrorMsg('');
				updateUser.reset();
			}}
			title={`${
				state
					? T.modals.toggle_org_user_seat.active_title
					: T.modals.toggle_org_user_seat.inactive_title
			}`}
			onConfirm={onConfirm}
			formAction={{
				loading: {
					is: updateUser.isLoading,
					message: translate('updating'),
				},
				error: {
					is: updateUser.isError,
					message: errorMsg,
				},
			}}>
			<div>
				<p>
					{state
						? T.modals.toggle_org_user_seat.active_body
						: T.modals.toggle_org_user_seat.inactive_body}
				</p>
			</div>
		</ConfirmationModal>
	);
};

export default ToggleOrgUserSeat;
