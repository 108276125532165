interface FormColumnsProps {
	children: React.ReactNode;
}

const FormColumns: React.FC<FormColumnsProps> = ({ children }) => {
	return (
		<div className="mb-5 grid grid-cols-1 gap-x-5 md:grid-cols-2">
			{children}
		</div>
	);
};

export default FormColumns;
