import { translate } from '@lang/index';
import { useState, useMemo } from 'react';
import { faCircleQuestion, faBug } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import C from '@root/constants';
// Hooks
import { useOrganisation, usePermissions } from '@hooks/index';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Models
import ReportBug from '@components/modal/ReportBug';

interface CustomerSupportLinksProps {
	user: CurrentUserRes;
}

const CustomerSupportLinks: React.FC<CustomerSupportLinksProps> = ({
	user,
}) => {
	// --------------------------------------
	// State / Hooks
	const [openReportBug, setOpenReportBug] = useState(false);

	const { organisation } = useOrganisation();
	const { manageOrganisation } = usePermissions();

	// --------------------------------------
	// Memos
	const customerSupportLink = useMemo(() => {
		if (manageOrganisation) {
			return C.MARKETING_CONTACT_URL;
		}
		if (organisation?.meta?.support_url)
			return organisation.meta.support_url;
		return C.MARKETING_CONTACT_URL;
	}, [organisation, manageOrganisation]);

	// --------------------------------------
	// Classes
	const buttonClasses = classNames(
		'block text-left text-body text-sm hover:underline duration-200 transition-colors'
	);
	const iconClasses = classNames('mr-2 text-body text-current');

	// --------------------------------------
	// Render
	if (user.is_super_admin) return null;

	return (
		<div className="flex flex-col">
			<a
				className={classNames(buttonClasses, 'mt-2')}
				href={customerSupportLink}
				target="_blank"
				rel="noreferrer">
				<FontAwesomeIcon
					icon={faCircleQuestion}
					className={iconClasses}
				/>
				{translate('customer_support')}
			</a>
			<button
				className={classNames(
					buttonClasses,
					'hover:fill-red hover:text-red'
				)}
				onClick={() => setOpenReportBug(true)}>
				<FontAwesomeIcon icon={faBug} className={iconClasses} />
				{translate('report_bug')}
			</button>
			<ReportBug open={openReportBug} setOpen={setOpenReportBug} />
		</div>
	);
};

export default CustomerSupportLinks;
