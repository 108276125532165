import { translate } from '@lang/index';
import { useMemo, lazy, Suspense } from 'react';
import { GoToT, GetPrevItemT, GetNextItemT } from '@routes/courses/single/View';
// Assets
import notifySVG from '@assets/images/svgs/notify.svg';
// Components
import MessageBlock from '@components/blocks/MessageBlock';
import AccessBadge from '@components/partials/AccessBadge';
// Utils
import { helpers } from '@utils/index';
// Groups
import CourseContentHeader from '@components/groups/single-course/ContentHeader';
import VideoContent from '@components/groups/single-course/content/VideoContent';
import QuizContent from '@components/groups/single-course/content/QuizContent';
import ChapterContent from '@components/groups/single-course/content/ChapterContent';

const SuccessContent = lazy(
	() => import('@components/groups/single-course/content/SuccessContent')
);

interface CourseNavigationProps {
	mode: 'preview' | 'standard';
	course: SaCourseRes | CourseRes;
	currentItem?: CourseItems;
	nextItem?: CourseItems;
	isFirstItem: boolean;
	isSuccess: boolean;
	bookmarks: BookmarkesRes[];
	courseAccess: boolean;
	functions: {
		refetchStatus: () => Promise<void>;
		goTo: GoToT;
		getNextItem: GetNextItemT;
		getPrevItem: GetPrevItemT;
		refetchBookmarks: () => Promise<void>;
	};
}

const CourseContent: React.FC<CourseNavigationProps> = ({
	mode,
	course,
	currentItem,
	nextItem,
	isFirstItem,
	isSuccess,
	bookmarks,
	courseAccess,
	functions: {
		goTo,
		refetchStatus,
		getNextItem,
		getPrevItem,
		refetchBookmarks,
	},
}) => {
	// -------------------------------------------------
	// Memos

	// Unique next item that includes chapters in the search
	const nextItemAll = useMemo(() => {
		return getNextItem(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentItem]);
	const prevItemAll = useMemo(() => {
		return getPrevItem(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentItem]);

	const itemBookmarked = useMemo(() => {
		return bookmarks.find(
			(bookmark) =>
				bookmark.bookmarkable_id === currentItem?.model_id &&
				bookmark.bookmarkable_type === currentItem?.model_type
		);
	}, [bookmarks, currentItem]);

	const accessInfo = useMemo(() => {
		const org = course.organisations ? course.organisations[0] : undefined;
		return helpers.courseAccessInfo(org);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [course.organisations]);

	// -------------------------------------------------
	// Render
	if (!courseAccess) {
		return (
			<MessageBlock
				title={translate('course_access_title')}
				message={translate('course_access_message')}
				image={notifySVG}>
				<div className="mt-5">
					<AccessBadge
						show={accessInfo.show}
						full_access={accessInfo.full_access}
						access_in_days={accessInfo.access_in_days}
						expires_in_days={accessInfo.expires_in_days}
						expired_ago={accessInfo.expired_ago}
					/>
				</div>
			</MessageBlock>
		);
	}

	return (
		<Suspense>
			<div key={currentItem?.model_id}>
				{currentItem !== undefined && !isSuccess && (
					<CourseContentHeader
						courseId={course.id}
						mode={mode}
						currentItem={currentItem}
						bookmark={itemBookmarked}
						refetch={refetchBookmarks}
					/>
				)}

				{isSuccess && <SuccessContent />}
				{currentItem === undefined && !isSuccess && (
					<MessageBlock
						title={translate('cant_find_item_in_course_title')}
						message={translate('cant_find_item_in_course_message')}
						image={notifySVG}
					/>
				)}
				{currentItem?.model_type === 'video' && (
					<VideoContent
						id={currentItem.model.id}
						mode={mode}
						courseId={course.id}
						nextItem={nextItem}
						functions={{
							goTo,
							refetchStatus,
						}}
					/>
				)}
				{currentItem?.model_type === 'quiz' && (
					<QuizContent
						id={currentItem.model.id}
						mode={mode}
						courseId={course.id}
						functions={{
							refetchStatus,
						}}
					/>
				)}
				{currentItem?.model_type === 'chapter' && (
					<ChapterContent
						chapter={currentItem}
						nextItem={nextItemAll}
						prevItem={prevItemAll}
						isFirstItem={isFirstItem}
						functions={{
							goTo,
						}}
					/>
				)}
			</div>
		</Suspense>
	);
};

export default CourseContent;
