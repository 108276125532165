import { translate } from '@lang/index';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Badges from '@components/partials/Badges';
import Table from '@components/table/Table';
import DynamicBlock from '@components/blocks/DynamicBlock';
import Date from '@components/partials/Date';
import CategoryIcon from '@components/partials/CategoryIcon';

interface UserStatsQuizzesNotPassedProps {
	categories: CategoriesRes[];
}

const UserStatsQuizzesNotPassed: React.FC<UserStatsQuizzesNotPassedProps> = ({
	categories,
}) => {
	// --------------------------------------
	// State & Hooks
	const { id } = useParams();
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries / Mutations
	const results = useQuery(
		[
			'organisations.quizzes.views.getMultiple',
			'organisations.quizzes.views.getMultiple.noPassed',
			organisation,
			id,
		],
		() => {
			return api.organisations.quizzes.results.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: false,
					courseCount: false,
					quiz: true,
					quizCount: false,
					user: false,
					userCount: false,
				},
				filters: [
					{
						key: 'user_id',
						value: id || 0,
					},
					{
						key: 'pass',
						value: 0,
					},
				],
				perPage: -1,
			});
		}
	);

	// --------------------------------------
	// Render
	return (
		<DynamicBlock
			state={{
				loading: results.isLoading,
				error: results.isError,
				noData: results.data?.data.data.length === 0,
			}}
			noData={{
				title: translate('no_data_title', {
					name: 'Assessments',
				}),
				message: translate('no_data_message', {
					name: 'assessments',
				}),
			}}>
			<Table
				head={[
					{
						label: translate('name'),
						type: 'standard',
					},
					{
						label: translate('result'),
						type: 'standard',
					},
					{
						label: translate('updated_at'),
						type: 'standard',
					},
				]}>
				{results.data?.data.data.map((result, index) => (
					<QuizNotPasswedRow
						key={index}
						categories={categories}
						result={result}
					/>
				))}
			</Table>
		</DynamicBlock>
	);
};

interface QuizNotePassedRowProps {
	categories: CategoriesRes[];
	result: QuizzesResultsRes;
}

const QuizNotPasswedRow: React.FC<QuizNotePassedRowProps> = ({
	categories,
	result,
}) => {
	// --------------------------------------
	// Memos
	const category = useMemo(
		() =>
			categories.find((category) => category.id === result.quiz?.type_id),
		[categories, result.quiz?.type_id]
	);

	// --------------------------------------
	// Render
	return (
		<tr>
			<td className="td-default">
				<span className="flex">
					<span className="mr-2.5 block">
						<CategoryIcon
							colour={category?.meta?.icon_colour}
							initials={category?.meta?.icon_initials}
							label={category?.name}
							categoryScope={'quizType'}
							size={'xs'}
						/>
					</span>
					{result.quiz?.title}
				</span>
			</td>
			<td className="td-default">
				<Badges theme="red">{result.percentage}%</Badges>
			</td>
			<td className="td-default">
				<Date date={result.updated_at} />
			</td>
		</tr>
	);
};

export default UserStatsQuizzesNotPassed;
