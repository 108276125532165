import placeholderVideo from '@assets/images/placeholder-video.jpg';
import { useMemo, useState } from 'react';
import { faLink } from '@fortawesome/free-solid-svg-icons';
// Utils
import highlightContent from '@utils/highlight-content';
import { dateHelpers } from '@utils/index';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from '@components/forms/Select';
import Button from '@components/partials/Button';

interface VideoSearchRowProps {
	video: VideosRes;
	query: string;
}

const VideoSearchRow: React.FC<VideoSearchRowProps> = ({ video, query }) => {
	// -------------------------------------------------
	// State
	const { organisation } = useOrganisation();

	const [selectedCourseId, setSelectedCourseId] = useState<number>();

	// -------------------------------------------------
	// Memos
	const highlightedTitle = useMemo(() => {
		return highlightContent(video.title, query);
	}, [query, video.title]);

	const highlightedDesc = useMemo(() => {
		return highlightContent(video.desc || '', query);
	}, [query, video.desc]);

	const selectedCourse = useMemo(() => {
		const course = video.courses?.find(
			(course) => course.id === selectedCourseId
		);
		if (course) return course;
		return video.courses?.[0];
	}, [selectedCourseId, video.courses]);

	// -------------------------------------------------
	// Render
	return (
		<li className="mb-2.5 flex w-full items-start border-b border-border pb-2.5 last:border-b-0">
			<div className="relative mr-2.5 w-24 min-w-[96px] border border-border after:block after:pt-[56%]">
				<div className="absolute inset-0">
					<img
						src={video.thumbnail || placeholderVideo}
						className="block h-full w-full object-cover"
						alt={video.title}
						loading="lazy"
					/>
				</div>
			</div>
			<div className="ml-2.5 w-full justify-self-center">
				<h3 className="font-semibold text-title">{highlightedTitle}</h3>
				{video.desc && (
					<p className="mt-0.5 text-sm text-body">
						{highlightedDesc}
					</p>
				)}
				{video.courses && video.courses?.length > 0 && (
					<div className="mt-2.5 flex items-end">
						<Select
							value={selectedCourse?.id || 'No course'}
							setValue={(state) => {
								setSelectedCourseId(state as number);
							}}
							errors={[]}
							options={video.courses.map((type) => {
								return {
									value: type.id,
									label: type.title,
								};
							})}
						/>
						<Button
							theme="icon"
							type="link"
							className="ml-2.5 !rounded-md"
							href={`/${organisation?.slug}/courses/${selectedCourse?.id}/video/${video.id}`}>
							<FontAwesomeIcon icon={faLink} />
						</Button>
					</div>
				)}
				{/* Cues */}
				{video.cues && video.cues.length > 0 && (
					<div className="mt-2.5">
						<div className="w-full rounded-md border border-border p-2.5">
							<ul>
								{video.cues?.map((cue) => (
									<CueRow
										key={cue.id}
										cue={cue}
										query={query}
										href={`/${organisation?.slug}/courses/${
											selectedCourse?.id
										}/video/${video.id}?timestamp=${
											cue.start_time || 0
										}`}
									/>
								))}
							</ul>
						</div>
					</div>
				)}
			</div>
		</li>
	);
};

interface CueRowProps {
	cue: {
		id: number;
		start_time: number;
		end_time: number;
		text: string;
	};
	href?: string;
	query: string;
}

const CueRow: React.FC<CueRowProps> = ({ cue, query, href }) => {
	// -------------------------------------------------
	// Memos
	const highlightedCue = useMemo(() => {
		return highlightContent(cue.text, query);
	}, [query, cue.text]);

	// -------------------------------------------------
	// Render
	return (
		<li key={cue.id} className="w-full">
			<Link
				to={href || '#'}
				className="flex items-center justify-between hover:underline">
				<span className="text-sm text-body">{highlightedCue}</span>
				<span className={'ml-5 flex flex-col text-sm'}>
					{dateHelpers.formatSeconds(cue.start_time)}
				</span>
			</Link>
		</li>
	);
};

export default VideoSearchRow;
