/*
	Either renders action for modal or for page using standard design
*/

// Components
import FormRow from '@components/forms/FormRow';
import FormAction from '@components/forms/FormAction';
import FormActionModal from '@components/forms/FormActionModal';
import Button from '@components/partials/Button';

interface DynamicActionProps {
	mode: 'page' | 'modal';
	state: {
		title: string;
		isLoading: boolean;
		isLoadingMessage: string;
		isError: boolean;
		isErrorMessage: string;
		resetBtn: string;
		actionBtn: string;
		resetCallback: () => void;
	};
	children: React.ReactNode;
}

const DynamicAction: React.FC<DynamicActionProps> = ({
	mode,
	state,
	children,
}) => {
	return (
		<>
			{mode === 'modal' && (
				<>
					{children}
					<FormActionModal
						loading={{
							is: state.isLoading,
							message: state.isLoadingMessage,
						}}
						error={{
							is: state.isError,
							message: state.isErrorMessage,
						}}>
						<Button
							theme="outline"
							type="button"
							className="mr-3"
							onClick={state.resetCallback}>
							{state.resetBtn}
						</Button>
						<Button theme="primary" type="submit">
							{state.actionBtn}
						</Button>
					</FormActionModal>
				</>
			)}
			{mode === 'page' && (
				<>
					<FormRow title={state.title}>{children}</FormRow>
					<FormAction
						loading={{
							is: state.isLoading,
							message: state.isLoadingMessage,
						}}
						error={{
							is: state.isError,
							message: state.isErrorMessage,
						}}>
						<Button theme="primary" type="submit">
							{state.actionBtn}
						</Button>
					</FormAction>
				</>
			)}
		</>
	);
};

export default DynamicAction;
