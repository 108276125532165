import {
	IconDefinition,
	faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Fragment, useMemo } from 'react';
import classNames from 'classnames';
// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface NavigationLinkProps {
	label: string;
	permission?: boolean;
	icon: IconDefinition;
	to?: string;
	mode?: 'internal' | 'external' | 'text';
	isChild?: boolean;
	items?: Array<{
		permission: boolean;
		component: React.ReactNode;
	}>;
	openDropdown?: boolean;
	setNavOpen?: (open: boolean) => void;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
	to,
	label,
	icon,
	permission = true,
	mode = 'internal',
	isChild = false,
	items,
	setNavOpen,
	openDropdown,
}) => {
	// --------------------------------------
	// Classes
	const navLinkClasses = classNames(
		'group flex items-center p-1 text-icon text-base font-semibold rounded-md transition-colors duration-200 w-full block'
	);

	// --------------------------------------
	// Memos
	const hasItems = useMemo(() => {
		if (!items) return false;
		return items.some((item) => item.permission);
	}, [items]);

	// --------------------------------------
	// Render
	if (!permission) return null;

	return (
		<Disclosure
			as={'li'}
			className={classNames('last:mb-0', {
				'mb-1.5': !isChild,
			})}
			defaultOpen={openDropdown}>
			<Disclosure.Button className="relative w-full">
				{({ open }) => (
					<>
						{mode === 'internal' && (
							<NavLink
								end={true}
								to={to || '/'}
								className={({ isActive }) =>
									classNames(navLinkClasses, {
										'hover:text-title': !isActive,
										'text-title': isActive,
									})
								}
								onClick={(e) => {
									e.stopPropagation();
									if (setNavOpen) setNavOpen(false);
								}}>
								{({ isActive }) => (
									<NavLinkInner
										isActive={isActive}
										icon={icon}
										label={label}
										isChild={isChild}
									/>
								)}
							</NavLink>
						)}
						{mode === 'external' && (
							<a
								href={to}
								className={navLinkClasses}
								target="_blank"
								rel="noreferrer"
								onClick={(e) => {
									e.stopPropagation();
								}}>
								<NavLinkInner
									isActive={false}
									icon={icon}
									label={label}
									isChild={isChild}
								/>
							</a>
						)}
						{mode === 'text' && (
							<div
								className={classNames(
									navLinkClasses,
									'hover:text-title'
								)}>
								<NavLinkInner
									isActive={false}
									icon={icon}
									label={label}
									isChild={isChild}
								/>
							</div>
						)}
						{hasItems && (
							<span className="absolute top-1/2 right-2.5 -translate-y-1/2">
								<FontAwesomeIcon
									icon={faChevronCircleRight}
									className={classNames(
										'ml-auto h-4 w-4 transition-all duration-200',
										{
											'text-icon': !open,
											'rotate-90 text-title': open,
										}
									)}
								/>
							</span>
						)}
					</>
				)}
			</Disclosure.Button>
			{hasItems && (
				<Disclosure.Panel className="text-gray-500">
					<ul className="mt-1 mb-3 pl-2.5">
						{items?.map((item, index) => {
							if (!item.permission) return null;
							return (
								<Fragment key={index}>
									{item.component}
								</Fragment>
							);
						})}
					</ul>
				</Disclosure.Panel>
			)}
		</Disclosure>
	);
};

const NavLinkInner: React.FC<{
	isActive: boolean;
	icon: IconDefinition;
	label: string;
	isChild?: boolean;
}> = ({ isActive, icon, label, isChild }) => {
	return (
		<>
			{icon && (
				<span
					className={classNames(
						'flex items-center justify-center rounded-md border border-border border-opacity-50 transition-colors duration-200',
						{
							'border-brandPrimary bg-brandPrimary': isActive,
							'border-border bg-uiLight group-hover:border-brandPrimaryHover group-hover:bg-brandPrimaryHover':
								!isActive,
							'mr-2 h-7 w-7': isChild,
							'mr-2.5 h-8 w-8': !isChild,
						}
					)}>
					<FontAwesomeIcon
						icon={icon}
						className={classNames(
							'h-4 w-4 transition-colors duration-200',
							{
								'text-white': isActive,
								'text-icon group-hover:text-white': !isActive,
								'h-3 w-3': isChild,
							}
						)}
					/>
				</span>
			)}
			<span
				className={classNames('duration-20 transition-colors', {
					'text-title': isActive,
					'text-icon group-hover:text-title': !isActive,
					'text-sm': isChild,
				})}>
				{label}
			</span>
		</>
	);
};

export default NavigationLink;
