import { translate } from '@lang/index';
import { useState } from 'react';
// Components
import PageHeading from '@components/blocks/PageHeading';
import HeadingTabs from '@components/partials/HeadingTabs';
// Actions
import CreateCourseAction from '@components/actions/CreateCourse';

const CreateCourse: React.FC = () => {
	// -------------------------------------------
	// State & Hooks
	const [tab, setTab] = useState<'details' | 'builder'>('details');

	// -------------------------------------------
	// Render
	return (
		<>
			<PageHeading title={translate('create')}>
				<HeadingTabs
					activeKey={tab}
					tabs={[
						{
							name: translate('details'),
							key: 'details',
							onClick: () => setTab('details'),
						},
						{
							name: translate('builder'),
							key: 'builder',
							onClick: () => setTab('builder'),
						},
					]}
				/>
			</PageHeading>
			<CreateCourseAction tab={tab} setTab={setTab} />
		</>
	);
};

export default CreateCourse;
