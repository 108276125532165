import { translate } from '@lang/index';
import { useMemo } from 'react';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import { Link } from 'react-router-dom';
import CategoryIcon from '@components/partials/CategoryIcon';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface QuizRowProps {
	quiz: SaQuizzesRes;
	selectRow: (course: SaQuizzesRes, action: 'delete') => void;
	categories: SaCategoriesRes[];
}

const QuizRow: React.FC<QuizRowProps> = ({ quiz, selectRow, categories }) => {
	// -------------------------------------------------
	// Memos
	const category = useMemo(() => {
		return categories.find((c) => c.id === quiz.type_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories, quiz]);

	// -------------------------------------------------
	// Render
	return (
		<tr>
			<td className="td-default">
				<Link
					to={`/assessments/${quiz.id}`}
					className="text-sm font-semibold hover:underline">
					{quiz.title}
				</Link>
			</td>
			<td className="td-default">{quiz.ref}</td>
			<td className="td-default">
				{category !== undefined && (
					<CategoryIcon
						colour={category?.meta?.icon_colour}
						initials={category?.meta?.icon_initials}
						label={category?.name}
						categoryScope={'quizType'}
						size="sm"
					/>
				)}
			</td>
			<td className="td-default">
				<DateTimeColumn date={quiz.updated_at} />
			</td>
			<DropdownMenuColumn
				items={[
					{
						label: translate('edit'),
						type: 'link',
						href: `/assessments/${quiz.id}`,
						show: true,
					},
					{
						label: translate('delete'),
						type: 'button',
						show: true,
						onClick: () => {
							selectRow(quiz, 'delete');
						},
					},
				]}
			/>
		</tr>
	);
};

export default QuizRow;
