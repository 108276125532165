import { translate } from '@lang/index';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import Badges from '@components/partials/Badges';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';
import { useMemo } from 'react';

interface NominatedCourseRowProps {
	course: CourseRes;
	selectRow: (
		course: CourseRes,
		action: 'nominate' | 'teams-list' | 'users-list'
	) => void;
}

const NominatedCourseRow: React.FC<NominatedCourseRowProps> = ({
	course,
	selectRow,
}) => {
	// -------------------------------------------------
	// Memos
	const organisation = useMemo(() => {
		if (!course.organisations) return undefined;
		return course.organisations[0];
	}, [course.organisations]);

	// -------------------------------------------------
	// Render
	return (
		<tr>
			<td className="td-default">
				<span className="text-sm font-semibold text-title">
					{course.title}
				</span>
			</td>
			<td className="td-default">
				<Badges theme="green">
					{course.course_type_id === 1
						? translate('course')
						: translate('content')}
				</Badges>
			</td>
			<td className="td-default">
				<Badges
					theme="grey"
					onClick={() => {
						selectRow(course, 'teams-list');
					}}>
					{course.teams_count || 0}
				</Badges>
			</td>
			<td className="td-default">
				<span className="text-sm font-semibold text-title">
					<Badges
						theme="grey"
						onClick={() => {
							selectRow(course, 'users-list');
						}}>
						{course.users_count || 0}
					</Badges>
				</span>
			</td>
			<td className="td-default">
				{organisation?.access.start_date ? (
					<DateTimeColumn date={organisation?.access.start_date} />
				) : (
					<span>-</span>
				)}
			</td>
			<td className="td-default">
				{organisation?.access.end_date ? (
					<DateTimeColumn date={organisation?.access.end_date} />
				) : (
					<span>-</span>
				)}
			</td>
			<DropdownMenuColumn
				items={[
					{
						label: translate('nominate'),
						type: 'button',
						show: true,
						onClick: () => selectRow(course, 'nominate'),
					},
				]}
			/>
		</tr>
	);
};

export default NominatedCourseRow;
