import { useState } from 'react';
// Hooks
import { useVideoPlayer } from '@hooks/index';
// Components
import VideoTransscript from '@components/partials/VideoTranscript';
import TabList from '@components/blocks/TabList';

interface CourseVideoPlayerProps {
	mode: 'preview' | 'standard';
	sources: Array<{
		src: string;
		type: string;
	}>;
	state: {
		started?: boolean;
		targetMet?: boolean;
		completed?: boolean;
		timestamp?: number;
	};
	setState: {
		setStarted?: (started: boolean) => void;
		setTargetMet?: (targetMet: boolean) => void;
		setCompleted?: (completed: boolean) => void;
		setTimestamp?: (timestamp: number) => void;
	};
	tracks?: Array<{
		srclang: string;
		label: string;
		src: string;
	}>;
	textTracks?: SaVideosRes['textTracks'];
	poster?: string;
	options?: {
		autoplay?: boolean;
		controls?: boolean;
		volume?: number;
		preload?: string;
		responsive?: boolean;
		fluid?: boolean;
	};
	children?: React.ReactNode;
}

const CourseVideoPlayer: React.FC<CourseVideoPlayerProps> = ({
	mode,
	sources,
	tracks,
	poster,
	options,
	textTracks,
	children,
	state: { started, targetMet, completed, timestamp },
	setState: { setStarted, setTargetMet, setCompleted, setTimestamp },
}) => {
	// -------------------------------------------------
	// State / Hooks
	const [active, setActive] = useState('transcript');

	const { VideoPlayer, playerRef } = useVideoPlayer({
		target: timestamp,
		options: {
			html5: {
				hls: {
					enableLowInitialPlaylist: false,
				},
			},
			autoplay: options?.autoplay || false,
			controls: options?.autoplay || true,
			responsive: options?.responsive || true,
			fluid: options?.fluid || true,
			volume: options?.volume || 0,
			preload: options?.preload || 'false',
			poster: poster,
			sources: sources,
		},
		tracks: tracks,
		setStarted,
		setTargetMet,
		setCompleted,
		setTimestamp,
	});

	// -------------------------------------------------
	// Render
	return (
		<>
			<div className="mb-5 overflow-hidden rounded-md">
				<VideoPlayer />
			</div>
			<div>
				<TabList
					active={active}
					setActive={setActive}
					options={[
						{
							label: 'Transcript',
							value: 'transcript',
						},
						{
							label: 'Notes',
							value: 'notes',
							permission: mode === 'standard',
						},
					]}
				/>
				{active === 'transcript' && (
					<div className="mt-2.5 rounded-md bg-uiLight">
						<VideoTransscript
							textTracks={textTracks}
							currentTimestamp={timestamp || 0}
							goToTimestamp={(timestamp) => {
								playerRef.current?.currentTime(timestamp);
							}}
						/>
					</div>
				)}
				{active === 'notes' && mode === 'standard' && (
					<div>{children}</div>
				)}
			</div>
		</>
	);
};

export default CourseVideoPlayer;
