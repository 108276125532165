import { translate } from '@lang/index';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Breadcrumbs from '@components/partials/Breadcrumbs';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import HeadingTabs from '@components/partials/HeadingTabs';
import Loading from '@components/partials/Loading';
import ErrorBlock from '@components/partials/Error';
import ContentRow from '@components/partials/ContentRow';
// Group
import UserStatsCourseProgress from '@components/groups/user-statistics/CourseProgress';
import UserStatsUserSummary from '@components/groups/user-statistics/UserSummary';
import UserStatsVideoViews from '@components/groups/user-statistics/VideoViews';
import UserStatsQuizResults from '@components/groups/user-statistics/QuizzesNotPassed';
import UserStatsCertificates from '@components/groups/user-statistics/Certificates';

const OrganisationUserStatistics: React.FC = () => {
	// --------------------------------------
	// State & Hooks
	const { id } = useParams();
	const { organisation } = useOrganisation();

	// --------------------------------------
	// Queries & Mutations
	const user = useQuery(
		['organisations.users.getSingle', organisation, id],
		() => {
			if (!id) throw new Error(translate('no_id_provided'));
			return api.organisations.users.getSingle({
				organisationId: organisation?.id || 0,
				id: parseInt(id),
				include: {
					courses: true,
					lastLogin: false,
					lastLoginCount: false,
					teams: true,
					teamsCount: true,
					roles: false,
				},
			});
		}
	);
	const videoViews = useQuery(
		['organisations.videos.views.getMultiple', organisation, id],
		() => {
			return api.organisations.videos.views.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					videoMedia: false,
					course: false,
					courseCount: false,
					video: true,
					videoCount: false,
				},
				filters: [
					{
						key: 'user_id',
						value: id || 0,
					},
				],
				perPage: -1,
			});
		}
	);
	const certificates = useQuery(
		['organisations.userAwards.getMultiple', organisation, id],
		() => {
			return api.organisations.userAwards.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					user: true,
					award: true,
				},
				filters: {
					user_id: id || 0,
					award_type_id: 2,
				},
				perPage: -1,
			});
		}
	);
	const quizzesTaken = useQuery(
		[
			'organisations.quizzes.views.getMultiple',
			'organisations.quizzes.views.getMultiple.quizzesTaken',
			organisation,
			id,
		],
		() => {
			return api.organisations.quizzes.results.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: false,
					courseCount: false,
					quiz: false,
					quizCount: false,
					user: false,
					userCount: false,
				},
				filters: [
					{
						key: 'user_id',
						value: id || 0,
					},
				],
				perPage: 1,
			});
		}
	);
	const praticalExercisesTaken = useQuery(
		[
			'organisations.quizzes.views.getMultiple.praticalExercisesTaken',
			organisation,
			id,
		],
		() => {
			return api.organisations.quizzes.results.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: false,
					courseCount: false,
					quiz: false,
					quizCount: false,
					user: false,
					userCount: false,
				},
				filters: [
					{
						key: 'user_id',
						value: id || 0,
					},
					{
						key: 'quiz.type_id',
						value: 3,
					},
				],
				perPage: 1,
			});
		}
	);
	const courseProgress = useQuery(
		['organisations.courses.progress.getSix', organisation, id],
		() => {
			return api.organisations.courses.progress.getMultiple({
				queryString: undefined,
				organisationId: organisation?.id || 0,
				include: {
					course: true,
					user: false,
				},
				filters: [
					{
						key: 'user_id',
						value: id || 0,
					},
				],
				perPage: 6,
			});
		}
	);
	const coursesInProgress = useQuery(
		['organisations.courses.progress.coursesInProgress', organisation, id],
		() => {
			return api.organisations.courses.progress.getMultiple({
				queryString: undefined,
				organisationId: organisation?.id || 0,
				include: {
					course: false,
					user: false,
				},
				filters: [
					{
						key: 'user_id',
						value: id || 0,
					},
					{
						key: 'completed',
						value: 0,
					},
				],
				perPage: 1,
			});
		}
	);
	const coursesCompleted = useQuery(
		['organisations.courses.progress.coursesCompleted', organisation, id],
		() => {
			return api.organisations.courses.progress.getMultiple({
				queryString: undefined,
				organisationId: organisation?.id || 0,
				include: {
					course: false,
					user: false,
				},
				filters: [
					{
						key: 'user_id',
						value: id || 0,
					},
					{
						key: 'completed',
						value: 1,
					},
				],
				perPage: 1,
			});
		}
	);
	const categories = useQuery(
		['organisations.categories.getAll', organisation?.id],
		() => {
			return api.organisations.categories.getAll({
				organisationId: organisation?.id || 0,
				include: {
					meta: true,
					metaCount: false,
				},
			});
		}
	);

	// --------------------------------------
	// Functions

	// --------------------------------------
	// Render
	if (
		user.isLoading ||
		videoViews.isLoading ||
		certificates.isLoading ||
		quizzesTaken.isLoading ||
		praticalExercisesTaken.isLoading ||
		coursesInProgress.isLoading ||
		coursesCompleted.isLoading ||
		courseProgress.isLoading ||
		categories.isLoading
	)
		return <Loading type="page" />;
	if (
		user.isError ||
		videoViews.isError ||
		certificates.isError ||
		quizzesTaken.isError ||
		praticalExercisesTaken.isError ||
		coursesInProgress.isError ||
		coursesCompleted.isError ||
		courseProgress.isError ||
		categories.isError
	)
		return <ErrorBlock type="page" />;

	return (
		<>
			<PageHeading
				title={translate('user_statistics')}
				breadcrumbs={
					<Breadcrumbs
						links={[
							{
								href: `/${organisation?.slug}/users`,
								label: translate('users'),
							},
							{
								href: `/${organisation?.slug}/users/${id}`,
								label:
									user.data.data.data.name ||
									translate('details'),
							},
							{
								href: `/${organisation?.slug}/users/${id}/statistics`,
								label: translate('statistics'),
							},
						]}
					/>
				}>
				<HeadingTabs
					tabs={[
						{
							name: translate('details'),
							href: `/${organisation?.slug}/users/${id}`,
						},
						{
							name: translate('statistics'),
							href: `/${organisation?.slug}/users/${id}/statistics`,
						},
						{
							name: translate('nominated_courses'),
							href: `/${organisation?.slug}/users/${id}/nominated-courses`,
						},
						{
							name: translate('teams'),
							href: `/${organisation?.slug}/users/${id}/teams`,
						},
					]}
				/>
			</PageHeading>
			<PageWrapper>
				<ContentRow title={'User Summary'}>
					<UserStatsUserSummary
						stats={{
							videosViewed: videoViews.data.data.data.reduce(
								(acc: number[], curr) => {
									if (acc.indexOf(curr.video_id) === -1) {
										acc.push(curr.video_id);
									}
									return acc;
								},
								[]
							).length,
							certificates: certificates.data.data.data.length,
							quizzesTaken:
								quizzesTaken.data.data.meta?.total || 0,
							praticalQuizzesTaken:
								praticalExercisesTaken.data.data.meta?.total ||
								0,
							coursesInProgress:
								coursesInProgress.data.data.meta?.total || 0,
							coursesCompleted:
								coursesCompleted.data.data.meta?.total || 0,
						}}
					/>
				</ContentRow>
				<ContentRow
					title={'Course Progress'}
					href={`/${organisation?.slug}/progress?filter[user_id]=${id}`}
					link={'See progress'}>
					<UserStatsCourseProgress
						courseProgress={courseProgress.data?.data.data || []}
					/>
				</ContentRow>
				<ContentRow
					title="Certificates"
					href={`/${organisation?.slug}/user-awards?filter[user_id]=${id}`}
					link={'User awards'}>
					<UserStatsCertificates
						certificates={certificates.data.data.data}
					/>
				</ContentRow>
				<ContentRow
					title="Assessments Not Passed"
					href={`/${organisation?.slug}/assessment-results?filter[user_id]=${id}`}
					link={'Quiz results'}>
					<UserStatsQuizResults
						categories={categories.data.data.data || []}
					/>
				</ContentRow>
				<ContentRow
					title={translate('video_views')}
					href={`/${organisation?.slug}/video-views?filter[user_id]=${id}`}
					link={translate('video_views')}>
					<UserStatsVideoViews
						videoViews={videoViews.data.data.data}
					/>
				</ContentRow>
			</PageWrapper>
		</>
	);
};
export default OrganisationUserStatistics;
