import { translate } from '@lang/index';
// Components
import Table from '@components/table/Table';
import DynamicBlock from '@components/blocks/DynamicBlock';
import CertificateRow from '@components/rows/CertificateRow';

interface UserStatsCertificatesProps {
	certificates: OrgUserAwardsRes[];
}

const UserStatsCertificates: React.FC<UserStatsCertificatesProps> = ({
	certificates,
}) => {
	// --------------------------------------
	// Render
	return (
		<DynamicBlock
			state={{
				loading: false,
				error: false,
				noData: certificates.length === 0,
			}}
			noData={{
				title: translate('no_data_title', {
					name: 'Certificates',
				}),
				message: translate('no_data_message', {
					name: 'certificates',
				}),
			}}>
			<Table
				head={[
					{
						label: translate('name'),
						type: 'standard',
					},
				]}>
				{certificates.map((award, index) => (
					<CertificateRow
						key={index}
						certificate={award}
						mode="table"
						hideDownload={true}
					/>
				))}
			</Table>
		</DynamicBlock>
	);
};

export default UserStatsCertificates;
