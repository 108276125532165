import { translate } from '@lang/index';
import { useState } from 'react';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import Sort from '@components/query/Sort';
import Filter from '@components/query/Filter';
import Search from '@components/query/Search';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Button from '@components/partials/Button';
import OrganisationsTable from '@components/table/sa/OrganisationsTable';
import FilterPresetsRow from '@components/query/FilterPresetsRow';
// Modals
import CreateOrganisation from '@components/modal/CreateOrganisation';
import UtilityRow from '@components/blocks/UtilityRow';

const OrganisationList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			perPage: 10,
			sort: [
				{
					key: 'name',
					value: 'off',
				},
				{
					key: 'active',
					value: 'desc',
				},
				{
					key: 'source',
					value: 'off',
				},
				{
					key: 'type',
					value: 'off',
				},
			],
			filter: [
				{
					key: 'active',
					value: '',
					type: 'boolean',
				},
				{
					key: 'has_plan',
					value: '1',
					type: 'boolean',
				},
				{
					key: 'has_subscription',
					value: '',
					type: 'boolean',
				},
			],
		});

	// Modal State
	const [openCreateOrganisation, setOpenCreateOrganisation] = useState(false);

	return (
		<>
			<PageHeading
				title={translate('organisations')}
				body={translate('organisations_body')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'active',
								label: translate('active'),
							},
							{
								key: 'has_plan',
								label: translate('has_plan'),
							},
							{
								key: 'has_subscription',
								label: translate('has_subscription'),
							},
						]}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'name',
								label: translate('name'),
							},
							{
								key: 'active',
								label: translate('active'),
							},
							{
								key: 'source',
								label: translate('source'),
							},
							{
								key: 'type',
								label: translate('type'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={translate('search_for_organisation')}
						width={'full'}
					/>
				}
			/>
			<PageWrapper>
				<UtilityRow>
					<Button
						theme={'primary'}
						onClick={() => setOpenCreateOrganisation(true)}>
						{translate('add_account')}
					</Button>
				</UtilityRow>
				<OrganisationsTable
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					utilityBar={
						<FilterPresetsRow
							filter={searchParams.filter.value}
							setMultipleFilters={
								searchParams.filter.setMultipleValues
							}
							presets={[
								{
									label: 'Enterprises',
									filters: [
										{
											key: 'has_plan',
											value: '1',
										},
									],
								},
								{
									label: 'Individuals',
									filters: [
										{
											key: 'has_plan',
											value: '0',
										},
									],
								},
							]}
						/>
					}
				/>
			</PageWrapper>
			{/* Modals */}
			<CreateOrganisation
				open={openCreateOrganisation}
				setOpen={setOpenCreateOrganisation}
			/>
		</>
	);
};

export default OrganisationList;
