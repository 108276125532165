import { translate } from '@lang/index';
import classNames from 'classnames';
// Utils
import { helpers } from '@utils/index';
// Components
import NoData from '@components/partials/NoData';

interface UserStatsCourseProgressProps {
	courseProgress: OrgCourseProgressRes[];
}

const UserStatsCourseProgress: React.FC<UserStatsCourseProgressProps> = ({
	courseProgress,
}) => {
	// -------------------------------------------------
	// Render

	// No data
	if (!courseProgress.length) {
		return (
			<NoData
				title={translate('no_data_title', {
					name: 'Progress',
				})}
				message={translate('no_data_message', {
					name: 'progress',
				})}
			/>
		);
	}

	// Render
	return (
		<ul className="grid grid-cols-3 gap-5">
			{courseProgress.map((courseProgress, index) => {
				const percent = helpers.convertPercentString(
					courseProgress.percentage
				);
				return (
					<li
						key={index}
						className="overflow-hidden rounded-md border border-border">
						<div className="bg-brandTertiary px-5 py-2.5 text-center text-brandTertiaryText">
							<h3 className="text-sm">
								{courseProgress.course?.title}
							</h3>
						</div>
						<div className="flex w-full items-center justify-center p-5">
							<span className="mr-6 block h-5 w-[40%] overflow-hidden rounded-full bg-border">
								<span
									className={classNames(
										'duration-400 block h-full w-0 transition-all',
										{
											'bg-red': percent <= 25,
											'bg-[#FFC107]':
												percent > 25 && percent < 100,
											'bg-brandPrimaryHover':
												percent === 100,
										}
									)}
									style={{
										width: percent,
									}}></span>
							</span>
							<span className="block text-center text-sm">
								{courseProgress.percentage}%{' '}
								{translate('complete')}
							</span>
						</div>
					</li>
				);
			})}
		</ul>
	);
};

export default UserStatsCourseProgress;
