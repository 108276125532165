import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSearchParams, useOrganisation } from '@hooks/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Filter from '@components/query/Filter';
import BookmarksTable from '@components/table/shared/BookmarksTable';
import Sort from '@components/query/Sort';

const OrganisationBookmarksList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			perPage: 20,
			sort: [
				{
					key: 'types',
					value: 'asc',
				},
			],
			filter: [
				{
					key: 'bookmarkable_type',
					value: '',
					type: 'select',
				},
				{
					key: 'course_id',
					value: '',
					type: 'select',
				},
			],
		});
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries / Mutations
	const courses = useQuery(['organisations.courses.getMultiple'], () => {
		return api.organisations.courses.getMultiple({
			organisationId: organisation?.id as number,
			include: {
				items: false,
				itemsModel: false,
				itemsModelType: false,
				media: false,
				type: false,
				teamsCount: false,
				usersCount: false,
			},
			sort: {
				title: 'asc',
			},
			perPage: -1,
		});
	});

	// -----------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('bookmarks')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'bookmarkable_type',
								label: translate('type'),
								values: [
									{
										label: translate('courses'),
										value: 'course',
									},
									{
										label: translate('quizzes'),
										value: 'quiz',
									},
									{
										label: translate('videos'),
										value: 'video',
									},
								],
								allowAll: true,
							},
							{
								key: 'course_id',
								label: translate('course'),
								values: courses.data?.data.data.map(
									(course) => {
										return {
											label: course.title,
											value: course.id.toString(),
										};
									}
								),
								allowAll: true,
							},
						]}
						loading={courses.isLoading}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'types',
								label: translate('type'),
							},
						]}
					/>
				}
			/>
			<PageWrapper>
				<BookmarksTable
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
				/>
			</PageWrapper>
		</>
	);
};

export default OrganisationBookmarksList;
