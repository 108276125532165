import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import CreateOrganisationAction from '@components/actions/CreateOrganisation';

interface CreateOrganisationProps {
	open: boolean;
	setOpen: (state: boolean) => void;
}

const CreateOrganisation: React.FC<CreateOrganisationProps> = ({
	open,
	setOpen,
}) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.create_account.title}>
			<CreateOrganisationAction
				callback={(success) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default CreateOrganisation;
