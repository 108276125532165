import { translate } from '@lang/index';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast, useOrganisation } from '@hooks/index';
// Utils
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Input from '@components/forms/Input';
import Button from '@components/partials/Button';
import FormRow from '@components/forms/FormRow';
import FormAction from '@components/forms/FormAction';
import NominatedCourses from '@components/partials/NominatedCourses';

interface UpdateTeamActionProps extends ActionProps {
	team: TeamRes;
	courses: CourseRes[];
}

const UpdateTeamAction: React.FC<UpdateTeamActionProps> = ({
	team,
	courses,
	callback,
}) => {
	// -------------------------------------------------
	// State
	const { addToast } = useToast();
	const { organisation } = useOrganisation();
	const formState = useFormState();
	const queryClient = useQueryClient();
	const [lock, setLock] = useState<boolean>(false);

	const [name, setName] = useState(team.name);
	const [selectedCourses, setSelectedCourses] = useState<Array<number>>(
		team.courses?.map((course) => course.id) || []
	);

	// -------------------------------------------------
	// Queries / Mutations
	const updateTeam = useMutation(api.organisations.teams.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Team',
				}),
				message: translate('toast_updated_message', {
					name: 'Team',
				}),
				type: 'success',
			});
			callback(true);
			queryClient.invalidateQueries(['organisations.teams.getMultiple']);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		await updateTeam.mutateAsync({
			id: team.id,
			organisationId: organisation?.id || 0,
			body: returnModifiedData([
				{
					key: 'name',
					state: name,
					defaultState: team.name,
				},
				{
					key: 'course_ids',
					state: selectedCourses.map((id) => id),
					defaultState: team.courses?.map((course) => course.id),
				},
			]),
		});
	};

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		if (!lock) {
			setName(team.name);
			setSelectedCourses(team.courses?.map((course) => course.id) || []);
			setLock(true);
		}
	}, [team, lock]);

	// -------------------------------------------------
	// Render
	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<FormRow title={translate('details')}>
				<Input
					id="teamName"
					type="text"
					name="name"
					label={translate('name')}
					value={name}
					setValue={setName}
					errors={formState.errors.value['name']}
					required={true}
				/>
			</FormRow>
			<FormRow title={translate('nominated_courses')}>
				<NominatedCourses
					courses={courses}
					lockedCourses={[]}
					selectedCourses={selectedCourses}
					setSelectedCourses={setSelectedCourses}
				/>
			</FormRow>
			<FormAction
				loading={{
					is: updateTeam.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateTeam.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateTeamAction;
