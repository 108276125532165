import { useState } from 'react';
// Components
import AcceptTerms from '@components/modal/AcceptTerms';

interface TermsBannerProps {
	fetchUser: () => void;
}

const TermsBanner: React.FC<TermsBannerProps> = ({ fetchUser }) => {
	const [showTerms, setShowTerms] = useState(false);

	// ---------------------
	// Render
	return (
		<>
			<button
				className="flex h-10 w-full items-center justify-center bg-brandSecondary transition-colors duration-200 hover:bg-brandSecondaryHover"
				onClick={() => {
					setShowTerms(true);
				}}>
				<p className="text-sm font-semibold text-white">
					Please accept our terms and conditions to continue
				</p>
			</button>
			<AcceptTerms
				open={showTerms}
				setOpen={(state) => {
					setShowTerms(state);
					if (!state) {
						window.history.replaceState(
							{},
							document.title,
							window.location.pathname
						);
					}
				}}
				onSucess={() => {
					fetchUser();
				}}
			/>
		</>
	);
};

export default TermsBanner;
