import { translate } from '@lang/index';
import { GoToT } from '@routes/courses/single/View';
import { useEffect, useState, useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';
// Assets
import videoStramingSVG from '@assets/images/svgs/video-streaming.svg';
import placeholderVideo from '@assets/images/placeholder-video.jpg';
// Hooks
import { useOrganisation, useToast } from '@hooks/index';
// api
import api from 'api';
// Components
import CourseVideoPlayer from '@components/groups/single-course/content/CourseVideoPlayer';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
import MessageBlock from '@components/blocks/MessageBlock';
import NoteRow from '@components/rows/NoteRow';

interface SingleCourseVideoContentProps {
	mode: 'preview' | 'standard';
	id: number;
	courseId: number;
	nextItem?: CourseItems;
	functions: {
		refetchStatus: () => Promise<void>;
		goTo: GoToT;
	};
}

const SingleCourseVideoContent: React.FC<SingleCourseVideoContentProps> = ({
	id,
	courseId,
	mode,
	nextItem,
	functions: { goTo, refetchStatus },
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const queryClient = useQueryClient();
	const { addToast } = useToast();

	const [started, setStarted] = useState(false);
	const [targetMet, setTargetMet] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [timestamp, setTimestamp] = useState(0);

	// -------------------------------------------------
	// Queries
	type SaVideo = AxiosResponse<APIResponse<SaVideosRes>, any>;
	type OrgVideo = AxiosResponse<APIResponse<CourseVideosRes>, any>;

	const video = useQuery<SaVideo | OrgVideo>(
		['sa.quizzes.getSingle', id, courseId],
		() => {
			if (mode === 'preview') {
				return api.sa.videos.getSingle({
					id,
					include: {
						textTracks: true,
						textTracksCount: true,
						textTracksCues: true,
						media: true,
					},
				});
			} else {
				return api.organisations.courses.videos.getSingle({
					organisationId: organisation?.id || 0,
					courseId: courseId,
					videoId: id,
					include: {
						type: true,
						media: true,
						textTracks: true,
						textTracksCues: true,
					},
				});
			}
		}
	);
	const notes = useQuery(
		['organisations.notes.getSingle.video', id],
		() => {
			if (mode === 'preview') return;
			return api.organisations.notes.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					noteable: false,
					course: false,
				},
				filters: {
					noteable_type: 'video',
					noteable_id: id,
					course_id: courseId,
				},
			});
		},
		{
			enabled: mode === 'standard',
		}
	);

	// -------------------------------------------------
	// Mutations
	const videoView = useMutation(api.organisations.videos.views.createSingle, {
		onSuccess: async () => {
			queryClient.invalidateQueries(['organisations.courses.getStatus']);
			queryClient.invalidateQueries([
				'organisations.videos.views.getMultiple',
			]);
			if (mode === 'standard') await refetchStatus();

			if (!nextItem) return;
			goTo(nextItem);
		},
		onError: () => {
			addToast({
				title: translate('toast_error_title'),
				message: translate('toast_video_view_error_message'),
				type: 'error',
			});
		},
	});

	// -------------------------------------------------
	// Functions
	const registerVideoView = () => {
		if (mode === 'preview') return;
		videoView.mutate({
			organisationId: organisation?.id || 0,
			videoId: id,
			body: {
				course_id: courseId,
			},
		});
	};

	const firstNote = useMemo(() => {
		return notes.data?.data.data[0];
	}, [notes.data]);

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		if (completed) {
			registerVideoView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [completed]);

	// -------------------------------------------------
	// Memos
	const loading = useMemo(() => {
		const notesLoading = mode === 'standard' ? notes.isLoading : false;
		return video.isLoading || notesLoading;
	}, [video.isLoading, mode, notes.isLoading]);

	// -------------------------------------------------
	// Render
	if (loading) {
		return (
			<>
				<div className="relative w-full overflow-hidden rounded-md before:block before:pb-[56%]">
					<Loading type="skeleton" />
				</div>
				<div className="relative mt-5 h-52 w-full overflow-hidden rounded-md">
					<Loading type="skeleton" />
				</div>
			</>
		);
	}
	if (video.isError || notes.isError) {
		return <Error type="block" />;
	}

	if (!video.data?.data.data.meta) {
		return (
			<MessageBlock
				title={translate('video_not_available')}
				message={translate('video_not_available_message')}
				image={videoStramingSVG}
			/>
		);
	}

	return (
		<CourseVideoPlayer
			mode={mode}
			sources={[
				{
					src: video.data?.data.data.meta.hls_playlist_url,
					type: 'application/x-mpegURL',
				},
			]}
			tracks={video.data?.data.data.meta.tracks}
			poster={video.data?.data.data.thumbnail || placeholderVideo}
			textTracks={video.data?.data.data.textTracks}
			state={{
				started,
				targetMet,
				completed,
				timestamp,
			}}
			options={{
				volume: 0.6,
			}}
			setState={{
				setStarted,
				setTargetMet,
				setCompleted,
				setTimestamp,
			}}>
			<NoteRow
				key={id}
				note={firstNote}
				hideHeading={true}
				create={firstNote === undefined}
				noteableType="video"
				noteableId={id}
				courseId={courseId}
			/>
		</CourseVideoPlayer>
	);
};

export default SingleCourseVideoContent;
