import { translate } from '@root/lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
// Components
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface LastLoginColumnProps {
	date: string | undefined;
}

const LastLoginColumn: React.FC<LastLoginColumnProps> = ({ date }) => {
	return (
		<>
			{!date ? (
				<span>
					<FontAwesomeIcon
						icon={faKey}
						className="mr-2 text-gray-500"
					/>
					{translate('not_logged_in')}
				</span>
			) : (
				<DateTimeColumn date={date} />
			)}
		</>
	);
};

export default LastLoginColumn;
