import { translate } from '@lang/index';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast, useOrganisation } from '@hooks/index';
// Utils
import { helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Button from '@components/partials/Button';
import FormAction from '@components/forms/FormAction';
import MultiSelect from '@components/forms/MultiSelect';

interface UpdateCourseNominationsActionProps extends ActionProps {
	courseId: number;
}

const UpdateCourseNominationsAction: React.FC<
	UpdateCourseNominationsActionProps
> = ({ courseId, callback }) => {
	// -------------------------------------------------
	// State
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const { organisation } = useOrganisation();

	const [selectedTeams, setSelectedTeams] = useState<
		{
			key: string;
			label: string;
			data: CourseResTeams;
		}[]
	>([]);
	const [selectedUsers, setSelectedusers] = useState<
		{
			key: string;
			label: string;
			data: CourseResUsers;
		}[]
	>([]);

	// -------------------------------------------------
	// Queries / Mutations
	const course = useQuery(
		['organisations.courses.getSingle.admin', courseId],
		() => {
			return api.organisations.courses.getSingle({
				courseId: courseId,
				organisationId: organisation?.id as number,
				include: {
					items: false,
					itemsCount: false,
					itemsModel: false,
					itemsModelType: false,
					media: false,
					mediaCount: false,
					type: false,
					typeCount: false,
					teams: true,
					users: true,
					meta: false,
				},
			});
		},
		{
			enabled: organisation?.id !== undefined,
			onSuccess: (data) => {
				setSelectedTeams(
					data.data.data.teams?.map((team) => {
						return {
							key: team.name,
							label: `${team.name} - ${team.id}`,
							data: team,
						};
					}) || []
				);
				setSelectedusers(
					data.data.data.users?.map((user) => {
						return {
							key: user.name,
							label: `${user.name} - ${user.id}`,
							data: user,
						};
					}) || []
				);
			},
		}
	);
	const users = useQuery(
		['organisations.users.getMultiple', organisation?.id],
		() => {
			return api.organisations.users.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					lastLogin: false,
					roles: false,
				},
				sorts: {
					name: 'asc',
				},
				perPage: -1,
			});
		},
		{
			enabled: organisation?.id !== undefined,
		}
	);
	const teams = useQuery(
		['organisations.teams.getMultiple', organisation?.id],
		() => {
			return api.organisations.teams.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					courses: false,
					coursesCount: false,
					users: false,
					usersCount: false,
				},
				sort: {
					name: 'asc',
				},
				perPage: -1,
			});
		},
		{
			enabled: organisation?.id !== undefined,
		}
	);

	const updateCourse = useMutation(api.organisations.courses.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Course',
				}),
				message: translate('toast_updated_message', {
					name: 'Course',
				}),
				type: 'success',
			});
			queryClient.invalidateQueries([
				'organisations.courses.getMultiple.admin',
			]);
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		const body = helpers.deepDiff(
			{
				team_ids: course.data?.data.data.teams?.map((team) => team.id),
				user_ids: course.data?.data.data.users?.map((user) => user.id),
			},
			{
				team_ids: selectedTeams.map((team) => team.data.id),
				user_ids: selectedUsers.map((user) => user.data.id),
			}
		);
		if (Object.keys(body).length === 0) return;

		updateCourse.mutate({
			courseId: courseId,
			organisationId: organisation?.id as number,
			body: body,
		});
	};

	// -------------------------------------------------
	// Render
	return (
		<Form
			onSubmit={onSubmit}
			state={formState}
			validation={true}
			isLoading={course.isLoading}
			isError={course.isError}>
			<MultiSelect
				label={translate('nominated_teams')}
				name={'team_ids'}
				id={'team_ids'}
				values={selectedTeams}
				setValues={setSelectedTeams}
				errors={{}}
				options={
					teams.data?.data.data.map((team) => {
						return {
							key: team.name,
							label: `${team.name} - ${team.id}`,
							data: team,
						};
					}) || []
				}
			/>
			<MultiSelect
				label={translate('nominated_users')}
				name={'user_ids'}
				id={'user_ids'}
				values={selectedUsers}
				setValues={setSelectedusers}
				errors={{}}
				options={
					users.data?.data.data.map((user) => {
						return {
							key: user.name,
							label: `${user.name} - ${user.id}`,
							data: user,
						};
					}) || []
				}
			/>

			{/* Update */}
			<FormAction
				loading={{
					is: updateCourse.isLoading,
					message: translate('updating'),
				}}
				error={{
					is: updateCourse.isError,
					message: formState.errorMessage.value,
				}}>
				<Button
					theme="primary"
					type="submit"
					disabled={formState.lock.value}>
					{translate('update')}
				</Button>
			</FormAction>
		</Form>
	);
};

export default UpdateCourseNominationsAction;
