import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation, useSearchParams } from '@hooks/index';
// Components
import DynamicBlock from '@components/blocks/DynamicBlock';
import Pagination from '@components/query/Pagination';
import NoteRow from '@components/rows/NoteRow';

interface NoteSearchListProps {}

const NoteSearchList: React.FC<NoteSearchListProps> = () => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { searchParams, queryString, enabled } = useSearchParams({
		page: 1,
		search: '',
		searchKey: 's',
		perPage: 10,
		sort: [],
		filter: [],
	});

	// -------------------------------------------------
	// Queries / Mutations
	const notes = useQuery(
		['organisations.notes.getMultiple', queryString],
		() => {
			return api.organisations.notes.getMultiple({
				organisationId: organisation?.id || 0,
				queryString: queryString,
				include: {
					noteable: true,
					course: true,
				},
				filters: {
					has_context: 1,
				},
			});
		},
		{
			enabled,
			keepPreviousData: true,
		}
	);

	return (
		<DynamicBlock
			state={{
				loading: notes.isLoading,
				error: notes.isError,
				noData: notes.data?.data.data.length === 0,
			}}
			noData={{
				title: translate('no_data_title', {
					name: 'Search',
				}),
				message: translate('no_data_message', {
					name: 'search',
				}),
			}}>
			<ul>
				{notes.data?.data.data.map((note) => (
					<NoteRow key={note.id} note={note} />
				))}
			</ul>
			<Pagination
				meta={notes.data?.data?.meta}
				page={searchParams.page.value}
				setPage={searchParams.page.setValue}
			/>
		</DynamicBlock>
	);
};

export default NoteSearchList;
