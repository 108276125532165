interface ListWrapperProps {
	children: React.ReactNode;
}

const ListWrapper: React.FC<ListWrapperProps> = ({ children }) => {
	return (
		<div className="mb-2.5 border-b border-border pb-2.5 last:border-b-0">
			{children}
		</div>
	);
};

export default ListWrapper;
