import { translate } from '@lang/index';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import Sort from '@components/query/Sort';
import Search from '@components/query/Search';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Button from '@components/partials/Button';
import VideoTable from '@components/table/sa/VideoTable';
// Modals
import CreateVideo from '@components/modal/CreateVideo';
import Filter from '@components/query/Filter';

const VideoList = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'title',
					value: 'off',
				},
				{
					key: 'sync_id',
					value: 'off',
				},
				{
					key: 'ref',
					value: 'asc',
				},
				{
					key: 'synced_at',
					value: 'off',
				},
				{
					key: 'updated_at',
					value: 'off',
				},
			],
			filter: [
				{
					key: 'has_sync_id',
					value: '',
					type: 'boolean',
				},
				{
					key: 'type_id',
					value: '',
					type: 'select',
				},
			],
		});
	const [openCreate, setOpenCreate] = useState(false);

	// -------------------------------------------------
	// Queries / Mutations
	const categories = useQuery(['sa.categories.getAll'], () => {
		return api.sa.categories.getAll({
			filters: [
				{
					key: 'scope',
					value: 'videoType',
				},
			],
			include: {
				meta: false,
				metaCount: false,
			},
			sort: {
				name: 'asc',
			},
		});
	});

	return (
		<>
			<PageHeading
				title={translate('videos')}
				body={translate('videos_body')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'has_sync_id',
								label: translate('has_sync_id'),
							},
							{
								key: 'type_id',
								label: translate('type'),
								values: categories.data?.data.data.map(
									(category) => {
										return {
											label: `${category.name} - ${category.id}`,
											value: category.id.toString(),
										};
									}
								),
								allowAll: true,
							},
						]}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'title',
								label: translate('title'),
							},
							{
								key: 'sync_id',
								label: translate('synced'),
							},
							{
								key: 'ref',
								label: translate('ref'),
							},
							{
								key: 'synced_at',
								label: translate('synced_at'),
							},
							{
								key: 'updated_at',
								label: translate('updated_at'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={translate('search_for_video')}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<VideoTable
					enabled={enabled}
					queryString={queryString}
					searchParams={searchParams}
					resetFilters={resetFilters}
					utilityBar={
						<Button
							theme={'primary'}
							onClick={() => setOpenCreate(true)}>
							{translate('add_video')}
						</Button>
					}
				/>
				<CreateVideo open={openCreate} setOpen={setOpenCreate} />
			</PageWrapper>
		</>
	);
};

export default VideoList;
