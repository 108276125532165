import { useEffect, useRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
// Assets
import baobabGreen from '@assets/images/baobab-green.svg';
// Hooks
import { useOrganisation, usePermissions } from '@hooks/index';
// Components
import SearchBar from '@components/groups/search/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PoweredByLogo from '@components/partials/PoweredByLogo';

interface HeaderProps {
	layout: 'main' | 'plain';
	navOpen?: boolean;
	setNavOpen?: (open: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ layout, navOpen, setNavOpen }) => {
	// --------------------------------------
	// State
	const header = useRef<HTMLHeadingElement>(null);
	const { organisation } = useOrganisation();
	const { viewCourses } = usePermissions();
	const [siteLogo, setSiteLogo] = useState<string>();
	const [query, setQuery] = useState<string>('');

	// --------------------------------------
	// Functions
	const getImageUrl = () => {
		if (organisation?.logo_thumbnail) {
			setSiteLogo(organisation.logo_thumbnail);
			return;
		}
		setSiteLogo(baobabGreen);
	};

	// --------------------------------------
	// Memos
	const hasOrganisationPerm = useMemo(() => {
		if (!organisation) return false;
		if (organisation.active === false) return false;
		if (organisation.seated_at === null) return false;
		return true;
	}, [organisation]);

	const showSearchBar = useMemo(() => {
		return layout === 'main' && viewCourses && hasOrganisationPerm;
	}, [layout, viewCourses, hasOrganisationPerm]);

	const bottomRowBg = useMemo(() => {
		const computedStyles = getComputedStyle(document.documentElement);
		const primaryBase = computedStyles.getPropertyValue(
			'--theme-primary-base'
		);
		if (primaryBase !== '#08d9bc') return 'bg-brandPrimary';
		return 'header-gradient';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	// --------------------------------------
	// Effects
	useEffect(() => {
		const handleResize = () => {
			const headerHeight = header.current?.clientHeight;
			document.documentElement.style.setProperty(
				'--header-height',
				`${headerHeight}px`
			);
			header.current?.setAttribute(
				'style',
				`--header-height: ${headerHeight}px`
			);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		getImageUrl();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	// --------------------------------------
	// Render
	return (
		<header
			ref={header}
			className="fixed top-0 left-0 right-0 z-50 h-[75px] w-full bg-uiHeader shadow-md">
			<div className="relative z-10 flex h-[71px] w-full items-center justify-between px-5">
				<div className="flex items-center">
					<Link to={'/'}>
						<img
							id="header-logo"
							className="h-10 w-auto max-w-[120px] object-contain object-left"
							src={siteLogo}
							alt=""
						/>
					</Link>
					<PoweredByLogo thumbnail={organisation?.logo_thumbnail} />
				</div>
				<div className="ml-10 flex">
					{showSearchBar && (
						<div className="md:w-[300px] lg:w-[500px]">
							<SearchBar
								query={query}
								setQuery={setQuery}
								resetOnSubmit={true}
							/>
						</div>
					)}
					{navOpen !== undefined && setNavOpen !== undefined && (
						<button
							onClick={() => setNavOpen(!navOpen)}
							className={
								'ml-2.5 flex h-10 w-10 min-w-[40px] items-center justify-center rounded-md bg-brandPrimary text-brandPrimaryText transition-colors duration-200 hover:bg-brandPrimaryHover lg:hidden'
							}>
							<FontAwesomeIcon
								icon={navOpen ? faXmark : faBars}
								className="h-5 w-5"
							/>
						</button>
					)}
				</div>
			</div>
			<span className="absolute right-0 top-1/2 z-0 mr-10 block h-[35px] max-w-[50%] -translate-y-1/2">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 294.4 31.4"
					className="h-full w-full">
					<g data-name="Group 1260">
						<g data-name="Group 1259">
							<g fill="#08d9bc" data-name="Group 1261">
								<path
									d="M.5.6C.5.9.4 1 .3 1S0 1 0 .6.1 0 .3 0s.2.3.2.6"
									data-name="Path 712"
								/>
								<path
									d="M20.1.6c0 .3-.1.5-.2.5s-.3-.2-.3-.5.1-.6.3-.6.2.3.2.6"
									data-name="Path 713"
								/>
								<path
									d="M39.7.6c0 .3-.1.5-.3.5s-.2-.2-.2-.5.1-.6.2-.6.3.3.3.6"
									data-name="Path 714"
								/>
								<path
									d="M59.3.6c0 .3-.1.5-.3.5s-.2-.2-.2-.5.1-.6.2-.6.3.3.3.6"
									data-name="Path 715"
								/>
								<path
									d="M78.9.6c0 .3-.1.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.3.3.6"
									data-name="Path 716"
								/>
								<path
									d="M98.5.6c0 .3-.1.5-.3.5S98 1 98 .6s0-.6.2-.6.3.3.3.6"
									data-name="Path 717"
								/>
								<path
									d="M118 .6c0 .3 0 .5-.2.5s-.2-.2-.2-.5 0-.6.2-.6.3.3.3.6"
									data-name="Path 718"
								/>
								<path
									d="M137.7.6c0 .3-.1.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.3.3.6"
									data-name="Path 719"
								/>
								<path
									d="M157.3.6c0 .3-.1.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.3.3.6"
									data-name="Path 720"
								/>
								<path
									d="M176.9.6c0 .3-.1.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.3.3.6"
									data-name="Path 721"
								/>
								<path
									d="M196.5.6c0 .3-.2.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.3.3.6"
									data-name="Path 722"
								/>
								<path
									d="M216 .6c0 .3 0 .5-.2.5s-.3-.2-.3-.5.2-.6.3-.6.3.3.3.6"
									data-name="Path 723"
								/>
								<path
									d="M235.7.6c0 .3-.2.5-.3.5s-.3-.2-.3-.5.2-.6.3-.6.3.3.3.6"
									data-name="Path 724"
								/>
								<path
									d="M255.2.6c0 .3 0 .5-.2.5s-.3-.2-.3-.5.1-.6.3-.6.2.3.2.6"
									data-name="Path 725"
								/>
								<path
									d="M274.8.6c0 .3 0 .5-.2.5s-.3-.2-.3-.5.1-.6.3-.6.2.3.2.6"
									data-name="Path 726"
								/>
								<path
									d="M294.4.6c0 .3 0 .5-.2.5s-.3-.2-.3-.5.1-.6.3-.6.2.3.2.6"
									data-name="Path 727"
								/>
								<path
									d="M.5 30.9c0 .3-.1.5-.2.5S0 31.2 0 31s.1-.6.3-.6.2.2.2.6"
									data-name="Path 728"
								/>
								<path
									d="M20.1 30.9c0 .3-.1.5-.2.5s-.3-.2-.3-.5.1-.6.3-.6.2.2.2.6"
									data-name="Path 729"
								/>
								<path
									d="M39.7 30.9c0 .3-.1.5-.3.5s-.2-.2-.2-.5.1-.6.2-.6.3.2.3.6"
									data-name="Path 730"
								/>
								<path
									d="M59.3 30.9c0 .3-.1.5-.3.5s-.2-.2-.2-.5.1-.6.2-.6.3.2.3.6"
									data-name="Path 731"
								/>
								<path
									d="M78.9 30.9c0 .3-.1.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.2.3.6"
									data-name="Path 732"
								/>
								<path
									d="M98.5 30.9c0 .3-.1.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.2.3.6"
									data-name="Path 733"
								/>
								<path
									d="M118 30.9c0 .3 0 .5-.2.5s-.2-.2-.2-.5 0-.6.2-.6.3.2.3.6"
									data-name="Path 734"
								/>
								<path
									d="M137.7 30.9c0 .3-.1.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.2.3.6"
									data-name="Path 735"
								/>
								<path
									d="M157.3 30.9c0 .3-.1.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.2.3.6"
									data-name="Path 736"
								/>
								<path
									d="M176.9 30.9c0 .3-.1.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.2.3.6"
									data-name="Path 737"
								/>
								<path
									d="M196.5 30.9c0 .3-.2.5-.3.5s-.2-.2-.2-.5 0-.6.2-.6.3.2.3.6"
									data-name="Path 738"
								/>
								<path
									d="M216 30.9c0 .3 0 .5-.2.5s-.3-.2-.3-.5.2-.6.3-.6.3.2.3.6"
									data-name="Path 739"
								/>
								<path
									d="M235.7 30.9c0 .3-.2.5-.3.5s-.3-.2-.3-.5.2-.6.3-.6.3.2.3.6"
									data-name="Path 740"
								/>
								<path
									d="M255.2 30.9c0 .3 0 .5-.2.5s-.3-.2-.3-.5.1-.6.3-.6.2.2.2.6"
									data-name="Path 741"
								/>
								<path
									d="M274.8 30.9c0 .3 0 .5-.2.5s-.3-.2-.3-.5.1-.6.3-.6.2.2.2.6"
									data-name="Path 742"
								/>
								<path
									d="M294.4 30.9c0 .3 0 .5-.2.5s-.3-.2-.3-.5.1-.6.3-.6.2.2.2.6"
									data-name="Path 743"
								/>
							</g>
						</g>
					</g>
				</svg>
			</span>
			<div
				className={classNames(
					'relative h-1 w-full',
					bottomRowBg
				)}></div>
		</header>
	);
};

export default Header;
