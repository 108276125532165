import { translate } from '@lang/index';
import placeholderVideo from '@assets/images/placeholder-video.jpg';
// Components
import DropdownMenuColumn from '@components/table-columns/DropdownMenuColumn';
import { Link } from 'react-router-dom';
import Badges from '@components/partials/Badges';
import DateTimeColumn from '@components/table-columns/DateTimeColumn';

interface VideoRowProps {
	video: SaVideosRes;
	selectRow: (video: SaVideosRes, action: 'delete') => void;
}

const VideoRow: React.FC<VideoRowProps> = ({ video, selectRow }) => {
	return (
		<tr>
			<td className="td-default">
				<Link
					to={`/videos/${video.id}`}
					className="flex items-center text-sm font-semibold hover:underline">
					<div className="relative mr-2.5 w-24 after:block after:pt-[56%]">
						<div className="absolute inset-0">
							<img
								src={video.thumbnail || placeholderVideo}
								className="block h-full w-full object-cover"
								alt={video.title}
								loading="lazy"
							/>
						</div>
					</div>
					{video.title}
				</Link>
			</td>
			<td className="td-default">{video.ref}</td>
			<td className="td-default">
				{video.synced_at ? (
					<Badges theme="green">{translate('synced')}</Badges>
				) : (
					<Badges theme="red">{translate('not_synced')}</Badges>
				)}
			</td>
			<td className="td-default">
				{video.synced_at ? (
					<DateTimeColumn date={video.synced_at} />
				) : (
					<span>-</span>
				)}
			</td>
			<td className="td-default">
				<DateTimeColumn date={video.updated_at} />
			</td>
			<DropdownMenuColumn
				items={[
					{
						label: translate('edit'),
						type: 'link',
						href: `/videos/${video.id}`,
						show: true,
					},
					{
						label: translate('delete'),
						type: 'button',
						show: true,
						onClick: () => selectRow(video, 'delete'),
					},
				]}
			/>
		</tr>
	);
};

export default VideoRow;
