import { translate } from '@lang/index';
import {
	faUser,
	faBook,
	faBarsProgress,
	faClipboardQuestion,
	faBuildingNgo,
	faRectangleList,
	faSliders,
	faVideo,
	faAward,
	faFolderClosed,
	faSquarePollVertical,
	faUserGear,
} from '@fortawesome/free-solid-svg-icons';
// Groups
import NavigationLink from '@components/groups/navigation/NavigationLink';
import ListWrapper from '@components/groups/navigation/ListWrapper';
// Components
import SubscriptionsLink from '@components/groups/navigation/SubscriptionsLink';

interface SuperAdminListProps {
	setNavOpen?: (open: boolean) => void;
}

const SuperAdminList: React.FC<SuperAdminListProps> = ({ setNavOpen }) => {
	// --------------------------------------
	// Render
	return (
		<ListWrapper>
			<NavigationLink
				to="/progress"
				label={translate('progress')}
				icon={faBarsProgress}
				permission={true}
				setNavOpen={setNavOpen}
				openDropdown={true}
				items={[
					{
						permission: true,
						component: (
							<NavigationLink
								to="/video-views"
								label={translate('video_views')}
								icon={faVideo}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
					{
						permission: true,
						component: (
							<NavigationLink
								to="/assessment-results"
								label={translate('assessment_results')}
								icon={faSquarePollVertical}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
				]}
			/>
			<NavigationLink
				to="/organisations"
				label={translate('organisations')}
				icon={faBuildingNgo}
				permission={true}
				setNavOpen={setNavOpen}
			/>
			<NavigationLink
				label={translate('video_library')}
				icon={faBook}
				permission={true}
				mode="text"
				setNavOpen={setNavOpen}
				items={[
					{
						permission: true,
						component: (
							<NavigationLink
								to="/videos"
								label={translate('videos')}
								icon={faVideo}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
					{
						permission: true,
						component: (
							<NavigationLink
								to={`/videos/types`}
								label={translate('video_types')}
								icon={faRectangleList}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
					{
						permission: true,
						component: (
							<NavigationLink
								to={`/subjects`}
								label={translate('subjects')}
								icon={faBook}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
				]}
			/>
			<NavigationLink
				to="/courses"
				label={translate('courses_and_content')}
				icon={faBook}
				permission={true}
				setNavOpen={setNavOpen}
				items={[
					{
						permission: true,
						component: (
							<NavigationLink
								to={`/courses/categories`}
								label={translate('course_categories')}
								icon={faRectangleList}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
					{
						permission: true,
						component: (
							<NavigationLink
								to="/assessments"
								label={translate('assessments')}
								icon={faClipboardQuestion}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
					{
						permission: true,
						component: (
							<NavigationLink
								to={`/assessments/types`}
								label={translate('assessment_types')}
								icon={faRectangleList}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
					// {
					// 	permission: true,
					// 	component: (
					// 		<NavigationLink
					// 			to={`/courses/categories`}
					// 			label={translate('categories')}
					// 			icon={faRectangleList}
					// 			permission={true}
					// 			isChild={true}
					// 		/>
					// 	),
					// },
				]}
			/>
			{/* <NavigationLink
				to="/assessments"
				label={translate("assessments")}
				icon={faClipboardQuestion}
				permission={true}
				items={[
					{
						permission: true,
						component: (
							<NavigationLink
								to={`/assessments/types`}
								label={translate('types')}
								icon={faRectangleList}
								permission={true}
								isChild={true}
							/>
						),
					},
				]}
			/>
			<NavigationLink
				to="/videos"
				label={translate('videos')}
				icon={faVideo}
				permission={true}
				items={[
					{
						permission: true,
						component: (
							<NavigationLink
								to={`/videos/types`}
								label={translate('types')}
								icon={faRectangleList}
								permission={true}
								isChild={true}
							/>
						),
					},
				]}
			/> */}
			<NavigationLink
				to="/users"
				label={translate('users')}
				icon={faUser}
				permission={true}
				setNavOpen={setNavOpen}
				items={[
					{
						permission: true,
						component: (
							<NavigationLink
								to={`/logins`}
								label={translate('logins')}
								icon={faFolderClosed}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
					{
						permission: true,
						component: (
							<NavigationLink
								to="/user-awards"
								label={translate('awards')}
								icon={faAward}
								permission={true}
								isChild={true}
								setNavOpen={setNavOpen}
							/>
						),
					},
				]}
			/>
			<NavigationLink
				to={`/awards`}
				label={translate('awards')}
				icon={faAward}
				permission={true}
				setNavOpen={setNavOpen}
			/>
			<NavigationLink
				to={`/plans`}
				label={translate('plans')}
				icon={faRectangleList}
				permission={true}
				setNavOpen={setNavOpen}
			/>
			<NavigationLink
				to={`/options`}
				label={translate('options')}
				icon={faSliders}
				permission={true}
				setNavOpen={setNavOpen}
			/>
			<NavigationLink
				to={`/account`}
				label={translate('my_account')}
				icon={faUserGear}
				permission={true}
				setNavOpen={setNavOpen}
			/>
			<SubscriptionsLink urlMode={'superadmin'} />
		</ListWrapper>
	);
};

export default SuperAdminList;
