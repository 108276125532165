import { translate } from '@lang/index';
import C from '@root/constants';
import { useState, useMemo } from 'react';
import packageJson from '@root/../package.json';
import { faHome, faGear } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useOrganisation, usePermissions } from '@hooks/index';
// Components
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Preloader from '@components/partials/Preloader';
import AccountDetailsBar from '@components/partials/AccountDetailsBar';
import LogOut from '@components/partials/LogOut';
import CustomerSupportLinks from '@components/partials/CustomerSupportLinks';
import UpsellButton from '@components/partials/UpsellButton';
// Groups
import NavigationLink from '@components/groups/navigation/NavigationLink';
// Queries
import { useAuthenticatedUser } from '@queries/user';
// Lists
import ListWrapper from '@components/groups/navigation/ListWrapper';
import SuperAdminList from '@components/groups/navigation/lists/SuperAdminList';
import AdminList from '@components/groups/navigation/lists/AdminList';
import StandardList from '@components/groups/navigation/lists/StandardList';
import UsersList from '@components/groups/navigation/lists/UserList';
import classNames from 'classnames';

interface NavigationBarProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

const Navigation: React.FC<NavigationBarProps> = ({ open, setOpen }) => {
	// --------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const [showPreloader, setShowPreloader] = useState<boolean>(true);
	const {
		viewCourses,
		manageNotes,
		manageOrganisation,
		manageTeams,
		manageUsers,
		organisationAccess,
		hasSuperAdminPermission,
		manageOrganisationWithPlan,
	} = usePermissions();

	// --------------------------------------
	// Queries / Mutations
	const user = useAuthenticatedUser({
		onSuccess: () => {
			if (showPreloader) {
				setTimeout(() => {
					setShowPreloader(false);
				}, 100);
			}
		},
	});
	const organisationRes = useQuery(
		['organisations.current.get', organisation],
		() => {
			return api.organisations.current.get({
				id: organisation?.id as number,
				include: {
					media: true,
					mediaCount: true,
					plan: true,
					planCount: true,
					meta: true,
					usersCount: true,
					seatedUsersCount: true,
				},
			});
		},
		{
			enabled: !!organisation?.id,
		}
	);

	const status = useQuery(['root.status'], () => {
		return api.root.status();
	});

	// --------------------------------------
	// Memos
	const viewSuperAdmin = useMemo(() => {
		return hasSuperAdminPermission(
			user.data?.data.data.is_super_admin || false
		);
	}, [user, hasSuperAdminPermission]);

	// --------------------------------------
	// Render
	return (
		<>
			{showPreloader && <Preloader />}
			<div
				className={classNames(
					'fixed top-[75px] bottom-0 z-50 w-[300px] border-r border-border bg-white transition-all duration-200 lg:left-0',
					{
						'left-[-300px]': !open,
						'left-0': open,
					}
				)}>
				<div className="flex h-full w-full flex-col justify-between overflow-y-auto">
					<nav className="w-full">
						{/* Org Details */}
						<AccountDetailsBar
							organisationRes={organisationRes.data?.data.data}
						/>

						{/* User Details */}
						{(user.data?.data.data.name ||
							user.data?.data.data.email) && (
							<div className="w-full p-4 pb-0">
								<h3 className="border-b pb-4">
									<span className="text-sm text-body">
										{translate('welcome_back')},
									</span>
									<br></br>
									<Link
										className="group flex items-center justify-between"
										to={'/account'}>
										<span className="text-base font-semibold text-title transition-colors duration-200 group-hover:text-brandTertiary">
											{user.data?.data.data.name ||
												user.data?.data.data.email}
										</span>
										<FontAwesomeIcon
											icon={faGear}
											className="ml-2.5 text-xs text-current text-body transition-colors duration-200 group-hover:text-brandTertiary"
										/>
									</Link>
								</h3>
							</div>
						)}

						{/* Nav */}
						<ul className="p-4">
							{/* Shared */}
							<ListWrapper>
								<NavigationLink
									to="/"
									label={translate('dashboard')}
									icon={faHome}
									permission={true}
									setNavOpen={setOpen}
								/>
							</ListWrapper>

							{/* Super Admin */}
							{viewSuperAdmin && (
								<SuperAdminList setNavOpen={setOpen} />
							)}

							{/* Org Admin */}
							<AdminList
								permissions={{
									manageOrganisation: manageOrganisation,
									manageUsers: manageUsers,
									manageTeams: manageTeams,
								}}
								setNavOpen={setOpen}
							/>

							{/* Org User */}
							<StandardList
								permissions={{
									viewCourses: viewCourses,
									manageNotes: manageNotes,
								}}
								setNavOpen={setOpen}
							/>

							{/* Shared user/admin */}
							{!viewSuperAdmin && (
								<UsersList
									permissions={{
										organisationAccess: organisationAccess,
										viewCourses: viewCourses,
										manageNotes: manageNotes,
										manageOrganisation: manageOrganisation,
										manageOrganisationWithPlan:
											manageOrganisationWithPlan,
									}}
									setNavOpen={setOpen}
								/>
							)}
						</ul>
					</nav>
					<div className="mb-5 mt-10 w-full px-5">
						{/* Support Links */}
						{user.data?.data.data && (
							<CustomerSupportLinks user={user.data.data.data} />
						)}
						<div className="mt-5">
							<LogOut setNavOpen={setOpen} />
							<UpsellButton classes='mt-2.5' pulse={true}/>
						</div>
						<div className="mt-2.5 flex text-xs text-body">
							<span className="mr-2">
								APP <b>v{packageJson.version}</b>
							</span>
							<span>
								API <b>{status.data?.data.version}</b>
							</span>
						</div>

						{(status.data?.data.env !== 'production' ||
							C.FORCE_TEST_ENV_MESSAGE) && (
							<div className="mt-5 w-full rounded-md bg-red p-5">
								<div className="text-xs text-body">
									<h3 className="mb-2 font-bold capitalize text-white">
										Test Environment
									</h3>
									<p className="text-xs text-white">
										Any data/progress created in this
										environment may be lost.
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{
				// Overlay
				open && (
					<div
						className="fixed top-0 left-0 right-0 bottom-0 z-40 bg-black bg-opacity-50 lg:hidden"
						onClick={() => setOpen(!open)}
					/>
				)
			}
		</>
	);
};

export default Navigation;
