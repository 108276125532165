import T from '@lang/index';
// Components
import Modal from '@components/modal/Modal';
// Actions
import CreateOrgTeamAction from '@components/actions/CreateOrgTeam';

interface CreateOrgTeamProps {
	open: boolean;
	setOpen: (state: boolean) => void;
	organisationSlug: string;
	organisationId?: number;
}

const CreateOrgTeam: React.FC<CreateOrgTeamProps> = ({
	open,
	setOpen,
	organisationSlug,
	organisationId,
}) => {
	return (
		<Modal
			open={open}
			setOpen={setOpen}
			title={T.modals.create_team.title}
			description={T.modals.create_team.description}>
			<CreateOrgTeamAction
				organisationSlug={organisationSlug}
				organisationId={organisationId}
				callback={(success) => {
					if (success) {
						setOpen(false);
					}
				}}
			/>
		</Modal>
	);
};

export default CreateOrgTeam;
