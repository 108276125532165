import { translate } from '@lang/index';
import { useEffect, useState } from 'react';
// Components
import Badges from '@components/partials/Badges';
import Table from '@components/table/Table';
import DynamicBlock from '@components/blocks/DynamicBlock';

interface UserStatsVideoViewsProps {
	videoViews: VideosViewsRes[];
}

const UserStatsVideoViews: React.FC<UserStatsVideoViewsProps> = ({
	videoViews,
}) => {
	// -------------------------------------------------
	// Functions
	const buildVideoViewObjects = () => {
		const newVideoViewsCount: {
			id: number;
			title: string;
			views: number;
		}[] = [];

		videoViews.forEach((view) => {
			if (!view.video) return;
			const video = newVideoViewsCount.find(
				(v) => v.id === view.video_id
			);
			if (video) {
				video.views += 1;
			} else {
				newVideoViewsCount.push({
					id: view.video_id,
					title: view.video.title,
					views: 1,
				});
			}
		});

		return newVideoViewsCount;
	};

	// -------------------------------------------------
	// State / Hooks
	const [videoViewsCount] = useState(buildVideoViewObjects());

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		buildVideoViewObjects();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoViews]);

	// -------------------------------------------------
	// Queries / Mutations
	return (
		<DynamicBlock
			state={{
				loading: false,
				error: false,
				noData: videoViewsCount.length === 0,
			}}
			noData={{
				title: translate('no_data_title', {
					name: 'Video View',
				}),
				message: translate('no_data_message', {
					name: 'video view',
				}),
			}}>
			<Table
				head={[
					{
						label: translate('name'),
						type: 'standard',
					},
					{
						label: translate('views'),
						type: 'standard',
						style: 'text-center justify-center',
					},
				]}>
				{videoViewsCount.map((videoView, index) => (
					<tr key={index}>
						<td className="td-default">{videoView.title}</td>
						<td className="td-default text-center">
							<Badges theme="green">{videoView.views}</Badges>
						</td>
					</tr>
				))}
			</Table>
		</DynamicBlock>
	);
};

export default UserStatsVideoViews;
