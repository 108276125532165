interface DataListProps {
	className?: string;
	items: Array<{
		label: string;
		children: React.ReactNode;
	}>;
}

const DataList: React.FC<DataListProps> = ({ className, items }) => {
	return (
		<ul className={className || ''}>
			{items.map((item, index) => (
				<li
					key={index}
					className="flex items-center justify-between flex-wrap border-b last:border-b-0 pb-2 mb-2 last:mb-0 last:pb-0 text-sm">
					<span className="mr-2.5 text-sm font-medium">
						{item.label}
					</span>{' '}
					{item.children}
				</li>
			))}
		</ul>
	);
};

export default DataList;
