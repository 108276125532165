import { translate } from '@lang/index';
import { useQuery } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Filter from '@components/query/Filter';
import UserCertificateTable from '@components/table/user/UserCertificateTable';
import Loading from '@components/partials/Loading';
import Error from '@components/partials/Error';
// Queries
import { useAuthenticatedUser } from '@queries/user';

const OrganisationMyAwardsList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			perPage: 10,
			sort: [
				{
					key: 'created_at',
					value: 'desc',
				},
			],
			filter: [
				{
					key: 'award.award_type_id',
					value: '',
					type: 'select',
				},
			],
		});

	// -------------------------------------------------
	// Queries / Mutations
	const awardTypes = useQuery(
		['auth.awards.getAwardTypes'],
		api.auth.awards.getAwardTypes
	);
	const user = useAuthenticatedUser();

	// -----------------------------------------
	// Render
	if (user.isLoading) return <Loading type="page" />;
	if (user.isError) return <Error type="page" />;

	return (
		<>
			<PageHeading
				title={translate('awards')}
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'award.award_type_id',
								label: translate('award_type'),
								values: awardTypes.data?.data.data
									.filter((type) => {
										return type.name !== 'badge';
									})
									.map((type) => {
										return {
											label: type.name as string,
											value: type.id.toString(),
										};
									}),
								allowAll: true,
							},
						]}
						loading={awardTypes.isLoading}
					/>
				}
			/>
			<PageWrapper>
				<UserCertificateTable
					enabled={enabled}
					queryString={queryString}
					searchParams={searchParams}
					resetFilters={resetFilters}
					user={user.data?.data.data}
				/>
			</PageWrapper>
		</>
	);
};

export default OrganisationMyAwardsList;
