import { translate } from '@lang/index';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
// API
import api from 'api';
// Hooks
import { useSearchParams } from '@hooks/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Breadcrumbs from '@components/partials/Breadcrumbs';
import HeadingTabs from '@components/partials/HeadingTabs';
import Error from '@components/partials/Error';
import Loading from '@components/partials/Loading';
import Sort from '@components/query/Sort';
import Search from '@components/query/Search';
import Button from '@components/partials/Button';
// Actions
import UpdateOrganisationCoursesAction from '@components/actions/UpdateOrganisationCourses';
import UtilityRow from '@components/blocks/UtilityRow';
// Modals
import InviteOrgUserSA from '@components/modal/InviteOrgUserSA';

const OrganisationCourses = () => {
	// -------------------------------------------------
	// State
	const { id } = useParams();
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 'title',
			perPage: -1,
			sort: [
				{
					key: 'title',
					value: 'off',
				},
			],
			filter: [],
		});
	const [changesMade, setChangesMade] = useState(false);
	const [openInviteUser, setOpenInviteUser] = useState(false);
	const navigate = useNavigate();

	// -------------------------------------------------
	// Queries / Mutations
	const organisation = useQuery(
		['sa.organisations.getSingle', id],
		() => {
			return api.sa.organisations.getSingle({
				id: id as string,
				include: {
					courses: true,
					coursesCount: false,
					media: false,
					mediaCount: false,
					plan: false,
					planCount: false,
					partnerCourseCategories: false,
					meta: false,
				},
			});
		},
		{
			enabled: !!id,
		}
	);

	// -------------------------------------------------
	// Render
	if (organisation.isLoading) return <Loading type="page" />;
	if (organisation.isError) return <Error type="page" />;

	return (
		<>
			<>
				<PageHeading
					breadcrumbs={
						<Breadcrumbs
							links={[
								{
									href: '/organisations',
									label: translate('organisations'),
								},
								{
									href: `/organisations/${id}`,
									label: translate('edit'),
								},
								{
									href: `/organisations/${id}`,
									label: translate('courses'),
								},
							]}
						/>
					}
					title={
						organisation.data.data.data.name ||
						translate('edit_organisation')
					}>
					<div className="flex flex-wrap justify-between gap-2.5">
						<HeadingTabs
							tabs={[
								{
									name: translate('edit'),
									onClick: () => {
										navigate(`/organisations/${id}`);
									},
									navigationWarning: changesMade,
								},
								{
									name: translate('users'),
									onClick: () => {
										navigate(`/organisations/${id}/users`);
									},
									navigationWarning: changesMade,
								},
								{
									name: translate('courses'),
									href: `/organisations/${id}/courses`,
								},
								{
									name: translate('partner'),
									href: `/organisations/${id}/partnership`,
								},
							]}
						/>
						<div>
							<Button
								type="button"
								theme="primary"
								onClick={() => {
									setOpenInviteUser(true);
								}}>
								{translate('add_user')}
							</Button>
						</div>
					</div>
				</PageHeading>
				<PageWrapper>
					<UtilityRow>
						<div className="flex">
							<Sort
								sort={searchParams.sort.value}
								setSort={searchParams.sort.setValue}
								labels={[
									{
										key: 'title',
										label: translate('title'),
									},
								]}
							/>
						</div>
						<Search
							search={searchParams.search.value}
							setSearch={searchParams.search.setValue}
							placeholder={translate('search_for_course')}
						/>
					</UtilityRow>
					<UpdateOrganisationCoursesAction
						organisation={organisation.data.data.data}
						changesMade={changesMade}
						setChangesMade={setChangesMade}
						searchParams={searchParams}
						queryString={queryString}
						enabled={enabled}
						resetFilters={resetFilters}
						callback={(success) => {
							if (success) {
								organisation.refetch();
								setChangesMade(false);
							}
						}}
					/>
					<InviteOrgUserSA
						open={openInviteUser}
						setOpen={setOpenInviteUser}
						organisationId={organisation.data.data.data.id}
					/>
				</PageWrapper>
			</>
		</>
	);
};

export default OrganisationCourses;
