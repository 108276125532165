import { translate } from '@lang/index';
import { useState } from 'react';
// Hooks
import { useSearchParams, useOrganisation } from '@hooks/index';
// Components
import Sort from '@components/query/Sort';
import Search from '@components/query/Search';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Button from '@components/partials/Button';
import OrgTeamsTable from '@components/table/admin/OrgTeamsTable';
// Modals
import InviteOrgUser from '@components/modal/InviteOrgUser';
import CreateOrgTeam from '@components/modal/CreateOrgTeam';

const OrganisationTeamsList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'name',
					value: 'off',
				},
			],
			filter: [],
		});

	// Modal State
	const [openCreate, setOpenCreate] = useState(false);
	const [openInviteUser, setOpenInviteUser] = useState(false);

	// -------------------------------------------------
	// Render
	if (!organisation) return null;

	return (
		<>
			<PageHeading
				title={translate('teams')}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'name',
								label: translate('name'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder={translate('search_for_team')}
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<OrgTeamsTable
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
					utilityBar={
						<div className="flex flex-wrap gap-2.5">
							<Button
								theme={'primary'}
								onClick={() => setOpenCreate(true)}>
								{translate('create_team')}
							</Button>
							<Button
								theme={'primary'}
								onClick={() => setOpenInviteUser(true)}>
								{translate('add_user')}
							</Button>
						</div>
					}
				/>
			</PageWrapper>
			{/* Modals */}
			<CreateOrgTeam
				open={openCreate}
				setOpen={setOpenCreate}
				organisationSlug={organisation.slug}
				organisationId={organisation?.id}
			/>
			{/* Modals */}
			<InviteOrgUser
				organisationId={organisation?.id || 0}
				open={openInviteUser}
				setOpen={setOpenInviteUser}
			/>
		</>
	);
};
export default OrganisationTeamsList;
