import { translate } from '@lang/index';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
// API
import api from 'api';
// Hooks
import { useSearchParams, useOrganisation } from '@hooks/index';
// Components
import Sort from '@components/query/Sort';
import Search from '@components/query/Search';
import PageWrapper from '@components/layout/PageWrapper';
import PageHeading from '@components/blocks/PageHeading';
import Filter from '@components/query/Filter';
import OrgNominatedCourseTable from '@components/table/admin/OrgNominatedCourseTable';

const OrganisationCoursesNominationsList: React.FC = () => {
	// -------------------------------------------------
	// State / Hooks
	const { searchParams, queryString, resetFilters, enabled } =
		useSearchParams({
			page: 1,
			search: '',
			searchKey: 's',
			perPage: 10,
			sort: [
				{
					key: 'title',
					value: 'off',
				},
			],
			filter: [
				{
					key: 'team_id',
					value: '',
					type: 'select',
				},
				{
					key: 'user_id',
					value: '',
					type: 'select',
				},
			],
		});
	const { organisation } = useOrganisation();

	// -------------------------------------------------
	// Queries / Mutations
	const users = useQuery(
		['organisations.users.getMultiple', organisation?.id],
		() => {
			return api.organisations.users.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					lastLogin: false,
					roles: false,
				},
				sorts: {
					name: 'asc',
				},
				perPage: -1,
			});
		},
		{
			enabled: organisation?.id !== undefined,
		}
	);
	const teams = useQuery(
		['organisations.teams.getMultiple', organisation?.id],
		() => {
			return api.organisations.teams.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					courses: false,
					coursesCount: false,
					users: false,
					usersCount: false,
				},
				sort: {
					name: 'asc',
				},
				perPage: -1,
			});
		},
		{
			enabled: organisation?.id !== undefined,
		}
	);

	// -------------------------------------------------
	// Memos
	const isLoading = useMemo(() => {
		return users.isLoading || teams.isLoading;
	}, [users.isLoading, teams.isLoading]);

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('course_nominations')}
				body=""
				tooltip=""
				filter={
					<Filter
						filter={searchParams.filter.value}
						setFilter={searchParams.filter.setValue}
						resetFilters={resetFilters}
						labels={[
							{
								key: 'team_id',
								label: translate('teams'),
								values: teams.data?.data.data.map(
									(team: any) => {
										return {
											label: team.name,
											value: team.id.toString(),
										};
									}
								),
								allowAll: true,
							},
							{
								key: 'user_id',
								label: translate('users'),
								values: users.data?.data.data.map(
									(user: any) => {
										return {
											label: user.name,
											value: user.id.toString(),
										};
									}
								),
								allowAll: true,
							},
						]}
						loading={isLoading}
					/>
				}
				sort={
					<Sort
						sort={searchParams.sort.value}
						setSort={searchParams.sort.setValue}
						labels={[
							{
								key: 'title',
								label: translate('title'),
							},
						]}
					/>
				}
				search={
					<Search
						search={searchParams.search.value}
						setSearch={searchParams.search.setValue}
						placeholder="Search for a course"
						width="full"
					/>
				}
			/>
			<PageWrapper>
				<OrgNominatedCourseTable
					enabled={enabled}
					searchParams={searchParams}
					queryString={queryString}
					resetFilters={resetFilters}
				/>
			</PageWrapper>
		</>
	);
};
export default OrganisationCoursesNominationsList;
