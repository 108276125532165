import { translate } from '@lang/index';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
// API
import api from 'api';
// Hooks
import { useOrganisation } from '@hooks/index';
// Components
import Date from '@components/partials/Date';
import Textarea from '@components/forms/Textarea';
import Badges from '@components/partials/Badges';
import Button from '@components/partials/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface NoteRowProps {
	note?: NotesRes;
	hideHeading?: boolean;
	create?: boolean;
	courseId?: number;
	noteableType?: 'quiz' | 'video';
	noteableId?: number;
}

const NoteRow: React.FC<NoteRowProps> = ({
	note,
	hideHeading = false,
	create = false,
	courseId,
	noteableType,
	noteableId,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { organisation } = useOrganisation();
	const queryClient = useQueryClient();
	const [noteValue, setNoteValue] = useState<string>(note?.note || '');
	const [errors, setErrors] = useState<{ [key: string]: string[] }>();
	const [ignoreNoteUpdate, setIgnoreNoteUpdate] = useState<boolean>(true);

	const type = useMemo(() => {
		if (note?.noteable_type === 'quiz') return 'assessment';
		else return note?.noteable_type;
	}, [note?.noteable_type]);

	const link = `/${organisation?.slug}/courses/${note?.course?.id}/${type}/${note?.noteable_id}`;

	// -------------------------------------------------
	// Mutation
	const updateNote = useMutation(api.organisations.notes.updateSingle, {
		onSuccess: () => {
			queryClient.invalidateQueries(['organisations.notes.getMultiple']);
			queryClient.invalidateQueries([
				'organisations.notes.getSingle.quiz',
			]);
			queryClient.invalidateQueries([
				'organisations.notes.getSingle.video',
			]);
		},
		onError: (error: AxiosAPIError) => {
			if (error.response?.data.errors) {
				const errors = error.response.data.errors;
				setErrors(errors);
			}
		},
	});
	const createNote = useMutation(api.organisations.notes.createSingle, {
		onSuccess: () => {
			queryClient.invalidateQueries(['organisations.notes.getMultiple']);
			queryClient.invalidateQueries([
				'organisations.notes.getSingle.quiz',
			]);
			queryClient.invalidateQueries([
				'organisations.notes.getSingle.video',
			]);
		},
		onError: (error: AxiosAPIError) => {
			if (error.response?.data.errors) {
				const errors = error.response.data.errors;
				setErrors(errors);
			}
		},
	});

	// -------------------------------------------------
	// Functions
	const updateNoteHandler = () => {
		if (create && noteableId && noteableType) {
			createNote.mutate({
				organisationId: organisation?.id || 0,
				body: {
					note: noteValue,
					course_id: courseId,
					noteable_type: noteableType,
					noteable_id: noteableId,
				},
			});
		}
		if (note && !create) {
			updateNote.mutate({
				organisationId: organisation?.id || 0,
				noteableId: note?.id,
				body: {
					note: noteValue,
				},
			});
		}
	};

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		const timer = setTimeout(() => {
			if (ignoreNoteUpdate) return;
			updateNoteHandler();
		}, 500);
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [noteValue]);

	useEffect(() => {
		if (ignoreNoteUpdate) setIgnoreNoteUpdate(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// -------------------------------------------------
	// Render
	return (
		<li className="mb-2.5 flex w-full flex-col rounded-md border border-border bg-uiLight p-2.5 last:mb-0">
			{note !== undefined && !hideHeading && (
				<div className="mb-2.5 flex justify-between border-b border-border pb-2.5">
					<div>
						<Link to={link} className="hover:underline">
							<h3 className="mb-1 text-base font-semibold text-title">
								{note.noteable?.title}
							</h3>
						</Link>
						<Badges theme="green">{note.noteable_type}</Badges>
					</div>
					<Button theme="icon" type="link" href={link}>
						<FontAwesomeIcon
							icon={faChevronRight}
							className="text-sm"
						/>
					</Button>
				</div>
			)}
			<div>
				<Textarea
					id="note"
					name="note"
					value={noteValue}
					setValue={setNoteValue}
					errors={errors ? errors['note'] || [] : []}
					required={false}
				/>
			</div>
			{note !== undefined && (
				<div className="mt-2.5 flex justify-between">
					<span className="text-sm text-body">
						{translate('updated')}: <Date date={note.updated_at} />
					</span>
					<div>
						{updateNote.isLoading && (
							<span className="text-sm text-emerald-500">
								{translate('saving')}
							</span>
						)}
						{!updateNote.isLoading &&
							note.note !== noteValue.trim() && (
								<span className="text-sm text-body">
									{translate('modified')}
								</span>
							)}
					</div>
				</div>
			)}
		</li>
	);
};

export default NoteRow;
