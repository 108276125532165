import { translate } from '@lang/index';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// api
import api from 'api';
// Hooks
import { useFormState, useToast } from '@hooks/index';
// Util
import { returnModifiedData, helpers } from '@utils/index';
// Components
import Form from '@components/forms/Form';
import Checkbox from '@components/forms/Checkbox';
import DynamicAction from '@components/blocks/DynamicAction';
import NoData from '@components/partials/NoData';

interface UpdateOrgUserTeamsActionProps extends ActionProps {
	user: UserRes;
	mode: 'modal' | 'page';
	teams: TeamRes[];
	organisationId: number;
}

const UpdateOrgUserTeamsAction: React.FC<UpdateOrgUserTeamsActionProps> = ({
	user,
	organisationId,
	mode,
	teams,
	callback,
}) => {
	// -------------------------------------------------
	// State
	const formState = useFormState();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const [lock, setLock] = useState<boolean>(false);
	// Inputs
	const [selectedTeams, setSelectedTeams] = useState<number[]>([]);

	// -------------------------------------------------
	// Queries / Mutations
	const updateUser = useMutation(api.organisations.users.updateSingle, {
		onSuccess: () => {
			addToast({
				title: translate('toast_updated_title', {
					name: 'Organisation',
				}),
				message: translate('toast_updated_message', {
					name: 'Organisation',
				}),
				type: 'success',
			});
			queryClient.invalidateQueries(['organisations.users.getSingle']);
			callback(true);
		},
		onError: (error: AxiosAPIError) => {
			helpers.mutationErrorHandler(error, formState);
			callback(false);
		},
	});

	// -------------------------------------------------
	// Functions
	const onSubmit = async () => {
		await updateUser.mutateAsync({
			id: user.id,
			organisationId: organisationId,
			body: returnModifiedData([
				{
					key: 'team_ids',
					state: selectedTeams.map((id) => id),
					defaultState: user.teams?.map((team) => team.id),
				},
			]),
		});
	};
	const resetForm = () => {
		formState.errorMessage.setErrorMessage('');
		formState.errors.setErrors({});
		updateUser.reset();
		setSelectedTeams(user.teams?.map((team) => team.id) || []);
	};

	// -------------------------------------------------
	// Effects
	useEffect(() => {
		if (!lock) {
			setSelectedTeams(user.teams?.map((team) => team.id) || []);
			setLock(true);
		}
	}, [lock, user, setLock]);

	// -------------------------------------------------
	// Render

	return (
		<Form onSubmit={onSubmit} state={formState} validation={true}>
			<DynamicAction
				mode={mode}
				state={{
					title: translate('teams'),
					isLoading: updateUser.isLoading,
					isLoadingMessage: translate('loading'),
					isError: updateUser.isError,
					isErrorMessage: formState.errorMessage.value,
					resetBtn: translate('reset'),
					actionBtn: translate('update'),
					resetCallback: resetForm,
				}}>
				{teams.length === 0 ? (
					<NoData
						title={translate('no_data_title', {
							name: 'Team',
						})}
						message={translate('no_user_teams_message')}
					/>
				) : null}
				{teams.map((team: TeamRes) => {
					return (
						<Checkbox
							key={team.id}
							label={team.name}
							name={`team-${team.id}`}
							checked={selectedTeams.some((id) => id === team.id)}
							setChecked={() => {
								if (
									selectedTeams.some((id) => id === team.id)
								) {
									setSelectedTeams(
										selectedTeams.filter(
											(id) => id !== team.id
										)
									);
								} else {
									setSelectedTeams([
										...selectedTeams,
										team.id,
									]);
								}
							}}
							errors={[]}
						/>
					);
				})}
			</DynamicAction>
		</Form>
	);
};

export default UpdateOrgUserTeamsAction;
