import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import classNames from 'classnames';
// Utils
import { helpers } from '@utils/index';
// Types
import { NestedItem } from '@utils/helpers';
// Assets
import placeholderCourseImg from '@assets/images/placeholder-course.jpg';
// Components
import CourseBreakdownIcons from '@components/partials/CourseBreakdownIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, Transition } from '@headlessui/react';
import BookmarkButtonAction from '@components/actions/BookmarkButton';
import AccessDates from '@components/partials/AccessDates';

interface VideoLibraryCardProps {
	content: CourseRes;
	organisationSlug: string;
	bookmarks?: BookmarkesRes[];
	categories: CategoriesRes[];
	courseProgress: OrgCourseProgressRes[];

	chapterModal: {
		open: boolean;
		setOpen: (state: boolean) => void;
	};
	setSelectedNestedItem: (item: NestedItem) => void;
	setSelectedContentId: (id: number) => void;
	functions?: {
		refetchBookmarks?: () => void;
	};
}

const VideoLibraryCard: React.FC<VideoLibraryCardProps> = ({
	content,
	categories,
	chapterModal,
	courseProgress,
	bookmarks,
	setSelectedNestedItem,
	setSelectedContentId,
	functions,
}) => {
	// -------------------------------------------------
	// State / Hooks
	const { refetchBookmarks } = functions || {};

	// -------------------------------------------------
	// Memos
	const itemTree = useMemo(() => {
		return helpers.courseItemTree(content.items || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content]);

	const progress = useMemo(() => {
		return courseProgress.find(
			(progress) => progress.course_id === content.id
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseProgress, content]);

	const percent = useMemo(() => {
		return helpers.convertPercentString(progress?.percentage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [progress]);

	const bookmark = useMemo(() => {
		return bookmarks?.find(
			(b) =>
				b.bookmarkable_id === content.id &&
				b.bookmarkable_type === 'course'
		);
	}, [bookmarks, content.id]);

	const accessInfo = useMemo(() => {
		const org = content.organisations
			? content.organisations[0]
			: undefined;
		return helpers.courseAccessInfo(org);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content.organisations]);

	// -------------------------------------------------
	// Render
	return (
		<Disclosure
			as={'li'}
			className="relative mb-2.5 w-full rounded-md border border-border pr-10 last:mb-0">
			{({ open }) => (
				<>
					{/* Button */}
					<Disclosure.Button
						className="cursor-pointer text-left"
						as="div">
						{/*  Image / Tile */}
						<div className="flex items-center p-2.5">
							{/* Image */}
							<div className="w-28">
								<div className="relative w-full pb-[58%]">
									{/* Image */}
									<div className="absolute inset-0 z-0 overflow-hidden">
										<img
											src={
												content.thumbnail ||
												placeholderCourseImg
											}
											alt={content.title}
											className={
												'h-full w-full scale-100 object-cover transition-transform duration-200'
											}
										/>
									</div>
								</div>
							</div>
							{/* Content */}
							<div className="relative flex w-full flex-col pl-2.5 pr-10">
								<h3 className="mb-1.5 font-bold text-title line-clamp-1">
									{content.title} - {percent}%
								</h3>
								<div className="flex w-full flex-wrap items-center gap-2.5">
									<CourseBreakdownIcons
										size="xs"
										items={content.items || []}
										categories={categories}
									/>
									<div className="flex gap-2.5">
										<AccessDates
											start={accessInfo.start}
											end={accessInfo.end}
										/>
									</div>
								</div>
								{bookmarks && (
									<span className="absolute top-0 right-0 z-10">
										<BookmarkButtonAction
											courseId={content.id}
											bookmarkable_id={content.id}
											bookmark={bookmark}
											type={'course'}
											simplified={true}
											refetch={refetchBookmarks}
										/>
									</span>
								)}
							</div>
						</div>
						{/* Description */}
						<div className="px-2.5 pb-2.5">
							<p className="text-sm line-clamp-2">
								{content.desc}
							</p>
						</div>

						{/* Dropdown btn */}
						<button
							className={
								'absolute right-0 top-0 bottom-0 w-10 bg-uiLight transition-colors duration-200 hover:bg-uiLightGray'
							}>
							<FontAwesomeIcon
								icon={faChevronDown}
								className={classNames(
									'text-sm text-icon transition-transform duration-200',
									{
										'rotate-180': open,
									}
								)}
							/>
						</button>
					</Disclosure.Button>
					{/* Content */}
					<Transition
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0">
						<Disclosure.Panel className="border-t border-border p-2.5">
							<ul className="grid grid-cols-1 gap-2.5 md:grid-cols-2">
								{itemTree.map((item, index) => {
									if (item.item.model_type !== 'chapter')
										return null;

									return (
										<li className="w-full" key={index}>
											<button
												className="group flex w-full items-center"
												onClick={() => {
													setSelectedNestedItem(item);
													setSelectedContentId(
														content.id
													);
													chapterModal.setOpen(true);
												}}>
												<span className="mr-2.5 flex h-7 w-7 min-w-[28px] items-center justify-center rounded-md border border-border bg-uiLight text-sm font-bold">
													{index + 1}
												</span>
												<h4 className="text-left text-sm group-hover:underline">
													{item.item.model.title}
												</h4>
											</button>
										</li>
									);
								})}
							</ul>
						</Disclosure.Panel>
					</Transition>
				</>
			)}
		</Disclosure>
	);
};

export default VideoLibraryCard;
