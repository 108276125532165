// Actions
import BookmarkButtonAction from '@components/actions/BookmarkButton';

interface SingleCourseContentHeaderProps {
	courseId: number;
	mode: 'preview' | 'standard';
	currentItem: CourseItems;
	bookmark?: BookmarkesRes;
	refetch: () => void;
}

const SingleCourseContentHeader: React.FC<SingleCourseContentHeaderProps> = ({
	courseId,
	mode,
	currentItem,
	bookmark,
	refetch,
}) => {
	// -------------------------------------------------
	// Render
	return (
		<div className="mb-5 flex w-full items-start justify-between md:mb-2 md:border-b md:pb-2">
			<h3 className="pr-10 font-semibold">{currentItem.model.title}</h3>
			{mode === 'standard' && currentItem.model_type !== 'chapter' && (
				<BookmarkButtonAction
					courseId={courseId}
					bookmarkable_id={currentItem.model.id}
					bookmark={bookmark}
					type={currentItem.model_type}
					refetch={refetch}
				/>
			)}
		</div>
	);
};

export default SingleCourseContentHeader;
